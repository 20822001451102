export const mask = ({ value, mask, substitute }: { value?: string; mask: string; substitute: string }) => {
  if (!value) {
    return value;
  }
  let lastUnmaskedIndex = -1;
  const result = [...mask].reduce((result, char) => {
    if (lastUnmaskedIndex === value.length - 1) {
      return result;
    }

    if (char === substitute) {
      lastUnmaskedIndex++;

      return result + value[lastUnmaskedIndex];
    }

    return result + char;
  }, '');

  return result;
};

export const unmask = ({
  value,
  mask,
  substitute,
  separators,
}: {
  value: string;
  mask: string;
  substitute: string;
  separators: string[];
}) => {
  let lastUnmaskedIndex = -1;
  const result = [...mask].reduce((result, char) => {
    lastUnmaskedIndex++;

    if (char === substitute && value[lastUnmaskedIndex]) {
      return result + value[lastUnmaskedIndex];
    }

    if (separators.includes(char) && value[lastUnmaskedIndex] && value[lastUnmaskedIndex] !== char) {
      return result + value[lastUnmaskedIndex];
    }

    return result;
  }, '');

  return result;
};
