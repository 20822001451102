import { AnyAction } from 'redux';

import { CreateOfferWatchDto } from '../dtos/offerWatches.dtos';

import { createAction, State, Type } from './utils';

export const offerWatchesGetListRequested = (): AnyAction =>
  createAction(State.actionRequested(Type.OFFER_WATCHES_GET_LIST));
export const offerWatchesGetListSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.OFFER_WATCHES_GET_LIST), payload);

export const offerWatchesCreateRequested = (payload: CreateOfferWatchDto): AnyAction =>
  createAction(State.actionRequested(Type.OFFER_WATCHES_CREATE), payload);
export const offerWatchesCreateSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.OFFER_WATCHES_CREATE), payload);
export const offerWatchesCreateCleared = (): AnyAction => createAction(State.actionCleared(Type.OFFER_WATCHES_CREATE));

export const offerWatchesDeleteRequested = (offerWatchId: string): AnyAction =>
  createAction(State.actionRequested(Type.OFFER_WATCHES_DELETE), { offerWatchId });
export const offerWatchesDeleteSucceeded = (): AnyAction =>
  createAction(State.actionSucceeded(Type.OFFER_WATCHES_DELETE));
export const offerWatchesDeleteCleared = (): AnyAction => createAction(State.actionCleared(Type.OFFER_WATCHES_DELETE));
