import { AnyAction } from 'redux';
import { UpdatePhoneNumberBodyDto } from 'src/dtos';

import { createAction, State, Type } from './utils';

export const signUp = (payload: any): AnyAction => createAction(State.actionRequested(Type.SIGN_UP), payload);

export const clearSignup = (): AnyAction => createAction(State.actionCleared(Type.SIGN_UP));

export const patchUserAddress = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.PATCH_USER_ADDRESS), payload);

export const clearUserAddressPatch = (): AnyAction => createAction(State.actionCleared(Type.PATCH_USER_ADDRESS));

export const toggleMfaConfig = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.TOGGLE_MFA_CONFIG), payload);

export const clearMfaConfigToggle = () => createAction(State.actionCleared(Type.TOGGLE_MFA_CONFIG));

export const login = (payload: any): AnyAction => createAction(State.actionRequested(Type.LOGIN), payload);

export const logout = (): AnyAction => createAction(State.actionRequested(Type.LOGOUT));

export const clearLogin = (): AnyAction => createAction(State.actionCleared(Type.LOGIN));

export const getAuthenticatedUser = (): AnyAction => createAction(State.actionRequested(Type.GET_AUTHENTICATED_USER));

export const confirmMfaCode = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CONFIRM_MFA), payload);

export const clearConfirmMfaCode = (): AnyAction => createAction(State.actionCleared(Type.CONFIRM_MFA));

export const resendMfaCode = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.RESEND_MFA_CODE), payload);

export const clearResendMfaCode = (): AnyAction => createAction(State.actionCleared(Type.RESEND_MFA_CODE));

export const forgotPassword = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.FORGOT_PASSWORD), payload);

export const clearForgotPassword = (): AnyAction => createAction(State.actionCleared(Type.FORGOT_PASSWORD));

export const resetPassword = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.RESET_PASSWORD), payload);

export const doClearUpdateEmail = (): AnyAction => createAction(State.actionCleared(Type.EMAIL_CHANGE));

export const emailUpdateRequested = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.EMAIL_CHANGE), payload);

export const emailUpdateSucceeded = (): AnyAction => createAction(State.actionSucceeded(Type.EMAIL_CHANGE));

export const doClearEmailConfirm = (): AnyAction => createAction(State.actionCleared(Type.EMAIL_CONFIRM));

export const doEmailConfirm = (payload: { code: string; updateId: number }): AnyAction =>
  createAction(State.actionRequested(Type.EMAIL_CONFIRM), payload);

export const doSucceededEmailConfirm = (): AnyAction => createAction(State.actionSucceeded(Type.EMAIL_CONFIRM));

export const doUpdatePhoneNumber = (payload: UpdatePhoneNumberBodyDto): AnyAction =>
  createAction(State.actionRequested(Type.UPDATE_PHONE_NUMBER), payload);

export const doClearUpdatePhoneNumber = (): AnyAction => createAction(State.actionCleared(Type.UPDATE_PHONE_NUMBER));

export const doSucceededUpdatePhoneNumber = (): AnyAction =>
  createAction(State.actionSucceeded(Type.UPDATE_PHONE_NUMBER));

export const doFailedUpdatePhoneNumber = (payload: { message: string }): AnyAction =>
  createAction(State.actionFailed(Type.UPDATE_PHONE_NUMBER), payload);

export const getSignature = (): AnyAction => createAction(State.actionRequested(Type.GET_SIGNATURE));

export const signAccountAgreement = (payload: { signature: string }): AnyAction => {
  return createAction(State.actionRequested(Type.SIGN_ACCOUNT_AGREEMENT), payload);
};
