import { OfferOrderStatusDto } from 'src/dtos';

export namespace OfferOrderConstant {
  export const PENDING_STATUSES = [
    OfferOrderStatusDto.PendingFunds,
    OfferOrderStatusDto.PendingAction,
    OfferOrderStatusDto.PendingOfferClose,
    OfferOrderStatusDto.Approved,
    OfferOrderStatusDto.PendingFirmCancellation,
  ];

  export const CARD_PAYMENT_MAX_AMOUNT = 999999.99;
}
