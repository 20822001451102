import * as Url from '../constants/url';
import {
  GetMonthlyStatementListResponseDto,
  GetTaxDocumentListResponseDto,
  GetTradeConfirmationListResponseDto,
  TaxDocumentForm,
} from '../dtos/statements.dtos';
import { replacePlaceholders } from '../sagas/utils';
import { httpRequest } from '../utils/httpRequest';

export interface BaseAccountStatementParamsDto {
  accountId?: number; // NOTE: temporarily optinal until store state is typed
}

export interface BaseDownloadAccountStatementParamsDto extends BaseAccountStatementParamsDto {
  date: string;
}

export interface GetTradeConfirmationListArgs {
  authToken: string;
  params: BaseAccountStatementParamsDto;
}

export interface DownloadTradeConfirmationArgs {
  authToken: string;
  params: BaseDownloadAccountStatementParamsDto;
}

export interface GetMonthlyStatementListArgs {
  authToken: string;
  params: BaseAccountStatementParamsDto;
}

export interface DownloadMonthlyStatementArgs {
  authToken: string;
  params: BaseDownloadAccountStatementParamsDto;
}

export interface GetTaxDocumentListArgs {
  authToken: string;
  params: BaseAccountStatementParamsDto;
}

export interface DownloadTaxDocumentParamsDto extends BaseDownloadAccountStatementParamsDto {
  form: TaxDocumentForm;
}
export interface DownloadTaxDocumentArgs {
  authToken: string;
  params: DownloadTaxDocumentParamsDto;
}

export class StatementsApi {
  getTradeConfirmationList({
    authToken,
    params,
  }: GetTradeConfirmationListArgs): Promise<GetTradeConfirmationListResponseDto> {
    const { accountId } = params;
    const url = replacePlaceholders(Url.GET_TRADE_CONFIRMATION_LIST_URL, { accountId });

    return httpRequest<GetTradeConfirmationListResponseDto>({ method: 'GET', url, authToken });
  }

  downloadTradeConfirmation({ authToken, params }: DownloadTradeConfirmationArgs): Promise<Blob> {
    const url = replacePlaceholders(Url.DOWNLOAD_TRADE_CONFIRMATION_URL, { ...params });

    return httpRequest<Blob>({
      method: 'GET',
      url,
      authToken,
      headers: { contentType: 'application/octet-stream' },
      responseType: 'blob',
    });
  }

  getMonthlyStatementList({
    authToken,
    params,
  }: GetMonthlyStatementListArgs): Promise<GetMonthlyStatementListResponseDto> {
    const { accountId } = params;
    const url = replacePlaceholders(Url.GET_MONTHLY_STATEMENT_URL, { accountId });

    return httpRequest<GetMonthlyStatementListResponseDto>({ method: 'GET', url, authToken });
  }

  downloadMonthlyStatement({ authToken, params }: DownloadMonthlyStatementArgs): Promise<Blob> {
    const url = replacePlaceholders(Url.DOWNLOAD_MONTHLY_STATEMENT_URL, { ...params });

    return httpRequest<Blob>({
      method: 'GET',
      url,
      authToken,
      headers: { contentType: 'application/octet-stream' },
      responseType: 'blob',
    });
  }

  getTaxDocumentList({ authToken, params }: GetTaxDocumentListArgs): Promise<GetTaxDocumentListResponseDto> {
    const { accountId } = params;
    const url = replacePlaceholders(Url.GET_TAX_DOCUMENTS_URL, { accountId });

    return httpRequest<GetTaxDocumentListResponseDto>({ method: 'GET', url, authToken });
  }

  downloadTaxDocument({ authToken, params }: DownloadTaxDocumentArgs): Promise<Blob> {
    const url = replacePlaceholders(Url.DOWNLOAD_TAX_DOCUMENT_URL, { ...params });

    return httpRequest<Blob>({
      method: 'GET',
      url,
      authToken,
      headers: { contentType: 'application/octet-stream' },
      responseType: 'blob',
    });
  }
}
