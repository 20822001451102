import { css } from '@emotion/css';

import {
  BaseStyle,
  BorderRadius,
  ColorConstant,
  Font,
  FontWeight,
  SpaceSize,
  Spacing,
  TextAlign,
} from '../../../styles';

export const button = ({ isDirty }: { isDirty: boolean }) => css`
  ${Spacing.p8}
  ${TextAlign.center}
  ${BaseStyle.level}
  width: fit-content !important;
  height: ${SpaceSize.is48} !important;
  ${Font.sm}
  ${FontWeight.normal}
  ${BorderRadius.xs}
  border: 1px solid ${isDirty ? ColorConstant.BRAND6 : ColorConstant.GRAY7} !important;
  color: ${isDirty ? ColorConstant.BRAND6 : ColorConstant.GRAY7} !important;

  &:focus {
    color: ${ColorConstant.BRAND6} !important;
    border-color: ${ColorConstant.BRAND6} !important;
  }

  &:hover:not(:focus) {
    color: ${ColorConstant.BRAND6} !important;
    border-color: ${ColorConstant.BRAND6} !important;
  }
`;

export const cardContent = css`
  max-width: 358px;
  min-width: 200px !important;
  width: auto;
  ${Spacing.p16}
  background: ${ColorConstant.GRAY1};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.35);
  ${BorderRadius.xs}
  .ant-card-body {
    ${Spacing.p0}
  }
`;
