import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { MFormRadioGroup } from 'src/lib';
import { OfferDetails } from 'src/models';

import { NewOfferWatchDisclaimer } from './NewOfferWatchDisclaimer';
import * as Styles from './NewOfferWatchModal.styles';

export interface NewOfferWatchFormValues {
  investmentRange: string;
}

interface NewOfferWatchFormProps {
  offer: OfferDetails;
  form: FormikProps<NewOfferWatchFormValues>;
}

export const NewOfferWatchForm = ({ offer, form }: NewOfferWatchFormProps) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <p className={Styles.description}>
            Please complete the form below to indicate your desired investment amount. You will receive updates and
            notifications when the Offer becomes for actual investment.
          </p>
        </Col>
      </Row>
      <Row>
        <>
          <MFormRadioGroup
            label='Investment Range'
            align='vertical'
            defaultValue={offer.investmentRanges[0]}
            options={offer.investmentRanges.map(item => ({ label: item, value: item }))}
            value={form.values.investmentRange}
            onChange={value => form.setFieldValue('investmentRange', value)}
            error={form.errors.investmentRange}
          />
          <NewOfferWatchDisclaimer />
        </>
      </Row>
    </>
  );
};
