import React, { useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

export const TermsOfUse = () => {
  const [, setTitle] = useOutletContext<any>();

  useEffect(() => {
    setTitle('Terms of Use');
  }, []);

  return (
    <div>
      <p>
        Cambria Asset Management, Inc is a privately-held company and is based in Salt Lake City, Utah. Cambria Capital,
        LLC dba My IPO, its wholly owned subsidiary is a securities Broker/Dealer and member FINRA/SIPC. All references
        to “My IPO” or “Cambria” on this web site (this “Site”) refer to Cambria Asset Management, Inc and its wholly
        owned subsidiary, Cambria Capital, LLC. By using this Site, you agree to the following terms and conditions,
        which My IPO or Cambria may modify at any time without prior notice.
      </p>
      <p>
        The information on this website has been published for informational purposes only, and is not a solicitation or
        offer of any security or of any investment service. The content of this website is provided solely for your
        personal use (subject to the limitations described on this web site), and the information may not be copied,
        reproduced, republished, transmitted or distributed in any way, without the express written permission of
        Cambria. Modification or use of the information for any purpose other than your personal use, is a violation of
        the copyrights and other proprietary rights of Cambria.
      </p>
      <p>
        The information provided on this site is not intended for distribution to, or use by, any person or entity in
        any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
        subject My IPO and Cambria or its subsidiaries to any registration requirement within such jurisdiction or
        country. Neither the information, nor any opinion contained in this site, constitutes a solicitation or offer by
        My IPO, Cambria or its affiliates to buy or sell any securities, futures, options or other financial
        instruments. My IPO and Cambria does not intend the information at this web site to be investment advice, and
        the information presented at this site should not be relied upon to make an investment decision. Although the
        information provided to you on this site is obtained or compiled from sources we believe to be reliable, the
        content of this website is provided “as is” without warranty of any kind (either express or implied). My IPO
        does not assume any obligation to update the information and data at this web site and does not guarantee that
        it is accurate, current, valid, complete or suitable for any particular purpose
      </p>
      <p>
        The Web site and its contents are the property of Cambria. Copyright © 2018 Cambria Asset Management, Inc.
        Unless otherwise noted, the information, trademarks, and logos on this web site are the property of Cambria. The
        posting of such material at this web site shall not limit Cambria’s ownership interests in such material. Users
        of this web site may make single copies of information for their own personal use, but are prohibited from
        selling or republishing any portion of this web site without the prior written consent of Cambria.
      </p>
    </div>
  );
};

export default TermsOfUse;
