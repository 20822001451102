import { ErrorCode, ConfirmCardPaymentErrorCause } from '../constants/common.constants';

import { BaseError } from './BaseError';

export class ConfirmCardPaymentError extends BaseError {
  code = ErrorCode.CONFIRM_CARD_PAYMENT;
  cause: ConfirmCardPaymentErrorCause;

  constructor({ message, cause }: { message: string; cause: ConfirmCardPaymentErrorCause }) {
    super(message);
    this.cause = cause;
  }
}
