import Spinner from '../Miscellaneous/Spinner';
import { MModal } from '../MModal/MModal';

interface ExternalOfferDisclaimerModalProps {
  disclaimer?: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export const ExternalOfferDisclaimerModal = ({
  disclaimer,
  isOpen,
  onClose,
  isLoading,
}: ExternalOfferDisclaimerModalProps) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (disclaimer) {
      return disclaimer;
    }

    return (
      <>
        This Offering requires you to send funds directly from an existing bank account to the Offering Escrow Account.
        You will receive an email with instructions on how to complete your purchase. For this offering type, If you
        have funds in your My IPO account they cannot be transferred to the Offering Escrow Account. The securities
        purchased will be held in electronic form at the Transfer Agent of the Issuer. Some brokerage firms may not hold
        these securities or may charge fees to deposit these securities.
      </>
    );
  };

  return (
    <MModal
      title='Escrow Offer Disclaimer'
      customWidth={520}
      visible={isOpen}
      onClose={onClose}
      tertiaryButtonText='OK'
      onTertiaryButtonClick={onClose}>
      <div>{renderContent()}</div>
    </MModal>
  );
};
