import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { offerWatchesCreateCleared, offerWatchesCreateRequested } from 'src/actions';
import { CreateOfferWatchDto, OfferWatchDto, UserDto } from 'src/dtos';
import { MButton } from 'src/lib';
import { MModalV2 } from 'src/lib/MModal/MModalv2';
import { OfferDetails } from 'src/models';
import * as Yup from 'yup';

import { NewOfferWatchForm } from './NewOfferWatchForm';
import { NewOfferWatchSucceeded } from './NewOfferWatchSucceeded';

const newOfferWatchValidationSchema = Yup.object().shape({
  investmentRange: Yup.string().required('Investment Range is required'),
});

export interface NewOfferWatchFormValues {
  investmentRange: string;
}

interface OfferWatchModalProps {
  visible: boolean;
  onClose: () => void;
  offer: OfferDetails;
}

export const NewOfferWatchModal = ({ visible, onClose, offer }: OfferWatchModalProps) => {
  const dispatch = useDispatch();
  const user: UserDto = useSelector((state: any) => state.user.authenticated.data);
  const succeededCreateOfferWatch = useSelector((state: any) =>
    Boolean(state.offerWatches.createOfferWatch?.__succeeded),
  );
  const isLoading: boolean = useSelector((state: any) => state.offerWatches.createOfferWatch?.isLoading);
  const offerWatch: OfferWatchDto = useSelector((state: any) => state.offerWatches.createOfferWatch?.data);

  const [step, setStep] = useState<'new' | 'succeeded'>('new');

  const form = useFormik<NewOfferWatchFormValues>({
    initialValues: {
      investmentRange: offer.investmentRanges[0],
    },
    validationSchema: newOfferWatchValidationSchema,
    onSubmit: values => {
      const dto: CreateOfferWatchDto = {
        userId: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        stateCode: user.address?.state,
        offerId: offer.id,
        investmentRange: values.investmentRange,
      };

      dispatch(offerWatchesCreateRequested(dto));
    },
  });

  const reset = () => {
    setStep('new');
    form.resetForm();
    dispatch(offerWatchesCreateCleared());
  };

  const _onClose = () => {
    onClose();
    reset();
  };

  const renderModal = () => {
    if (step === 'succeeded') {
      return {
        onClose: _onClose,
        body: <NewOfferWatchSucceeded offer={offer} offerWatch={offerWatch} user={user} />,
        footer: <MButton onClick={_onClose}>Close</MButton>,
      };
    }

    return {
      onClose: _onClose,
      body: <NewOfferWatchForm offer={offer} form={form} />,
      footer: (
        <>
          <MButton type='tertiary' onClick={_onClose}>
            Close
          </MButton>
          <MButton type='primary' onClick={form.submitForm} disabled={!form.isValid || isLoading}>
            Indicate Interest
          </MButton>
        </>
      ),
    };
  };

  useEffect(() => {
    if (succeededCreateOfferWatch) {
      setStep('succeeded');
    }
  }, [succeededCreateOfferWatch]);

  return <MModalV2 open={visible} title={'Indicate Interest'} width={725} {...renderModal()} />;
};
