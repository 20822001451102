import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const doGetAccount = (): AnyAction => createAction(State.actionRequested(Type.GET_ACCOUNT), undefined);

export const doSucceededGetAccount = (payload: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_ACCOUNT), payload);

export const doSubmitAccount = (payload: { signature?: string }): AnyAction =>
  createAction(State.actionRequested(Type.SUBMIT_ACCOUNT), payload);

export const doClearSubmitAccount = (): AnyAction => createAction(State.actionCleared(Type.SUBMIT_ACCOUNT));
