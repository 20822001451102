import { ColumnsType } from 'antd/lib/table';
import { LinkText } from 'src/lib';

import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import PercentageField from '../../../../../../lib/Miscellaneous/FormattedFields/PercentageField/PercentageField';
import { OrderPosition } from '../../../../../../models/orders.models';

import * as Styles from './SummaryTable.styles';

const SecurityColumns: ColumnsType<OrderPosition> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: value => <LinkText value={value} />,
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Last Price',
    dataIndex: 'value',
    render: (value: number) => <CurrencyField value={value} />,
  },
  {
    title: 'Current Value',
    dataIndex: 'totalValue',
    render: (totalValue: number) => <CurrencyField value={totalValue} />,
  },
  {
    title: '% Change',
    dataIndex: 'change',
    render: (change: number) => (
      <PercentageField value={change} className={change >= 0 ? Styles.pozitiveValue : Styles.negativeValue} />
    ),
  },
];

export default SecurityColumns;
