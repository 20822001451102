import { ModalSteps } from '../../../../../../models/modal-steps.model';
import { SecuritiesModalStepsEnum } from '../../../../../../models/securities-modal-steps.enum';
import ReadMoreDisclaimer from '../SecuritiesDisclaimer/components/ReadMoreDisclaimer/ReadMoreDisclaimer';
import RestrictedPersonDisclaimer from '../SecuritiesDisclaimer/components/RestrictedPersonDisclaimer/RestrictedPersonDisclaimer';
import ServiceFeeDisclaimer from '../SecuritiesDisclaimer/components/ServiceFeeDisclaimer/ServiceFeeDisclaimer';
import SecuritiesDisclaimer from '../SecuritiesDisclaimer/SecuritiesDisclaimer';

const shouldHideTertiaryButton = ({ isMobile, id }: { isMobile: boolean; id: SecuritiesModalStepsEnum }) =>
  [
    SecuritiesModalStepsEnum.SECURITIES_DETAILS,
    SecuritiesModalStepsEnum.BUY_FORM,
    SecuritiesModalStepsEnum.SELL_FORM,
    SecuritiesModalStepsEnum.REVIEW,
  ].includes(id) && isMobile;

const SecurityModalSteps: ModalSteps[] = [
  {
    id: SecuritiesModalStepsEnum.SECURITIES_DETAILS,
    previousStep: SecuritiesModalStepsEnum.BUY_FORM,
    nextStep: SecuritiesModalStepsEnum.SELL_FORM,
    title: 'Securities Details',
    closable: true,
    isDisclamer: false,
    secondaryButtonText: 'Buy',
    primaryButtonText: 'Sell',
    tertiaryButtonText: 'Close',
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.BUY_FORM,
    nextStep: SecuritiesModalStepsEnum.REVIEW,
    closable: true,
    isDisclamer: false,
    primaryButtonText: 'Review',
    secondaryButtonText: 'Back',
    tertiaryButtonText: 'Close',
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.SELL_FORM,
    nextStep: SecuritiesModalStepsEnum.REVIEW,
    closable: true,
    isDisclamer: false,
    primaryButtonText: 'Review',
    secondaryButtonText: 'Back',
    tertiaryButtonText: 'Close',
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.REVIEW,
    nextStep: SecuritiesModalStepsEnum.SUCCEEDED,
    closable: true,
    isDisclamer: false,
    primaryButtonText: 'Submit',
    secondaryButtonText: 'Back',
    tertiaryButtonText: 'Close',
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.SUCCEEDED,
    closable: false,
    isDisclamer: false,
    tertiaryButtonText: 'Close',
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.INFO_DISCLAIMER_1,
    closable: false,
    tertiaryButtonText: 'Back',
    isDisclamer: true,
    renderChild: () => (
      <SecuritiesDisclaimer title='What is a restricted person?' info={<RestrictedPersonDisclaimer />} />
    ),
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.INFO_DISCLAIMER_2,
    closable: false,
    tertiaryButtonText: 'Back',
    isDisclamer: true,
    renderChild: () => (
      <SecuritiesDisclaimer
        title='Last Price'
        info={
          'Last price fluctuates. The value displayed here may not reflect the most recent trade price for this security.'
        }
      />
    ),
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.INFO_DISCLAIMER_3,
    closable: false,
    tertiaryButtonText: 'Back',
    isDisclamer: true,
    renderChild: () => <SecuritiesDisclaimer title='Commission/Service Fee' info={<ServiceFeeDisclaimer />} />,
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.INFO_DISCLAIMER_4,
    closable: false,
    tertiaryButtonText: 'Back',
    isDisclamer: true,
    renderChild: () => (
      <SecuritiesDisclaimer
        title='Market Price'
        info={
          'Market price fluctuates. The value displayed here may not reflect the most recent trade price for this security.'
        }
      />
    ),
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.INFO_DISCLAIMER_5,
    closable: false,
    tertiaryButtonText: 'Back',
    isDisclamer: true,
    renderChild: () => <SecuritiesDisclaimer title='Commission/Service Fee' info={<ServiceFeeDisclaimer />} />,
  },
  {
    title: '',
    id: SecuritiesModalStepsEnum.INFO_DISCLAIMER_6,
    closable: false,
    tertiaryButtonText: 'Back',
    isDisclamer: true,
    renderChild: () => <SecuritiesDisclaimer info={<ReadMoreDisclaimer />} />,
  },
];

export const getSecuritiesModalSteps = ({
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  isMobile,
}: Partial<ModalSteps> & { isMobile: boolean }): ModalSteps[] => {
  const fullSteps: ModalSteps[] = SecurityModalSteps.map(step => ({
    ...step,
    onClose,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    tertiaryButtonText: shouldHideTertiaryButton({ id: step.id, isMobile }) ? undefined : step.tertiaryButtonText,
  }));

  return fullSteps;
};
