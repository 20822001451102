import React, { PropsWithChildren } from 'react';

import { Col, Row } from 'antd';

import * as Styles from './MFormCheckboxField.styles';

interface MFormRadioGroupFieldProps {
  label: string;
  value?: JSX.Element | string | number;
}

export const MFormCheckboxField = ({ label, value, children }: PropsWithChildren<MFormRadioGroupFieldProps>) => (
  <Row className={Styles.container}>
    <Col span={24} className={Styles.label}>
      {label}
    </Col>
    <Col span={24} className={Styles.value}>
      {value}
    </Col>
    <Col span={24}>{children}</Col>
  </Row>
);
