import { css } from '@emotion/css';

import { SpaceSize } from './variables.styles';

export const Spacing = {
  m0: css({
    margin: '0 !important',
  }),
  m4: css({
    margin: `${SpaceSize.is4}`,
  }),
  m8: css({
    margin: `${SpaceSize.is8}`,
  }),
  m12: css({
    margin: `${SpaceSize.is12}`,
  }),
  m16: css({
    margin: `${SpaceSize.is16}`,
  }),
  m20: css({
    margin: `${SpaceSize.is20}`,
  }),
  m24: css({
    margin: `${SpaceSize.is24}`,
  }),
  m32: css({
    margin: `${SpaceSize.is32}`,
  }),
  m40: css({
    margin: `${SpaceSize.is40}`,
  }),
  m48: css({
    margin: `${SpaceSize.is48}`,
  }),
  m64: css({
    margin: `${SpaceSize.is64}`,
  }),
  m80: css({
    margin: `${SpaceSize.is80}`,
  }),
  m96: css({
    margin: `${SpaceSize.is96}`,
  }),
  m128: css({
    margin: `${SpaceSize.is128}`,
  }),
  m160: css({
    margin: `${SpaceSize.is160}`,
  }),
  mAuto: css({
    margin: 'auto',
  }),
  mt0: css({
    marginTop: 0,
  }),
  mt4: css({
    marginTop: `${SpaceSize.is4}`,
  }),
  mt8: css({
    marginTop: `${SpaceSize.is8}`,
  }),
  mt12: css({
    marginTop: `${SpaceSize.is12}`,
  }),
  mt16: css({
    marginTop: `${SpaceSize.is16}`,
  }),
  mt20: css({
    marginTop: `${SpaceSize.is20}`,
  }),
  mt24: css({
    marginTop: `${SpaceSize.is24}`,
  }),
  mt32: css({
    marginTop: `${SpaceSize.is32}`,
  }),
  mt40: css({
    marginTop: `${SpaceSize.is40}`,
  }),
  mt48: css({
    marginTop: `${SpaceSize.is48}`,
  }),
  mt64: css({
    marginTop: `${SpaceSize.is64}`,
  }),
  mt80: css({
    marginTop: `${SpaceSize.is80}`,
  }),
  mt96: css({
    marginTop: `${SpaceSize.is96}`,
  }),
  mt128: css({
    marginTop: `${SpaceSize.is128}`,
  }),
  mt160: css({
    marginTop: `${SpaceSize.is160}`,
  }),
  mtAuto: css({
    marginTop: 'auto',
  }),
  mr0: css({
    marginRight: 0,
  }),
  mr4: css({
    marginRight: `${SpaceSize.is4}`,
  }),
  mr8: css({
    marginRight: `${SpaceSize.is8}`,
  }),
  mr12: css({
    marginRight: `${SpaceSize.is12}`,
  }),
  mr16: css({
    marginRight: `${SpaceSize.is16}`,
  }),
  mr20: css({
    marginRight: `${SpaceSize.is20}`,
  }),
  mr24: css({
    marginRight: `${SpaceSize.is24}`,
  }),
  mr32: css({
    marginRight: `${SpaceSize.is32}`,
  }),
  mr40: css({
    marginRight: `${SpaceSize.is40}`,
  }),
  mr48: css({
    marginRight: `${SpaceSize.is48}`,
  }),
  mr64: css({
    marginRight: `${SpaceSize.is64}`,
  }),
  mr80: css({
    marginRight: `${SpaceSize.is80}`,
  }),
  mr96: css({
    marginRight: `${SpaceSize.is96}`,
  }),
  mr128: css({
    marginRight: `${SpaceSize.is128}`,
  }),
  mr160: css({
    marginRight: `${SpaceSize.is160}`,
  }),
  mrAuto: css({
    marginRight: 'auto',
  }),
  mb0: css({
    marginBottom: 0,
  }),
  mb4: css({
    marginBottom: `${SpaceSize.is4} !important`,
  }),
  mb8: css({
    marginBottom: `${SpaceSize.is8} !important`,
  }),
  mb12: css({
    marginBottom: `${SpaceSize.is12}`,
  }),
  mb16: css({
    marginBottom: `${SpaceSize.is16}`,
  }),
  mb20: css({
    marginBottom: `${SpaceSize.is20}`,
  }),
  mb24: css({
    marginBottom: `${SpaceSize.is24}`,
  }),
  mb32: css({
    marginBottom: `${SpaceSize.is32}`,
  }),
  mb40: css({
    marginBottom: `${SpaceSize.is40}`,
  }),
  mb48: css({
    marginBottom: `${SpaceSize.is48}`,
  }),
  mb64: css({
    marginBottom: `${SpaceSize.is64}`,
  }),
  mb80: css({
    marginBottom: `${SpaceSize.is80}`,
  }),
  mb96: css({
    marginBottom: `${SpaceSize.is96}`,
  }),
  mb128: css({
    marginBottom: `${SpaceSize.is128}`,
  }),
  mb160: css({
    marginBottom: `${SpaceSize.is160}`,
  }),
  mbAuto: css({
    marginBottom: 'auto',
  }),
  ml0: css({
    marginLeft: '0 !important',
  }),
  ml4: css({
    marginLeft: `${SpaceSize.is4}`,
  }),
  ml8: css({
    marginLeft: `${SpaceSize.is8}`,
  }),
  ml12: css({
    marginLeft: `${SpaceSize.is12}`,
  }),
  ml16: css({
    marginLeft: `${SpaceSize.is4}`,
  }),
  ml20: css({
    marginLeft: `${SpaceSize.is20}`,
  }),
  ml24: css({
    marginLeft: `${SpaceSize.is24}`,
  }),
  ml32: css({
    marginLeft: `${SpaceSize.is32}`,
  }),
  ml40: css({
    marginLeft: `${SpaceSize.is40}`,
  }),
  ml48: css({
    marginLeft: `${SpaceSize.is48}`,
  }),
  ml64: css({
    marginLeft: `${SpaceSize.is64}`,
  }),
  ml80: css({
    marginLeft: `${SpaceSize.is80}`,
  }),
  ml96: css({
    marginLeft: `${SpaceSize.is96}`,
  }),
  ml128: css({
    marginLeft: `${SpaceSize.is128}`,
  }),
  ml160: css({
    marginLeft: `${SpaceSize.is160}`,
  }),
  mlAuto: css({
    marginLeft: 'auto',
  }),
  mx0: css({
    marginLeft: '0 !important',
    marginRight: '0 !important',
  }),
  mx4: css({
    marginLeft: `${SpaceSize.is4}`,
    marginRight: `${SpaceSize.is4}`,
  }),
  mx8: css({
    marginLeft: `${SpaceSize.is8}`,
    marginRight: `${SpaceSize.is8}`,
  }),
  mx12: css({
    marginLeft: `${SpaceSize.is12}`,
    marginRight: `${SpaceSize.is12}`,
  }),
  mx16: css({
    marginLeft: `${SpaceSize.is16}`,
    marginRight: `${SpaceSize.is16}`,
  }),
  mx20: css({
    marginLeft: `${SpaceSize.is20}`,
    marginRight: `${SpaceSize.is20}`,
  }),
  mx24: css({
    marginLeft: `${SpaceSize.is24}`,
    marginRight: `${SpaceSize.is24}`,
  }),
  mx32: css({
    marginLeft: `${SpaceSize.is32}`,
    marginRight: `${SpaceSize.is32}`,
  }),
  mx40: css({
    marginLeft: `${SpaceSize.is40}`,
    marginRight: `${SpaceSize.is40}`,
  }),
  mx48: css({
    marginLeft: `${SpaceSize.is48}`,
    marginRight: `${SpaceSize.is48}`,
  }),
  mx64: css({
    marginLeft: `${SpaceSize.is64}`,
    marginRight: `${SpaceSize.is64}`,
  }),
  mx80: css({
    marginLeft: `${SpaceSize.is80}`,
    marginRight: `${SpaceSize.is80}`,
  }),
  mx96: css({
    marginLeft: `${SpaceSize.is96}`,
    marginRight: `${SpaceSize.is96}`,
  }),
  mx128: css({
    marginLeft: `${SpaceSize.is128}`,
    marginRight: `${SpaceSize.is128}`,
  }),
  mx160: css({
    marginLeft: `${SpaceSize.is160}`,
    marginRight: `${SpaceSize.is160}`,
  }),
  mxAuto: css({
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  my0: css({
    marginTop: 0,
    marginBottom: 0,
  }),
  my4: css({
    marginTop: `${SpaceSize.is4}`,
    marginBottom: `${SpaceSize.is4}`,
  }),
  my8: css({
    marginTop: `${SpaceSize.is8}`,
    marginBottom: `${SpaceSize.is8}`,
  }),
  my12: css({
    marginTop: `${SpaceSize.is12}`,
    marginBottom: `${SpaceSize.is12}`,
  }),
  my16: css({
    marginTop: `${SpaceSize.is16}`,
    marginBottom: `${SpaceSize.is16}`,
  }),
  my20: css({
    marginTop: `${SpaceSize.is20}`,
    marginBottom: `${SpaceSize.is20}`,
  }),
  my24: css({
    marginTop: `${SpaceSize.is24}`,
    marginBottom: `${SpaceSize.is24}`,
  }),
  my32: css({
    marginTop: `${SpaceSize.is32}`,
    marginBottom: `${SpaceSize.is32}`,
  }),
  my40: css({
    marginTop: `${SpaceSize.is40}`,
    marginBottom: `${SpaceSize.is40}`,
  }),
  my48: css({
    marginTop: `${SpaceSize.is48}`,
    marginBottom: `${SpaceSize.is48}`,
  }),
  my64: css({
    marginTop: `${SpaceSize.is64}`,
    marginBottom: `${SpaceSize.is64}`,
  }),
  my80: css({
    marginTop: `${SpaceSize.is80}`,
    marginBottom: `${SpaceSize.is80}`,
  }),
  my96: css({
    marginTop: `${SpaceSize.is96}`,
    marginBottom: `${SpaceSize.is96}`,
  }),
  my128: css({
    marginTop: `${SpaceSize.is128}`,
    marginBottom: `${SpaceSize.is128}`,
  }),
  my160: css({
    marginTop: `${SpaceSize.is160}`,
    marginBottom: `${SpaceSize.is160}`,
  }),
  myAuto: css({
    marginTop: 'auto',
    marginBottom: 'auto',
  }),
  // Padding
  p0: css({
    padding: '0 !important',
  }),
  p4: css({
    padding: `${SpaceSize.is4} !important`,
  }),
  p8: css({
    padding: `${SpaceSize.is8} !important`,
  }),
  p12: css({
    padding: `${SpaceSize.is12}`,
  }),
  p16: css({
    padding: `${SpaceSize.is16}`,
  }),
  p20: css({
    padding: `${SpaceSize.is20}`,
  }),
  p24: css({
    padding: `${SpaceSize.is24}`,
  }),
  p32: css({
    padding: `${SpaceSize.is32}`,
  }),
  p40: css({
    padding: `${SpaceSize.is40}`,
  }),
  p48: css({
    padding: `${SpaceSize.is48}`,
  }),
  p64: css({
    padding: `${SpaceSize.is64}`,
  }),
  p80: css({
    padding: `${SpaceSize.is80}`,
  }),
  p96: css({
    padding: `${SpaceSize.is96}`,
  }),
  p128: css({
    padding: `${SpaceSize.is128}`,
  }),
  pt0: css({
    paddingTop: 0,
  }),
  pt4: css({
    paddingTop: `${SpaceSize.is4}`,
  }),
  pt8: css({
    paddingTop: `${SpaceSize.is8}`,
  }),
  pt12: css({
    paddingTop: `${SpaceSize.is12}`,
  }),
  pt16: css({
    paddingTop: `${SpaceSize.is16}`,
  }),
  pt20: css({
    paddingTop: `${SpaceSize.is20}`,
  }),
  pt24: css({
    paddingTop: `${SpaceSize.is24}`,
  }),
  pt32: css({
    paddingTop: `${SpaceSize.is32}`,
  }),
  pt40: css({
    paddingTop: `${SpaceSize.is40}`,
  }),
  pt48: css({
    paddingTop: `${SpaceSize.is48}`,
  }),
  pt64: css({
    paddingTop: `${SpaceSize.is64}`,
  }),
  pt80: css({
    paddingTop: `${SpaceSize.is80}`,
  }),
  pt96: css({
    paddingTop: `${SpaceSize.is96}`,
  }),
  pt128: css({
    paddingTop: `${SpaceSize.is128}`,
  }),
  pt160: css({
    paddingTop: `${SpaceSize.is160}`,
  }),
  pr0: css({
    paddingRight: 0,
  }),
  pr4: css({
    paddingRight: `${SpaceSize.is4}`,
  }),
  pr8: css({
    paddingRight: `${SpaceSize.is8}`,
  }),
  pr12: css({
    paddingRight: `${SpaceSize.is12}`,
  }),
  pr16: css({
    paddingRight: `${SpaceSize.is16}`,
  }),
  pr20: css({
    paddingRight: `${SpaceSize.is20}`,
  }),
  pr24: css({
    paddingRight: `${SpaceSize.is24}`,
  }),
  pr32: css({
    paddingRight: `${SpaceSize.is32}`,
  }),
  pr40: css({
    paddingRight: `${SpaceSize.is40}`,
  }),
  pr48: css({
    paddingRight: `${SpaceSize.is48}`,
  }),
  pr64: css({
    paddingRight: `${SpaceSize.is64}`,
  }),
  pr80: css({
    paddingRight: `${SpaceSize.is80}`,
  }),
  pr96: css({
    paddingRight: `${SpaceSize.is96}`,
  }),
  pr128: css({
    paddingRight: `${SpaceSize.is128}`,
  }),
  pb0: css({
    paddingBottom: 0,
  }),
  pb4: css({
    paddingBottom: `${SpaceSize.is4}`,
  }),
  pb8: css({
    paddingBottom: `${SpaceSize.is8}`,
  }),
  pb12: css({
    paddingBottom: `${SpaceSize.is12}`,
  }),
  pb16: css({
    paddingBottom: `${SpaceSize.is16}`,
  }),
  pb20: css({
    paddingBottom: `${SpaceSize.is20}`,
  }),
  pb24: css({
    paddingBottom: `${SpaceSize.is24}`,
  }),
  pb32: css({
    paddingBottom: `${SpaceSize.is32}`,
  }),
  pb40: css({
    paddingBottom: `${SpaceSize.is40}`,
  }),
  pb48: css({
    paddingBottom: `${SpaceSize.is48}`,
  }),
  pb64: css({
    paddingBottom: `${SpaceSize.is64}`,
  }),
  pb80: css({
    paddingBottom: `${SpaceSize.is80}`,
  }),
  pb96: css({
    paddingBottom: `${SpaceSize.is96}`,
  }),
  pb128: css({
    paddingBottom: `${SpaceSize.is128}`,
  }),
  pl0: css({
    paddingLeft: 0,
  }),
  pl4: css({
    paddingLeft: `${SpaceSize.is4}`,
  }),
  pl8: css({
    paddingLeft: `${SpaceSize.is8}`,
  }),
  pl12: css({
    paddingLeft: `${SpaceSize.is12}`,
  }),
  pl16: css({
    paddingLeft: `${SpaceSize.is16}`,
  }),
  pl20: css({
    paddingLeft: `${SpaceSize.is20}`,
  }),
  pl24: css({
    paddingLeft: `${SpaceSize.is24}`,
  }),
  pl32: css({
    paddingLeft: `${SpaceSize.is32}`,
  }),
  pl40: css({
    paddingLeft: `${SpaceSize.is40}`,
  }),
  pl48: css({
    paddingLeft: `${SpaceSize.is48}`,
  }),
  pl64: css({
    paddingLeft: `${SpaceSize.is64}`,
  }),
  pl80: css({
    paddingLeft: `${SpaceSize.is80}`,
  }),
  pl96: css({
    paddingLeft: `${SpaceSize.is96}`,
  }),
  pl128: css({
    paddingLeft: `${SpaceSize.is128}`,
  }),
  px0: css({
    paddingLeft: 0,
    paddingRight: 0,
  }),
  px4: css({
    paddingLeft: `${SpaceSize.is4}`,
    paddingRight: `${SpaceSize.is4}`,
  }),
  px8: css({
    paddingLeft: `${SpaceSize.is8}`,
    paddingRight: `${SpaceSize.is8}`,
  }),
  px12: css({
    paddingLeft: `${SpaceSize.is12}`,
    paddingRight: `${SpaceSize.is12}`,
  }),
  px16: css({
    paddingLeft: `${SpaceSize.is16}`,
    paddingRight: `${SpaceSize.is16}`,
  }),
  px20: css({
    paddingLeft: `${SpaceSize.is20}`,
    paddingRight: `${SpaceSize.is20}`,
  }),
  px24: css({
    paddingLeft: `${SpaceSize.is24}`,
    paddingRight: `${SpaceSize.is24}`,
  }),
  px32: css({
    paddingLeft: `${SpaceSize.is32}`,
    paddingRight: `${SpaceSize.is32}`,
  }),
  px40: css({
    paddingLeft: `${SpaceSize.is40}`,
    paddingRight: `${SpaceSize.is40}`,
  }),
  px48: css({
    paddingLeft: `${SpaceSize.is48}`,
    paddingRight: `${SpaceSize.is48}`,
  }),
  px64: css({
    paddingLeft: `${SpaceSize.is64}`,
    paddingRight: `${SpaceSize.is64}`,
  }),
  px80: css({
    paddingLeft: `${SpaceSize.is80}`,
    paddingRight: `${SpaceSize.is80}`,
  }),
  px96: css({
    paddingLeft: `${SpaceSize.is96}`,
    paddingRight: `${SpaceSize.is96}`,
  }),
  px128: css({
    paddingLeft: `${SpaceSize.is128}`,
    paddingRight: `${SpaceSize.is128}`,
  }),
  py0: css({
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  }),
  py4: css({
    paddingTop: `${SpaceSize.is4}`,
    paddingBottom: `${SpaceSize.is4}`,
  }),
  py8: css({
    paddingTop: `${SpaceSize.is8}`,
    paddingBottom: `${SpaceSize.is8}`,
  }),
  py12: css({
    paddingTop: `${SpaceSize.is12}`,
    paddingBottom: `${SpaceSize.is12}`,
  }),
  py16: css({
    paddingTop: `${SpaceSize.is16}`,
    paddingBottom: `${SpaceSize.is16}`,
  }),
  py20: css({
    paddingTop: `${SpaceSize.is20}`,
    paddingBottom: `${SpaceSize.is8}`,
  }),
  py24: css({
    paddingTop: `${SpaceSize.is24}`,
    paddingBottom: `${SpaceSize.is24}`,
  }),
  py32: css({
    paddingTop: `${SpaceSize.is32}`,
    paddingBottom: `${SpaceSize.is32}`,
  }),
  py40: css({
    paddingTop: `${SpaceSize.is40}`,
    paddingBottom: `${SpaceSize.is40}`,
  }),
  py48: css({
    paddingTop: `${SpaceSize.is48}`,
    paddingBottom: `${SpaceSize.is48}`,
  }),
  py64: css({
    paddingTop: `${SpaceSize.is64}`,
    paddingBottom: `${SpaceSize.is64}`,
  }),
  py80: css({
    paddingTop: `${SpaceSize.is80}`,
    paddingBottom: `${SpaceSize.is80}`,
  }),
  py96: css({
    paddingTop: `${SpaceSize.is96}`,
    paddingBottom: `${SpaceSize.is96}`,
  }),
  py128: css({
    paddingTop: `${SpaceSize.is128}`,
    paddingBottom: `${SpaceSize.is128}`,
  }),
  py224: css({
    paddingTop: `${SpaceSize.is224}`,
    paddingBottom: `${SpaceSize.is224}`,
  }),
};
