import { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { AccountHolderPersonalInformationForm } from './AccountHolderPersonalInformationForm';
import { AccountHolderPersonalInformationView } from './AccountHolderPersonalInformationView';

export const AccountHolderPersonalInformation = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isPersonalInformationAlreadySaved = () => !isUndefined(account?.primaryAccountHolder?.dateOfBirth);

  useEffect(() => {
    if (isPersonalInformationAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder]);

  return (
    <MFormSection
      title='Account Owner Information'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId={'personal-information'}>
      {isPersonalInformationAlreadySaved() && !isEditMode ? (
        <AccountHolderPersonalInformationView account={account} />
      ) : (
        <AccountHolderPersonalInformationForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
