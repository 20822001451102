import { CardLayout } from '../../lib/Layout/CardLayout/CardLayout';

export const CloseAccount = () => {
  return (
    <CardLayout
      cardTitle='Account Closing Request'
      cardSubtitle={
        <span>
          If you would like to close your account, please contact{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a> and your request will be processed.
        </span>
      }>
      <></>
    </CardLayout>
  );
};
