import { useRef, useState } from 'react';

import { Col, Row } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { emailUpdateRequested, doClearUpdateEmail } from 'src/actions';
import { MInput, MModal } from 'src/lib';
import { Spacing } from 'src/styles';
import { formatValidationSchemaError } from 'src/utils';
import * as Yup from 'yup';

import ConfirmPanel from '../../lib/Miscellaneous/ConfirmPanel/ConfirmPanel';
import Spinner from '../../lib/Miscellaneous/Spinner';
import { ConfirmPanelType } from '../../models/confirm-panel.enum';

import * as Styles from './UpdateEmailModal.styles';

interface UpdateEmailModalProps {
  isVisible: boolean;
  onClose: () => void;
}

interface UpdateEmailFormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
});

export const UpdateEmailModal = ({ isVisible, onClose }: UpdateEmailModalProps) => {
  const dispatch = useDispatch();
  const isEmailChangeLoading = useSelector((state: any) => state.user.emailChange?.isLoading);
  const hasEmailChangeSucceeded = useSelector((state: any) => state.user.emailChange?.__succeeded);
  const hasEmailChangeFailed = useSelector((state: any) => state.user.emailChange?.__failed);
  const updateEmailError: string = useSelector((state: any) => state.user.emailChange?.message);

  const formRef = useRef<FormikProps<UpdateEmailFormValues> | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const isRequestCompleted = () => hasEmailChangeFailed || hasEmailChangeSucceeded;

  const _onClose = () => {
    onClose();
    dispatch(doClearUpdateEmail());
  };

  const onSubmit = (values: UpdateEmailFormValues) => {
    dispatch(emailUpdateRequested({ email: values.email }));
  };

  const onPrimaryButtonClick = () => {
    if (isRequestCompleted()) {
      _onClose();

      return;
    }
    formRef.current?.submitForm();
  };
  const renderPrimaryButtonText = () => (isRequestCompleted() ? 'Close' : 'Submit');

  const renderPrimaryButtonType = () => (isRequestCompleted() ? 'tertiary' : 'primary');

  const renderUpdateEmailModalContent = () => {
    if (isEmailChangeLoading) {
      return <Spinner customSpinnerStyle={Styles.spinnerHeight} />;
    }

    if (hasEmailChangeFailed) {
      return (
        <ConfirmPanel
          type={ConfirmPanelType.ERROR}
          iconSource={'ri-close-circle-line'}
          title={'Something went wrong'}
          description={
            updateEmailError ?? (
              <>
                Unknown error occurred. Please try again later or contact My IPO at{' '}
                <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>
              </>
            )
          }
        />
      );
    }

    if (hasEmailChangeSucceeded) {
      return (
        <ConfirmPanel
          type={ConfirmPanelType.SUCCESS}
          iconSource={'ri-mail-open-line'}
          title={'Check your email'}
          description={'We have just send you a message to confirm your email. Please check your email.'}
        />
      );
    }

    return (
      <Formik<UpdateEmailFormValues>
        enableReinitialize
        innerRef={form => (formRef.current = form)}
        initialValues={{
          email: '',
        }}
        validate={async values => {
          try {
            await validationSchema.validate(values, {
              abortEarly: false,
            });
            setIsFormValid(true);

            return {};
          } catch (error: Yup.ValidationError | any) {
            setIsFormValid(false);

            return formatValidationSchemaError(error);
          }
        }}
        onSubmit={onSubmit}>
        {(form: FormikProps<UpdateEmailFormValues>) => {
          return (
            <Form>
              <Row>
                <Col span={24} className={Spacing.my16}>
                  <MInput
                    placeholder='Enter new email address'
                    value={form.values.email}
                    onChange={value => form.setFieldValue('email', value)}
                    error={form.errors.email}
                    autoComplete='off'
                  />
                  <p className={Styles.fieldLabel}>A confirmation message will be sent to this email address.</p>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <MModal
      title={'Change Email Address'}
      visible={isVisible}
      centered
      onClose={_onClose}
      primaryButtonText={renderPrimaryButtonText()}
      primaryButtonType={renderPrimaryButtonType()}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isDisabledPrimaryButton={isEmailChangeLoading || !isFormValid}>
      {renderUpdateEmailModalContent()}
    </MModal>
  );
};
