import { ModalFormItem } from '../../../../../../models/modal-form-item.model';

export const getSecuritiesStatisticsFields = (): ModalFormItem[] => {
  return [
    {
      key: 'high',
      readOnly: true,
      props: {
        label: 'Day High',
        decimalPlaces: 2,
        isPercentage: false,
        isCurrency: true,
      },
    },
    {
      key: 'changepercent',
      readOnly: true,
      props: {
        label: 'Change',
        decimalPlaces: 2,
        isPercentage: true,
        isCurrency: true,
      },
    },
    {
      key: 'week52high',
      readOnly: true,
      props: {
        label: '52 Week high',
        decimalPlaces: 2,
        isPercentage: false,
        isCurrency: true,
      },
    },
    {
      key: 'low',
      readOnly: true,
      props: {
        label: 'Day Low',
        decimalPlaces: 2,
        isPercentage: false,
        isCurrency: true,
      },
    },
    {
      key: 'bid',
      readOnly: true,
      props: {
        label: 'Bid',
        decimalPlaces: 2,
        isPercentage: false,
        isCurrency: true,
      },
    },
    {
      key: 'week52low',
      readOnly: true,
      props: {
        label: '52 Week Low',
        decimalPlaces: 2,
        isPercentage: false,
        isCurrency: true,
      },
    },
    {
      key: 'open',
      readOnly: true,
      props: {
        label: 'Open Price',
        decimalPlaces: 2,
        isPercentage: false,
        isCurrency: true,
      },
    },
    {
      key: 'ask',
      readOnly: true,
      props: {
        label: 'Ask',
        decimalPlaces: 2,
        isCurrency: true,
      },
    },
    {
      key: 'sharevolume',
      readOnly: true,
      props: {
        label: 'Volume',
        decimalPlaces: 0,
        isPercentage: false,
        isCurrency: false,
      },
    },
  ];
};
