import { css } from '@emotion/css';
import { InvestigationStatusLabel } from 'src/models';
import { getInvestigationStatusTagColor } from 'src/utils';

export const tag = (transfer: InvestigationStatusLabel) => {
  const schema = getInvestigationStatusTagColor(transfer);

  return css`
    border-radius: 0.5rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding: 0.25rem 0.5rem;
    border: 0.0625rem solid ${schema.background};
    color: ${schema.color};
    background-color: ${schema.background};
    height: 1.5rem;
  `;
};
