import { css } from '@emotion/css';
import DriverLicenseAndPassport from 'src/assets/images/driver_license_and_passport.svg';
import { ColorConstant, Font } from 'src/styles';

export const text = css`
  ${Font.lg};
  color: ${ColorConstant.GRAY7};
`;

export const IdentityVerificationInstructions = () => {
  return (
    <>
      <img src={DriverLicenseAndPassport} alt='Driver License and Passport' style={{ width: '100%' }} />
      <p className={text}>
        Please enter your name as shown on a government-issued ID (preferably your driver&apos;s license, state ID or
        passport).
      </p>
    </>
  );
};
