import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderSuitabilityInformation,
  doClearPatchAccountHolderSuitabilityInformation,
  doCreateAccountHolderSuitabilityInformation,
  doPatchAccountHolderSuitabilityInformation,
} from 'src/actions';
import { AccountHolderFinancialInvestmentExperienceTypeDto, AccountHolderLiquidityNeedsDto } from 'src/dtos';
import { MFormRadioGroup, MFormSaveButton, MFormSelect } from 'src/lib';
import { Account, AccountHolderLiquidityNeedsLabel } from 'src/models';

import { InvestmentExperienceForm } from './InvestmentExperienceForm';
import { LiquidityNeedsTooltipContent } from './LiquidityNeedsTooltipContent';
import { INVESTMENT_OBJECTIVES_OPTION_LIST, TIME_HORIZON_OPTION_LIST } from './optionList';
import { upsertAccountHolderSuitabilityInformationValidation } from './validations';

const getRiskTolerance = (investmentObjective?: string) => {
  switch (investmentObjective) {
    case 'capitalPreservation':
      return 'low';
    case 'income':
      return 'low';
    case 'growth':
      return 'high';
    case 'growthAndIncome':
      return 'medium';
    case 'speculation':
      return 'high';
  }
};

export const LIQUIDITY_NEEDS_OPTION_LIST = [
  {
    value: AccountHolderLiquidityNeedsDto.VeryImportant,
    label: AccountHolderLiquidityNeedsLabel.VeryImportant,
    alert: 'In order to qualify for My IPO Offers your Liquidity Needs must be "Somewhat Important" or "Not Important"',
  },
  {
    value: AccountHolderLiquidityNeedsDto.SomewhatImportant,
    label: AccountHolderLiquidityNeedsLabel.SomewhatImportant,
  },
  {
    value: AccountHolderLiquidityNeedsDto.NotImportant,
    label: AccountHolderLiquidityNeedsLabel.NotImportant,
  },
];

export interface InvestmentExperienceView {
  stocks?: AccountHolderFinancialInvestmentExperienceTypeDto & { checked: boolean };
  options?: AccountHolderFinancialInvestmentExperienceTypeDto & { checked: boolean };
  commodities?: AccountHolderFinancialInvestmentExperienceTypeDto & { checked: boolean };
  bonds?: AccountHolderFinancialInvestmentExperienceTypeDto & { checked: boolean };
  margins?: AccountHolderFinancialInvestmentExperienceTypeDto & { checked: boolean };
}

export interface SuitabilityInformationFormValues {
  liquidityNeeds?: string;
  investmentObjectives?: string;
  timeHorizon?: string;
  financialInvestmentExperience?: InvestmentExperienceView;
}

export interface SuitabilityInformationFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const SuitabilityInformationForm = ({ account, onCancel, onSave }: SuitabilityInformationFormProps) => {
  const dispatch = useDispatch();

  const isCreateSuitabilityInformationLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createSuitabilityInformation.__requested),
  );
  const succeededCreateSuitabilityInformation = useSelector(
    (state: any) => state.accountHolders.createSuitabilityInformation.__succeeded,
  );

  const succeededPatchSuitabilityInformation = useSelector(
    (state: any) => state.accountHolders.patchSuitabilityInformation.__succeeded,
  );
  const isPatchSuitabilityInformationLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchSuitabilityInformation.__requested),
  );

  const [initialValues, setInitialValues] = useState<SuitabilityInformationFormValues>({});
  const [shouldPatch, setShouldPatch] = useState<boolean>(false);

  const isSuitabilityInformationAlreadySaved = () => !isEmpty(account?.primaryAccountHolder?.suitabilityInformation);

  const _onCancel = (form: FormikProps<SuitabilityInformationFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<SuitabilityInformationFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    setInitialValues({
      liquidityNeeds: account?.primaryAccountHolder?.suitabilityInformation?.liquidityNeeds?.value,
      investmentObjectives: account?.primaryAccountHolder?.suitabilityInformation?.investmentObjectives?.value,
      timeHorizon: account?.primaryAccountHolder?.suitabilityInformation?.timeHorizon?.value,
      financialInvestmentExperience: {
        margins: account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.margins
          ? {
              tradesPerYear:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.margins.tradesPerYear
                  .value,
              yearsOfExperience:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.margins
                  .yearsOfExperience.value,
              checked: true,
            }
          : undefined,
        stocks: account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.stocks
          ? {
              tradesPerYear:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.stocks.tradesPerYear
                  .value,
              yearsOfExperience:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.stocks
                  .yearsOfExperience.value,
              checked: true,
            }
          : undefined,
        bonds: account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.bonds
          ? {
              tradesPerYear:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.bonds.tradesPerYear
                  .value,
              yearsOfExperience:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.bonds
                  .yearsOfExperience.value,
              checked: true,
            }
          : undefined,
        options: account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.options
          ? {
              tradesPerYear:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.options.tradesPerYear
                  .value,
              yearsOfExperience:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.options
                  .yearsOfExperience.value,
              checked: true,
            }
          : undefined,
        commodities: account?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.commodities
          ? {
              tradesPerYear:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.commodities
                  .tradesPerYear.value,
              yearsOfExperience:
                account.primaryAccountHolder.suitabilityInformation.financialInvestmentExperience.commodities
                  .yearsOfExperience.value,
              checked: true,
            }
          : undefined,
      },
    });
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (isSuitabilityInformationAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (succeededCreateSuitabilityInformation || succeededPatchSuitabilityInformation) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreateSuitabilityInformation, succeededPatchSuitabilityInformation]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderSuitabilityInformation());
      dispatch(doClearPatchAccountHolderSuitabilityInformation());
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={values => {
        const cValues = upsertAccountHolderSuitabilityInformationValidation.cast(values);
        const dto = {
          ...cValues,
          financialInvestmentExperience: isEmpty(cValues.financialInvestmentExperience)
            ? undefined
            : {
                margins: cValues.financialInvestmentExperience.margins?.checked
                  ? omit(cValues.financialInvestmentExperience.margins, 'checked')
                  : undefined,
                stocks: cValues.financialInvestmentExperience.stocks?.checked
                  ? omit(cValues.financialInvestmentExperience.stocks, 'checked')
                  : undefined,
                options: cValues.financialInvestmentExperience.options?.checked
                  ? omit(cValues.financialInvestmentExperience.options, 'checked')
                  : undefined,
                commodities: cValues.financialInvestmentExperience.commodities?.checked
                  ? omit(cValues.financialInvestmentExperience.commodities, 'checked')
                  : undefined,
                bonds: cValues.financialInvestmentExperience.bonds?.checked
                  ? omit(cValues.financialInvestmentExperience.bonds, 'checked')
                  : undefined,
              },
          riskTolerance: getRiskTolerance(cValues.investmentObjectives),
        };

        if (shouldPatch) {
          // TODO: sanitize the dto values
          dispatch(doPatchAccountHolderSuitabilityInformation(dto));

          return;
        }

        dispatch(doCreateAccountHolderSuitabilityInformation(dto));
      }}
      initialValues={initialValues}
      validationSchema={upsertAccountHolderSuitabilityInformationValidation}>
      {form => {
        return (
          <>
            <Col span={24}>
              <MFormSelect
                testId={'account-liquidity-needs'}
                label='Liquidity Needs'
                options={LIQUIDITY_NEEDS_OPTION_LIST}
                defaultValue={account?.primaryAccountHolder?.suitabilityInformation?.liquidityNeeds.value}
                tooltip={{
                  type: 'modal',
                  title: 'Liquidity Needs',
                  content: <LiquidityNeedsTooltipContent />,
                  icon: 'info',
                }}
                error={form.errors.liquidityNeeds}
                alert={LIQUIDITY_NEEDS_OPTION_LIST.find(option => option.value === form.values.liquidityNeeds)?.alert}
                onChange={value => {
                  form.setFieldValue('liquidityNeeds', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormRadioGroup
                testId={'investment-objectives'}
                label='Investment Objectives'
                align='vertical'
                value={form.values.investmentObjectives}
                defaultValue={account?.primaryAccountHolder?.suitabilityInformation?.investmentObjectives.value}
                options={INVESTMENT_OBJECTIVES_OPTION_LIST}
                onChange={value => {
                  form.setFieldValue('investmentObjectives', value);
                }}
                error={form.errors.investmentObjectives}
              />
            </Col>
            <Col span={24}>
              <MFormRadioGroup
                testId={'time-horizon'}
                label='Time Horizon'
                align='vertical'
                value={form.values.timeHorizon}
                defaultValue={account?.primaryAccountHolder?.suitabilityInformation?.timeHorizon.value}
                options={TIME_HORIZON_OPTION_LIST}
                onChange={value => {
                  form.setFieldValue('timeHorizon', value);
                }}
                error={form.errors.timeHorizon}
              />
            </Col>
            <Col span={24}>
              <InvestmentExperienceForm form={form} />
            </Col>
            <Col span={24}>
              <MFormSaveButton<SuitabilityInformationFormValues>
                loading={isCreateSuitabilityInformationLoading || isPatchSuitabilityInformationLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={'suitability-information'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
