import { FromTradingBlockServerEventDto } from '../events/FromTradingBlockServerEvent';

const EVENT_DELIMETER = '|';

export const parseTradingBlockMessageEvent = (
  event: MessageEvent<string>,
): FromTradingBlockServerEventDto.WebSocketEventDto => {
  const fields = event.data.replace(/\n/, '').split(EVENT_DELIMETER);
  let result: Record<string, any> = {};
  fields.forEach(anEventField => {
    const pair = anEventField.split('=');
    const key = pair[0];
    const value = pair[1];
    result[key] = value;
  });

  return result as FromTradingBlockServerEventDto.WebSocketEventDto;
};
