import { ReactNode } from 'react';

import { cx } from '@emotion/css';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';

import * as Styles from './MTable.styles';

export const HISTORY_PATH = 'history';

interface MTableProps<ObjectType extends Object> {
  columns: ColumnsType<any> | undefined;
  data: ObjectType[];
  loading?: boolean;
  onRowClick?: (record: any, rowIndex: any) => any;
  emptyState?: ReactNode | (() => ReactNode);
  pagination?: false | TablePaginationConfig | undefined;
  tableStyle?: string | undefined;
  scroll?:
    | ({
        x?: string | number | true | undefined;
        y?: string | number | undefined;
      } & {
        scrollToFirstRowOnChange?: boolean | undefined;
      })
    | undefined;
  testId?: string;
}

export function MTable<ObjectType extends Object>({
  columns,
  data,
  loading = false,
  pagination = false,
  onRowClick,
  scroll = undefined,
  tableStyle = '',
  emptyState = undefined,
  testId,
}: MTableProps<ObjectType>) {
  return (
    <Table
      data-testid={testId}
      locale={{ emptyText: emptyState }}
      columns={columns}
      dataSource={data}
      pagination={pagination}
      className={
        !!onRowClick ? cx(Styles.tableHeader, tableStyle) : cx(Styles.tableHeader, Styles.notSelectableRow, tableStyle)
      }
      scroll={scroll}
      loading={loading}
      {...(onRowClick && {
        onRow: (record, rowIndex) => {
          return {
            onClick: () => onRowClick(record, rowIndex),
          };
        },
      })}
    />
  );
}
