export const LIST_ROW_BORDER_GREY = '#EDF2F7';

// Depracated: Use color from ColorConstant namespace
const BRAND1 = '#E6EEF6';
const BRAND2 = '#CCDDED';
const BRAND3 = '#B3CCE3';
const BRAND4 = '#81AAD1';
const BRAND5 = '#3877B0';
const BRAND6 = '#0255A3';
const BRAND7 = '#013B72';
const BRAND8 = '#012B52';
const BRAND9 = '#011E39';
const BRAND10 = '#000D18';

export const BRAND = {
  BRAND1,
  BRAND2,
  BRAND3,
  BRAND4,
  BRAND5,
  BRAND6,
  BRAND7,
  BRAND8,
  BRAND9,
  BRAND10,
};

const GRAY1 = '#FFFFFF';
const GRAY2 = '#F9FBFC';
const GRAY3 = '#F2F5F7';
const GRAY4 = '#DADDE0';
const GRAY5 = '#BDC2C7';
const GRAY6 = '#989EA6';
const GRAY7 = '#5C6572';
const GRAY8 = '#353D4A';
const GRAY9 = '#1B2028';
const GRAY10 = '#101217';

export const GRAYSCALE = {
  GRAY1,
  GRAY2,
  GRAY3,
  GRAY4,
  GRAY5,
  GRAY6,
  GRAY7,
  GRAY8,
  GRAY9,
  GRAY10,
};

const RED1 = '#FFF1F0';
const RED2 = '#FFCCC7';
const RED3 = '#FFA39E';
const RED4 = '#FF7875';
const RED5 = '#FF4D4F';
const RED6 = '#F5222D';
const RED7 = '#CF1322';
const RED8 = '#A8071A';
const RED9 = '#820014';
const RED10 = '#5C0011';

export const RED = {
  RED1,
  RED2,
  RED3,
  RED4,
  RED5,
  RED6,
  RED7,
  RED8,
  RED9,
  RED10,
};

const GREEN1 = '#F6FFED';
const GREEN2 = '#D9F7BE';
const GREEN3 = '#B7EB8F';
const GREEN4 = '#95DE64';
const GREEN5 = '#73D13D';
const GREEN6 = '#52C41A';
const GREEN7 = '#389E0D';
const GREEN8 = '#237804';
const GREEN9 = '#135200';
const GREEN10 = '#092B00';

export const GREEN = {
  GREEN1,
  GREEN2,
  GREEN3,
  GREEN4,
  GREEN5,
  GREEN6,
  GREEN7,
  GREEN8,
  GREEN9,
  GREEN10,
};

const ORANGE1 = '#FFF7E6';
const ORANGE2 = '#FFE7BA';
const ORANGE3 = '#FFD591';
const ORANGE4 = '#FFC069';
const ORANGE5 = '#FFA940';
const ORANGE6 = '#FA8C16';
const ORANGE7 = '#D46B08';
const ORANGE8 = '#AD4E00';
const ORANGE9 = '#873800';
const ORANGE10 = '#612500';

export const ORANGE = {
  ORANGE1,
  ORANGE2,
  ORANGE3,
  ORANGE4,
  ORANGE5,
  ORANGE6,
  ORANGE7,
  ORANGE8,
  ORANGE9,
  ORANGE10,
};

export namespace ColorConstant {
  export const BRAND1 = '#CCDDED';
  export const BRAND2 = '#E6EEF6';
  export const BRAND3 = '#B3CCE3';
  export const BRAND4 = '#81AAD1';
  export const BRAND5 = '#3877B0';
  export const BRAND6 = '#0255A3';
  export const BRAND7 = '#013B72';
  export const BRAND8 = '#012B52';
  export const BRAND9 = '#011E39';
  export const BRAND10 = '#000D18';
  export const GRAY1 = '#FFFFFF';
  export const GRAY2 = '#F9FBFC';
  export const GRAY3 = '#F2F5F7';
  export const GRAY4 = '#DADDE0';
  export const GRAY5 = '#BDC2C7';
  export const GRAY6 = '#989EA6';
  export const GRAY7 = '#5C6572';
  export const GRAY8 = '#353D4A';
  export const GRAY9 = '#1B2028';
  export const GRAY10 = '#101217';
  export const GREEN1 = '#F6FFED';
  export const GREEN2 = '#D9F7BE';
  export const GREEN3 = '#B7EB8F';
  export const GREEN4 = '#95DE64';
  export const GREEN5 = '#73D13D';
  export const GREEN6 = '#52C41A';
  export const GREEN7 = '#389E0D';
  export const GREEN8 = '#237804';
  export const GREEN9 = '#135200';
  export const GREEN10 = '#092B00';
  export const RED1 = '#FFF1F0';
  export const RED2 = '#FFCCC7';
  export const RED3 = '#FFA39E';
  export const RED4 = '#FF7875';
  export const RED5 = '#FF4D4F';
  export const RED6 = '#F5222D';
  export const RED7 = '#CF1322';
  export const RED8 = '#A8071A';
  export const RED9 = '#820014';
  export const RED10 = '#5C0011';
  export const ORANGE1 = '#FFF7E6';
  export const ORANGE2 = '#FFE7BA';
  export const ORANGE3 = '#FFD591';
  export const ORANGE4 = '#FFC069';
  export const ORANGE5 = '#FFA940';
  export const ORANGE6 = '#FA8C16';
  export const ORANGE7 = '#D46B08';
  export const ORANGE8 = '#AD4E00';
  export const ORANGE9 = '#873800';
  export const ORANGE10 = '#612500';
}
