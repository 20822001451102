import { cx } from '@emotion/css';

import { Font, Spacing } from '../styles';

export const renderTradingBlockPasswordExpirationErrorMessage = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <div>
      For security purposes My IPO requires your password to be changed once a year. To change your password, please
      click on
      <a className={cx(Spacing.p4, Font.lg)} onClick={onClick}>
        reset password
      </a>
      <span>.</span>
    </div>
  );
};
