import React from 'react';

import { Card, Row, Col, Space } from 'antd';

import { PortofolioSummaryInfo } from '../../../../../../models/portofolio-info.model';
import SummaryChart from '../SummaryChart/SummaryChart';
import SummaryInfo from '../SummaryInfo/SummaryInfo';

import * as Styles from './SummaryCard.styles';

interface SummaryCardProps {
  accountValue: number;
  portofolio: PortofolioSummaryInfo;
  infoTitle: string;
  onRefresh: () => void;
}

const SummaryCard = ({ accountValue, portofolio, infoTitle, onRefresh }: SummaryCardProps) => {
  return (
    <Card className={Styles.cardWrapper}>
      <Row>
        <Col span={24}>
          <Space align='start' size={16} className={Styles.spaceWrapper}>
            <SummaryChart accountValue={accountValue} portofolio={portofolio}></SummaryChart>
            <SummaryInfo infoTitle={infoTitle} portofolio={portofolio} onRefresh={onRefresh}></SummaryInfo>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default SummaryCard;
