import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const quoteMediaData = (state: any = initialState.quoteMedia.snapQuote, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_QUOTE_MEDIA):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_QUOTE_MEDIA):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_QUOTE_MEDIA):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_QUOTE_MEDIA):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const quoteMediaEnhancedData = (state: any = initialState.quoteMedia.enhancedSnapQuote, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ENHANCED_QUOTE_MEDIA):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ENHANCED_QUOTE_MEDIA):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ENHANCED_QUOTE_MEDIA):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ENHANCED_QUOTE_MEDIA):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const quoteMediaChartData = (state: any = initialState.quoteMedia.chartSnapQuote, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_CHART_QUOTE_MEDIA):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_CHART_QUOTE_MEDIA):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_CHART_QUOTE_MEDIA):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_CHART_QUOTE_MEDIA):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export default combineReducers({
  quoteMediaData,
  quoteMediaEnhancedData,
  quoteMediaChartData,
});
