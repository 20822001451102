import { useEffect } from 'react';

import { cx } from '@emotion/css';
import { Col } from 'antd';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createACHRelationship, doClearCreateAchRelationship } from 'src/actions';
import { MButton, MFormInput, MModalV2, SubmissionResult } from 'src/lib';
import { BaseStyle, FlexDirection, Font, FontWeight, Images, Spacing, TextAlign } from 'src/styles';
import * as Yup from 'yup';

const newAchRelationshipValidationSchema = Yup.object().shape({
  nickName: Yup.string().required('Nickname is required').max(20, 'Nickname can have a maximum of 20 characters'),
});

interface NewAchRelationshipFormValues {
  nickName: string;
}

interface NewAchRelationshipFinishModalProps {
  accountId?: number;
  bankLinkPayload: any;
  open: boolean;
  onOk: () => void;
  onClose: () => void;
}

export const NewAchRelationshipFinishModal = ({
  accountId,
  bankLinkPayload,
  open,
  onOk,
  onClose,
}: NewAchRelationshipFinishModalProps) => {
  const dispatch = useDispatch();

  const isLoading: boolean = useSelector((state: any) => state.cashiering.createACHRelationship.isLoading);
  const succeeded = useSelector((state: any) => Boolean(state.cashiering.createACHRelationship.__succeeded));
  const failed = useSelector((state: any) => Boolean(state.cashiering.createACHRelationship.__failed));
  const message = useSelector((state: any) => state.cashiering.createACHRelationship.message);

  const form = useFormik<NewAchRelationshipFormValues>({
    initialValues: {
      nickName: '',
    },
    validationSchema: newAchRelationshipValidationSchema,
    onSubmit: values => {
      dispatch(
        createACHRelationship(
          {
            ...bankLinkPayload,
            nickName: values.nickName,
          },
          accountId,
        ),
      );
    },
  });

  const isDuplicateAchRelationshipError = () => message?.includes('511');

  const _onClose = () => {
    onClose();
  };

  const renderModal = () => {
    if (failed) {
      return {
        body: (
          <SubmissionResult
            type='error'
            message={
              isDuplicateAchRelationshipError()
                ? 'The selected bank account has already been linked to your My IPO account. If you would like to link another bank account please start over and select a different bank account.'
                : message
            }
          />
        ),
        footer: (
          <MButton type='tertiary' onClick={_onClose}>
            Close
          </MButton>
        ),
      };
    }

    if (succeeded) {
      return {
        body: <SubmissionResult type='success' message='Bank account has been successfully linked' />,
        onClose: onOk,
        footer: (
          <MButton type='primary' onClick={onOk}>
            Close
          </MButton>
        ),
      };
    }

    return {
      body: (
        <>
          <Col span={24} className={cx(BaseStyle.level, FlexDirection.column, Spacing.my12)}>
            <img
              alt='bank'
              src={Images.BankPlaceholder}
              style={{
                marginTop: 16,
                marginBottom: 16,
                width: 40,
                height: 40,
              }}
            />
            <span className={cx(Font.lg, FontWeight.semibold)}> Final Step</span>
          </Col>

          <Col span={24} className={cx(TextAlign.center, Spacing.my12, Font.md)}>
            <div>
              You can provide a nickname for your newly re-linked account in order to explicitly identify the linkage
              beyond its default group of attributes. <br />
              You can also add or edit the nickname at any time needed.
            </div>
          </Col>

          <Col span={24} className={Spacing.my12}>
            <MFormInput
              label='Nickname'
              placeholder='Enter nickname'
              value={form.values.nickName}
              onChange={value => {
                form.setFieldValue('nickName', value);
              }}
              error={form.errors.nickName}
              testId={'link-bank-nickname'}
            />
          </Col>
        </>
      ),
      footer: (
        <Col span={24} className={cx(BaseStyle.level, FlexDirection.column)}>
          <MButton
            type='primary'
            onClick={form.submitForm}
            loading={isLoading}
            disabled={isLoading}
            className={Spacing.my4}
            testId={'link-bank-finish-linking-button'}>
            Finish Linking
          </MButton>
        </Col>
      ),
    };
  };

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAchRelationship());
    };
  }, []);

  return <MModalV2 title='Bank Account Link' width={400} open={open} onClose={_onClose} {...renderModal()} />;
};
