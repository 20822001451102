import React, { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Row, Space } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { TradingBlockOrderTypeEnum } from 'src/dtos';
import { useAccountSelector } from 'src/hooks';

import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';
import { ModalFormItem } from '../../../../../../models/modal-form-item.model';
import { CreateOrderView, OrderPosition } from '../../../../../../models/orders.models';
import { QuoteData, SnapQuotes } from '../../../../../../models/quote-media.model';

import * as Styles from './styles';

interface SubmissionFormProps {
  securityData: SnapQuotes;
  selectedSecurity: OrderPosition;
  onDisclaimerClick?: (step: number) => void;
}

export const SubmissionForm = ({ securityData, selectedSecurity }: SubmissionFormProps) => {
  const [securityQuoteMsediaData, setSecurityQuoteMediaData] = useState<QuoteData>();
  const orderToReview: CreateOrderView = useSelector((state: any) => state.orders.createOrdersReviewPositions?.data);
  const isLoadingCreateOrder = useSelector((state: any) => state.orders.createOrdersPositions?.__requested);
  const hasFailedCreateOrder = useSelector((state: any) => state.orders.createOrdersPositions?.__failed);
  const hasSucceededCreateOrder = useSelector((state: any) => state.orders.createOrdersPositions?.__succeeded);
  const { account } = useAccountSelector();

  useEffect(() => {
    const quoteMediaSecurity = securityData.quotedata.find(data => data.symbol === selectedSecurity.symbol);
    setSecurityQuoteMediaData(quoteMediaSecurity);
  }, [securityData]);

  const getSubmissionFormFields = (): ModalFormItem[] => {
    const dateNow = Date.now();

    return [
      {
        key: 'investorName',
        readOnly: true,
        show: true,
        props: {
          label: 'Investor Name',
          value: `${account?.primaryAccountHolder?.firstName} ${account?.primaryAccountHolder?.lastName}`,
        },
      },
      {
        key: 'address',
        readOnly: true,
        show: true,
        props: {
          label: 'Address',
          value: `${account?.primaryAccountHolder?.physicalAddress?.address1} ${account?.primaryAccountHolder?.physicalAddress?.address2}, ${account?.primaryAccountHolder?.physicalAddress?.city}, ${account?.primaryAccountHolder?.physicalAddress?.state} ${account?.primaryAccountHolder?.physicalAddress?.postalCode}, ${account?.primaryAccountHolder?.physicalAddress?.country.value}`,
        },
      },
      {
        key: 'date',
        readOnly: true,
        show: true,
        props: {
          label: 'Date',
          value: moment(dateNow).format('MM/DD/YYYY'),
        },
      },
      {
        key: 'sharePrice',
        readOnly: true,
        show: true,
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
        props: {
          label: 'Last Price',
          value: orderToReview.marketPrice,
        },
      },
      {
        key: 'limitPrice',
        readOnly: true,
        show: [TradingBlockOrderTypeEnum.Limit, TradingBlockOrderTypeEnum.Stop_Limit].includes(orderToReview.orderType),
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
        props: {
          label: 'Limit Price',
          value: orderToReview.limitPrice,
        },
      },
      {
        key: 'stopPrice',
        readOnly: true,
        show: [TradingBlockOrderTypeEnum.Stop_Market, TradingBlockOrderTypeEnum.Stop_Limit].includes(
          orderToReview.orderType,
        ),
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
        props: {
          label: 'Stop Price',
          value: orderToReview.stopPrice,
        },
      },
      {
        key: 'shareQuantity',
        readOnly: true,
        show: true,
        props: {
          label: 'Share Quantity',
          value: orderToReview.shares,
        },
      },
      {
        key: 'serviceFee',
        readOnly: true,
        show: true,
        props: {
          label: 'Service Fee',
          value: orderToReview.serviceFee,
        },
        componentValue: (value: string) => <CurrencyField value={Number(value)} />,
      },
    ];
  };

  const generateSubmissionFormFields = () => {
    return getSubmissionFormFields()
      ?.filter(field => field.show)
      ?.map((field: ModalFormItem) => {
        return (
          <React.Fragment key={field.key}>
            <Col span={10} className={cx(Styles.borderBottom, Styles.fieldLabel)}>
              {field.componentLabel ? field.componentLabel() : <span>{field.props?.label || ''}</span>}
            </Col>
            <Col
              span={14}
              className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue)}
              key={`${field.key}_value`}>
              {field.componentValue ? (
                field.componentValue(field.props?.value || '')
              ) : (
                <span>{field.props?.value || ''}</span>
              )}
            </Col>
          </React.Fragment>
        );
      });
  };

  if (isLoadingCreateOrder) return <Spinner />;

  return (
    <>
      <Row className={Styles.submissionStatusHeader} gutter={[, 28]}>
        {hasFailedCreateOrder && (
          <Col span={24} className={cx(Styles.submissionStatus, Styles.submissionFailedStatus, Styles.alignCenter)}>
            <Space size={8} direction='vertical'>
              <i className={'ri-error-warning-line'}></i>
              <span>Submission Failed</span>
            </Space>
          </Col>
        )}
        {hasSucceededCreateOrder && (
          <Col span={24} className={cx(Styles.submissionStatus, Styles.submissionSuccessStatus, Styles.alignCenter)}>
            <Space size={8} direction='vertical'>
              <i className={'ri-checkbox-circle-line'}></i>
              <span>Submission Succeeded</span>
            </Space>
          </Col>
        )}
        <Col span={24} className={Styles.alignCenter}>
          <Space size={14}>
            <span className={Styles.securityHeader}>{securityQuoteMsediaData?.longname || ''}</span>
          </Space>
        </Col>
      </Row>
      <Row gutter={[, 8]} className={Styles.bodyWrapper}>
        {generateSubmissionFormFields()}
        <Col className={cx(Styles.totalInvestmentWrapper, Styles.alignCenter)} span={24} key={`totalInvestment`}>
          <span>
            Total Investment{' '}
            <b>
              <CurrencyField value={Number(orderToReview?.estimatedTotal || 0)} />
            </b>
          </span>
        </Col>
      </Row>
    </>
  );
};
