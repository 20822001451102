import React, { useEffect } from 'react';

import { Card, Col, Layout, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { doEmailConfirm, doClearEmailConfirm, logout, doRemoveRedirectToEmailConfirm } from 'src/actions';
import { UrlSearchParameterNotFoundError } from 'src/errors/UrlSearchParameterNotFoundError';

import { MButton } from '../../lib/FormComponents/MButton/MButton';
import ConfirmPanel from '../../lib/Miscellaneous/ConfirmPanel/ConfirmPanel';
import Spinner from '../../lib/Miscellaneous/Spinner';
import { ConfirmPanelType } from '../../models/confirm-panel.enum';
import { Images } from '../../styles';

import * as Styles from './AuthenticatedEmailConfirm.styles';

export const AuthenticatedEmailConfirm = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const codeConfirm = searchParams.get('confirm')?.replace(/\s/g, '+');
  const updateId = searchParams.get('id');

  const isEmailConfirmLoading = useSelector((state: any) => state.user.emailConfirm?.isLoading);
  const hasEmailConfirmSucceeded = useSelector((state: any) => state.user.emailConfirm?.__succeeded);
  const hasEmailConfirmFailed = useSelector((state: any) => state.user.emailConfirm?.__failed);
  const emailConfirmError = useSelector((state: any) => state.user.emailConfirm?.message);
  const [paramError, setParamError] = React.useState<UrlSearchParameterNotFoundError | null>(null);

  const hasError = () => Boolean(paramError) || hasEmailConfirmFailed;

  useEffect(() => {
    if (!updateId) {
      setParamError(
        new UrlSearchParameterNotFoundError({
          name: 'id',
        }),
      );

      return;
    }

    if (!codeConfirm) {
      setParamError(
        new UrlSearchParameterNotFoundError({
          name: 'confirm',
        }),
      );

      return;
    }

    dispatch(doEmailConfirm({ code: codeConfirm, updateId: Number(updateId) }));
  }, []);

  useEffect(() => {
    return () => {
      if (hasEmailConfirmSucceeded) {
        dispatch(logout());
      }
    };
  }, [hasEmailConfirmSucceeded]);

  useEffect(() => {
    return () => {
      dispatch(doClearEmailConfirm());
      dispatch(doRemoveRedirectToEmailConfirm());
    };
  }, []);

  return (
    <Layout className={Styles.container}>
      <Row justify={'center'}>
        <Col>
          <Link to='/'>
            <img src={Images.MyIPOLogo} alt='My IPO' className={Styles.myipoLogo} />
          </Link>
          <Card className={Styles.cardWrapper}>
            <Row justify={'center'}>
              <Col span={24}>
                {isEmailConfirmLoading && <Spinner customSpinnerStyle={Styles.spinnerHeight} />}
                {hasEmailConfirmSucceeded && (
                  <ConfirmPanel
                    type={ConfirmPanelType.SUCCESS}
                    iconSource={'ri-checkbox-circle-line'}
                    title={'Email confirmed'}
                    description={
                      'Your email has been successfully confirmed and changed. Please login again with the new email.'
                    }
                  />
                )}
                {hasError() && (
                  <ConfirmPanel
                    type={ConfirmPanelType.ERROR}
                    iconSource={'ri-close-circle-line'}
                    title={'Unable to confirm your email address'}
                    description={
                      emailConfirmError ??
                      paramError?.message ?? (
                        <>
                          Unknown error occurred. Please try again later or contact My IPO at{' '}
                          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>
                        </>
                      )
                    }
                  />
                )}
              </Col>
              <Col span={24} className={Styles.buttonWrapper}>
                {hasEmailConfirmSucceeded && (
                  <MButton
                    type='secondary'
                    onClick={() => {
                      navigate('/login');
                    }}>
                    Go To Log In
                  </MButton>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
