import { CurrencyRangeField } from '../CurrencyRangeField/CurrencyRangeField';
import { MFormTextField, MFormTextFieldProps } from '../MFormTextField/MFormTextField';

interface MFormCurrencyRangeFieldProps extends Omit<MFormTextFieldProps, 'value'> {
  label: string;
  min?: number;
  max?: number;
}

export const MFormCurrencyRangeField = ({ label, min, max, tooltip, testId }: MFormCurrencyRangeFieldProps) => {
  if (min === undefined || max === undefined) {
    return null;
  }

  return (
    <MFormTextField
      label={label}
      value={<CurrencyRangeField min={min} max={max} />}
      tooltip={tooltip}
      testId={testId}
    />
  );
};
