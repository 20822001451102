export const AboutImage = require('../assets/images/about_bg.jpg');
export const AboutImageMobile = require('../assets/images/about_bg_mobile.jpg');
export const HomeBackground = require('../assets/images/home_background.jpg');
export const HomeBackgroundWide = require('../assets/images/home_background_wide.jpg');

export const MyIPOLogo = require('../assets/images/logo.png');
export const MyIPOLogoSVG = require('../assets/images/logo_header_svg.svg');

export const logo = require('../assets/images/logo_header.png');
export const mobileNav = require('../assets/images/mobile_nav.png');

export const PlayMedium = require('../assets/images/videoPlay.png');
export const PlaySmall = require('../assets/images/videoPlay@2x.png');
export const DefaultOffering = require('../assets/images/DefaultOffering.png');
export const NoIndications = require('../assets/images/NoIndications.png');
export const Sort = require('../assets/images/Sort.png');
export const SortIconActive = require('../assets/images/SortIconActive.png');
export const Completed = require('../assets/images/Completed.png');
export const BankPlaceholder = require('../assets/images/BankPlaceholder.png');
export const ReloadIcon = require('../assets/images/ReloadIcon.png');

export const Countries: any = {
  // A
  AT: require('../assets/images/countries/AT.png'),
  AR: require('../assets/images/countries/AR.png'),
  AU: require('../assets/images/countries/AU.png'),
  AF: require('../assets/images/countries/AF.png'),
  DZ: require('../assets/images/countries/DZ.png'),
  AO: require('../assets/images/countries/AO.png'),
  AW: require('../assets/images/countries/AW.png'),
  AL: require('../assets/images/countries/AL.png'),
  AM: require('../assets/images/countries/AM.png'),
  AD: require('../assets/images/countries/AD.png'),
  AZ: require('../assets/images/countries/AZ.png'),
  AI: require('../assets/images/countries/AI.png'),
  AG: require('../assets/images/countries/AG.png'),
  // B
  BS: require('../assets/images/countries/BS.png'),
  BH: require('../assets/images/countries/BH.png'),
  BD: require('../assets/images/countries/BD.png'),
  BB: require('../assets/images/countries/BB.png'),
  BY: require('../assets/images/countries/BY.png'),
  BE: require('../assets/images/countries/BE.png'),
  BZ: require('../assets/images/countries/BZ.png'),
  BJ: require('../assets/images/countries/BJ.png'),
  BM: require('../assets/images/countries/BM.png'),
  BT: require('../assets/images/countries/BT.png'),
  BO: require('../assets/images/countries/BO.png'),
  BA: require('../assets/images/countries/BA.png'),
  BW: require('../assets/images/countries/BW.png'),
  BR: require('../assets/images/countries/BR.png'),
  BN: require('../assets/images/countries/BN.png'),
  BG: require('../assets/images/countries/BG.png'),
  BF: require('../assets/images/countries/BF.png'),
  BI: require('../assets/images/countries/BI.png'),
  // C
  KH: require('../assets/images/countries/KH.png'),
  CM: require('../assets/images/countries/CM.png'),
  CA: require('../assets/images/countries/CA.png'),
  CV: require('../assets/images/countries/CV.png'),
  KY: require('../assets/images/countries/KY.png'),
  CF: require('../assets/images/countries/CF.png'),
  TD: require('../assets/images/countries/TD.png'),
  CL: require('../assets/images/countries/CL.png'),
  CN: require('../assets/images/countries/CN.png'),
  CO: require('../assets/images/countries/CO.png'),
  KM: require('../assets/images/countries/KM.png'),
  CK: require('../assets/images/countries/CK.png'),
  CR: require('../assets/images/countries/CR.png'),
  HR: require('../assets/images/countries/HR.png'),
  CY: require('../assets/images/countries/CY.png'),
  CZ: require('../assets/images/countries/CZ.png'),
  // D
  CD: require('../assets/images/countries/CD.png'),
  DK: require('../assets/images/countries/DK.png'),
  DJ: require('../assets/images/countries/DJ.png'),
  DM: require('../assets/images/countries/DM.png'),
  DO: require('../assets/images/countries/DO.png'),
  // E
  EC: require('../assets/images/countries/EC.png'),
  EG: require('../assets/images/countries/EG.png'),
  SV: require('../assets/images/countries/SV.png'),
  GQ: require('../assets/images/countries/GQ.png'),
  EE: require('../assets/images/countries/EE.png'),
  ET: require('../assets/images/countries/ET.png'),
  // F
  FO: require('../assets/images/countries/FO.png'),
  FJ: require('../assets/images/countries/FJ.png'),
  FI: require('../assets/images/countries/FI.png'),
  FR: require('../assets/images/countries/FR.png'),
  GF: require('../assets/images/countries/GF.png'),
  // G
  GA: require('../assets/images/countries/GA.png'),
  GM: require('../assets/images/countries/GM.png'),
  GE: require('../assets/images/countries/GE.png'),
  DE: require('../assets/images/countries/DE.png'),
  GH: require('../assets/images/countries/GH.png'),
  GI: require('../assets/images/countries/GI.png'),
  GR: require('../assets/images/countries/GR.png'),
  GL: require('../assets/images/countries/GL.png'),
  GD: require('../assets/images/countries/GD.png'),
  GP: require('../assets/images/countries/GP.png'),
  GU: require('../assets/images/countries/GU.png'),
  GT: require('../assets/images/countries/GT.png'),
  GN: require('../assets/images/countries/GN.png'),
  GW: require('../assets/images/countries/GW.png'),
  GY: require('../assets/images/countries/GY.png'),
  // H
  HT: require('../assets/images/countries/HT.png'),
  HN: require('../assets/images/countries/HN.png'),
  HK: require('../assets/images/countries/HK.png'),
  HU: require('../assets/images/countries/HU.png'),
  // I
  IS: require('../assets/images/countries/IS.png'),
  IN: require('../assets/images/countries/IN.png'),
  ID: require('../assets/images/countries/ID.png'),
  IQ: require('../assets/images/countries/IQ.png'),
  IE: require('../assets/images/countries/IE.png'),
  IL: require('../assets/images/countries/IL.png'),
  IT: require('../assets/images/countries/IT.png'),
  CI: require('../assets/images/countries/CI.png'),
  // J
  JM: require('../assets/images/countries/JM.png'),
  JP: require('../assets/images/countries/JP.png'),
  JO: require('../assets/images/countries/JO.png'),
  // K
  KZ: require('../assets/images/countries/KZ.png'),
  KE: require('../assets/images/countries/KE.png'),
  KI: require('../assets/images/countries/KI.png'),
  KW: require('../assets/images/countries/KW.png'),
  KG: require('../assets/images/countries/KG.png'),
  // L
  LA: require('../assets/images/countries/LA.png'),
  LV: require('../assets/images/countries/LV.png'),
  LB: require('../assets/images/countries/LB.png'),
  LS: require('../assets/images/countries/LS.png'),
  LR: require('../assets/images/countries/LR.png'),
  LY: require('../assets/images/countries/LY.png'),
  LI: require('../assets/images/countries/LI.png'),
  LT: require('../assets/images/countries/LT.png'),
  LU: require('../assets/images/countries/LU.png'),
  // M
  MO: require('../assets/images/countries/MO.png'),
  MG: require('../assets/images/countries/MG.png'),
  MW: require('../assets/images/countries/MW.png'),
  MY: require('../assets/images/countries/MY.png'),
  MV: require('../assets/images/countries/MV.png'),
  ML: require('../assets/images/countries/ML.png'),
  MT: require('../assets/images/countries/MT.png'),
  MQ: require('../assets/images/countries/MQ.png'),
  MR: require('../assets/images/countries/MR.png'),
  MU: require('../assets/images/countries/MU.png'),
  MX: require('../assets/images/countries/MX.png'),
  MD: require('../assets/images/countries/MD.png'),
  MC: require('../assets/images/countries/MC.png'),
  MN: require('../assets/images/countries/MN.png'),
  ME: require('../assets/images/countries/ME.png'),
  MS: require('../assets/images/countries/MS.png'),
  MA: require('../assets/images/countries/MA.png'),
  MZ: require('../assets/images/countries/MZ.png'),
  MM: require('../assets/images/countries/MM.png'),
  // N
  NA: require('../assets/images/countries/NA.png'),
  NP: require('../assets/images/countries/NP.png'),
  NL: require('../assets/images/countries/NL.png'),
  AN: require('../assets/images/countries/AN.png'),
  NC: require('../assets/images/countries/NC.png'),
  NZ: require('../assets/images/countries/NZ.png'),
  NI: require('../assets/images/countries/NI.png'),
  NE: require('../assets/images/countries/NE.png'),
  NG: require('../assets/images/countries/NG.png'),
  MK: require('../assets/images/countries/MK.png'),
  NO: require('../assets/images/countries/NO.png'),
  // O
  OM: require('../assets/images/countries/OM.png'),
  // P
  PK: require('../assets/images/countries/PK.png'),
  PW: require('../assets/images/countries/PW.png'),
  PS: require('../assets/images/countries/PS.png'),
  PA: require('../assets/images/countries/PA.png'),
  PG: require('../assets/images/countries/PG.png'),
  PY: require('../assets/images/countries/PY.png'),
  PE: require('../assets/images/countries/PE.png'),
  PH: require('../assets/images/countries/PH.png'),
  PL: require('../assets/images/countries/PL.png'),
  PT: require('../assets/images/countries/PT.png'),
  PR: require('../assets/images/countries/PR.png'),
  // Q
  QA: require('../assets/images/countries/QA.png'),
  // R
  CG: require('../assets/images/countries/CG.png'),
  RE: require('../assets/images/countries/RE.png'),
  RO: require('../assets/images/countries/RO.png'),
  RU: require('../assets/images/countries/RU.png'),
  RW: require('../assets/images/countries/RW.png'),
  // S
  KN: require('../assets/images/countries/KN.png'),
  LC: require('../assets/images/countries/LC.png'),
  VC: require('../assets/images/countries/VC.png'),
  WS: require('../assets/images/countries/WS.png'),
  ST: require('../assets/images/countries/ST.png'),
  SA: require('../assets/images/countries/SA.png'),
  SN: require('../assets/images/countries/SN.png'),
  RS: require('../assets/images/countries/RS.png'),
  SC: require('../assets/images/countries/SC.png'),
  SL: require('../assets/images/countries/SL.png'),
  SG: require('../assets/images/countries/SG.png'),
  SK: require('../assets/images/countries/SK.png'),
  SI: require('../assets/images/countries/SI.png'),
  SB: require('../assets/images/countries/SB.png'),
  SO: require('../assets/images/countries/SO.png'),
  ZA: require('../assets/images/countries/ZA.png'),
  KR: require('../assets/images/countries/KR.png'),
  SS: require('../assets/images/countries/SS.png'),
  ES: require('../assets/images/countries/ES.png'),
  LK: require('../assets/images/countries/LK.png'),
  SR: require('../assets/images/countries/SR.png'),
  SZ: require('../assets/images/countries/SZ.png'),
  SE: require('../assets/images/countries/SE.png'),
  CH: require('../assets/images/countries/CH.png'),
  // T
  TW: require('../assets/images/countries/TW.png'),
  TJ: require('../assets/images/countries/TJ.png'),
  TZ: require('../assets/images/countries/TZ.png'),
  TH: require('../assets/images/countries/TH.png'),
  TL: require('../assets/images/countries/TL.png'),
  TG: require('../assets/images/countries/TG.png'),
  TO: require('../assets/images/countries/TO.png'),
  TT: require('../assets/images/countries/TT.png'),
  TN: require('../assets/images/countries/TN.png'),
  TR: require('../assets/images/countries/TR.png'),
  TM: require('../assets/images/countries/TM.png'),
  TC: require('../assets/images/countries/TC.png'),
  // U
  UG: require('../assets/images/countries/UG.png'),
  UA: require('../assets/images/countries/UA.png'),
  AE: require('../assets/images/countries/AE.png'),
  GB: require('../assets/images/countries/GB.png'),
  US: require('../assets/images/countries/US.png'),
  UY: require('../assets/images/countries/UY.png'),
  UZ: require('../assets/images/countries/UZ.png'),
  // V
  VU: require('../assets/images/countries/VU.png'),
  VE: require('../assets/images/countries/VE.png'),
  VN: require('../assets/images/countries/VN.png'),
  VG: require('../assets/images/countries/VG.png'),
  VI: require('../assets/images/countries/VI.png'),
  // X
  XK: require('../assets/images/countries/XK.png'),
  // Y
  YE: require('../assets/images/countries/YE.png'),
  // Z
  ZM: require('../assets/images/countries/ZM.png'),
  ZW: require('../assets/images/countries/ZW.png'),
};
