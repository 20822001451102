import * as Colors from './colors';
import * as IMGS from './images';
import * as Typographies from './typography';

// Deprecated: Use exports from relevant files
export const Color = Colors;
export const Typography = Typographies;
export const Images = IMGS;

export * from './colors';
export * from './flexbox.styles';
export * from './other.styles';
export * from './spacing.styles';
export * from './typography';
export * from './variables.styles';
export * from './visibility.styles';
