import React from 'react';

import { cx } from '@emotion/css';
import { Col, Popover, Row, Space } from 'antd';

import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { PortofolioInfo, PortofolioSummaryInfo } from '../../../../../../models/portofolio-info.model';
import { Color, Images } from '../../../../../../styles';

import * as Styles from './SummaryInfo.styles';

interface SummaryInfoProps {
  infoTitle: string;
  portofolio: PortofolioSummaryInfo;
  onRefresh: () => void;
}

const SummaryInfo = ({ portofolio, infoTitle, onRefresh }: SummaryInfoProps) => {
  function generatePortofolioInfo() {
    return Object.entries(portofolio)?.map(([, info]: [string, PortofolioInfo]) => {
      if (!info?.totalValue && !Number.isNaN(info?.totalValue) && !info.alwaysShow) return;

      return (
        <React.Fragment key={info.title}>
          <Col span={12} className={Styles.dataRow} style={{ color: info.color }}>
            {info.title}
          </Col>
          <Col
            span={12}
            className={cx(Styles.dataRow, Styles.alignRight)}
            style={{ color: info.color }}
            data-testid={`summary-${info.title.toLowerCase().replace(/\s/g, '-')}`}>
            {!Number.isNaN(info?.totalValue) ? availableData(info) : noAvailableData()}
          </Col>
        </React.Fragment>
      );
    });
  }

  function availableData(info: PortofolioInfo) {
    return (
      <Space size={24}>
        <CurrencyField value={info.totalValue || 0} />
        {info?.isExpandible && (
          <i
            className='ri-arrow-right-s-line'
            style={{ color: Color.GRAYSCALE.GRAY7 }}
            {...(info.onClick && {
              onClick: () => {
                return info.onClick();
              },
            })}></i>
        )}
      </Space>
    );
  }

  function noAvailableData() {
    return (
      <Popover
        placement='topRight'
        content='Something went wrong. Data failed to import.'
        overlayClassName={Styles.popoverWrapper}
        overlayInnerStyle={{ borderRadius: '2px', padding: '12px 16px 13px 16px' }}>
        <i className={cx('ri-error-warning-line', Styles.errorInfo)}></i>
      </Popover>
    );
  }

  function generatePortofolioInfoHeader() {
    return (
      <React.Fragment key={new Date().toString()}>
        <Col span={20} className={Styles.title}>
          {infoTitle}
        </Col>
        <Col
          key='refresh'
          flex={'auto'}
          className={cx(Styles.refresh, Styles.alignRight)}
          style={{ color: '#0255A3' }}
          onClick={onRefresh}>
          <Space size={4}>
            <img src={Images.ReloadIcon} alt='refresh' />
            <span>Refresh</span>
          </Space>
        </Col>
      </React.Fragment>
    );
  }

  function generatePortofolioInfoFooter() {
    return (
      <Col span={24} className={Styles.disclaimer}>
        <Space size={4}>
          <i className='ri-error-warning-line'></i>
          <span>Disclaimer</span>
        </Space>
      </Col>
    );
  }

  return (
    <Row align='middle' gutter={[, 16]}>
      {generatePortofolioInfoHeader()}
      {generatePortofolioInfo()}
      {false && generatePortofolioInfoFooter()}
    </Row>
  );
};

export default SummaryInfo;
