import { Col, Row } from 'antd';

import { MTooltip } from '../MTooltip/MTooltip';

import * as Styles from './MFormTextField.styles';

export interface MFormTextFieldProps {
  label: string | JSX.Element;
  value?: JSX.Element | string | number;
  tooltip?: {
    title?: string;
    content?: string | JSX.Element;
    type: 'modal' | 'popover';
    icon?: 'question' | 'info';
  };
  className?: string;
  testId?: string;
}

export const MFormTextField = ({ label, value, tooltip, className = ' ', testId }: MFormTextFieldProps) => {
  return (
    <Row className={Styles.container.concat(' ').concat(className).trimEnd()}>
      <Col span={10} className={Styles.label} data-testid={testId + '-' + 'label'}>
        {label}
        {tooltip && (
          <MTooltip type={tooltip.type} title={tooltip.title} icon={tooltip.icon} showIcon>
            {tooltip.content}
          </MTooltip>
        )}
      </Col>
      <Col span={14} className={Styles.value} data-testid={testId + '-' + 'option'}>
        {value}
      </Col>
    </Row>
  );
};
