import { Col, Row } from 'antd';
import { TransferStatusTag } from 'src/lib/TransferStatusTag/TransferStatusTag';
import { Transfer } from 'src/models/transfers.models';

import * as Styles from './TransferTypeCol.styles';

interface TransferTypeProps {
  testId: string;
  value: Transfer;
}

export const TransferTypeCol = ({ value, testId }: TransferTypeProps) => (
  <Row>
    <Col span={24}>
      <Row gutter={[8, 1]} data-testid={testId}>
        <Col className={Styles.description}>{value.description}</Col>
        <Col>
          <TransferStatusTag value={value.status.label} />
        </Col>
        <Col className={Styles.bankName} span={24}>
          {value.bankName}
        </Col>
      </Row>
    </Col>
  </Row>
);
