import { css } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  ColorConstant,
  Display,
  Font,
  JustifyContent,
  ScreenBreakpoint,
} from '../../../styles';

export const input = css`
  ${Font.sm};
  color: ${ColorConstant.GRAY10};
  width: 100% !important;
  ${BorderRadius.none};
  box-sizing: border-box !important;
  box-shadow: none !important;
`;

export const row = (hideBorder: boolean) => css`
  ${Display.flex};
  ${AlignItems.center};
  ${JustifyContent.center};
  border-bottom: ${hideBorder ? '0px solid #989ea6 !important' : '1px solid #989ea6 !important'};
  &:hover {
    border-bottom: ${hideBorder ? '0px solid #989ea6 !important' : '1px solid #3877b0 !important'};
  }
  .ant-input::placeholder,
  .ant-input:placeholder-shown {
    @media (max-width: ${ScreenBreakpoint.laptop.min}) {
      ${Font.sm};
      height: 30px;
    }
  }
`;

export const borderLess = css`
  border-bottom: none;
  &:hover {
    border-bottom: none;
  }
`;

export const rowDisabled = (hideBorder: boolean) => css`
  ${Display.flex};
  ${AlignItems.center};
  ${JustifyContent.center};
  border-bottom: ${hideBorder ? '0px solid #989ea6 !important' : '1px solid #989ea6 !important'};
`;

export const rowFocus = (hideBorder: boolean) => css`
  ${Display.flex};
  ${AlignItems.center};
  ${JustifyContent.center};
  border-bottom: ${hideBorder ? '0px solid #989ea6 !important' : '1px solid #0255a3 !important'};
`;

export const prefixStyle = css`
  padding-right: 0.3125rem;
  padding-top: 0.375rem;
  color: ${ColorConstant.GRAY6} !important;
  &:hover {
    color: ${ColorConstant.BRAND5} !important;
  }
`;

export const prefixDisabledStyle = css`
  color: ${ColorConstant.GRAY6} !important;
  padding-right: 0.3125rem;
  padding-top: 0.375rem;
`;

export const prefixStyleFocus = css`
  color: #0255a3 !important;
  padding-right: 0.3125rem;
  padding-top: 0.375rem;
  &:hover {
    color: ${ColorConstant.BRAND5} !important;
  }
`;

export const prefixError = css`
  color: red !important;
  padding-right: 0.3125rem;
  padding-top: 0.375rem;
`;

/////////////////////////

export const suffixStyle = css`
  ${prefixStyle};
  padding-left: 0.625rem;
`;

export const suffixDisabledStyle = css`
  ${prefixDisabledStyle};
  padding-left: 0.625rem;
`;

export const suffixStyleFocus = css`
  ${prefixStyleFocus};
  padding-left: 0.625rem;
`;

export const errorStyle = (hideBorder: boolean) => css`
  ${Display.flex};
  ${AlignItems.center};
  ${JustifyContent.center};
  border-bottom: ${hideBorder ? '0px solid #989ea6 !important' : '1px solid red !important'};
`;

export const suffixError = css`
  color: red !important;
  padding-left: 0.625rem;
  padding-top: 0.375rem;
`;

export const getInputRowStyle = (
  errors: any,
  touched: any,
  name: string,
  focus: boolean,
  disabled: boolean | undefined,
  hideBorder: boolean,
) => {
  return errors && errors[name] && touched && touched[name]
    ? errorStyle(hideBorder)
    : focus
    ? rowFocus(hideBorder)
    : disabled
    ? rowDisabled(hideBorder)
    : row(hideBorder);
};
