import { Space, Tag } from 'antd';
import { OfferOrderStatusLabel } from 'src/models/offer-orders.model';

import * as Styles from './OfferOrderStatusTag.styles';

interface OfferOrderStatusTagProps {
  value: OfferOrderStatusLabel;
}

export const OfferOrderStatusTag = ({ value }: OfferOrderStatusTagProps) => (
  <Tag className={Styles.tag(value)}>
    <Space direction='horizontal' size={8}>
      {value}
    </Space>
  </Tag>
);
