import { css } from '@emotion/css';

export const modalContentContainer = css`
  max-height: 750px;
  overflow: auto;
  overflow-x: hidden;
`;

export const justifyRightButton = css`
  justify-content: flex-end;
  display: flex;
`;

export const justifyLeftButton = css`
  justify-content: flex-start;
  display: flex;
`;
