class LocalStorageService {
  add({ key, value }: { key: string; value: string }): void {
    localStorage.setItem(key, value);
  }

  patch({ key, value }: { key: string; value: string }): void {
    localStorage.add({ key, value });
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  find(key: string): string | null {
    return localStorage.getItem(key);
  }

  addListener(callback: (e: StorageEvent) => void): void {
    window.addEventListener('storage', callback);
  }

  removeListener(callback: (e: StorageEvent) => void): void {
    window.removeEventListener('storage', callback);
  }
}
const storageService = new LocalStorageService();

export const getLocalStorageService = (): LocalStorageService => storageService;
