import { useEffect } from 'react';

import { Col, Row, Form } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { patchUserAddress, clearUserAddressPatch } from 'src/actions';
import { US_COUNTRY_LABEL, US_COUNTRY_STATE_LIST, US_COUNTRY_VALUE } from 'src/constants';
import { MSelect, MButton } from 'src/lib';
import { Spacing } from 'src/styles';
import * as Yup from 'yup';

import { ActiveTab } from '../constants';
import { SignUpWithOfferInvestReferrer } from '../SignUpWithOfferInvestReferrer';

export const addressValidationSchema = Yup.object().shape({
  country: Yup.string().required('Country Of Residence is required'),
  state: Yup.string().required('State is required'),
});

interface AddressFormValues {
  country?: string;
  state?: string;
}

export const Address = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.user.authenticated.data);
  const address = useSelector((state: any) => state.user.patchAddress.data);
  const isAddressRequested = useSelector((state: any) => Boolean(state.user.patchAddress.__requested));
  const hasAddressSucceeded = useSelector((state: any) => Boolean(state.user.patchAddress.__succeeded));

  useEffect(() => {
    if (hasAddressSucceeded) {
      window.gtag('event', 'sign_up_address_complete');

      return navigate('/security');
    }
  }, [hasAddressSucceeded]);

  useEffect(() => {
    return () => {
      dispatch(clearUserAddressPatch());
    };
  }, []);

  return (
    <SignUpWithOfferInvestReferrer
      activeTab={ActiveTab.Address}
      tabs={[ActiveTab.Register, ActiveTab.Address, ActiveTab.Security]}>
      <Formik<AddressFormValues>
        validationSchema={addressValidationSchema}
        initialValues={{
          country: US_COUNTRY_VALUE,
          state: userData?.address?.state ?? address?.state,
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => {
          dispatch(patchUserAddress(values));
        }}>
        {form => {
          return (
            <>
              <Row className={Spacing.mb12}>
                <Col span={24} className={Spacing.my12}>
                  <Form.Item colon={false}>
                    <MSelect
                      value={form.values.country}
                      placeholder='Country Of Residence'
                      defaultValue={US_COUNTRY_VALUE}
                      onChange={value => form.setFieldValue('country', value)}
                      error={form.errors.country}
                      options={[{ value: US_COUNTRY_VALUE, label: US_COUNTRY_LABEL }]}
                      testId={'signup-country'}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} className={Spacing.my12}>
                  <Form.Item colon={false}>
                    <MSelect
                      value={form.values.state}
                      placeholder='State'
                      onChange={value => form.setFieldValue('state', value)}
                      error={form.errors.state}
                      options={US_COUNTRY_STATE_LIST}
                      testId={'signup-state'}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify='center' className={Spacing.my12}>
                <MButton
                  onClick={() => {
                    form.submitForm();
                  }}
                  type='secondary'
                  disabled={isAddressRequested || !form.isValid}
                  loading={isAddressRequested}
                  testId={'address-btn-next'}>
                  Next
                </MButton>
              </Row>
            </>
          );
        }}
      </Formik>
    </SignUpWithOfferInvestReferrer>
  );
};
