import { Fragment } from 'react';
import type { PropsWithChildren } from 'react';

import { Button, Card, Col, Dropdown, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { ScreenBreakpoint } from '../../../styles';

import * as Styles from './DropdownFilterButton.styles';

interface DropdownFilterButtonProps {
  label?: string | JSX.Element;
  isDirty?: boolean;
}

export const DropdownFilterButton = ({
  children,
  label,
  isDirty = false,
}: PropsWithChildren<DropdownFilterButtonProps>) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const renderFilters = (): JSX.Element => {
    return (
      <Card className={Styles.cardContent}>
        <Fragment>
          <Row gutter={[, 16]}>
            <Col span={24}>{children}</Col>
          </Row>
        </Fragment>
      </Card>
    );
  };

  const renderInMobile = (): JSX.Element => (
    <Row>
      <Col span={24}>
        <div>{children}</div>
      </Col>
    </Row>
  );

  const render = (): JSX.Element => (
    <Dropdown dropdownRender={() => renderFilters()} destroyPopupOnHide trigger={['click']} placement='bottomLeft'>
      <Button type='link' className={Styles.button({ isDirty })}>
        {label}
      </Button>
    </Dropdown>
  );

  return <>{isMobile ? renderInMobile() : render()}</>;
};
