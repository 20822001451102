import { cx } from '@emotion/css';
import { Link } from 'react-router-dom';

import * as Styles from './Header.styles';

interface DesktopMenuItemLinkProps {
  text: string | JSX.Element;
  link: string;
  onClick?: () => void;
  className?: string;
}

export const DesktopMenuItemLink = ({ text, link, onClick, className = '' }: DesktopMenuItemLinkProps) => {
  const isActive = window.location.pathname.includes(link);

  return (
    <Link
      to={link}
      onClick={onClick}
      className={cx(Styles.desktopMenuLink, isActive ? Styles.desktopActiveMenuLink : '', className)}>
      {text}
    </Link>
  );
};
