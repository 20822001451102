import { css } from '@emotion/css';

import { Font, FontWeight, Spacing } from '../../../styles';

export const timeline = css`
  width: 100%;
`;

export const label = css`
  ${Font.lg}
  ${FontWeight.bold}
  ${Spacing.mb12}
`;

export const customTimelineWrapper = css`
  ${Spacing.mt12}
`;
