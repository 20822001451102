import { AnyAction } from 'redux';

import { createAction, Type } from './utils';

export const toastMessagesAdd = (payload: any): AnyAction =>
  createAction(Type.TOAST_MESSAGES_ADD, {
    ...payload,
    autoClose: payload.autoClose === false ? false : true,
    isClearable: payload.isClearable ?? false,
  });
export const toastMessagesRemove = (payload: any): AnyAction => createAction(Type.TOAST_MESSAGES_REMOVE, payload);

export const clearToastMessages = () => createAction(Type.CLEAR_TOAST_MESSAGES);
