import { css } from '@emotion/css';
import { AlignItems, Display, FlexDirection, JustifyContent, Spacing } from 'src/styles';

export const group = (align: 'horizontal' | 'vertical') => css`
  ${Display.flex}
  ${align === 'horizontal' ? FlexDirection.row : FlexDirection.column}
  ${align === 'horizontal' ? JustifyContent.spaceBetween : JustifyContent.flexStart}
  ${align === 'horizontal' ? AlignItems.center : AlignItems.flexStart}
  ${Spacing.my8}
`;

export const button = css`
  ${Spacing.my8}
`;
