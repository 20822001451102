import axios, { AxiosResponse, AxiosRequestConfig, ResponseType } from 'axios';

export type HttpMethod = 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';

export interface HttpRequestArgs {
  method: HttpMethod;
  url: string;
  body?: any;
  authToken?: string | null;
  queryParams?: Record<any, any>;
  headers?: Record<string, string>;
  responseType?: ResponseType;
}

interface HttpRequestConfig {
  method: HttpMethod;
  url: string;
  headers: {
    ContentType: string;
    Authorization?: string;
  };
  data?: Record<string, unknown>;
  queryParams?: Record<any, any>;
}

const instance = axios.create();

export async function httpRequest<T>({
  method,
  url,
  body,
  authToken,
  queryParams,
  headers,
  responseType = 'json',
}: HttpRequestArgs): Promise<T> {
  if (url.search(/\{\w+\}/) >= 0) {
    throw new TypeError(`HTTP Request invalid argument: url=${url} contains a placeholder`);
  }

  const response = await instance.request<T, AxiosResponse<T, AxiosRequestConfig<HttpRequestConfig>>>({
    method,
    url,
    headers: {
      'Content-Type': headers?.contentType ?? 'application/json',
      Authorization: authToken ?? '',
      // 'X-Platform-Code': PLATFORM_CODE,
    },
    data: body || undefined,
    params: queryParams ?? undefined,
    responseType,
  });

  return response.data;
}
