import { css } from '@emotion/css';

import { Display, FlexDirection, ScreenBreakpoint, Color, Spacing, BorderRadius } from '../../../../../../styles';

export const cardWrapper = css`
  width: 100%;
  ${Spacing.py24}
  ${Spacing.px0}
  background: ${Color.GRAYSCALE.GRAY1};
  box-shadow: 0px 4px 16px rgba(2, 85, 163, 0.15);
  ${BorderRadius.xs};
  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    .ant-card-body {
      ${Spacing.px4};
    }
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: auto;
    ${Spacing.mx16};
  }
`;

export const spaceWrapper = css`
  width: 100%;
  .ant-space-item:last-child {
    width: inherit;
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Display.flex};
    ${FlexDirection.column};
    .ant-space-item:first-child {
      ${Spacing.mAuto};
    }
  }
`;
