import React from 'react';

import { Col } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import * as Styles from './MyAccountProgressBar.styles';

export interface MyAccountProgressBarProps {
  steps: { isComplete: boolean }[];
}

export const MyAccountProgressBar = ({ steps }: MyAccountProgressBarProps) => {
  const completedSteps = steps.filter(aMenuItem => aMenuItem.isComplete);

  return (
    <Col span={22} className={Styles.progressBarContainer}>
      <div className={Styles.stepsContaienr}>
        {steps.map(aStep => (
          <div key={uuidv4()} className={Styles.step({ isComplete: aStep.isComplete })} />
        ))}
      </div>
      <div className={Styles.stepsCompleted}>
        {completedSteps.length}/{steps.length} Steps Completed
      </div>
    </Col>
  );
};
