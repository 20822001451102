import { useEffect } from 'react';

import { isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { clearToastMessages } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { ScreenBreakpoint } from 'src/styles';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { FinancialInformationAssetsSection } from './FinancialInformationAssetsSection';
import { FinancialInformationEmploymentSection } from './FinancialInformationEmploymentSection';

export const FinancialInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCreateAssetsSuccess = useSelector((state: any) =>
    Boolean(state.accountHolders.createFinancialInformationAssets.__succeeded),
  );
  const isPatchAssetsSuccess = useSelector((state: any) =>
    Boolean(state.accountHolders.patchFinancialInformationAssets.__succeeded),
  );
  const isCreateEmploymentSuccess = useSelector((state: any) =>
    Boolean(state.accountHolders.createFinancialInformationEmployment.__succeeded),
  );
  const isPatchEmploymentSuccess = useSelector((state: any) =>
    Boolean(state.accountHolders.patchFinancialInformationEmployment.__succeeded),
  );

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const isAssetsInformationComplete = () =>
    !isUndefined(account?.primaryAccountHolder?.financialInformation?.annualIncome);
  const isEmploymentComplete = () =>
    !isUndefined(account?.primaryAccountHolder?.financialInformation?.employmentStatus);

  const hasAnyCreateRequest = () => isCreateAssetsSuccess || isCreateEmploymentSuccess;
  const hasAnyPatchRequest = () => isPatchAssetsSuccess || isPatchEmploymentSuccess;
  const hasAnyUpsertRequest = () => hasAnyCreateRequest() || hasAnyPatchRequest();

  const shouldSendAnalyticsEvent = () =>
    !account?.accountId && hasAnyCreateRequest() && isAssetsInformationComplete() && isEmploymentComplete();

  const shouldRedirectToDisclosures = () =>
    !account?.accountId && hasAnyUpsertRequest() && isAssetsInformationComplete() && isEmploymentComplete();

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_financial_information_complete');
    }
  }, [isCreateAssetsSuccess, isCreateEmploymentSuccess]);

  useEffect(() => {
    if (shouldRedirectToDisclosures()) {
      navigate(`/accounts/${account?.id}/disclosures`);
    }
  }, [isCreateAssetsSuccess, isPatchAssetsSuccess, isCreateEmploymentSuccess, isPatchEmploymentSuccess]);

  useEffect(() => {
    return () => {
      dispatch(clearToastMessages());
    };
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.FinancialInformation}
      title={isMobile ? undefined : 'Financial Information'}>
      <FinancialInformationAssetsSection />
      <FinancialInformationEmploymentSection />
    </MyAccountLayout>
  );
};
