import { css } from '@emotion/css';
import { AlignItems, ColorConstant, Display, FlexDirection, Font, JustifyContent, Spacing } from 'src/styles';

export const title = css`
  ${Font.md}
`;

export const subtitle = css`
  ${Font.sm}
  ${Spacing.p4}
  color: ${ColorConstant.GRAY6};
`;

export const container = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.flexStart}
`;

export const subContainer = css`
  ${Display.flex}
  ${AlignItems.flexStart}
  ${JustifyContent.flexStart}
  ${FlexDirection.column}
  ${Spacing.ml12}
`;

export const alert = css`
  ${Spacing.m0}
  ${Spacing.p8}
`;
