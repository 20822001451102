import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { AccountHolderPhysicalAddressForm } from './AccountHolderPhysicalAddressForm';
import { AccountHolderPhysicalAddressView } from './AccountHolderPhysicalAddressView';

export const AccountHolderPhysicalAddress = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isPhysicalAddressAlreadySaved = () => !isEmpty(account?.primaryAccountHolder?.physicalAddress);

  useEffect(() => {
    if (isPhysicalAddressAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.physicalAddress]);

  return (
    <MFormSection
      title='Physical Address'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId={'physical-address'}>
      {isPhysicalAddressAlreadySaved() && !isEditMode ? (
        <AccountHolderPhysicalAddressView account={account} />
      ) : (
        <AccountHolderPhysicalAddressForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
