import { AccountHolderDto, AccountStatusDto } from './accountHolders.dtos';

export enum AccountTypeDto {
  Individual = 'individual',
}

export interface AccountDto {
  id: string;
  accountId?: number;
  accountNumber?: string;
  status?: AccountStatusDto;
  type: AccountTypeDto;
  primaryAccountHolder?: AccountHolderDto;
}
