/* eslint-disable camelcase */

export const DOCUMENT_TYPES: Record<string, { label: string; icon: string }> = {
  investor_presentation: { label: 'Investor Presentation', icon: 'ri-play-circle-line' },
  offering_summary: { label: 'Offering Summary', icon: 'ri-file-text-line' },
  offering_document: { label: 'Offering Documents', icon: 'ri-folders-line' },
  risk_factors: { label: 'Risk Factors', icon: 'ri-alert-line' },
  subscription_agreement: { label: 'Subscription Agreement', icon: 'ri-file-text-line' },
  circular_offering_document: { label: 'Circular Offering Document', icon: 'ri-file-text-line' },
};
