export enum HeaderMenuItemKey {
  MyAccount = 'MyAccount',
  MoneyTransfers = 'MoneyTransfers',
  AccountStatus = 'AccountStatus',
  AccountStatements = 'AccountStatements',
  ChangeEmail = 'ChangeEmail',
  IndicationsOfInterest = 'IndicationsOfInterst',
}

export interface HeaderMenuItem {
  key: HeaderMenuItemKey;
  name: string;
  path: (id?: string) => string;
}

export const headerMenuItems: HeaderMenuItem[] = [
  {
    key: HeaderMenuItemKey.MyAccount,
    name: 'My Account',
    path: (id?: string) => `/accounts/${id}/personal-information`,
  },
  {
    key: HeaderMenuItemKey.MoneyTransfers,
    name: 'Money Transfers & Preferences',
    path: (id?: string) => `/accounts/${id}/money-transfers`,
  },
  //TODO Activate when the communication preferences will be implemented
  /*{ label: 'Security & Communication Preferences', url: 'securities' },*/

  //TODO Activate when statements and documents will be implemented
  /*{ label: 'Statements & Documents', url: 'docs' },*/
  {
    key: HeaderMenuItemKey.AccountStatus,
    name: 'Account Status',
    path: (id?: string) => `/accounts/${id}/account-status`,
  },
  {
    key: HeaderMenuItemKey.AccountStatements,
    name: 'Statements & Documents',
    path: (id?: string) => `/accounts/${id}/statements`,
  },
  { key: HeaderMenuItemKey.ChangeEmail, name: 'Change Email Address & Phone Number', path: () => '/user/settings' },
  {
    key: HeaderMenuItemKey.IndicationsOfInterest,
    name: 'Indications of Interest',
    path: () => `/user/indications-of-interest`,
  },
];

export const completedAccountMenuItemKeyList = [
  HeaderMenuItemKey.MoneyTransfers,
  HeaderMenuItemKey.AccountStatus,
  HeaderMenuItemKey.AccountStatements,
  HeaderMenuItemKey.ChangeEmail,
];
