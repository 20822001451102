import { css } from '@emotion/css';
import {
  AlignItems,
  BorderRadius,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  Spacing,
} from 'src/styles';

import { MTagType } from './MTag';

const getColorPalette = (type: MTagType): { color: string; background: string } => {
  switch (type) {
    case 'lightblue': {
      return {
        color: ColorConstant.BRAND6,
        background: ColorConstant.BRAND2,
      };
    }
    case 'blue':
      return {
        color: ColorConstant.GRAY1,
        background: ColorConstant.BRAND6,
      };
    case 'lightgreen':
      return {
        color: ColorConstant.GREEN8,
        background: ColorConstant.GREEN1,
      };
    case 'green':
      return {
        color: ColorConstant.GRAY1,
        background: ColorConstant.GREEN6,
      };
    case 'lightred':
      return {
        color: ColorConstant.RED8,
        background: ColorConstant.RED1,
      };
    case 'red':
      return {
        color: ColorConstant.RED6,
        background: ColorConstant.RED2,
      };
    case 'orange':
      return {
        color: ColorConstant.ORANGE8,
        background: ColorConstant.ORANGE2,
      };
    default:
      return {
        color: ColorConstant.GRAY7,
        background: ColorConstant.BRAND1,
      };
  }
};

export const tag = (type: MTagType) => {
  const palette = getColorPalette(type);

  return css`
    border: none;
    ${BorderRadius.xxs}
    color: ${palette.color} !important;
    background-color: ${palette.background} !important;
    ${Spacing.px8}
    ${Spacing.py4}
    ${FontWeight.bold}
    ${Font.xs}
    ${AlignItems.center}
    ${Display.flex}
    ${JustifyContent.center}
  `;
};
