import React, { useEffect, useRef } from 'react';

import drawDonutChart from './drawDonutChart';
import * as Styles from './styles';

interface DonutChartProps {
  totalValueData: number;
  data: number[];
  colors: string[];
}

const DonutChart = ({ totalValueData, data, colors }: DonutChartProps) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) drawDonutChart(totalValueData, ref.current, data, colors);
  }, [ref, data, colors]);

  return (
    <div className={Styles.modalButtonStyle}>
      <div className='graph' ref={ref} />
    </div>
  );
};

export default React.memo(DonutChart);
