import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  FlexDirection,
  Font,
  FontWeight,
  ScreenBreakpoint,
  Spacing,
} from '../../../../styles';

export const headerContainer = css`
  ${Spacing.mx16}
  ${Spacing.my32}
  ${FlexDirection.column}
  ${AlignItems.flexStart}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.row}
    ${AlignItems.center}
  }
`;

export const titleContainer = css`
  ${Font.h3}
  ${FontWeight.bold}
  color: ${ColorConstant.BRAND6};
  ${Spacing.mb16}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mb0}
  }
`;

export const tableContainer = css`
  width: 100%;
`;

// duplicate
export const amountValue = ({ isNegative }: { isNegative: boolean }) => css`
  ${FontWeight.bold}
  ${Font.lg}
  color: ${isNegative ? ColorConstant.RED8 : ColorConstant.GREEN8};
`;

// duplicate
export const viewDetailsButton = css`
  ${Font.sm}
  ${FontWeight.semibold}
`;
