import { css } from '@emotion/css';
import { Display, JustifyContent, ScreenBreakpoint } from 'src/styles';

export const mSwitch = (alignment: 'left' | 'right') => css`
  ${Display.flex}
  ${alignment === 'right' ? JustifyContent.flexEnd : JustifyContent.flexStart}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${JustifyContent.flexStart}
  }
`;
