import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getConfigs } from 'src/actions';
import { ConfigDto, ConfigTypeDto } from 'src/dtos';

import { CustomHookBaseOptions } from '../models/common.model';

interface UseApplicationConfigsReturnType {
  configList: ConfigDto[];
  bankLink: { isDisabled: boolean; disclaimer?: string };
  deposit: { isDisabled: boolean; disclaimer?: string };
  withdrawal: { isDisabled: boolean; disclaimer?: string };
  isConfigListLoading: boolean;
  hasConfigListError?: boolean;
  configListError?: string;
}

export interface UseApplicationConfigsOptions extends CustomHookBaseOptions {}

const EMPTY_CONFIG_LIST: ConfigDto[] = [];

export const useApplicationConfigs = (
  { skip, refresh }: UseApplicationConfigsOptions = { skip: false },
): UseApplicationConfigsReturnType => {
  const dispatch = useDispatch();
  const configList: ConfigDto[] | undefined = useSelector((state: any) => state.configs.configsList.data?.data);
  const isConfigListLoading: boolean = useSelector((state: any) => state.configs.configsList.isLoading);
  const hasOfferWatchListFailed: boolean = useSelector((state: any) => Boolean(state.configs.configsList.__failed));
  const configListError: string | undefined = useSelector((state: any) => state.configs.configsList.__message);

  const banklinkConfig = configList?.find(config => config.configType === ConfigTypeDto.BANK_LINK);

  const isBankLinkDisabled = banklinkConfig?.appConfig.bankLink?.disableInFunnel;
  const bankLinkDisclaimer = banklinkConfig?.appConfig.bankLink?.funnelDisclaimer;

  const transferConfig = configList?.find(config => config.configType === ConfigTypeDto.BANK_TRANSFER);

  const isDepositDisabled = transferConfig?.appConfig.bankTransfer?.disabledInboundFunnel;
  const depositDisclaimer = transferConfig?.appConfig.bankTransfer?.disabledInboundDisclaimerFunnel;

  const isWithdrawalDisabled = transferConfig?.appConfig.bankTransfer?.disabledOutboundFunnel;
  const withdrawalDisclaimer = transferConfig?.appConfig.bankTransfer?.disabledOutboundDisclaimerFunnel;

  useEffect(() => {
    if (refresh || (!configList && !isConfigListLoading && !skip && !hasOfferWatchListFailed)) {
      dispatch(getConfigs());
    }
  }, [configList, isConfigListLoading, dispatch]);

  return {
    configList: Array.isArray(configList) ? configList : EMPTY_CONFIG_LIST,
    bankLink: {
      isDisabled: Boolean(isBankLinkDisabled),
      disclaimer: bankLinkDisclaimer ? bankLinkDisclaimer : undefined,
    },
    deposit: {
      isDisabled: Boolean(isDepositDisabled),
      disclaimer: depositDisclaimer ? depositDisclaimer : undefined,
    },
    withdrawal: {
      isDisabled: Boolean(isWithdrawalDisabled),
      disclaimer: withdrawalDisclaimer ? withdrawalDisclaimer : undefined,
    },
    isConfigListLoading,
    hasConfigListError: hasOfferWatchListFailed,
    configListError,
  };
};
