import { StripeError } from '@stripe/stripe-js';

import { ConfirmCardPaymentErrorCause, ErrorCode } from '../constants/common.constants';
import { ConfirmCardPaymentError } from '../errors/ConfirmCardPaymentError';

export const handleConfirmPaymentError = (error: StripeError): Error => {
  if (error?.decline_code === ConfirmCardPaymentErrorCause.GENERIC_DECLINE) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined. If you would like to try again, please re-enter an Order with the same payment information or try a different payment method.',
      cause: ConfirmCardPaymentErrorCause.GENERIC_DECLINE,
    });
  }

  if (error?.decline_code === ConfirmCardPaymentErrorCause.INSUFFICIENT_FUNDS) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to insufficient funds. If you would like to try again, please re-enter an Order with a different payment method.',
      cause: ConfirmCardPaymentErrorCause.INSUFFICIENT_FUNDS,
    });
  }

  if (error?.decline_code === ConfirmCardPaymentErrorCause.LOST_CARD) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to your credit card being reported as lost. Please contact your credit card company. If you would like to try again, please re-enter an Order with a different payment method.',
      cause: ConfirmCardPaymentErrorCause.LOST_CARD,
    });
  }

  if (error?.decline_code === ConfirmCardPaymentErrorCause.STOLEN_CARD) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to your credit card being reported as stolen. Please contact your credit card company. If you would like to try again, please re-enter an Order with a different payment method.',
      cause: ConfirmCardPaymentErrorCause.STOLEN_CARD,
    });
  }

  if (error?.decline_code === ConfirmCardPaymentErrorCause.CARD_VELOCITY_EXCEEDED) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to exceeded balance or credit limit. If you would like to try again, please re-enter an Order with a different payment method.',
      cause: ConfirmCardPaymentErrorCause.CARD_VELOCITY_EXCEEDED,
    });
  }

  if (error.code === ConfirmCardPaymentErrorCause.EXPIRED_CARD) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to your credit card being expired. If you would like to try again, please re-enter an Order with a different payment method.',
      cause: ConfirmCardPaymentErrorCause.EXPIRED_CARD,
    });
  }

  if (error.code === ConfirmCardPaymentErrorCause.INCORRECT_CVC) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to an incorrect CVC code entered. If you would like to try again, please re-enter an Order with the same payment information or try a different payment method.',
      cause: ConfirmCardPaymentErrorCause.INCORRECT_CVC,
    });
  }

  if (error.code === ConfirmCardPaymentErrorCause.PROCESSING_ERROR) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined. If you would like to try again, please re-enter an Order with the same payment information or try a different payment method.',
      cause: ConfirmCardPaymentErrorCause.PROCESSING_ERROR,
    });
  }

  if (error.code === ConfirmCardPaymentErrorCause.INCORRECT_NUMBER) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to an incorrect card number entered. If you would like to try again, please re-enter an Order with the same payment information or try a different payment method.',
      cause: ConfirmCardPaymentErrorCause.INCORRECT_NUMBER,
    });
  }

  if (error.code === ConfirmCardPaymentErrorCause.AUTHENTICATION_FAILURE) {
    return new ConfirmCardPaymentError({
      message:
        'Your credit card payment was declined due to failed authentication. If you would like to try again, please re-enter an Order with the same payment information or try a different payment method. Please contact your credit card company and/or My IPO at clientservices@myipo.com if you continue to experience problems.',
      cause: ConfirmCardPaymentErrorCause.AUTHENTICATION_FAILURE,
    });
  }

  return new ConfirmCardPaymentError({
    message: `Your credit card payment confirmation failed (error code: ${ErrorCode.CONFIRM_CARD_PAYMENT}). If you would like to try again, please re-enter an Order with the same payment information or try a different payment method.`,
    cause: ConfirmCardPaymentErrorCause.UNKNOWN,
  });
};
