import { css, cx } from '@emotion/css';

import { ScreenBreakpoint } from '../../../styles';

export const modalButtonStyle = cx(
  css`
    min-width: 280px;
    min-height: 280px;
    @media (max-width: ${ScreenBreakpoint.mobile.min}) {
      min-width: 100%;
    }
  `,
);
