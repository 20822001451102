import { cx } from '@emotion/css';
import { Row } from 'antd';

import { CurrencyRangeField } from '../CurrencyRangeField/CurrencyRangeField';

import * as Styles from './ThisOfferOrderWillDisclaimer.styles';

export interface ThisOfferOrderWillDisclaimerProps {
  min?: number;
  max?: number;
  className?: string;
  testId?: string;
}

export const ThisOfferOrderWillDisclaimer = ({
  min,
  max,
  testId,
  className = '',
}: ThisOfferOrderWillDisclaimerProps) => {
  return (
    <Row className={cx(Styles.container, className)}>
      <span data-testid={testId}>
        This order will be automatically submitted if the final offering price is within the estimated share range of{' '}
        <CurrencyRangeField min={min} max={max} />
      </span>
    </Row>
  );
};
