import { TradingBlockInvestigationCategoryNameDto } from 'src/dtos';

import * as Styles from './AccountStatus.styles';

interface CipFailureDescriptionProps {
  category: TradingBlockInvestigationCategoryNameDto;
}

export const InvestigationCipFailureDescription = ({ category }: CipFailureDescriptionProps) => {
  if (category === TradingBlockInvestigationCategoryNameDto.Name) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Name is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate Name are required to process your application.
        </div>
      </div>
    );
  }

  if (category === TradingBlockInvestigationCategoryNameDto.DateOfBirth) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Date of Birth is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate Date of Birth are required to process your application.
        </div>
      </div>
    );
  }

  if (category === TradingBlockInvestigationCategoryNameDto.TaxId) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Tax Identification is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate your Tax Identification Number are required to process your application.
        </div>
      </div>
    );
  }

  if (category === TradingBlockInvestigationCategoryNameDto.Address) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Address is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate your Address are required to process your application.
        </div>
      </div>
    );
  }

  return null;
};
