import { cx } from '@emotion/css';
import { Spin } from 'antd';
import { BaseStyle } from 'src/styles';

interface MSpinnerProps {
  className?: string;
}

export const MSpinner = ({ className }: MSpinnerProps) => {
  return <Spin className={cx(BaseStyle.level, className)} />;
};
