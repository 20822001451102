import { Radio } from 'antd';
import { MAlert, WarningAlertDescription } from 'src/lib';

import * as Styles from './PaymentTypeHeader.styles';

interface PaymentTypeHeaderProps {
  checked: boolean;
  title: string;
  subtitle?: string | JSX.Element;
  disclaimer?: string | null;
  dataTestId?: string;
  onDisclaimer?: () => void;
}

export const PaymentTypeHeader = ({
  title,
  subtitle,
  disclaimer,
  checked,
  onDisclaimer,
  dataTestId,
}: PaymentTypeHeaderProps) => {
  const _onDisclaimer = () => {
    if (onDisclaimer) {
      onDisclaimer();
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.container}>
        <Radio checked={checked} />
        <span className={Styles.title} data-testid={dataTestId}>
          {title}
        </span>
      </div>
      <div className={Styles.subContainer}>
        {disclaimer && (
          <MAlert
            type='warning'
            showIcon={false}
            description={<WarningAlertDescription onClick={_onDisclaimer} text={disclaimer} />}
            className={Styles.alert}
          />
        )}
        {subtitle && <div className={Styles.subtitle}>{subtitle}</div>}
      </div>
    </div>
  );
};
