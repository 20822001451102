import { useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { doDeleteAccountHolderTrustedContact } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { MButton, MFormSection, MTooltip } from 'src/lib';
import { Font, Spacing } from 'src/styles';

import * as Styles from './TrustedContact.styles';
import { TrustedContactForm } from './TrustedContactForm';
import { TrustedContactView } from './TrustedContactView';

export const TrustedContactSection = () => {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [addTrustedContact, setAddTrustedContact] = useState<boolean>(false);
  const isDeleteTrustedContactLoading: boolean = useSelector(
    (state: any) => state.accountHolders.deleteTrustedContact.isLoading,
  );

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setAddTrustedContact(false);
    setIsEditMode(false);
  };

  const isTrustedContactAlreadySaved = () => !isEmpty(account?.primaryAccountHolder?.trustedContact);

  const shouldRenderRemoveTrustedContactButton = () => {
    if (isTrustedContactAlreadySaved() && !isEditMode) {
      return (
        <Row>
          <Col span={24}>
            <MButton
              danger
              loading={isDeleteTrustedContactLoading}
              type='tertiary'
              onClick={async () => {
                dispatch(doDeleteAccountHolderTrustedContact({}));
              }}>
              Remove Trusted Contact
            </MButton>
          </Col>
        </Row>
      );
    }
  };

  const renderContent = () => {
    if (!isTrustedContactAlreadySaved() && !addTrustedContact) {
      return (
        <Row>
          <Col span={24}>
            <p className={Font.h1}>You currently have no Trusted Contact Person assigned to your account.</p>
          </Col>
          <Col span={24} className={Spacing.mb16}>
            <MTooltip
              type='modal'
              title='What is a Trusted Contact Person?'
              label='What is a Trusted Contact Person?'
              icon='info'
              showIcon
              className={Font.lg}>
              <p className={Font.md}>
                A Trusted Contact Person (“TCP”) is someone that you tell us we can contact if we have questions about
                your well-being. By providing this information, you authorize us to contact the TCP and disclose
                information about you in order to confirm the specifics of your current contact information, health
                status and inquire if another person or entity has legal authority to act on your behalf (such as legal
                guardian, executor, trustee or holder of a power of attorney). The TCP must be at least 18 years old and
                will not have the ability to transact on your account.
              </p>
            </MTooltip>
          </Col>
          <Col span={24} className={Styles.center}>
            <MButton
              testId={'account-add-trusted-contact-person'}
              className={Styles.center}
              onClick={() => {
                setAddTrustedContact(true);
              }}>
              <PlusCircleOutlined />
              <span>Add a Trusted Contact Person</span>
            </MButton>
          </Col>
        </Row>
      );
    }

    if (isTrustedContactAlreadySaved() && !isEditMode && !addTrustedContact) {
      return <TrustedContactView account={account} />;
    }

    return <TrustedContactForm account={account} onCancel={onCancel} />;
  };

  useEffect(() => {
    if (isTrustedContactAlreadySaved()) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [account?.primaryAccountHolder?.trustedContact]);

  return (
    <>
      <MFormSection
        title='Personal Information'
        onEdit={onEdit}
        isEditable={isEditable}
        isEditMode={isEditMode}
        testId={'trusted-contact'}>
        {renderContent()}
      </MFormSection>
      {shouldRenderRemoveTrustedContactButton()}
    </>
  );
};
