import { OfferDocumentDto } from '../../../../dtos/offers.dtos';

import { DOCUMENT_TYPES } from './constants';
import * as Styles from './OfferDocumentsTab.styles';

export type OfferDocumentsTabProps = {
  offerDocuments: OfferDocumentDto[];
};

export const OfferDocumentsTab = ({ offerDocuments }: OfferDocumentsTabProps) => {
  return (
    <div className={Styles.container}>
      {offerDocuments.map(document => {
        return (
          <a key={document.id} href={document.url} target='_blank' rel='noreferrer' className={Styles.documentLink}>
            <i className={DOCUMENT_TYPES[document.type].icon} style={Styles.documentLogo} />
            <div className={Styles.documentLabel}>{DOCUMENT_TYPES[document.type].label}</div>
          </a>
        );
      })}
    </div>
  );
};
