import { css } from '@emotion/css';

import { AlignItems, Color, Display, FlexDirection, Font, Spacing } from '../../../../styles';

export const documentLogo = css`
  width: 2.125rem;
  height: 2.125rem;
  ${Spacing.mr12};
`;

export const detailsLabel = css`
  ${Font.lg};
  color: ${Color.BRAND.BRAND5};
  ${Display.flex};
  ${AlignItems.center};
`;

export const detailsValue = css`
  ${Font.lg};
  color: ${Color.GRAYSCALE.GRAY9};
`;

export const tooltipSpan = css`
  ${Font.lg};
  color: ${Color.BRAND.BRAND5};
`;

export const container = css`
  ${Spacing.p0}
  ${FlexDirection.column}
`;

export const detailTabs = css`
  ${Spacing.py12};
  span {
    ${Spacing.pr8};
  }
`;

export const tabDataContainer = css`
  ${Display.flex};
  ${FlexDirection.column};
`;

export const tabContainer = css`
  ${Spacing.mr20};
  ${Display.flex};
  ${FlexDirection.column};
`;
