import { css } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  Color,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../styles';

export const mainContainer = css`
  width: 100%;
  max-width: 956px;
  ${Display.table}
  ${JustifyContent.center}
  ${Spacing.py0}
  ${Spacing.px48}
  ${Spacing.mt32}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mx20}
    ${Spacing.p0}
  }
`;

export const offerInfoContainer = css`
  ${Spacing.my32}
  width: 100%;
  ${Display.flex}
  ${JustifyContent.center}
  ${AlignItems.center}
`;

export const card = css`
  width: 100%;
  min-width: 335px;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(2, 85, 163, 0.2);
  ${Display.table}
  ${BorderRadius.xs}
  ${Spacing.px64}
  ${Spacing.py40}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    min-width: 100%;
    ${Spacing.p20}
  }
`;

export const description = css`
  ${Display.flex}
  ${JustifyContent.center}
  ${Spacing.mt12}
`;

export const tabHeader = css`
  ${Display.flex}
  ${JustifyContent.center}
  ${Spacing.mt32}
  ${Font.h1}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${JustifyContent.spaceAround}
  }
  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    ${FlexDirection.column}
  }
`;

export const tabItem = ({ isActive, index }: { isActive: boolean; index: number }) => css`
  color: ${isActive ? Color.BRAND.BRAND6 : Color.GRAYSCALE.GRAY6};
  ${isActive ? FontWeight.bold : FontWeight.normal}
  ${Spacing.ml48}
  ${index === 0 ? Spacing.ml0 : Spacing.ml48}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml0}
  }
`;
