import { useEffect } from 'react';

import { Button, Space } from 'antd';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  resendMfaCode,
  confirmMfaCode,
  clearConfirmMfaCode,
  clearResendMfaCode,
  doRemoveOfferInvestReferrer,
  setAuthentication,
} from 'src/actions';
import { AuthenticatedUserLoginResponseDto } from 'src/dtos';
import { MInput, MButton } from 'src/lib';
import { BaseStyle } from 'src/styles';
import * as Yup from 'yup';

import * as Styles from '../BaseLayout.styles';
import { ActiveTab } from '../constants';
import { SignUpWithOfferInvestReferrer } from '../SignUpWithOfferInvestReferrer';

export const securityConfirmValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Code is required.')
    .min(6, 'Confirmation code should contain 6 numbers')
    .max(6, 'Confirmation code should contain 6 numbers'),
});

export interface MfaConfirmFormValues {
  code: string;
}

export const SecurityConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = useSelector((state: any) => state.auth.data?.email);
  const password = useSelector((state: any) => state.auth.data?.password);
  const phoneNumber = useSelector((state: any) => state.auth.data?.phoneNumber);

  const confirmSucceded = useSelector((state: any) => Boolean(state.user.confirmMfaCode.__succeeded));
  const isConfirmMfaCodeLoading = useSelector((state: any) => Boolean(state.user.confirmMfaCode.__requested));
  const confirmMfaData: AuthenticatedUserLoginResponseDto | undefined = useSelector(
    (state: any) => state.user.confirmMfaCode.data,
  );

  const isResendMfaCodeLoading = useSelector((state: any) => Boolean(state.user.resendMfaCode.__requested));

  useEffect(() => {
    if (confirmSucceded && !isEmpty(confirmMfaData)) {
      dispatch(doRemoveOfferInvestReferrer());

      window.gtag('event', 'sign_up_security_confirm_complete');
      const { accessToken, idToken, tbToken } = confirmMfaData;
      const authToken = `Bearer ${accessToken}; idToken ${idToken}; tbToken ${tbToken}`;
      dispatch(setAuthentication({ authToken, tbToken }));
      navigate(`/complete-account`);

      return;
    }
  }, [confirmSucceded]);

  useEffect(() => {
    return () => {
      dispatch(clearConfirmMfaCode());
      dispatch(clearResendMfaCode());
    };
  }, []);

  return (
    <SignUpWithOfferInvestReferrer
      activeTab={ActiveTab.Security}
      tabs={[ActiveTab.Register, ActiveTab.Address, ActiveTab.Security]}
      cardTitle='Multi-factor authentication'>
      <Formik<MfaConfirmFormValues>
        initialValues={{
          code: '',
        }}
        validationSchema={securityConfirmValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => {
          dispatch(confirmMfaCode(values));
        }}>
        {form => {
          return (
            <>
              {phoneNumber && (
                <p className={Styles.description}>
                  We sent a six-digit security code to {`XXX - XXX - ${phoneNumber.substr(phoneNumber.length - 4)}`}.
                  Please enter the code in the below box.
                </p>
              )}
              <MInput
                placeholder='Enter code'
                value={form.values.code}
                onChange={value => form.setFieldValue('code', value)}
                error={form.errors.code}
              />

              <Button
                className={Styles.resendCodeButton}
                type='link'
                disabled={isResendMfaCodeLoading || isConfirmMfaCodeLoading}
                loading={isResendMfaCodeLoading}
                onClick={() => {
                  form.setFieldValue('code', '');
                  dispatch(resendMfaCode({ email, password }));
                }}>
                Resend Code
              </Button>

              <Space size={8} direction='horizontal' align='center' className={BaseStyle.level}>
                <MButton
                  type='tertiary'
                  onClick={() => {
                    navigate('/security', {
                      state: {
                        title: 'back-from-confirm',
                      },
                    });
                  }}>
                  Back
                </MButton>
                <MButton
                  type='secondary'
                  disabled={isConfirmMfaCodeLoading || isResendMfaCodeLoading || !form.isValid}
                  loading={isConfirmMfaCodeLoading}
                  onClick={() => {
                    form.submitForm();
                  }}>
                  Verify
                </MButton>
              </Space>
            </>
          );
        }}
      </Formik>
    </SignUpWithOfferInvestReferrer>
  );
};
