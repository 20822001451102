import * as Styles from './NewOfferWatchModal.styles';

export const NewOfferWatchDisclaimer = () => (
  <div className={Styles.disclaimer}>
    The offering of these securities will be made only by means of an offering circular. An offering statement on Form
    1-A relating to these securities has not yet been filed with the Securities and Exchange Commission. No money or
    other consideration is being solicited at this time with respect to this offering, and if sent in response to these
    materials, it will not be accepted. No securities may be sold, and no offer to buy securities can be accepted and no
    part of the purchase price can be received for an offering under Regulation A+ until an offering statement is
    qualified by the U.S. Securities and Exchange Commission, and any such offer may be withdrawn or revoked, without
    obligation or commitment of any kind, at any time before notice of its acceptance is given after the qualification
    date. An indication of interest made by a prospective investor in a Regulation A+ offering is non-binding and
    involves no obligation or commitment of any kind.
  </div>
);
