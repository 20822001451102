import type { AnyAction } from 'redux';

import {
  BaseAccountStatementParamsDto,
  BaseDownloadAccountStatementParamsDto,
  DownloadTaxDocumentParamsDto,
} from '../data-communication/StatementsApi';

import { createAction, State, Type } from './utils';

export const getTradeConfirmationList = (params: BaseAccountStatementParamsDto): AnyAction =>
  createAction(State.actionRequested(Type.GET_TRADE_CONFIRMATION_LIST), params);

export const downloadTradeConfirmation = (params: BaseDownloadAccountStatementParamsDto): AnyAction =>
  createAction(State.actionRequested(Type.DOWNLOAD_TRADE_CONFIRMATION), params);

export const revokeTradeConfirmationDownloads = (): AnyAction => createAction(Type.REVOKE_TRADE_CONFIRMATION_DOWNLOADS);

export const clearTradeConfirmationDownloads = (): AnyAction =>
  createAction(State.actionCleared(Type.DOWNLOAD_TRADE_CONFIRMATION));

export const getMonthlyStatementList = (params: BaseAccountStatementParamsDto): AnyAction =>
  createAction(State.actionRequested(Type.GET_MONTHLY_STATEMENT_LIST), params);

export const downloadMonthlyStatement = (params: BaseDownloadAccountStatementParamsDto): AnyAction =>
  createAction(State.actionRequested(Type.DOWNLOAD_MONTHLY_STATEMENT), params);

export const revokeMonthlyStatementDownloads = (): AnyAction => createAction(Type.REVOKE_MONTHLY_STATEMENT_DOWNLOADS);

export const clearMonthlyStatementDownloads = (): AnyAction =>
  createAction(State.actionCleared(Type.DOWNLOAD_MONTHLY_STATEMENT));

export const getTaxDocumentList = (params: BaseAccountStatementParamsDto): AnyAction =>
  createAction(State.actionRequested(Type.GET_TAX_DOCUMENT_LIST), params);

export const downloadTaxDocument = (params: DownloadTaxDocumentParamsDto): AnyAction =>
  createAction(State.actionRequested(Type.DOWNLOAD_TAX_DOCUMENT), params);

export const revokeTaxDocumentDownloads = (): AnyAction => createAction(Type.REVOKE_TAX_DOCUMENT_DOWNLOADS);

export const clearTaxDocumentDownloads = (): AnyAction => createAction(State.actionCleared(Type.DOWNLOAD_TAX_DOCUMENT));
