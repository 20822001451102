import { PropertyRequiredError } from '../errors';

export function assertPropertyIsFound<T, K extends keyof T>(
  value: T,
  key: K,
): asserts value is T & { [P in K]-?: T[P] } {
  if (!value[key]) {
    throw new PropertyRequiredError({ name: key.toString(), value });
  }
}
