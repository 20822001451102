import React, { useState } from 'react';

import { Images } from '../../../styles';

import { VideoComponentType } from './constants';
import * as Styles from './styles';

const Video = ({ playBtnSize, videoUrl, imageUrl }: VideoComponentType) => {
  const [shouldDisplayVideo, setShouldDisplayVideo] = useState(false);

  let playImage = Images.PlayMedium;
  let playStyle = Styles.PlayLarge;

  const isOnlineVideo = /vimeo/.test(videoUrl) || /youtube/.test(videoUrl);

  switch (playBtnSize) {
    case 'small':
      playImage = Images.PlaySmall;
      playStyle = Styles.PlaySmall;
      break;
    case 'medium':
      playImage = Images.PlayMedium;
      playStyle = Styles.PlayMedium;
      break;
    default:
      break;
  }

  const player = isOnlineVideo ? (
    <div className={Styles.VimeoContainer}>
      <iframe className={Styles.VimeoMedia} title='vimeo player' src={`${videoUrl}`} frameBorder='0' allowFullScreen />
    </div>
  ) : (
    <video className={Styles.Media} controls src={videoUrl} autoPlay>
      <track kind='captions' />
    </video>
  );

  return (
    <div className={Styles.Outer}>
      {!shouldDisplayVideo && !isOnlineVideo ? (
        <input
          type='image'
          id='playButton'
          className={playStyle}
          src={playImage}
          alt='Play video'
          onClick={() => {
            setShouldDisplayVideo(true);
          }}
        />
      ) : null}
      <div>
        {shouldDisplayVideo || isOnlineVideo ? (
          player
        ) : (
          <img src={imageUrl} className={Styles.Media} alt='Featured offer video thumbnail' />
        )}
      </div>
    </div>
  );
};

export default Video;
