import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const getPlaidLinkToken = (accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_PLAID_LIK_TOKEN), accountId);

export const doClearGetPlaidLinkToken = (): AnyAction =>
  createAction(State.actionCleared(Type.CASHIERING_GET_PLAID_LIK_TOKEN), {});

export const getACHRelationships = (accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_ACH_RELATIONSHIPS), {
    accountId,
  });

export const getACHRelationshipsSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.CASHIERING_GET_ACH_RELATIONSHIPS), payload);

export const clearACHRelationships = (): AnyAction =>
  createAction(State.actionCleared(Type.CASHIERING_GET_ACH_RELATIONSHIPS), {});

export const createACHRelationship = (payload: any, accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_CREATE_ACH_RELATIONSHIP), {
    accountId,
    body: payload,
  });

export const createACHRelationshipSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.CASHIERING_CREATE_ACH_RELATIONSHIP), payload);

export const doClearCreateAchRelationship = (): AnyAction =>
  createAction(State.actionCleared(Type.CASHIERING_CREATE_ACH_RELATIONSHIP), {});

export const unlinkBankAccount = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_UNLINK_ACH_RELATIONSHIP), payload);

export const doUpdateAchRelationshipNickName = ({
  accountId,
  relationshipId,
  nickName,
}: {
  accountId: number;
  relationshipId: number;
  nickName: string;
}): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_EDIT_NICKNAME), {
    accountId,
    relationshipId,
    nickName,
  });

export const doClearUpdateAchRelationshipNickName = (): AnyAction =>
  createAction(State.actionCleared(Type.CASHIERING_EDIT_NICKNAME), {});

export const depositFunds = (accountId: number, payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_DEPOSIT_FUNDS), {
    accountId,
    body: payload,
  });

export const withdrawFunds = (accountId: number, payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_WITHDRAW_FUNDS), {
    accountId,
    body: payload,
  });

export const getTransfers = (accountId?: number, payload?: any): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_TRANSFERS), {
    accountId,
    queryParams: payload,
  });

export const getTransactionDetails = (transactionId: number, accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_GET_TRANSACTION_DETAILS), {
    accountId,
    transactionId,
  });

export const cancelDeposit = (transactionId: number, accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_CANCEL_DEPOSIT), {
    accountId,
    transactionId,
  });

export const cancelWithdraw = (transactionId: number, accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.CASHIERING_CANCEL_WITHDRAW), {
    accountId,
    transactionId,
  });
