import { css } from '@emotion/css';
import { AlignContent, Display, JustifyContent, Spacing } from 'src/styles';

export const container = css`
  ${Display.flex}
  ${AlignContent.center}
  ${JustifyContent.center}
`;

export const button = css`
  ${Spacing.m8}
`;
