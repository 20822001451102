import { css } from '@emotion/css';

import { AlignItems, ColorConstant, Display, Font, JustifyContent, Spacing } from '../../../styles';

export const blurSection = css`
  pointer-events: none;
  -webkit-filter: blur(8px);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

export const boldLabel = css`
  font-weight: 600;
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const normalLabel = css`
  line-height: 1.75rem;
  color: #3c3c3c;
`;

export const alert = css`
  color: ${ColorConstant.BRAND6};
`;

export const buttonContainer = css`
  ${Spacing.mt16}
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
`;

export const timelineIcon = css`
  ${Font.h1}
  ${Spacing.ml4}
  position: absolute;
`;
