import { useState } from 'react';

import { Col, Layout, Row, Space } from 'antd';
import Card from 'antd/lib/card/Card';
import { useSelector } from 'react-redux';
import { UserDto } from 'src/dtos';

import { MButton } from '../../lib/FormComponents/MButton/MButton';
import { MyAccountLayout } from '../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from '../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { UpdateEmailModal } from './UpdateEmailModal';
import { UpdatePhoneNumberModal } from './UpdatePhoneNumberModal';
import * as Styles from './UserSettings.styles';

export const UserSettings = () => {
  const userInfo: UserDto = useSelector((state: any) => state.user.authenticated.data);

  const [isUpdateEmailModalVisible, setIsUpdateEmailModalVisible] = useState<boolean>(false);
  const [isUpdatePhoneNumberModalVisible, setIsUpdatePhoneNumberModalVisible] = useState<boolean>(false);

  return (
    <MyAccountLayout sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.UserSettings}>
      <Layout className={Styles.screen}>
        <Row gutter={[2, 32]}>
          <Col span={24}>
            <h1 className={Styles.subtitle}>Change Email Address</h1>
            <Card className={Styles.cardWrapper}>
              <Row gutter={24}>
                <Col flex={7}>
                  <Space direction='vertical' size={0}>
                    <span className={Styles.label}>Email address</span>
                    <span className={Styles.value}>{userInfo.email}</span>
                  </Space>
                </Col>
                <Col flex={3} className={Styles.justifyRight}>
                  <MButton onClick={() => setIsUpdateEmailModalVisible(true)}>Change Email</MButton>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <h1 className={Styles.subtitle}>Change Phone Number</h1>
            <Card className={Styles.cardWrapper}>
              <Row gutter={24}>
                <Col flex={7}>
                  <Space direction='vertical' size={0}>
                    <span className={Styles.label}>Phone number</span>
                    <span className={Styles.value}>{userInfo.phoneNumber}</span>
                  </Space>
                </Col>
                <Col flex={3} className={Styles.justifyRight}>
                  <MButton onClick={() => setIsUpdatePhoneNumberModalVisible(true)}>Change Phone Number</MButton>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <UpdateEmailModal isVisible={isUpdateEmailModalVisible} onClose={() => setIsUpdateEmailModalVisible(false)} />

        <UpdatePhoneNumberModal
          isVisible={isUpdatePhoneNumberModalVisible}
          onClose={() => setIsUpdatePhoneNumberModalVisible(false)}
        />
      </Layout>
    </MyAccountLayout>
  );
};
