import React, { Fragment } from 'react';

const SummaryDisclaimer = (): JSX.Element => {
  return (
    <Fragment>
      <p>
        <strong>Non-Traded Holdings</strong> are often illiquid securities that are not traded on a public exchange such
        as NYSE, NASDAQ or OTC Markets. Please note that values shown for such securities may be displayed at a lower
        price than the price you paid share for the securities. Under{' '}
        <a
          href='http://www.finra.org/sites/default/files/notice_doc_file_ref/Notice_Regulatory_15-02.pdf'
          target={'_blank'}
          rel='noreferrer'>
          FINRA Regulatory Notice 15-02
        </a>
        , financial firms are required to report certain securities such as public non-traded real estate investment
        trusts (REITs) and direct participation programs using a share value that is calculated using one of the
        following two methods:
      </p>
      <ul data-indent-level='1'>
        <li>
          <p>
            <strong>Net Investment Methodology</strong> - establishes the net value per share by deducting sales
            commissions, dealer manager fees, and organizational and offering costs from the offering price. The net
            investment methodology may be applied for up to 150 days after the second anniversary of breaking escrow.
          </p>
        </li>
        <li>
          <p>
            <strong>Appraised Value Methodology</strong> - establishes the net value per share based on an independent
            third-party valuation or with the material assistance of an independent third-party valuation firm. The
            appraised value methodology must be used no later than 150 days after the second anniversary of breaking
            escrow and may be implemented earlier at the discretion of the sponsor.
          </p>
        </li>
      </ul>
      <p>
        The value shown for your Non-Traded Holdings may or may not reflect an accurate potential liquidation value of
        your securities. Your securities values may remain static until the relevant company goes public or until a
        liquidity event occurs as detailed in each company’s offering documents. Should you have further questions
        regarding this please
        <a href='mailto:clientservices@myipo.com'> contact a Client Services representative</a> from My IPO.
      </p>
    </Fragment>
  );
};

export default SummaryDisclaimer;
