import * as State from '../actions/types';
import { Type } from '../actions/utils';

import initialState from './initial';

export const auth = (state: any = initialState.auth, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.SET_AUTHENTICATION):
      return { data: {} };
    case Type.SET_AUTHENTICATION:
      return { data: action.payload }; // NOTE: remove data from path (anywhere authToken is used must be replaced)
    case State.actionSucceeded(Type.LOGOUT):
      return { data: {} };
    default:
      return state;
  }
};
