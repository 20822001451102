import React, { useEffect } from 'react';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import { getOfferDetails, doClearOfferDetails } from '../../actions';
import { useAccountBalance } from '../../hooks/useAccountBalance';
import { OfferDetails } from '../../models/offers.models';
import Spinner from '../Miscellaneous/Spinner';

export const OFFER_ORDERS_PATH = 'offers';

export const NewOfferOrderGuard = () => {
  const dispatch = useDispatch();
  const { offerId }: any = useParams();
  const offerDetails: OfferDetails | undefined = useSelector((state: any) => state.offers.offerDetails.data);
  const isOfferDetailsLoading = useSelector((state: any) => state.offers.offerDetails.__requested);

  const { isAccountBalanceLoading } = useAccountBalance();

  useEffect(() => {
    dispatch(getOfferDetails(offerId));

    return () => {
      dispatch(doClearOfferDetails());
    };
  }, [dispatch]);

  // TODO: if offerDetails failed with 404 error or offerId parameter is not found return 404 page
  // if (!offerId) {
  //   return <></>;
  // }

  if (isOfferDetailsLoading || isAccountBalanceLoading || _.isEmpty(offerDetails)) return <Spinner />;

  return <Outlet />;
};
