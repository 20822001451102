import { css } from '@emotion/css';
import { Color, Font, FontWeight, Spacing } from 'src/styles';

export const fieldLabel = css`
  ${FontWeight.normal};
  ${Font.sm};
  ${Spacing.mt8}
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const spinnerHeight = css`
  height: 124px !important;
`;
