import { css } from '@emotion/css';
import { Display, Spacing } from 'src/styles';

export const dropdownListContainer = css`
  ${Display.flex}
`;

export const dropdown = css`
  ${Spacing.p8}
`;
