import { saveAs } from 'file-saver';
import { SagaReturnType, call, put, select, takeEvery } from 'redux-saga/effects';

import { clearMonthlyStatementDownloads, clearTaxDocumentDownloads, clearTradeConfirmationDownloads } from '../actions';
import * as State from '../actions/types';
import { Type } from '../actions/utils';
import { getClientApi } from '../data-communication/ClientApi';
import { StatementsApi } from '../data-communication/StatementsApi';
import {
  AccountStatementType,
  MonthlyStatementDto,
  TaxDocumentDto,
  TradeConfirmationDto,
} from '../dtos/statements.dtos';
import { AccountStatementDownload } from '../models/statements.models';
import { TReduxAction } from '../typings/commonTypes';
import { getAccountStatementFilename } from '../utils/getAccountStatementFilename';

import { safeSaga } from './utils';

export const revokeFiles = (urlList: (string | null)[]) => {
  urlList.forEach(aUrl => {
    if (aUrl) {
      URL.revokeObjectURL(aUrl);
    }
  });
};

export function* getTradeConfirmationList(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId } = action?.payload;

  const { data, total }: SagaReturnType<StatementsApi['getTradeConfirmationList']> = yield call(
    getClientApi().statements.getTradeConfirmationList,
    {
      authToken,
      params: {
        accountId,
      },
    },
  );

  yield put({
    type: State.actionSucceeded(Type.GET_TRADE_CONFIRMATION_LIST),
    payload: { data, total },
  });
}

export function* downloadTradeConfirmation(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId, date } = action?.payload;

  const result: SagaReturnType<StatementsApi['downloadTradeConfirmation']> = yield call(
    getClientApi().statements.downloadTradeConfirmation,
    {
      authToken,
      params: {
        accountId,
        date,
      },
    },
  );

  const url = URL.createObjectURL(result);

  saveAs(url, getAccountStatementFilename({ date, type: AccountStatementType.TradeConfirmation }));

  yield put({
    type: State.actionSucceeded(Type.DOWNLOAD_TRADE_CONFIRMATION),
    payload: { url, date, type: AccountStatementType.TradeConfirmation },
  });
}

export function* revokeTradeConfirmationDownloads() {
  const { downloads }: { downloads: AccountStatementDownload<TradeConfirmationDto>[] } = yield select(
    state => state.statements.tradeConfirmations,
  );

  const urls: (string | null)[] = downloads.map(aDownload => aDownload.data.url);
  yield call(revokeFiles, urls);

  yield put(clearTradeConfirmationDownloads());
}

export function* getMonthlyStatementList(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId } = action?.payload;

  const { data, total }: SagaReturnType<StatementsApi['getMonthlyStatementList']> = yield call(
    getClientApi().statements.getMonthlyStatementList,
    {
      authToken,
      params: {
        accountId,
      },
    },
  );

  yield put({
    type: State.actionSucceeded(Type.GET_MONTHLY_STATEMENT_LIST),
    payload: { data, total },
  });
}

export function* downloadMonthlyStatement(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId, date } = action?.payload;

  const result: SagaReturnType<StatementsApi['downloadMonthlyStatement']> = yield call(
    getClientApi().statements.downloadMonthlyStatement,
    {
      authToken,
      params: {
        accountId,
        date,
      },
    },
  );

  const url = URL.createObjectURL(result);

  saveAs(url, getAccountStatementFilename({ date, type: AccountStatementType.MonthlyStatement }));

  yield put({
    type: State.actionSucceeded(Type.DOWNLOAD_MONTHLY_STATEMENT),
    payload: { url, date, type: AccountStatementType.MonthlyStatement },
  });
}

export function* revokeMonthlyStatementDownloads() {
  const { downloads }: { downloads: AccountStatementDownload<MonthlyStatementDto>[] } = yield select(
    state => state.statements.monthlyStatements,
  );

  const urls: (string | null)[] = downloads.map(aDownload => aDownload.data.url);
  yield call(revokeFiles, urls);

  yield put(clearMonthlyStatementDownloads());
}

export function* getTaxDocumentList(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId } = action?.payload;

  const { data, total }: SagaReturnType<StatementsApi['getTaxDocumentList']> = yield call(
    getClientApi().statements.getTaxDocumentList,
    {
      authToken,
      params: {
        accountId,
      },
    },
  );
  yield put({
    type: State.actionSucceeded(Type.GET_TAX_DOCUMENT_LIST),
    payload: { data, total },
  });
}

export function* downloadTaxDocument(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId, date, form } = action.payload;

  const result: SagaReturnType<StatementsApi['downloadTaxDocument']> = yield call(
    getClientApi().statements.downloadTaxDocument,
    {
      authToken,
      params: {
        accountId,
        date,
        form,
      },
    },
  );

  const url = URL.createObjectURL(result);

  saveAs(url, getAccountStatementFilename({ date, type: AccountStatementType.TaxForm, form }));

  yield put({
    type: State.actionSucceeded(Type.DOWNLOAD_TAX_DOCUMENT),
    payload: { url, date, form, type: AccountStatementType.TaxForm },
  });
}

export function* revokeTaxDocumentDownloads() {
  const { downloads }: { downloads: AccountStatementDownload<TaxDocumentDto>[] } = yield select(
    state => state.statements.taxDocuments,
  );

  const urls: (string | null)[] = downloads.map(aDownload => aDownload.data.url);
  yield call(revokeFiles, urls);

  yield put(clearTaxDocumentDownloads());
}

export default function* registerStatementsSagas() {
  yield takeEvery(
    State.actionRequested(Type.GET_TRADE_CONFIRMATION_LIST),
    safeSaga(getTradeConfirmationList, Type.GET_TRADE_CONFIRMATION_LIST),
  );
  yield takeEvery(
    State.actionRequested(Type.DOWNLOAD_TRADE_CONFIRMATION),
    safeSaga(downloadTradeConfirmation, Type.DOWNLOAD_TRADE_CONFIRMATION),
  );
  yield takeEvery(Type.REVOKE_TRADE_CONFIRMATION_DOWNLOADS, revokeTradeConfirmationDownloads);
  yield takeEvery(
    State.actionRequested(Type.GET_MONTHLY_STATEMENT_LIST),
    safeSaga(getMonthlyStatementList, Type.GET_MONTHLY_STATEMENT_LIST),
  );
  yield takeEvery(
    State.actionRequested(Type.DOWNLOAD_MONTHLY_STATEMENT),
    safeSaga(downloadMonthlyStatement, Type.DOWNLOAD_MONTHLY_STATEMENT),
  );
  yield takeEvery(Type.REVOKE_MONTHLY_STATEMENT_DOWNLOADS, revokeMonthlyStatementDownloads);
  yield takeEvery(
    State.actionRequested(Type.GET_TAX_DOCUMENT_LIST),
    safeSaga(getTaxDocumentList, Type.GET_TAX_DOCUMENT_LIST),
  );
  yield takeEvery(
    State.actionRequested(Type.DOWNLOAD_TAX_DOCUMENT),
    safeSaga(downloadTaxDocument, Type.DOWNLOAD_TAX_DOCUMENT),
  );
  yield takeEvery(Type.REVOKE_TAX_DOCUMENT_DOWNLOADS, revokeTaxDocumentDownloads);
}
