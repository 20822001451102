import { useMediaQuery } from 'react-responsive';
import { AccountConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';
import { getLocalStorageService } from 'src/services';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import { ScreenBreakpoint } from '../../../styles';

import { AccountAgreementsForm } from './AccountAgreementsForm';
import { AccountAgreementsView } from './AccountAgreementsView';

const localStorageService = getLocalStorageService();

export const AccountAgreements = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const { account } = useAccountSelector();

  const isAccountAgreementAlreadyAccepted =
    'true' === localStorageService.find(`${AccountConstant.SIGN_APPLICATION_PREFIX_KEY}-${account?.id}`);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.AccountAgrements}
      title={isMobile ? undefined : 'Account Agreements'}>
      <MFormSection isEditMode isEditable={false} testId={'account-agreement'}>
        {isAccountAgreementAlreadyAccepted ? <AccountAgreementsView /> : <AccountAgreementsForm />}
      </MFormSection>
    </MyAccountLayout>
  );
};
