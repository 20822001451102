import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const offerWatchesList = (state: any = initialState.offerWatches.list, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.OFFER_WATCHES_GET_LIST):
      return clearedDefaults(state);
    case State.actionRequested(Type.OFFER_WATCHES_GET_LIST):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.OFFER_WATCHES_GET_LIST):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.OFFER_WATCHES_GET_LIST):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const createOfferWatch = (state: any = initialState.offerWatches.createOfferWatch, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.OFFER_WATCHES_CREATE):
      return clearedDefaults(state);
    case State.actionRequested(Type.OFFER_WATCHES_CREATE):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.OFFER_WATCHES_CREATE):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.OFFER_WATCHES_CREATE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export const deleteOfferWatch = (state: any = initialState.offerWatches.deleteOfferWatch, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.OFFER_WATCHES_DELETE):
      return clearedDefaults(state);
    case State.actionRequested(Type.OFFER_WATCHES_DELETE):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.OFFER_WATCHES_DELETE):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.OFFER_WATCHES_DELETE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  list: offerWatchesList,
  createOfferWatch,
  deleteOfferWatch,
});
