import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { SuitabilityInformationForm } from './SuitabilityInformationForm';
import { SuitabilityInformationView } from './SuitabilityInformationView';

export const SuitabilityInformationSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isSuitabilityInformationAlreadySaved = () => !isEmpty(account?.primaryAccountHolder?.suitabilityInformation);

  useEffect(() => {
    if (isSuitabilityInformationAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.suitabilityInformation]);

  return (
    <MFormSection onEdit={onEdit} isEditable={isEditable} isEditMode={isEditMode} testId={'suitability-information'}>
      {isSuitabilityInformationAlreadySaved() && !isEditMode ? (
        <SuitabilityInformationView account={account} />
      ) : (
        <SuitabilityInformationForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
