import { Space, Tag } from 'antd';
import { InvestigationStatusLabel } from 'src/models';

import * as Styles from './InvestigationStatusTag.styles';

interface InvestigationStatusTagProps {
  value: InvestigationStatusLabel;
}

export const InvestigationStatusTag = ({ value }: InvestigationStatusTagProps) => (
  <Tag className={Styles.tag(value)}>
    <Space direction='horizontal' size={8}>
      {value}
    </Space>
  </Tag>
);
