import { PropsWithChildren } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Col, Row } from 'antd';

import { MButton } from '../FormComponents/MButton/MButton';

import * as Styles from './MFormSection.styles';

export interface MFormSectionProps {
  title?: string;
  onEdit?: () => void;
  isEditable: boolean;
  isEditMode: boolean;
  className?: string;
  testId?: string;
}

export const MFormSection = ({
  title,
  onEdit,
  isEditable,
  isEditMode,
  className = '',
  children,
  testId,
}: PropsWithChildren<MFormSectionProps>) => {
  return (
    <div className={cx(Styles.section, className)}>
      <Row className={Styles.header(Boolean(title))}>
        {title && <Col className={Styles.title}>{title}</Col>}
        <Col>
          {isEditable && !isEditMode && (
            <MButton onClick={onEdit} type='tertiary' testId={testId + '-edit-button'}>
              Edit <EditOutlined />
            </MButton>
          )}
        </Col>
      </Row>
      <Row>{children}</Row>
    </div>
  );
};
