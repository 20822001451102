import { MFormItem } from '../MFormItem/MFormItem';
import { MSelect, MSelectProps } from '../MSelect/MSelect';

export interface MFormSelectProps extends MSelectProps {
  label: string;
  tooltip?: {
    type: 'modal' | 'popover';
    title?: string;
    content: string | JSX.Element;
    icon?: 'info' | 'question';
  };
  alert?: string;
  weight?: 'light' | 'medium';
  testId?: string;
}

export const MFormSelect = ({
  label,
  tooltip,
  alert,
  weight = 'medium',
  value,
  options,
  defaultValue,
  onChange,
  disabled,
  error,
  testId,
}: MFormSelectProps) => {
  return (
    <MFormItem label={label} tooltip={tooltip} alert={alert} weight={weight} testId={testId + '-' + 'label'}>
      <MSelect
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        testId={testId + '-' + 'option'}
      />
    </MFormItem>
  );
};
