import { Space, Tag } from 'antd';
import { OrderStatusLabel } from 'src/models/orders.models';

import * as Styles from './OrderStatusTag.styles';

interface OrderStatusTagProps {
  value: OrderStatusLabel;
}

export const OrderStatusTag = ({ value }: OrderStatusTagProps) => (
  <Tag className={Styles.tag(value)}>
    <Space direction='horizontal' size={8}>
      {value}
    </Space>
  </Tag>
);
