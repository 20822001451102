import { css } from '@emotion/css';

import { ColorConstant, Font, FontWeight, Spacing } from '../../../../../../styles';

export const herebyDisclaimerContainer = css`
  ${Spacing.mt24}
`;

export const herebyDisclaimer = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY7};
`;

export const creditCardTermsAndReasonsLink = css`
  ${FontWeight.bold}
  color: ${ColorConstant.GRAY7};
  text-decoration-line: underline;

  &:hover:not(:focus) {
    color: ${ColorConstant.GRAY7};
    text-decoration-line: underline;
  }
`;
