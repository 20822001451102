import { css, cx } from '@emotion/css';

export const spinnerContainer = cx(
  css`
    height: 70vh;
    align-items: center;
    justify-content: center;
    display: flex;
  `,
);
