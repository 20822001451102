import React, { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Space } from 'antd';
import * as d3 from 'd3';
import moment from 'moment';

import AreaChart from '../../../../../../lib/Miscellaneous/AreaChart/AreaChart';
import PercentageField from '../../../../../../lib/Miscellaneous/FormattedFields/PercentageField/PercentageField';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';
import {
  DateFilterEnum,
  DateFilterOptions,
  getActiveFilterOptions,
} from '../../../../../../models/date-filter-chart.model';

import * as Styles from './styles';

interface SecuritiesChartProps {
  data: [number, number][];
  change: number;
  isLoading?: boolean;
  onFilterChange: (dateFilter: string) => void;
}

const SecuritiesChart = ({ data, change, isLoading = true, onFilterChange }: SecuritiesChartProps) => {
  const [activeFilterOptions, setActiveFilterOptions] = useState<DateFilterOptions>(
    getActiveFilterOptions(DateFilterEnum.LAST_WEEK, moment(Date.now()).endOf('day')),
  );

  const [chartOptions, setChartOptions] = useState<{
    xAxis?: { tickInterval?: d3.CountableTimeInterval; formatTick?: string; tickStep?: number };
  }>({
    xAxis: {
      tickInterval: activeFilterOptions.tickInterval,
      formatTick: activeFilterOptions.formatTick,
      tickStep: activeFilterOptions.tickStep,
    },
  });

  function applyFilter(activeFilter: string) {
    const now = moment(Date.now()).endOf('day');
    const options: DateFilterOptions = getActiveFilterOptions(activeFilter, now);
    onFilterChange(options.value);
    setActiveFilterOptions(options);
  }

  useEffect(() => {
    setChartOptions({
      xAxis: {
        tickInterval: activeFilterOptions.tickInterval,
        formatTick: activeFilterOptions.formatTick,
        tickStep: activeFilterOptions.tickStep,
      },
    });
  }, [activeFilterOptions]);

  if (isLoading)
    return (
      <Col span={24} className={Styles.alignCenter}>
        <Spinner customSpinnerStyle={Styles.fullHeight} />
      </Col>
    );

  return (
    <>
      <Col span={24} className={Styles.alignCenter}>
        <PercentageField
          value={change}
          fixedDecimals={2}
          className={cx(
            Styles.totalTextDetail,
            change >= 0 ? Styles.pozitiveValue : Styles.negativeValue,
          )}></PercentageField>
      </Col>
      <Col span={24} className={Styles.alignCenter}>
        <Space size={16} className={Styles.filterStyling}>
          <span
            className={activeFilterOptions.name === DateFilterEnum.LAST_DAY ? Styles.activeFilter : ''}
            onClick={() => applyFilter(DateFilterEnum.LAST_DAY)}>
            {DateFilterEnum.LAST_DAY}
          </span>
          <span
            className={activeFilterOptions.name === DateFilterEnum.LAST_WEEK ? Styles.activeFilter : ''}
            onClick={() => applyFilter(DateFilterEnum.LAST_WEEK)}>
            {DateFilterEnum.LAST_WEEK}
          </span>
          <span
            className={activeFilterOptions.name === DateFilterEnum.LAST_MONTH ? Styles.activeFilter : ''}
            onClick={() => applyFilter(DateFilterEnum.LAST_MONTH)}>
            {DateFilterEnum.LAST_MONTH}
          </span>
          <span
            className={activeFilterOptions.name === DateFilterEnum.LAST_6_MONTH ? Styles.activeFilter : ''}
            onClick={() => applyFilter(DateFilterEnum.LAST_6_MONTH)}>
            {DateFilterEnum.LAST_6_MONTH}
          </span>
          <span
            className={activeFilterOptions.name === DateFilterEnum.LAST_YEAR ? Styles.activeFilter : ''}
            onClick={() => applyFilter(DateFilterEnum.LAST_YEAR)}>
            {DateFilterEnum.LAST_YEAR}
          </span>
          <span
            className={activeFilterOptions.name === DateFilterEnum.ALL ? Styles.activeFilter : ''}
            onClick={() => applyFilter(DateFilterEnum.ALL)}>
            {DateFilterEnum.ALL}
          </span>
        </Space>
      </Col>
      <Col span={24} className={Styles.alignCenter}>
        <AreaChart data={data} width={480} height={250} options={chartOptions} />
      </Col>
    </>
  );
};

export default SecuritiesChart;
