import { css, cx } from '@emotion/css';

import {
  AlignContent,
  AlignItems,
  BorderRadius,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../../../../styles';

export const container = css`
  ${Spacing.mx64}
  ${Spacing.my32}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mx32}
    ${Spacing.my16}
  }
`;

export const section = css`
  ${Display.flex}
  ${JustifyContent.spaceBetween}
  ${Spacing.my16}
  ${AlignItems.center}

  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    ${FlexDirection.column}
    ${AlignItems.flexStart}
  }
`;

export const card = css`
  ${Spacing.p32}
  ${Display.inlineBlck}
  width:100%;
  background-color: ${ColorConstant.GRAY1};

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.p16}
  }
`;

export const subTitle = css`
  ${Font.md}
  ${FontWeight.bold}
  color: ${ColorConstant.GRAY8};
  ${Display.flex}
`;

export const paragraph = css`
  ${Font.md}
  ${FontWeight.normal}
  color: ${ColorConstant.GRAY7};
`;

export const acceptableAndUnaccaptableRow = css`
  width: 100%;
  ${Spacing.mt20}
  ${AlignItems.flexStart}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column}
    ${AlignContent.flexStart}
  }
`;

export const acceptableAndUnaccaptableSubtitle = css`
  ${Font.sm}
  ${FontWeight.bold}
  color: ${ColorConstant.GRAY8};
`;

export const acceptableAndUnaccaptableList = css`
  ${Spacing.pl20}
  ${Font.sm}
  ${FontWeight.normal}
  color: ${ColorConstant.GRAY7};
`;

export const checkInfoCol = css`
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mt8}
  }
`;

export const baseIcon = css`
  width: 2rem;
  height: 2rem;
  ${Font.h2}
  ${BorderRadius.md}
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
`;

export const checkIcon = cx(
  baseIcon,
  css`
    color: ${ColorConstant.GREEN6};
    background-color: ${ColorConstant.GREEN2};
  `,
);

export const closeIcon = cx(
  baseIcon,
  css`
    color: ${ColorConstant.RED6};
    background-color: ${ColorConstant.RED2};
  `,
);
