import { css } from '@emotion/css';
import { AlignItems, Display, FlexDirection, JustifyContent, ScreenBreakpoint } from 'src/styles';

export const contentContainer = (align: 'horizontal' | 'vertical') => css`
  ${Display.flex}
  ${align === 'horizontal' ? FlexDirection.row : FlexDirection.column}
  ${align === 'horizontal' ? JustifyContent.spaceBetween : JustifyContent.flexStart}
  ${align === 'horizontal' ? AlignItems.center : AlignItems.flexStart}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column}
    ${AlignItems.flexStart}
  }
`;

export const col = css`
  width: 100%;
`;
