import { CardComponentType } from './constants';
import * as Styles from './styles';

const CardComponent = ({ children, customStyles, className }: CardComponentType) => {
  let classNames = Styles.card;

  if (className) {
    classNames = classNames.concat(` ${className}`);
  }

  return (
    <div className={classNames} style={{ ...customStyles }}>
      {children}
    </div>
  );
};

export default CardComponent;
