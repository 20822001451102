import { css } from '@emotion/css';
import { BorderRadius, ColorConstant, Display, FlexDirection, Font, Spacing } from 'src/styles';

export const input = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY10};
  width: 100% !important;
  ${Spacing.pl0}
  ${BorderRadius.none};
  box-sizing: border-box !important;
  box-shadow: none !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: rgb(217, 217, 217) !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  background-color: transparent !important;

  &:hover {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom-color: rgb(164, 200, 230) !important;
  }

  :focus-visible {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: none !important;
    border-bottom-color: rgb(164, 200, 230) !important;
  }
`;

export const inputContainer = css`
  ${Display.flex};
  ${FlexDirection.column}
`;
