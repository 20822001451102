import { css } from '@emotion/css';

import { BorderRadius, Font, TextAlign, Spacing, FontWeight, Display, AlignItems } from '../../../styles';

export const alert = css`
  ${FontWeight.normal}
  ${TextAlign.left}
  ${Font.md}
  ${BorderRadius.xs}
  ${Spacing.p12}
  ${Spacing.m8}
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.35);
  ${Display.flex}
  ${AlignItems.center}
`;
