export enum SecuritiesModalStepsEnum {
  SECURITIES_DETAILS = 1,
  BUY_FORM,
  SELL_FORM,
  REVIEW,
  SUCCEEDED,
  INFO_DISCLAIMER_1,
  INFO_DISCLAIMER_2,
  INFO_DISCLAIMER_3,
  INFO_DISCLAIMER_4,
  INFO_DISCLAIMER_5,
  INFO_DISCLAIMER_6,
}
