import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useInvestigationListSelector } from 'src/hooks';

import { ScreenBreakpoint } from '../../styles';

import { DesktopHeader } from './DesktopHeader';
import './index.css';
import { MobileHeader } from './MobileHeader';

export const Header = () => {
  const locationObj = useLocation();
  const authToken: string | undefined = useSelector((state: any) => state.auth.data?.authToken);
  const { status } = useInvestigationListSelector();

  return (
    <div className='headerMain'>
      <MediaQuery minWidth={ScreenBreakpoint.laptop.min}>
        <DesktopHeader authToken={authToken} pathName={locationObj.pathname} status={status} />
      </MediaQuery>
      <MediaQuery maxWidth={ScreenBreakpoint.mobile.max}>
        <MobileHeader authToken={authToken} pathName={locationObj.pathname} status={status} />
      </MediaQuery>
    </div>
  );
};
