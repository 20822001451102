import { ErrorCode } from '../constants/common.constants';

export class UrlSearchParameterNotFoundError extends Error {
  code = ErrorCode.URL_SEARCH_PARAMETER_NOT_FOUND;

  constructor({ name }: { name: string }) {
    super(
      `Url parameter (${name}) not found error (Error code: ${ErrorCode.URL_SEARCH_PARAMETER_NOT_FOUND}). Please make sure that you have the correct link.`,
    );
  }
}
