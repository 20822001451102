import { css } from '@emotion/css';
import { Color, ColorConstant, Display, JustifyContent, Spacing } from 'src/styles';

export const container = css`
  background-color: ${ColorConstant.GRAY1};
  height: 100%;
  @media (max-width: 489px) {
    width: 90%;
    align-self: center;
  }
`;

export const myipoLogo = css`
  margin: 5.875rem auto 2.625rem;
  ${Display.flex}
`;

export const cardWrapper = css`
  width: 564px;
  background: ${Color.GRAYSCALE.GRAY1};
  box-shadow: 0px 12px 16px rgba(2, 85, 163, 0.15);
  border-radius: 8px;
`;

export const buttonWrapper = css`
  ${Display.flex}
  ${JustifyContent.center}
  ${Spacing.mt32}
  ${Spacing.mb16}
`;

export const spinnerHeight = css`
  height: 230px !important;
`;
