import { css } from '@emotion/css';

import { ScreenBreakpoint, Font, Display, Color, Spacing, FlexDirection, AlignItems, TextAlign } from '../../styles';

export const footerP = css`
  ${Font.xs}
  color: ${Color.GRAYSCALE.GRAY8};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Font.md};
  }
`;

export const offeringTypesRow = css`
  @media (max-width: 489px) {
    ${Display.block};
    width: 100%;
  }
`;

export const footerMain = css`
  width: 100%;
  ${Display.flex};
  ${FlexDirection.column};
  ${AlignItems.center};
  background-color: ${Color.GRAYSCALE.GRAY3};
  ${Spacing.pt0};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.p0};
  }
`;

export const sectionContainer = css`
  width: 100%;
  max-width: 75rem;
  @media (max-width: ${ScreenBreakpoint.desktop.max}) {
    ${Spacing.px20};
  }
`;

export const footerLogoSection = css`
  ${Display.flex};
  ${AlignItems.center};
`;

export const footerLogo = css`
  width: 5rem;
  ${Spacing.mr64};
  ${Spacing.my32};
`;

export const footerTopLink = css`
  ${Spacing.mr32};
  ${Font.sm};
  color: ${Color.BRAND.BRAND5};
  text-decoration: none;
  &:hover {
    color: ${Color.BRAND.BRAND5};
  }
`;

export const subLinksSection = css`
  ${Spacing.mt32};
`;

export const footerSubLink = css`
  ${Spacing.mr32};
  ${Font.sm};

  color: ${Color.BRAND.BRAND5};
  text-decoration: none;

  &:hover {
    color: ${Color.BRAND.BRAND6};
  }

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Display.block};
    ${Spacing.mr0};
    ${Spacing.mt12};
  }
`;

export const quoteMediaSection = css`
  width: 100%;
  ${Spacing.mt32};
  ${Font.xs};
  color: ${Color.GRAYSCALE.GRAY8};
  ${TextAlign.center};
`;

export const quoteMediaLogo = css`
  ${Spacing.mt12};
`;
