import { MouseEvent } from 'react';

import * as Styles from './WarningAlertDescription.styles';

interface WarningAlertDescriptionProps {
  text: string | JSX.Element | null;
  onClick?: () => void;
  className?: string;
  testId?: string;
}

export const WarningAlertDescription = ({ text, onClick, testId, className = '' }: WarningAlertDescriptionProps) => {
  const _onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      event.stopPropagation();
      onClick();
    }
  };

  return (
    <div className={Styles.description.concat(' ' + className).trim()}>
      <div>
        <span className={Styles.text} data-testid={testId}>
          {text}
        </span>
        {onClick && (
          <a className={Styles.viewMoreButton} onClick={_onClick}>
            [View More]
          </a>
        )}
      </div>
    </div>
  );
};
