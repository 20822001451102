import { css } from '@emotion/css';
import { ColorConstant, Display, Font, FontWeight, Spacing } from 'src/styles';

export const displayValue = css`
  cursor: pointer;
`;

export const copiedTooltip = css`
  .ant-tooltip-inner {
    ${Display.flex}
    background-color: ${ColorConstant.GRAY2};
    position: relative;
    border: 1px solid ${ColorConstant.GRAY4};
    text-decoration: none;
    border-radius: 3px;
    ${Spacing.p12}
    ${Font.md}
    > i {
      font-size: 20px;
      ${FontWeight.bold}
      color: ${ColorConstant.GREEN6};
      ${Spacing.mr4}
    }
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: ${ColorConstant.GRAY2};
  }
`;
