import { CurrencyInput, CurrencyInputProps } from '../MCurrencyInput/CurrencyInput';
import { MFormItem } from '../MFormItem/MFormItem';

export interface MFormCurrencyInputProps extends CurrencyInputProps {
  label: string;
  tooltip?: {
    type: 'modal' | 'popover';
    title: string;
    content: string | JSX.Element;
  };
  size?: 'small' | 'middle' | 'large';
  weight?: 'light' | 'medium';
  testId?: string;
}

export const MFormCurrencyInput = ({
  label,
  placeholder,
  value,
  defaultValue,
  error,
  onChange,
  disabled,
  weight,
  tooltip,
  className,
  testId,
}: MFormCurrencyInputProps) => {
  return (
    <MFormItem label={label} tooltip={tooltip} weight={weight} testId={testId + '-' + 'label'}>
      <CurrencyInput
        testId={testId + '-' + 'input'}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        className={className}
      />
    </MFormItem>
  );
};
