import { MButton } from 'src/lib/FormComponents/MButton/MButton';
import { OfferOrder } from 'src/models/offer-orders.model';

import * as Styles from '../CancelTransferButton/CancelTransferButton.styles';

interface CancelOfferOrderButtonProps {
  value: OfferOrder;
  onClick: (offerOrder: OfferOrder) => void;
}

export const CancelOfferOrderButton = ({ value, onClick }: CancelOfferOrderButtonProps) => {
  if (value.status.isPendingFunds || value.status.isPendingOfferClose || value.status.isPendingAction) {
    return (
      <MButton type='tertiary' danger className={Styles.cancelButton} onClick={() => onClick(value)}>
        Cancel
      </MButton>
    );
  }

  return null;
};
