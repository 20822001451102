import { css } from '@emotion/css';

export const FlexDirection = {
  row: css({
    flexDirection: 'row',
  }),
  rowReverse: css({
    flexDirection: 'row-reverse',
  }),
  column: css({
    flexDirection: 'column',
  }),
  columnReverse: css({
    flexDirection: 'column-reverse',
  }),
};

export const FlexWrap = {
  noWrap: css({
    flexWrap: 'nowrap',
  }),
  wrap: css({
    flexWrap: 'wrap',
  }),
  wrapReverse: css({
    flexWrap: 'wrap-reverse',
  }),
};

export const JustifyContent = {
  flexStart: css({
    justifyContent: 'flex-start',
  }),
  flexEnd: css({
    justifyContent: 'flex-end',
  }),
  center: css({
    justifyContent: 'center',
  }),
  spaceBetween: css({
    justifyContent: 'space-between',
  }),
  spaceAround: css({
    justifyContent: 'space-around',
  }),
  spaceEvenly: css({
    justifyContent: 'space-evenly',
  }),
  start: css({
    justifyContent: 'start',
  }),
  end: css({
    justifyContent: 'end',
  }),
  left: css({
    justifyContent: 'left',
  }),
  right: css({
    justifyContent: 'right',
  }),
};

export const AlignContent = {
  flexStart: css({
    alignContent: 'flex-start',
  }),
  flexEnd: css({
    alignContent: 'flex-end',
  }),
  center: css({
    alignContent: 'center',
  }),
  spaceBetween: css({
    alignContent: 'space-between',
  }),
  spaceAround: css({
    alignContent: 'space-around',
  }),
  spaceEvenly: css({
    alignContent: 'space-evenly',
  }),
  stretch: css({
    alignContent: 'stretch',
  }),
  start: css({
    alignContent: 'start',
  }),
  end: css({
    alignContent: 'end',
  }),
  baseline: css({
    alignContent: 'baseline',
  }),
};

export const AlignItems = {
  stretch: css({
    alignItems: 'stretch',
  }),
  flexStart: css({
    alignItems: 'flex-start',
  }),
  flexEnd: css({
    alignItems: 'flex-end',
  }),
  center: css({
    alignItems: 'center !important',
  }),
  baseline: css({
    alignItems: 'baseline',
  }),
  start: css({
    alignItems: 'start',
  }),
  end: css({
    alignItems: 'end',
  }),
  selfStart: css({
    alignItems: 'self-start',
  }),
  selfEnd: css({
    alignItems: 'self-end',
  }),
};

export const AlignSelf = {
  auto: css({
    alignSelf: 'auto',
  }),
  flexStart: css({
    alignSelf: 'flex-start',
  }),
  flexEnd: css({
    alignSelf: 'flex-end',
  }),
  center: css({
    alignSelf: 'center',
  }),
  baseline: css({
    alignSelf: 'baseline',
  }),
  stretch: css({
    alignSelf: 'stretch',
  }),
};

export const FlexGrow = {
  zero: css({
    flexGrow: 0,
  }),
  one: css({
    flexGrow: 1,
  }),
  two: css({
    flexGrow: 2,
  }),
  three: css({
    flexGrow: 3,
  }),
};

export const FlexShrink = {
  zero: css({
    flexShrink: 0,
  }),
  one: css({
    flexShrink: 1,
  }),
  two: css({
    flexShrink: 2,
  }),
  three: css({
    flexShrink: 3,
  }),
};
