import { useRef, useState } from 'react';

import { Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { doClearUpdatePhoneNumber, doUpdatePhoneNumber } from 'src/actions';
import { US_PHONE_NUMBER_PREFIX } from 'src/constants';
import { AccountHolderPhoneNumberTypeDto } from 'src/dtos';
import { PhoneNumberInput, PhoneNumberOption, MModal } from 'src/lib';
import ConfirmPanel from 'src/lib/Miscellaneous/ConfirmPanel/ConfirmPanel';
import Spinner from 'src/lib/Miscellaneous/Spinner';
import { ConfirmPanelType } from 'src/models/confirm-panel.enum';
import { formatValidationSchemaError, phoneNumberValidator } from 'src/utils';
import * as Yup from 'yup';

import * as Styles from './UpdatePhoneNumberModal.styles';

interface UpdatePhoneNUmberModalProps {
  isVisible: boolean;
  onClose: () => void;
}

interface UpdatePhoneNumberFormValues {
  phoneNumber?: PhoneNumberOption[];
}

const validationSchema = Yup.object().shape({
  phoneNumber: phoneNumberValidator,
});

export const UpdatePhoneNumberModal = ({ isVisible, onClose }: UpdatePhoneNUmberModalProps) => {
  const dispatch = useDispatch();
  const isUpdatePhoneNumberLoading: boolean = useSelector((state: any) => state.user.updatePhoneNumber?.isLoading);
  const hasUpdatePhoneNumberFailed: boolean = useSelector((state: any) => state.user.updatePhoneNumber?.__failed);
  const updatePhoneNumberError: string = useSelector((state: any) => state.user.updatePhoneNumber?.message);
  const updatePhoneNumberSucceeded: boolean = useSelector((state: any) => state.user.updatePhoneNumber?.__succeeded);
  const formRef = useRef<FormikProps<UpdatePhoneNumberFormValues> | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const isRequestCompleted = () => hasUpdatePhoneNumberFailed || updatePhoneNumberSucceeded;

  const _onClose = () => {
    onClose();
    dispatch(doClearUpdatePhoneNumber());
  };

  const onSubmit = (values: UpdatePhoneNumberFormValues) => {
    const phoneNumber = `${values?.phoneNumber?.[0].prefix}${values?.phoneNumber?.[0].value}`;
    dispatch(doUpdatePhoneNumber({ phoneNumber }));
  };

  const onPrimaryButtonClick = () => {
    if (isRequestCompleted()) {
      _onClose();

      return;
    }
    formRef.current?.submitForm();
  };
  const renderPrimaryButtonText = () => (isRequestCompleted() ? 'Close' : 'Submit');

  const renderPrimaryButtonType = () => (isRequestCompleted() ? 'tertiary' : 'primary');

  const renderUpdatePhoneNumberModalContent = () => {
    if (isUpdatePhoneNumberLoading) {
      return <Spinner customSpinnerStyle={Styles.spinnerHeight} />;
    }

    if (hasUpdatePhoneNumberFailed) {
      return (
        <ConfirmPanel
          type={ConfirmPanelType.ERROR}
          iconSource={'ri-close-circle-line'}
          title={'Something went wrong'}
          description={
            updatePhoneNumberError ?? (
              <>
                Unknown error occurred. Please try again later or contact My IPO at{' '}
                <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>
              </>
            )
          }
        />
      );
    }

    if (updatePhoneNumberSucceeded) {
      return (
        <ConfirmPanel
          type={ConfirmPanelType.SUCCESS}
          iconSource={'ri-chat-check-line'}
          title={'Phone number successfully changed'}
          description={'You have successfully changed your phone number.'}
        />
      );
    }

    return (
      <Formik<UpdatePhoneNumberFormValues>
        enableReinitialize
        innerRef={form => (formRef.current = form)}
        initialValues={{
          phoneNumber: [
            {
              type: AccountHolderPhoneNumberTypeDto.Mobile,
              value: '',
              prefix: US_PHONE_NUMBER_PREFIX,
            },
          ],
        }}
        validate={async values => {
          try {
            await validationSchema.validate(values, {
              abortEarly: false,
            });
            setIsFormValid(true);

            return {};
          } catch (error: Yup.ValidationError | any) {
            setIsFormValid(false);

            return formatValidationSchemaError(error);
          }
        }}
        onSubmit={onSubmit}>
        {(form: FormikProps<UpdatePhoneNumberFormValues>) => {
          return (
            <Form>
              <PhoneNumberInput
                placeholder='Phone Number'
                multiple={false}
                international
                defaultValue={{
                  type: AccountHolderPhoneNumberTypeDto.Mobile,
                  value: '',
                  prefix: US_PHONE_NUMBER_PREFIX,
                }}
                options={
                  form.values.phoneNumber
                    ? form.values.phoneNumber
                    : [{ type: AccountHolderPhoneNumberTypeDto.Mobile, value: '', prefix: US_PHONE_NUMBER_PREFIX }]
                }
                onChange={value => {
                  form.setFieldValue('phoneNumber', value);
                }}
                error={form.errors.phoneNumber}
              />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <MModal
      title='Change Phone Number'
      visible={isVisible}
      centered
      customHeight={hasUpdatePhoneNumberFailed ? 300 : updatePhoneNumberSucceeded ? 200 : 150}
      onClose={_onClose}
      primaryButtonText={renderPrimaryButtonText()}
      primaryButtonType={renderPrimaryButtonType()}
      onPrimaryButtonClick={onPrimaryButtonClick}
      isDisabledPrimaryButton={isUpdatePhoneNumberLoading || !isFormValid}>
      {renderUpdatePhoneNumberModalContent()}
    </MModal>
  );
};
