export const arbitrationClausesLinks: Array<{ label: string; url: string }> = [
  {
    label: 'Apex Arbitration Clause',
    url: '/static/docs/Apex_Customer_Agreement_for_My_IPO_v5.6_20200424_final.pdf#page=3',
  },
  {
    label: 'Cambria Capital, LLC dba My IPO Arbitration Clause',
    url: '/static/docs/My_IPO_Customer_Agreement-TB_Apex_final_102122.pdf',
  },
];
