import { css } from '@emotion/css';
import { ColorConstant, Font, Spacing } from 'src/styles';

export const container = (size: 'small' | 'medium' | 'large') => css`
  ${size === 'small' ? Font.md : size === 'medium' ? Font.lg : Font.h1}
  width: 100%;
  background: #ffffff;
  min-height: 64px;
`;

export const col = css`
  border-top: 1px solid #ccdded;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);
`;

export const icon = (type: 'info' | 'warning' | 'error') => css`
  ${Font.h1}
  ${Spacing.mr4}
  color: ${type === 'info'
    ? ColorConstant.BRAND6
    : type === 'warning'
    ? ColorConstant.ORANGE6
    : ColorConstant.RED6} !important;
`;
