import moment from 'moment';

import { DateConstant } from '../constants/common.constants';
import { StatementConstant } from '../constants/statements.constants';
import { AccountStatementType } from '../dtos/statements.dtos';
import { AccountStatement } from '../models/statements.models';

export const getAccountStatementFilename = (statement: AccountStatement) => {
  let result = `${moment(statement.date).format(DateConstant.US_FORMAT)}-MyIPO-${statement.type}`;

  if (statement.type === AccountStatementType.TaxForm) {
    result = result.concat(`-${statement.form}`);
  }

  return result.concat(StatementConstant.FILE_EXTENSION);
};
