import { PropsWithChildren } from 'react';

import { Button } from 'antd';

import * as Styles from './MLinkButton.styles';

export interface MLinkButtonProps {
  onClick: () => void;
  className?: string;
  icon: 'info' | 'question';
}

export const MLinkButton = ({ onClick, className, icon = 'info', children }: PropsWithChildren<MLinkButtonProps>) => {
  return (
    <Button type='link' onClick={onClick} className={`${Styles.button} ${className ?? ''}`}>
      <span>
        <i className={`${Styles.icon(icon)}`} />
      </span>
      <span>{children}</span>
    </Button>
  );
};
