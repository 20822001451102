import Parser from 'html-react-parser';

export const OfferDescriptionTab = ({ offering }: any) => {
  let { description } = offering;

  while (description.includes('{{IMAGE_SOURCE="')) {
    const indexOf = description.indexOf('{{IMAGE_SOURCE="');
    let url = description.substring(indexOf + '{{IMAGE_SOURCE="'.length);
    url = url.substring(0, url.indexOf('"'));
    const partly = `{{IMAGE_SOURCE="${url}"`;
    let postUrlPart = description.substring(description.indexOf(partly) + partly.length);
    postUrlPart = postUrlPart.substring(0, postUrlPart.indexOf('}}'));
    description = description.replace(`{{IMAGE_SOURCE="${url}"${postUrlPart}}}`, `<img src="${url}"${postUrlPart}/>`);
  }

  while (description.includes('{{IMAGE_SOURCE_MOBILE="')) {
    const indexOf = description.indexOf('{{IMAGE_SOURCE_MOBILE="');
    let url = description.substring(indexOf + '{{IMAGE_SOURCE_MOBILE="'.length);
    url = url.substring(0, url.indexOf('"'));
    const partly = `{{IMAGE_SOURCE_MOBILE="${url}"`;
    let postUrlPart = description.substring(description.indexOf(partly) + partly.length);
    postUrlPart = postUrlPart.substring(0, postUrlPart.indexOf('}}'));
    description = description.replace(`{{IMAGE_SOURCE_MOBILE="${url}"${postUrlPart}}}`, '');
  }

  return <div>{Parser(description)}</div>;
};
