import { Col, Row } from 'antd';
import { OrderStatusTag } from 'src/lib';
import { Order } from 'src/models/orders.models';

import * as Styles from './OrderTypeCol.styles';

interface OrderTypeColProps {
  testId?: string;
  value: Order;
}

export const OrderTypeCol = ({ value, testId }: OrderTypeColProps) => {
  const renderPartialOrderIfFound = (): JSX.Element | null => {
    if (value.isPartial) {
      return (
        <span className={Styles.partialOrder}>
          Partially Filled {value.filledQuantity}/{value.quantity}
        </span>
      );
    }

    return null;
  };

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[8, 1]} data-testid={testId}>
          <Col className={Styles.description}>{value.action.isBuy ? 'Security (Buy)' : 'Security (Sell)'}</Col>
          <Col>
            <OrderStatusTag value={value.status.label} />
          </Col>
          <Col>{renderPartialOrderIfFound()}</Col>
          <Col className={Styles.descriptionSubtitle} span={24}>
            {value.symbol}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
