import { css } from '@emotion/css';
import { Font } from 'src/styles';

export const radio = css`
  .ant-radio-checked .ant-radio-inner {
    border-color: #0255a3 !important;
  }

  .ant-radio-inner::after {
    background-color: #0255a3 !important;
  }

  .ant-radio.ant-radio-checked.ant-radio-disabled .ant-radio-inner::after {
    border-color: #d9d9d9 !important;
  }

  .ant-radio.ant-radio-checked.ant-radio-disabled .ant-radio-inner {
    border-color: #d9d9d9 !important;
  }
`;

export const label = css`
  ${Font.lg}
`;

export const description = css`
  ${Font.sm}
`;
