import produce from 'immer';

import { Type } from '../actions/utils';
import { SeverityEnum, ToastMessage } from '../typings/commonTypes';

import initialState from './initial';

export const toastMessages = (state: any = { data: initialState.toastMessages }, action: any) => {
  switch (action.type) {
    case Type.TOAST_MESSAGES_REMOVE:
      return removeToastMessage(state, action);

    case Type.TOAST_MESSAGES_ADD:
      return addToastMessage(state, action);

    case Type.CLEAR_TOAST_MESSAGES:
      return clearToastMessages(state);
    default:
      return state;
  }
};

export const addToastMessage = produce((draftState: any, action: any) => {
  const hasSameError =
    draftState.data.list.filter(
      (item: ToastMessage) => item.message === action.payload?.message && item.severity !== SeverityEnum.Success,
    )?.length > 0;

  if (!hasSameError) {
    draftState.data = {
      list: [...draftState.data.list, action.payload],
      open: action.payload,
      close: draftState.data.close,
      clearList: draftState.data.clearList,
    };
  }
});

export const removeToastMessage = produce((draftState: any, action: any) => {
  const newList = draftState.data.list.filter((item: ToastMessage) => item.key !== action.payload?.key);
  draftState.data.list = draftState?.data?.list ? newList : [];

  if (draftState.data?.open?.key === action.payload.key) {
    draftState.data.open = null;
  }

  draftState.data.close = action.payload.key;
});

export const clearToastMessages = produce((draftState: any) => {
  const clerableToasts: ToastMessage[] = draftState.data.list.filter((aToast: ToastMessage) => aToast.isClearable);
  draftState.data.list = draftState?.data.list.filter((aToast: ToastMessage) => !aToast.isClearable);
  draftState.data.clearList = clerableToasts;
});

export default toastMessages;
