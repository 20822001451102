import { GetInvestigationListResponseDto } from 'src/dtos';
import { InvestigationStatus, GetInvestigationListResponse, InvestigationDocumentName } from 'src/models';

export const mapGetInvestigationListResponseDtoToModel = (
  response: GetInvestigationListResponseDto,
): GetInvestigationListResponse => {
  return {
    id: response.state,
    applications:
      response.applications?.map(application => ({
        ...application,
        appCategories:
          application.appCategories?.map(category => ({
            ...category,
            requestedDocuments:
              category.requestedDocuments?.map(document => new InvestigationDocumentName(document)) ?? [],
          })) ?? [],
      })) ?? [],
    investigations:
      response.investigations?.map(investigation => ({
        ...investigation,
        cipCategories:
          investigation.cipCategories?.map(category => ({
            ...category,
            requestedDocuments:
              category.requestedDocuments?.map(document => new InvestigationDocumentName(document)) ?? [],
          })) ?? [],
      })) ?? [],
    status: new InvestigationStatus(response.state),
  };
};
