import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { orderBy } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { doClearGetOfferOrderDetails, doGetOfferOrderDetails, getOfferDetails } from 'src/actions';
import { DateConstant } from 'src/constants';
import { OfferOrderStatusDto } from 'src/dtos';
import { ModalField, OfferOrderStatusTag } from 'src/lib';
import CurrencyField from 'src/lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { OfferDetails, OfferOrder, OfferOrderDetails, OfferOrderRefund } from 'src/models';

import { CurrencyRangeField } from '../CurrencyRangeField/CurrencyRangeField';
import Spinner from '../Miscellaneous/Spinner';
import { MModal } from '../MModal/MModal';
import * as Styles from '../TransferDetailsModal/TransferDetailsModal.styles';

import { OfferOrderDetailsDisclaimer } from './OfferOrderDetailsDisclaimer';
import { OfferOrderRefundDisclaimer } from './OfferOrderRefundDisclaimer';

export interface OfferOrderDetailsModalProps {
  value: OfferOrder;
  isOpen: boolean;
  onClose: () => void;
}

export const OfferOrderDetailsModal = ({ value, isOpen, onClose }: OfferOrderDetailsModalProps) => {
  const dispatch = useDispatch();
  const offerOrderDetails: OfferOrderDetails | undefined = useSelector((state: any) => state.offerOrders.details.data);

  const offerDetails: OfferDetails | undefined = useSelector((state: any) => state.offers.offerDetails?.data);
  const isOfferDetailsLoading: boolean = useSelector((state: any) => state.offers.offerDetails?.isLoading);

  const [lastRefund, setLastRefund] = useState<OfferOrderRefund | null>(null);

  const renderContent = () => {
    if (isOfferDetailsLoading) {
      return <Spinner />;
    }

    return (
      <>
        <Row>
          <ModalField
            label='Status'
            value={<OfferOrderStatusTag value={value.status.label} />}
            testId={'offer-order-details-status'}
          />
          <ModalField label='Type' value={'Offer Order(s)'} testId={'offer-order-details-type'} />
          <ModalField label='Offer' value={value.offerName} testId={'offer-order-details-offer'} />
          {value.conditional &&
          ![OfferOrderStatusDto.Approved, OfferOrderStatusDto.Complete].includes(value.status.value) ? (
            <>
              <ModalField
                label='Price Range'
                value={
                  <CurrencyRangeField min={value.conditional.minSharePrice} max={value.conditional.maxSharePrice} />
                }
                testId={'offer-order-details-price-range'}
              />
              <ModalField
                label='Investment Amount'
                value={
                  <CurrencyField
                    value={-value.conditional.totalInvestment}
                    className={Styles.amountValue({ isNegative: true })}
                  />
                }
                testId={'offer-order-details-investment-amount'}
              />
            </>
          ) : (
            <>
              <ModalField label='Share Quantity' value={value.quantity} testId={'offer-order-details-share-quantity'} />
              <ModalField
                label='Share Price'
                value={<CurrencyField value={value.purchasePrice} />}
                testId={'offer-order-details-share-price'}
              />
              <ModalField
                label='Investment Amount'
                value={<CurrencyField value={-value.value} className={Styles.amountValue({ isNegative: true })} />}
                testId={'offer-order-details-investment-amount'}
              />
            </>
          )}
          <ModalField
            label='Date of Creation'
            value={<span>{moment(value.createdAt).format(DateConstant.DEFAULT_DATETIME_FORMAT)}</span>}
            testId={'offer-order-details-date-of-creation'}
          />
          {value.offerCloseDate && (
            <ModalField label='Closing Date' value={value.offerCloseDate} testId={'offer-order-details-closing-date'} />
          )}
          {value.paymentType.label && (
            <ModalField
              label='Payment Type'
              value={value.paymentType.label}
              testId={'offer-order-details-payment-type'}
            />
          )}
          {lastRefund && (
            <ModalField
              label='Refund Type'
              value={lastRefund.mechanism.label}
              testId={'offer-order-details-refund-type'}
            />
          )}
          {lastRefund && lastRefund.bankName && (
            <ModalField
              label='Refund Bank Name'
              value={lastRefund.bankName}
              testId={'offer-order-details-refund-bank-name'}
            />
          )}
        </Row>
        {lastRefund && (
          <Row className={Styles.disclaimerContainer}>
            <Col>
              <OfferOrderRefundDisclaimer value={lastRefund} status={value.status} />
            </Col>
          </Row>
        )}
        <Row className={Styles.disclaimerContainer}>
          <Col data-testid={'offer-order-modal-disclaimer'}>
            <OfferOrderDetailsDisclaimer value={value} offer={offerDetails} />
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    if (offerOrderDetails) {
      const sortedRefunds = orderBy(offerOrderDetails.refunds, 'createdAt', 'desc');
      setLastRefund(sortedRefunds?.[0] ?? null);
    }
  }, [offerOrderDetails]);

  useEffect(() => {
    dispatch(doGetOfferOrderDetails(value.id));

    return () => {
      dispatch(doClearGetOfferOrderDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (offerDetails?.id !== value.offerId && !isOfferDetailsLoading && value.status.isCompleted) {
      dispatch(getOfferDetails(value.offerId));
    }
  }, [offerDetails]);

  return (
    <MModal
      title={`${value.isExternal ? 'Escrow ' : ''}Offer Order(s) Details`}
      visible={isOpen}
      onClose={onClose}
      tertiaryButtonText='Close'
      onTertiaryButtonClick={onClose}>
      {renderContent()}
    </MModal>
  );
};
