import { Tooltip } from 'antd';
import Parser from 'html-react-parser';
import { findOfferCloseDate, findOfferClosedDate, isOfferClosed } from 'src/utils';

import { CurrencyRangeField } from '../../../../lib/CurrencyRangeField/CurrencyRangeField';
import CurrencyField from '../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { OfferDetails } from '../../../../models/offers.models';
import { Color } from '../../../../styles';

import * as Styles from './OfferDetailsTab.styles';

export const getFirstColumnDetails = (offer: OfferDetails) => {
  return [
    {
      label: 'Offering Type',
      value: offer.offerType,
      hidden: (!offer.offerType || offer.offerType.length === 0) && offer.isComingSoon,
    },
    {
      label: 'Offering Category',
      value: 'Conditional',
      hidden: !offer.isConditional,
    },
    {
      label: 'Industry',
      value: offer.industry,
      hidden: offer.isComingSoon && !offer.industry,
    },
    {
      label: 'Sector',
      value: offer.sector,
      hidden: offer.isComingSoon && !offer.sector,
    },
    {
      label: isOfferClosed(offer) ? 'Closed Date' : offer.isRecurring ? 'Next Closing Date' : 'Closing Date',
      value: isOfferClosed(offer) ? findOfferClosedDate(offer) ?? '-' : findOfferCloseDate(offer) ?? '-',
      hidden: offer.isComingSoon,
    },
    {
      label: 'Yield',
      value: offer.yieldDescription,
      hidden:
        (offer.isComingSoon && !offer.yieldDescription) || offer?.nonVisibleAppFields?.includes('yieldDescription'),
    },
    {
      label: 'Exit Strategy/Liquidity',
      value: Parser(offer.exitDescription),
      hidden: offer.isComingSoon && !offer.exitDescription,
    },
  ];
};

export const getSecondColumnDetails = (offer: OfferDetails) => {
  return [
    {
      label: 'Security Type',
      value: offer.securityType,
      description: '',
      hidden: offer.isComingSoon,
    },
    {
      label: 'Offering Price',
      value: <CurrencyField value={offer.pricePerShare} />,
      description: 'Per share price at which the issued securities are made available for purchase.',
      hidden: offer.isConditional || (offer.isComingSoon && !offer.pricePerShare),
    },
    {
      label: 'Offering Price Range',
      value: <CurrencyRangeField min={offer.minSharePrice} max={offer.maxSharePrice} />,
      hidden: !offer.isConditional,
    },
    {
      label: 'Offering Amount (Min)',
      value: <CurrencyField value={offer.minTotalRaiseCurrencyAmt} />,
      description: 'The minimum amount the offer must reach in order to be fulfilled.',
      hidden: offer.isComingSoon && !offer.minTotalRaiseCurrencyAmt,
    },

    {
      label: 'Offering Amount (Max)',
      value: <CurrencyField value={offer.maxTotalRaiseCurrencyAmt} />,
      description: 'The maximum amount an offer will collect before closing.',
      hidden: offer.isComingSoon && !offer.maxTotalRaiseCurrencyAmt,
    },
    {
      label: 'Min Investment',
      value: <CurrencyField value={offer.minimumInvestCurrencyAmt} />,
      description: 'The minimum amount you must invest in the offer in order to qualify.',
      hidden: offer.isComingSoon && !offer.minimumInvestCurrencyAmt,
    },
    {
      label: 'Max Investment',
      value: (offering => {
        if (isRegAPlus(offering)) {
          const hasMax = Number.isFinite(offering.maximumInvestCurrencyAmt);
          const nonAccredTip =
            "An investor who does not meet the net worth requirements for an accredited investor under the Securities & Exchange Commission's Regulation D. A non-accredited individual investor is one who has a net worth of less than $1 million (including spouse) and who earned less than $200,000 annually ($300,000 with spouse) in the current calendar year.";
          const accredTip =
            "To be considered an accredited investor, one must have a net worth of at least $1,000,000, excluding the value of one's primary residence, or have income at least $200,000 each year for the last two years (or $300,000 combined income if married) and have the expectation to make the same amount in the current calendar year.";

          return (
            <div>
              {hasMax ? <CurrencyField value={offer.maximumInvestCurrencyAmt} /> : null}
              {hasMax ? <br /> : null}
              <span>
                <Tooltip
                  getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
                  placement='top'
                  color={Color.GRAYSCALE.GRAY1}
                  title={nonAccredTip}>
                  <span className={Styles.tooltipSpan}>Non-accredited investors</span>
                </Tooltip>
                &nbsp; can invest a maximum of the greater of 10% of their net worth or 10% of their net income in a Reg
                A+ offering (per offering).
                {!hasMax ? (
                  <span>
                    &nbsp;No maximum limit for&nbsp;
                    <Tooltip
                      getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
                      placement='top'
                      color={Color.GRAYSCALE.GRAY1}
                      title={accredTip}>
                      <span className={Styles.tooltipSpan}>accredited</span>
                    </Tooltip>
                    &nbsp;
                    <Tooltip
                      getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
                      placement='top'
                      color={Color.GRAYSCALE.GRAY1}
                      title={accredTip}>
                      <span className={Styles.tooltipSpan}>investors.</span>
                    </Tooltip>
                  </span>
                ) : null}
              </span>
            </div>
          );
        }

        return Number.isFinite(offering.maximumInvestCurrencyAmt) ? (
          <CurrencyField value={offer.maximumInvestCurrencyAmt} />
        ) : (
          <div>No maximum investment</div>
        );
      })(offer),
      description: 'The maximum amount you are allowed to invest in an offer.',
      hidden: offer.isComingSoon && !offer.maximumInvestCurrencyAmt,
    },
  ];
};

const isRegAPlus = (offer: OfferDetails) =>
  offer.offerType && offer.offerType.toLowerCase().replace(/\s+/g, '').includes('rega+');
