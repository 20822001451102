import { call, put, select, takeEvery } from 'redux-saga/effects';
import { mapGetInvestigationListResponseDtoToModel } from 'src/mappers/investigations.mappers';

import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, TReduxAction } from '../typings/commonTypes';

import { HttpClient, replacePlaceholders, safeSaga } from './utils';

export function* getInvestigations(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { accountId } = action?.payload;

  const url = replacePlaceholders(Url.INVESTIGATIONS_URL, {
    accountId,
  });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);

  yield put({
    type: State.actionSucceeded(Type.GET_INVESTIGATIONS),
    payload: mapGetInvestigationListResponseDtoToModel(response.data),
  });
}

/**
 * Investigations sagas
 */
export default function* investigationsSaga() {
  yield takeEvery(State.actionRequested(Type.GET_INVESTIGATIONS), safeSaga(getInvestigations, Type.GET_INVESTIGATIONS));
}
