import { css } from '@emotion/css';
import { ColorConstant, Font, FontWeight, Spacing } from 'src/styles';

export const container = css`
  ${Spacing.my8}
`;

export const label = (weight: 'light' | 'medium') => css`
  ${Spacing.py4}
  ${Spacing.px0}
  ${weight === 'light' ? Font.md : Font.lg}
  ${weight === 'light' ? FontWeight.semibold : FontWeight.bold}
  color: ${ColorConstant.GRAY8};
`;

export const value = css`
  ${FontWeight.normal}
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
`;

export const description = css`
  ${Font.sm}
`;
