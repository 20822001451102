import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { Spacing, TextAlign } from 'src/styles';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import * as Styles from './SubmissionResult.styles';

interface SubmissionResultProps {
  type: 'success' | 'error';
  title?: string;
  message?: string | JSX.Element;
}

export const SubmissionResult = ({
  type,
  title = type === 'error' ? 'Submission Failed' : 'Submission Succeeded',
  message,
}: SubmissionResultProps) => {
  return (
    <Row>
      <Col span={24} className={Styles.title(type)}>
        {type === 'error' ? <i className={'ri-error-warning-line'} /> : <i className={'ri-checkbox-circle-line'} />}
        <span>{title}</span>
      </Col>
      <Col span={24} className={cx(Spacing.my12, type === 'success' ? TextAlign.center : TextAlign.left)}>
        {type === 'error' ? (
          <MFormItemError value={message} />
        ) : (
          <span className={Styles.successMessage}>{message}</span>
        )}
      </Col>
    </Row>
  );
};
