import { PropsWithChildren } from 'react';

import { Layout } from 'antd';

import { Footer } from '../../Footer/Footer';

import * as Styles from './styles';

const { Content } = Layout;

export interface GlobalLyoutProps extends PropsWithChildren {}

export const GlobalLayout = ({ children }: GlobalLyoutProps) => {
  return (
    <Layout>
      <Content className={Styles.contentWrapper}>{children}</Content>
      <Footer />
    </Layout>
  );
};
