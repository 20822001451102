import { css } from '@emotion/css';
import { ColorConstant, Font, FontWeight, Spacing } from 'src/styles';

export const container = css`
  ${Spacing.my8}
`;

export const label = css`
  ${Font.lg}
  ${Spacing.py4}
  ${Spacing.px0}
  color: ${ColorConstant.GRAY8};
`;

export const value = css`
  ${Font.md}
  ${FontWeight.normal}
  color: ${ColorConstant.GRAY8};
`;
