import { Col, Row } from 'antd';
import { MAlert } from 'src/lib';
import { OfferOrder } from 'src/models/offer-orders.model';

import { OfferOrderStatusTag } from '../OfferOrderStatusTag/OfferOrderStatusTag';
import { WarningAlertDescription } from '../WarningAlertDescription/WarningAlertDescription';

import * as Styles from './OfferOrderTypeCol.styles';

interface OfferOrderTypeColProps {
  testId?: string;
  value: OfferOrder;
  onViewMore: () => void;
}

export const OfferOrderTypeCol = ({ value, testId, onViewMore }: OfferOrderTypeColProps) => (
  <Row>
    <Col span={24}>
      <Row gutter={[8, 1]} data-testid={testId}>
        <Col className={Styles.description}>Offer Order(s)</Col>
        <Col>
          <OfferOrderStatusTag value={value.status.label} />
        </Col>
        <Col className={Styles.descriptionSubtitle} span={24}>
          {value.offerName}
        </Col>
      </Row>
      <Row>
        {value.paymentType.isCard ? (
          <MAlert
            type='warning'
            showIcon
            icon={<i className='ri-funds-fill' />}
            className={Styles.alert}
            description={
              <WarningAlertDescription
                text='Securities purchased by credit card are funded and held outside of your My IPO account.'
                onClick={onViewMore}
              />
            }
            testId={'history-order-creditcard-disclaimer'}
          />
        ) : value.isExternal ? (
          <MAlert
            type='warning'
            showIcon
            icon={<i className='ri-funds-fill' />}
            className={Styles.alert}
            description={
              <WarningAlertDescription
                text='This type of offering is held & funded outside of My IPO.'
                onClick={onViewMore}
              />
            }
            testId={'history-order-external-disclaimer'}
          />
        ) : null}
      </Row>
    </Col>
  </Row>
);
