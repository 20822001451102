export const LiquidityNeedsTooltipContent = () => (
  <div>
    <p>
      Liquidity is the ability to quickly and easily convert to cash all, or a portion, of the investments in this
      account without experiencing significant loss in value from, for example, the lack of a ready market, or incurring
      significant costs or penalties.
    </p>
    <div>Very Important</div>
    <p>
      The ability to quickly and easily convert to cash all or a portion of the investments in under 5 years in this
      account is very important.
    </p>
    <div>Somewhat Important</div>
    <p>
      The ability to quickly and easily convert to cash all or a portion of the investments in this account in over 5
      years is somewhat important.
    </p>
    <div>Not Important</div>
    <p>
      The ability to quickly and easily convert to cash all or a portion of the investments in this account is not
      important.
    </p>
  </div>
);
