import React, { PropsWithChildren } from 'react';

import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { BacklinkButton } from '../../Miscellaneous/BacklinkButton/BacklinkButton';

import * as Styles from './NewOfferOrderLayout.styles';

type NewOfferOrderStep = 'new' | 'documents' | 'security-delivery' | 'confirm' | 'create-succeeded' | 'create-failed';

const offerStepList: NewOfferOrderStep[] = ['new', 'documents', 'confirm'];

const externalOfferWithManySecurityDeliveryOptionsStepList: NewOfferOrderStep[] = [
  'new',
  'documents',
  'security-delivery',
  'confirm',
];

const stepTitleMapper = new Map<NewOfferOrderStep, string>([
  ['new', 'Offer Order'],
  ['documents', 'Offer Documents & Disclosures'],
  ['security-delivery', 'Security Delivery Information'],
  ['confirm', 'Confirm Order'],
]);

export interface NewOfferorderLayoutProps {
  step: NewOfferOrderStep;
  description?: string | JSX.Element;
  offerId: string;
  hasManySecurityDeliveryOptions?: boolean;
}

export const NewOfferOrderLayout = ({
  children,
  step,
  description,
  offerId,
  hasManySecurityDeliveryOptions = false,
}: PropsWithChildren<NewOfferorderLayoutProps>) => {
  let location: any = useLocation();

  const getSteps = () =>
    hasManySecurityDeliveryOptions ? externalOfferWithManySecurityDeliveryOptionsStepList : offerStepList;

  return (
    <div>
      <div className={Styles.offerInfoContainer}>
        <div className={Styles.mainContainer}>
          <BacklinkButton
            to={`/offers/${offerId}`}
            state={{ from: location.pathname }}
            label='Back to Offer'
            testId={'offer-order-btn-back-to-offer'}
          />
          {!['create-succeeded', 'create-failed'].includes(step) && (
            <div className={Styles.tabHeader} data-testid={'offer-order-tabs'}>
              {getSteps().map((tab, index) => {
                const isActive = tab === step;

                return (
                  <span key={`${uuidv4()}`} className={Styles.tabItem({ isActive, index })}>
                    {stepTitleMapper.get(tab)}
                  </span>
                );
              })}
            </div>
          )}
          {description && <div className={Styles.description}>{description}</div>}
          <div className={Styles.card}>{children}</div>
        </div>
      </div>
    </div>
  );
};
