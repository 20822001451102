import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from 'src/actions';
import { MInputPassword, MButton, PasswordStrengthBar, CardLayout } from 'src/lib';
import { Images, Spacing } from 'src/styles';

import * as Styles from '../BaseLayout.styles';

import { newPasswordValidationSchema } from './validations';

interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

export const ResetPassword = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isSucceeded = useSelector((state: any) => Boolean(state.user.resetPassword.__succeeded));
  const isRequested = useSelector((state: any) => Boolean(state.user.resetPassword.__requested));

  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const firstName = searchParams.get('firstName') ?? '';
  const lastName = searchParams.get('lastName') ?? '';

  const renderResetPasswordForm = () => {
    return (
      <Formik<ResetPasswordFormValues>
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={newPasswordValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => {
          dispatch(resetPassword({ code, email, password: values.password }));
        }}>
        {form => {
          return (
            <>
              <Row justify='center'>
                <span className={Styles.formTitle}>Forgot Password</span>
              </Row>
              <Row>
                <Col span={24} className={Spacing.my16}>
                  <MInputPassword
                    placeholder='New Password'
                    onChange={(value: string) => form.setFieldValue('password', value)}
                    value={form.values.password}
                    error={form.errors.password}
                    autoComplete='off'
                  />
                </Col>
                <Col span={24} className={Spacing.my16}>
                  <MInputPassword
                    placeholder='Confirm New Password'
                    onChange={(value: string) => form.setFieldValue('confirmPassword', value)}
                    value={form.values.confirmPassword}
                    error={form.errors.confirmPassword}
                    autoComplete='off'
                  />
                </Col>
                <Col span={24}>
                  <span>*New password needs to be different from the existing one.</span>
                </Col>
                <Col span={24}>
                  <PasswordStrengthBar
                    password={form.values.password}
                    confirmPassword={form.values.confirmPassword}
                    firstName={firstName}
                    lastName={lastName}
                    showStrengthLabel
                    showValidationRules
                  />
                </Col>
              </Row>
              <Row justify='center' className={Spacing.my16}>
                <MButton
                  type='secondary'
                  loading={isRequested}
                  disabled={!form.isValid}
                  onClick={() => {
                    form.submitForm();
                  }}>
                  Save
                </MButton>
              </Row>
            </>
          );
        }}
      </Formik>
    );
  };

  const renderResetPasswordSubmitted = () => {
    return (
      <>
        <Row justify='center'>
          <div className={Styles.iconContainer}>
            <img src={Images.Completed} alt='Completed' />
          </div>
        </Row>
        <Row justify='center'>
          <p className={Styles.afterActionTitle}>Password Changed!</p>
        </Row>
        <Row justify='center'>
          <p className={Styles.afterActionSubtitle}>Your password has been successfully changed.</p>
        </Row>

        <Row justify='center'>
          <MButton
            type='secondary'
            onClick={() => {
              navigate('/login');
            }}>
            Go To Log In
          </MButton>
        </Row>
      </>
    );
  };

  return <CardLayout>{!isSucceeded ? renderResetPasswordForm() : renderResetPasswordSubmitted()}</CardLayout>;
};
