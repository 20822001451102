import { css } from '@emotion/css';

import { Color } from '../../../styles';

export const gridStyling = css`
  fill: none;
  stroke: rgba(189, 189, 189);
  stroke-width: 1px;
  path {
    stroke-dasharray: 2 2;
    opacity: 0.3;
  }
  line {
    stroke-dasharray: 2 2;
    opacity: 0.3;
  }
  text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-size: 11px;
    line-height: 11px;
    stroke-dasharray: none;
    stroke: ${Color.GRAYSCALE.GRAY8};
  }
`;
