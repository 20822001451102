import * as d3 from 'd3';
import { Line } from 'd3';
import moment from 'moment';

import * as Styles from './styles';

const drawAreaChart = (
  element: any,
  data: [number, number][],
  chartWidth: number,
  chartHeight: number,
  options?: { xAxis?: { tickInterval?: d3.CountableTimeInterval; formatTick?: string; tickStep?: number } },
) => {
  const margin = { top: 16, right: 20, bottom: 60, left: 45 },
    width = chartWidth - margin.left - margin.right,
    height = chartHeight - margin.top - margin.bottom;

  const yMaxValue: number = d3.max(data, d => d[1]) as number;
  const xMinValue: number = d3.min(data, d => d[0]) as number;
  const xMaxValue: number = d3.max(data, d => d[0]) as number;

  // Remove the old svg
  d3.select(element).select('svg').remove();
  d3.select(element).select('.tooltip').remove();

  let svg = d3
    .select(element)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  const xScale = d3.scaleTime().domain([xMinValue, xMaxValue]).range([0, width]);

  const yScale = d3.scaleLinear().range([height, 0]).domain([0, yMaxValue]);

  const area: Line<[number, number]> = d3
    .area()
    .x((d: [number, number]) => xScale(new Date(d[0])))
    .y0(yScale(0))
    .y1(d => yScale(d[1]))
    .curve(d3.curveMonotoneX);

  const line: Line<[number, number]> = d3
    .line()
    .x((d: [number, number]) => xScale(new Date(d[0])))
    .y((d: [number, number]) => yScale(d[1]))
    .curve(d3.curveMonotoneX);

  svg
    .append('g')
    .attr('class', Styles.gridStyling)
    .attr('transform', `translate(0,${height})`)
    .call(
      d3
        .axisBottom(xScale)
        .ticks(options?.xAxis?.tickInterval?.every(options?.xAxis?.tickStep || 5) || d3.timeYear)
        .tickSize(-height - 50)
        .tickPadding(10)
        .tickFormat(value => moment(value as Date).format(options?.xAxis?.formatTick || 'MM/DD/YYYY')),
    );

  svg.append('g').attr('class', 'x-axis').attr('transform', `translate(0,${height})`);

  svg
    .append('g')
    .attr('class', 'y-axis')
    .attr('class', Styles.gridStyling)
    .call(
      d3
        .axisLeft(yScale)
        .tickSize(-width - 50)
        .tickPadding(10)
        .tickFormat(d => `$${Number(d).toFixed(1)}`),
    );

  let defs = svg.append('defs');
  let gradient = defs
    .append('linearGradient')
    .attr('id', 'svgGradient')
    .attr('x1', '100%')
    .attr('x2', '100%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('direction', `178.86`);

  gradient
    .append('stop')
    .attr('class', 'start')
    .attr('offset', '12.7%')
    .attr('stop-color', 'rgba(2, 85, 163)')
    .attr('stop-opacity', 0.47);

  gradient
    .append('stop')
    .attr('class', 'end')
    .attr('offset', '99.04%')
    .attr('stop-color', 'rgba(255, 255, 255)')
    .attr('stop-opacity', 0);

  svg.append('path').datum(data).attr('fill', 'url(#svgGradient)').attr('class', 'area').attr('d', area);
  svg
    .append('path')
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', '#0255A3')
    .attr('stroke-width', 3)
    .attr('class', 'line')
    .attr('d', line);

  svg
    .selectAll('myCircles')
    .data(data)
    .enter()
    .append('circle')
    .attr('fill', '#0255A3')
    .attr('stroke', 'none')
    .attr('cx', (d: [number, number]) => xScale(new Date(d[0])))
    .attr('cy', (d: [number, number]) => yScale(d[1]))
    .attr('r', 3.5);
};

export default drawAreaChart;
