import React, { useEffect, useState } from 'react';

import { Form, Input as AntInput } from 'antd';

import { Color } from '../../../styles';

import { InputCompType, InputType, MaskDefinition, MaskType, TextAlign } from './constants';
import { formatNumber, getMaskAndRawValue, getMaskForRawValue, MaskAndRaw } from './masks';
import * as Styles from './styles';
import { getInputRowStyle } from './styles';

const Input = ({
  name,
  label,
  value = '',
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  type = InputType.Text,
  disabled,
  mask = MaskType.None,
  step,
  min,
  max,
  placeholder,
  customMask = '',
  maxLength,
  preChangeHook,
  postChangeHook,
  customStyles,
  prefix,
  suffix,
  antInputCustomStyles,
  hideBorder = false,
  textAlign = TextAlign.Left,
  customSetFieldValue,
  hideErrorMessage,
  tabIndex,
  dataTestId,
}: InputCompType) => {
  const hasMask = mask !== MaskType.None;
  const maskDefinition: string = mask !== MaskType.Custom ? MaskDefinition[mask] : customMask;
  const InputField = type === 'password' ? AntInput.Password : AntInput;
  const formattedValue = type === InputType.PhoneNumber ? formatNumber(value) : value;
  const [focus, setFocus] = useState(false);
  const [autoFocusInput, setAutoFocusInput] = useState(false);

  useEffect(() => {
    if (type === InputType.PhoneNumber) {
      const maskLength = formatNumber(customMask).length;

      if (maskLength < value.length) {
        const newValue = value.substring(0, maskLength);
        setFieldValue(name, newValue);
      }
    }
  }, [customMask]);

  useEffect(() => {
    if (focus === true) {
      setAutoFocusInput(true);
    }
  }, [focus]);

  return (
    <Form.Item
      validateStatus={!!errors && errors[name] && 'error'}
      help={hideErrorMessage ? false : !!errors && touched[name] && errors[name]}
      label={label}
      colon={false}
      style={{
        marginBottom: 0,
        backgroundColor: disabled ? Color.GRAYSCALE.GRAY2 : '',
        ...customStyles,
      }}
      data-testid={dataTestId}>
      <div
        className={getInputRowStyle(errors, touched, name, focus, disabled, hideBorder)}
        onFocus={() => {
          if (!disabled) {
            setFocus(true);
          }
        }}
        onBlur={() => {
          setFocus(false);
        }}>
        {prefix && (
          <span
            className={
              errors && errors[name] && touched && touched[name]
                ? Styles.prefixError
                : focus
                ? Styles.prefixStyleFocus
                : disabled
                ? Styles.prefixDisabledStyle
                : Styles.prefixStyle
            }>
            {prefix}
          </span>
        )}
        <InputField
          {...{ 'data-lpignore': 'true' }}
          style={{
            textAlign,
            padding: 0,
            backgroundColor: errors && errors[name] && touched && touched[name] ? Color.RED.RED1 : undefined,
            marginBottom: errors && errors[name] && touched && touched[name] ? 0 : undefined,
            ...antInputCustomStyles,
          }}
          name={name}
          autoFocus={tabIndex === 0 ? true : autoFocusInput}
          className={Styles.input}
          placeholder={placeholder}
          type={type === 'number' ? undefined : type}
          disabled={disabled}
          defaultValue={hasMask ? getMaskForRawValue(maskDefinition, formattedValue) : value}
          value={hasMask ? getMaskForRawValue(maskDefinition, formattedValue) : value}
          onChange={event => {
            const inputValue: string =
              type === InputType.Number || type === InputType.PhoneNumber
                ? formatNumber(event.target.value)
                : event.target.value;

            if (hasMask) {
              var maskAndRaw: MaskAndRaw = getMaskAndRawValue(maskDefinition, inputValue);

              if (preChangeHook) {
                preChangeHook();
              }
              setFieldTouched(name);
              setFieldValue(name, maskAndRaw.raw, true);

              if (postChangeHook) {
                postChangeHook();
              }
            } else {
              if (preChangeHook) {
                preChangeHook();
              }
              setFieldTouched(name);

              if (customSetFieldValue) {
                customSetFieldValue(inputValue);
              } else {
                setFieldValue(name, inputValue, true);
              }

              if (postChangeHook) {
                postChangeHook();
              }
            }
          }}
          onBlur={() => setFieldTouched(name)}
          onPressEnter={() => handleSubmit}
          step={step}
          min={min}
          max={max}
          bordered={false}
          maxLength={hasMask ? maskDefinition.length : maxLength}
        />
        {suffix && (
          <span
            className={
              errors && errors[name] && touched && touched[name]
                ? Styles.suffixError
                : focus
                ? Styles.suffixStyleFocus
                : disabled
                ? Styles.suffixDisabledStyle
                : Styles.suffixStyle
            }>
            {suffix}
          </span>
        )}
      </div>
    </Form.Item>
  );
};

export default Input;
