import React from 'react';

import { Progress } from 'antd';

import { CircleProgressBarCompType } from './constants';

const CircleProgressBar = ({
  percent,
  width = 224,
  strokeWidth = 15,
  trailColor = '',
  strokeColor = '#0255A3',
  circleText = 'Progress',
  percentageFontSize = 48,
  circleTextFontSize = 14,
}: CircleProgressBarCompType) => {
  return (
    <Progress
      trailColor={trailColor}
      type='circle'
      percent={percent}
      width={width}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeColor={strokeColor}
      format={percent => {
        return (
          <div>
            <div
              style={{
                fontSize: circleTextFontSize,
                fontStyle: 'normal',
                fontWeight: 'normal',
              }}>
              {circleText}
            </div>
            <span
              style={{
                fontSize: percentageFontSize,
                fontStyle: 'normal',
                fontWeight: 'normal',
              }}>
              {percent}%
            </span>
          </div>
        );
      }}
    />
  );
};

export default CircleProgressBar;
