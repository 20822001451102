import { css } from '@emotion/css';
import { ColorConstant, Font, FontWeight, Spacing } from 'src/styles';

export const title = css`
  ${Spacing.mb32}
  ${Spacing.mt40}
  ${FontWeight.bold}
  ${Font.h2}
  color: ${ColorConstant.GRAY8};
`;
