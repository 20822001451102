import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { offerWatchesGetListRequested } from '../actions';
import { OfferWatchDto } from '../dtos/offerWatches.dtos';
import { CustomHookBaseOptions } from '../models/common.model';

interface UseOfferWatchesReturnType {
  offerWatchList: OfferWatchDto[];
  isOfferWatchListLoading: boolean;
  hasOfferWatchListError?: boolean;
  offerWatchListError?: string;
}

export interface UseOfferWatchesOptions extends CustomHookBaseOptions {}

const EMPTY_OFFER_WATCH_LIST: OfferWatchDto[] = [];

export const useOfferWatches = ({ skip }: UseOfferWatchesOptions = { skip: false }) => {
  const dispatch = useDispatch();
  const authToken: string | undefined = useSelector((state: any) => state.auth.data?.authToken);
  const offerWatchList: OfferWatchDto[] | undefined = useSelector((state: any) => state.offerWatches.list?.data?.data);
  const isOfferWatchListLoading = useSelector((state: any) => Boolean(state.offerWatches.list?.__requested));
  const hasOfferWatchListFailed: boolean = useSelector((state: any) => Boolean(state.offerWatches.list?.__failed));
  const offerWatchListError: string = useSelector((state: any) => state.offerWatches.list?.__message);

  useEffect(() => {
    if (!offerWatchList && !isOfferWatchListLoading && !skip && !hasOfferWatchListFailed && Boolean(authToken)) {
      dispatch(offerWatchesGetListRequested());
    }
  }, [offerWatchList, isOfferWatchListLoading, authToken, dispatch]);

  return {
    offerWatchList: Array.isArray(offerWatchList) ? offerWatchList : EMPTY_OFFER_WATCH_LIST,
    isOfferWatchListLoading,
    hasOfferWatchListError: hasOfferWatchListFailed,
    offerWatchListError,
  } as UseOfferWatchesReturnType;
};
