import { useEffect } from 'react';

import { Col } from 'antd';
import { Formik } from 'formik';
import getCanvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { doClearSubmitAccount, doSubmitAccount } from 'src/actions';
import { AccountConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import { MAlert, MButton, MFormSection } from 'src/lib';
import { getLocalStorageService } from 'src/services';
import { ScreenBreakpoint } from 'src/styles';
import * as Yup from 'yup';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { AccountAgreementContent } from './AccountAgreementContent';
import * as Styles from './SignApplication.styles';
import { UserSignatureOptionList } from './UserSignatureOptionList';

const localStorageService = getLocalStorageService();

export const signApplicationValidationSchema = Yup.object().shape({
  signature: Yup.string().required('Signature is required').nullable(),
  isSubjectToBackupWithholding: Yup.boolean().default(false),
});

export interface SignAccountFormValues {
  isBackupWithholding?: boolean;
  signature?: HTMLElement;
}

export const SignApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadingAccountSubmit: boolean = useSelector((state: any) => state.accounts.submit.isLoading);
  const succeededAccountSubmit = useSelector((state: any) => Boolean(state.accounts.submit.__succeeded));

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const agreeElectronicConsent =
    'true' === localStorageService.find(`${AccountConstant.SIGN_APPLICATION_PREFIX_KEY}-${account?.id}`);

  const isAccountApplicationComplete = () => {
    return (
      account?.primaryAccountHolder?.dateOfBirth &&
      account?.primaryAccountHolder?.physicalAddress &&
      account?.primaryAccountHolder?.mailingAddress &&
      account?.primaryAccountHolder?.suitabilityInformation &&
      account?.primaryAccountHolder?.financialInformation?.annualIncome &&
      account?.primaryAccountHolder?.financialInformation?.employmentStatus &&
      account?.primaryAccountHolder?.disclosure &&
      agreeElectronicConsent
    );
  };

  const onSubmit = async (values: SignAccountFormValues) => {
    let userSignature: string | undefined;

    if (values.signature) {
      const signatureToSave = await getCanvas(values.signature).then(canvas => {
        return canvas.toDataURL();
      });
      userSignature = signatureToSave;
    }

    dispatch(doSubmitAccount({ signature: userSignature }));
  };

  useEffect(() => {
    if (succeededAccountSubmit) {
      window.gtag('event', 'account', {
        accountId: account?.accountId,
      });

      return navigate(`/`);
    }
  }, [succeededAccountSubmit]);

  useEffect(() => {
    dispatch(doClearSubmitAccount());
  }, []);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.SignApplication}
      title={isMobile ? undefined : 'Sign Application'}>
      {!isAccountApplicationComplete && (
        <Col span={24}>
          <MAlert
            showIcon
            type='info'
            description={
              <>
                <span>
                  Before you can sign the application to set up your My IPO account, you will need to complete all the
                  required information listed under the sections
                  {isMobile ? 'above' : 'on the left'}. If there is a section that has not been completed, it will
                  appear with the following pending icon
                </span>
                <i className={[`ri-time-line`, Styles.timelineIcon].join(' ')} />
              </>
            }
            className={Styles.alert}
          />
        </Col>
      )}
      <MFormSection
        isEditable
        isEditMode
        className={isAccountApplicationComplete() ? undefined : Styles.blurSection}
        testId={'account-sign-application'}>
        <Formik<SignAccountFormValues>
          validateOnChange
          initialValues={{
            isBackupWithholding: false,
          }}
          validationSchema={signApplicationValidationSchema}
          onSubmit={values => {
            onSubmit(values);
          }}>
          {form => {
            return (
              <>
                <Col span={24}>
                  <AccountAgreementContent form={form} />
                </Col>
                <Col span={24}>
                  <UserSignatureOptionList
                    fullName={`${account?.primaryAccountHolder?.firstName} ${account?.primaryAccountHolder?.lastName}`}
                    value={form.values.signature}
                    error={form.errors.signature}
                    onChange={signature => {
                      form.setFieldValue('signature', signature);
                    }}
                  />
                </Col>

                <Col span={24} className={Styles.buttonContainer}>
                  <MButton
                    loading={loadingAccountSubmit}
                    disabled={!form.isValid}
                    onClick={() => {
                      form.submitForm();
                    }}
                    testId={`account-btn-create-account`}>
                    Create Account
                  </MButton>
                </Col>
              </>
            );
          }}
        </Formik>
      </MFormSection>
    </MyAccountLayout>
  );
};
