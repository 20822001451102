import { css } from '@emotion/css';

import { Color } from '../../../../../../styles';

export const titleWrapper = css`
  margin-bottom: 36px;

  h1 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: ${Color.BRAND.BRAND6};
  }

  span {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GRAYSCALE.GRAY10};
  }
`;
