import { call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects';
import { doSucceededAccountBalance } from 'src/actions';

import { State, Type } from '../actions/utils';
import { BalancesApi } from '../data-communication/BalancesApi';
import { getClientApi } from '../data-communication/ClientApi';
import { mapAccountBalanceDetailsDtoToModel } from '../mappers/accountBalances.mappers';
import { TReduxAction } from '../typings/commonTypes';

import { safeSaga } from './utils';

export function* accountsGetBalances(action: TReduxAction) {
  const { accountId } = action.payload;

  const { authToken } = yield select(state => state.auth.data);

  const response: SagaReturnType<BalancesApi['retrieve']> = yield call(getClientApi().balances.retrieve, {
    params: { accountId },
    authToken,
  });

  yield put(doSucceededAccountBalance(mapAccountBalanceDetailsDtoToModel(response)));
}

/**
 * Account Details sagas
 */
export default function* accountDetailsSaga() {
  // ACCOUNT_BALANCE
  yield takeEvery(
    State.actionRequested(Type.ACCOUNTS_GET_BALANCE),
    safeSaga(accountsGetBalances, Type.ACCOUNTS_GET_BALANCE),
  );
  yield takeEvery(Type.REFRESH_ACCOUNT_BALANCE, safeSaga(accountsGetBalances, Type.REFRESH_ACCOUNT_BALANCE));
}

// NOTE: switch from beta to prod wss env
