import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const getOfferList = (): AnyAction => createAction(State.actionRequested(Type.OFFER_GET_OFFER_LIST), {});

export const doClearOfferDetails = (): AnyAction => createAction(State.actionCleared(Type.OFFER_GET_OFFER_DETAILS));

export const getOfferDetails = (id: string): AnyAction =>
  createAction(State.actionRequested(Type.OFFER_GET_OFFER_DETAILS), { id });

export const getOfferDetailsSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.OFFER_GET_OFFER_DETAILS), payload);

export const doGetAllOfferOptions = (): AnyAction => createAction(State.actionRequested(Type.GET_ALL_OFFER_OPTIONS));
