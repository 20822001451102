import { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { useAccountSelector } from 'src/hooks';
import { ScreenBreakpoint } from 'src/styles';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { TrustedContactSection } from './TrustedContactSection';

export const TrustedContact = () => {
  const navigate = useNavigate();

  const isCreateSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.createTrustedContact.__succeeded),
  );
  const isPatchSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patchTrustedContact.__succeeded),
  );

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const hasAnyUpsertRequest = () => isCreateSuccess || isPatchSuccess;

  const isComplete = () => !isEmpty(account?.primaryAccountHolder?.trustedContact);

  const shouldSendAnalyticsEvent = () => !account?.accountId && isCreateSuccess && isComplete();
  const shouldRedirectToSuitabilityInformation = () => !account?.accountId && hasAnyUpsertRequest() && isComplete();

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_trusted_contact_complete');
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (shouldRedirectToSuitabilityInformation()) {
      navigate(`/accounts/${account?.id}/suitability-information`);
    }
  }, [isCreateSuccess, isPatchSuccess]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.TrustedContact}
      title={isMobile ? undefined : 'Trusted Contact (Optional)'}>
      <TrustedContactSection />
    </MyAccountLayout>
  );
};
