import { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { DisclosuresForm } from './DisclosuresForm';
import { DisclosuresView } from './DisclosuresView';

export const DisclosuresSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isDisclosuresAlreadySaved = () =>
    !isUndefined(account?.primaryAccountHolder?.disclosure?.statementDelivery?.value);

  useEffect(() => {
    if (isDisclosuresAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.disclosure]);

  return (
    <MFormSection onEdit={onEdit} isEditable={isEditable} isEditMode={isEditMode} testId={'disclosures'}>
      {isDisclosuresAlreadySaved() && !isEditMode ? (
        <DisclosuresView account={account} />
      ) : (
        <DisclosuresForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
