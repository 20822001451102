import { PropsWithChildren } from 'react';

import { Space } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { ScreenBreakpoint } from '../../styles';

import * as Styles from './SpaceWithBreakpoint.styles';

export interface SpaceWithBreakpointProps {
  size: number;
}

export const SpaceWithBreakpoint = ({ size, children }: PropsWithChildren<SpaceWithBreakpointProps>) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  return (
    <Space
      size={isMobile ? size - 4 : size}
      direction={isMobile ? 'vertical' : 'horizontal'}
      className={Styles.container}>
      {children}
    </Space>
  );
};
