import { OfferOrderPaymentTypeDto } from 'src/dtos';

import { MModal } from '../MModal/MModal';

interface SecuritiesHeldAndFundedOutsideDisclaimerModalProps {
  isVisible: boolean;
  onClose: () => void;
  onOK: () => void;
  type: Exclude<OfferOrderPaymentTypeDto, OfferOrderPaymentTypeDto.Ach>;
}

export const SecuritiesHeldAndFundedOutsideDisclaimerModal = ({
  isVisible,
  onClose,
  onOK,
  type,
}: SecuritiesHeldAndFundedOutsideDisclaimerModalProps) => {
  const findTitle = () => {
    if (type === OfferOrderPaymentTypeDto.Wire) {
      return 'Wire Transfer for Escrow Offer';
    }

    if (type === OfferOrderPaymentTypeDto.Check) {
      return 'Check Transfer for Escrow Offer';
    }

    return 'Card Payment';
  };

  const renderContent = () => {
    if (type === OfferOrderPaymentTypeDto.Wire) {
      return (
        <>
          This Offering requires you to wire funds directly from an existing bank account to the Offering Escrow
          Account. You will receive an email with instructions on how to complete your purchase. For this offering type,
          If you have funds in your My IPO account they cannot be transferred to the Offering Escrow Account. The
          securities purchased can be retained in electronic form at the transfer agent for the Issuer or can be
          delivered to you in physical form. Some brokerage firms may not hold these securities or may charge fees to
          deposit these securities.
        </>
      );
    }

    if (type === OfferOrderPaymentTypeDto.Check) {
      return (
        <>
          This Offering requires you to send a check directly to the Offering Escrow Account. You will receive an email
          with instructions on how to complete your purchase. For this offering type, if you have funds in your My IPO
          account they cannot be transferred to the Offering Escrow Account. Securities purchased in Escrow Offers are
          funded and held outside of your My IPO account. If your order is accepted, then funds will be sent from
          Issuers escrow account to Issuer once the closing occurs. Once completed, your Shares will be issued after
          closing and held in electronic form at the Issuer’s Transfer Agent. Some brokerage firms may not hold these
          securities or may charge fees to deposit these securities.
        </>
      );
    }

    return (
      <>
        Securities purchased by credit card are funded and held outside of your My IPO account. Payment made by credit
        card will be forwarded to the Issuers escrow account and then sent to the Issuer if accepted once the closing
        occurs. If your Order is completed, your Shares will be issued after closing and held in electronic form at the
        Issuer’s Transfer Agent. Some brokerage firms may not hold these securities or may charge fees to deposit these
        securities.
      </>
    );
  };

  return (
    <MModal
      title={findTitle()}
      visible={isVisible}
      centered
      primaryButtonType='tertiary'
      primaryButtonText='OK'
      onPrimaryButtonClick={onOK}
      closable
      onClose={onClose}>
      <div>{renderContent()}</div>
    </MModal>
  );
};
