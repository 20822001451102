import { css } from '@emotion/css';

import { ColorConstant, Font, FontWeight, ScreenBreakpoint, Spacing } from '../../../styles';

export const radioWrapper = css`
  margin-right: 0px !important;
  > span {
    padding-right: 0px !important;
  }

  .ant-radio-inner {
    width: 16px;
    height: 16px;

    &::after {
      width: 16px;
      height: 16px;
    }
  }
  .ant-radio {
    ${Font.md}
    ${FontWeight.semibold}
    color: ${ColorConstant.BRAND6};

    @media (max-width: ${ScreenBreakpoint.mobile.max}) {
      ${Font.sm}
    }
  }

  .ant-radio-disabled .ant-radio-inner {
    background-color: ${ColorConstant.GRAY3} !important;
    border-color: ${ColorConstant.GRAY3} !important;
    color: ${ColorConstant.GRAY3} !important;
    &:after {
      background-color: ${ColorConstant.GRAY5} !important;
    }
  }
`;

export const labelWrapper = css`
  ${Font.md}
  ${FontWeight.semibold}
  color: ${ColorConstant.BRAND6};

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Font.sm}
  }
`;

export const label = css`
  ${Font.lg}
  ${FontWeight.bold}
  ${Spacing.mb12}
`;

export const labelDisableWrapper = css`
  ${Font.md}
  ${FontWeight.semibold}
  color: ${ColorConstant.GRAY4};
`;
