import { css } from '@emotion/css';

import {
  Color,
  ScreenBreakpoint,
  Spacing,
  Font,
  BaseStyle,
  FontWeight,
  TextAlign,
  BorderRadius,
  FontFamily,
} from '../../../styles';

export const cardWrapper = css`
  max-width: 600px;
  ${Spacing.p48};
  ${Spacing.mb48};
  ${Font.sm};
  ${FontFamily.sourceSansPro};
  -webkit-box-shadow: 0 6px 10px 0 rgb(2 85 163 / 15%);
  box-shadow: 0 6px 10px 0 rgb(2 85 163 / 15%);
  background-color: ${Color.GRAYSCALE.GRAY1};
  border: 1px solid ${Color.BRAND.BRAND2};
  ${BorderRadius.xxs};
  > p {
    ${Spacing.mb4};
  }
  .ant-card-body {
    ${Spacing.p0};
  }
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    ${Font.lg};
    ${Spacing.m20};
    ${Spacing.p20};
  }
`;

export const title = css`
  ${BaseStyle.level};
  ${Font.h4};
  ${FontWeight.semibold};
  color: ${Color.BRAND.BRAND6};
  ${Spacing.pt48};
  ${TextAlign.center};
`;
