import { css } from '@emotion/css';
import { ColorConstant, FontWeight, Spacing } from 'src/styles';

export const phoneNumberSpan = css`
  ${FontWeight.bold}
  color: ${ColorConstant.GRAY8}
`;

export const button = css`
  ${Spacing.m8}
`;
