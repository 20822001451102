import { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Popover, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { getInvestigations, logout } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { InvestigationStatus } from 'src/models';
import { MAIN_PATH } from 'src/pages/Main';
import { HISTORY_PATH } from 'src/pages/Main/pages/History/History';
import { SUMMARY_PATH } from 'src/pages/Main/pages/Summary/Summary';
import { BaseStyle, Color, Spacing } from 'src/styles';
import { logo } from 'src/styles/images';

import { MButton } from '../FormComponents/MButton/MButton';

import { AccountHolderFullName } from './AccountHolderFullName';
import { AccountStatusBanner } from './AccountStatusBanner';
import { AccountValue } from './AccountValue';
import { completedAccountMenuItemKeyList, HeaderMenuItem, HeaderMenuItemKey, headerMenuItems } from './constants';
import { DesktopMenuItemLink } from './DesktopMenuLinkItem';
import './index.css';
import * as Styles from './Header.styles';
import ResourceLinks from './ResourceLinks';
import { UserInfo } from './UserInfo';

export type DesktopProps = {
  authToken?: string;
  pathName: string;
  status?: InvestigationStatus;
};

export const DesktopHeader = ({ authToken, pathName, status }: DesktopProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setIsVisible] = useState(false);

  const { account } = useAccountSelector();

  const getMenuItemText = (item: HeaderMenuItem) => {
    if (item.key !== HeaderMenuItemKey.AccountStatus) {
      return item.name;
    }

    if (status?.isActive) {
      return item.name;
    }

    return (
      <Row align='middle' justify='space-between' className={Styles.menuItemTextContainer}>
        <span>{item.name}</span>
        <span className={Styles.inactiveAccountMenuItemTextIconContainer}>
          <i className={Styles.inactiveAccountMenuItemTextIcon(status?.isPending ?? false)} />
        </span>
      </Row>
    );
  };

  const handleVisibleChange = (visible: boolean) => {
    setIsVisible(visible);
  };

  const onHide = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (account?.accountId) dispatch(getInvestigations(account.accountId));
  }, [account?.accountId]);

  return (
    <div>
      {authToken ? (
        <div className='resourceSection'>
          <div className='resourceWidthRestriction'>
            <div>
              <ResourceLinks className='linksSmall' isAuthenticated />
            </div>
          </div>
        </div>
      ) : null}
      <Row justify='center'>
        <Col span={24}>
          <div className='mainHeader'>
            <div className='headerWidthRestriction'>
              <Link to='/'>
                <img src={logo} alt='My IPO' className='logo' />
              </Link>
              {!authToken ? (
                <div className='signedOutLinks'>
                  <ResourceLinks className='linksMedium' />
                  <div className='authOptions'>
                    <div>
                      <MButton type='secondary' onClick={() => navigate('/register')} testId={'home-btn-signup'}>
                        Sign up
                      </MButton>
                    </div>
                    <div className='authBtnContainer'>
                      <MButton type='secondary' onClick={() => navigate('/login')} testId={'home-btn-login'}>
                        Log in
                      </MButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='headerLinkSection'>
                  <div
                    style={{
                      display: 'inline-block',
                      margin: '0 auto',
                    }}>
                    <NavLink
                      to={`/${MAIN_PATH}/${SUMMARY_PATH}`}
                      className={pathName.indexOf(`/${SUMMARY_PATH}`) > 0 ? 'linksLargeActive' : 'linksLarge'}>
                      Summary
                    </NavLink>

                    <NavLink
                      to='/offers'
                      className={pathName.startsWith('/offers') ? 'linksLargeActive' : 'linksLarge'}>
                      Offers
                    </NavLink>

                    <NavLink
                      to={`/${MAIN_PATH}/${HISTORY_PATH}`}
                      className={pathName.indexOf(`/${HISTORY_PATH}`) > 0 ? 'linksLargeActive' : 'linksLarge'}>
                      History
                    </NavLink>
                  </div>
                  <Popover
                    content={
                      <div
                        style={{
                          width: 345,
                          backgroundColor: Color.GRAYSCALE.GRAY1,
                          borderRadius: 12,
                          paddingTop: 22,
                          boxShadow: '0 4px 16px rgba(2, 85, 163, 0.15)',
                        }}
                        data-testid={'summary-dropdown-account-menu'}>
                        <Row justify='center' align='middle' className={Spacing.mb12}>
                          <Col span={24} className={cx(Spacing.mb4, BaseStyle.level)}>
                            <AccountHolderFullName alignment='center' />
                          </Col>
                          <Col span={24} className={Spacing.my4}>
                            <AccountValue />
                          </Col>
                        </Row>
                        <Row className={cx(Spacing.pl0, Spacing.pb12)}>
                          {headerMenuItems
                            .filter(aMenuItem =>
                              !account?.accountId && completedAccountMenuItemKeyList.includes(aMenuItem.key)
                                ? false
                                : true,
                            )
                            .map(aMenuItem => (
                              <DesktopMenuItemLink
                                key={aMenuItem.key}
                                text={getMenuItemText(aMenuItem)}
                                link={aMenuItem.path(account?.id)}
                                onClick={onHide}
                              />
                            ))}
                          <div
                            className='logoutLink'
                            onClick={() => {
                              onHide();
                              dispatch(logout());
                            }}>
                            Log out
                          </div>
                        </Row>
                      </div>
                    }
                    trigger='click'
                    open={visible}
                    onOpenChange={handleVisibleChange}
                    placement='bottomRight'>
                    <UserInfo onClick={() => handleVisibleChange(!visible)} />
                  </Popover>
                </div>
              )}
            </div>
          </div>
        </Col>
        <AccountStatusBanner />
      </Row>
    </div>
  );
};
