import { cx } from '@emotion/css';
import { useAccountSelector } from 'src/hooks';
import { AlignItems, Display, FlexDirection, JustifyContent } from 'src/styles';

import * as Styles from './Header.styles';

interface AccountHolderFullNameProps {
  alignment: 'start' | 'center';
}

export const AccountHolderFullName = ({ alignment }: AccountHolderFullNameProps) => {
  const { account } = useAccountSelector();

  if (!account || !account.primaryAccountHolder) {
    return null;
  }

  return (
    <div
      className={cx(
        Display.flex,
        JustifyContent.center,
        alignment === 'start' ? AlignItems.flexStart : AlignItems.center,
        FlexDirection.column,
        JustifyContent.flexStart,
      )}>
      <span className={Styles.fullName}>{`${account.primaryAccountHolder.firstName} ${
        account.primaryAccountHolder.middleName ?? ''
      } ${account.primaryAccountHolder.lastName} ${account.primaryAccountHolder.suffix ?? ''}`}</span>
      <span className={Styles.accountType}>{account.type}</span>
    </div>
  );
};
