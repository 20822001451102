import React from 'react';

import { MTag, MTagType } from 'src/lib';

import { TradingBlockAchBankLinkStatusEnum } from '../../../../dtos/achBankLinks.dtos';

export interface AchBankLinkStatusTagProps {
  state: TradingBlockAchBankLinkStatusEnum;
  testId?: string;
}

export const AchBankLinkStatusTag = ({ state, testId }: AchBankLinkStatusTagProps) => {
  const getTagType = (): MTagType => {
    switch (state) {
      case TradingBlockAchBankLinkStatusEnum.Approved:
        return 'lightgreen';
      case TradingBlockAchBankLinkStatusEnum.Cancelled:
        return 'orange';
      case TradingBlockAchBankLinkStatusEnum.CreateRejected:
      case TradingBlockAchBankLinkStatusEnum.Rejected:
        return 'red';
      default:
        return 'gray';
    }
  };

  // TODO: Use the mapper when achbanklink model is introduced
  const mapStateToLabel = () => {
    switch (state) {
      case TradingBlockAchBankLinkStatusEnum.Approved:
        return 'APPROVED';
      case TradingBlockAchBankLinkStatusEnum.Cancelled:
        return 'CANCELLED';
      case TradingBlockAchBankLinkStatusEnum.CreateRejected:
      case TradingBlockAchBankLinkStatusEnum.Rejected:
        return 'REJECTED';
      case TradingBlockAchBankLinkStatusEnum.Pending:
      case TradingBlockAchBankLinkStatusEnum.PendingCreateApproval:
        return 'PENDING';
      default:
        return 'NA';
    }
  };

  return (
    <MTag type={getTagType()} testId={testId}>
      {mapStateToLabel()}
    </MTag>
  );
};
