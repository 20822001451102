import { css, cx } from '@emotion/css';
import { Color, Display, Font, JustifyContent, Spacing } from 'src/styles';

export const description = cx(
  css`
    font-size: 14px;
    line-height: 20px;
    color: ${Color.GRAYSCALE.GRAY7};
    margin-bottom: 30px;
    text-align: center;
  `,
);

export const disclaimer = cx(
  css`
    font-size: 14px;
    line-height: 20px;
    color: #5c6572;
    margin-top: 20px;
  `,
);

export const title = css`
  ${Font.lg}
  ${Display.flex}
  ${JustifyContent.center}
  ${Spacing.my12}
`;

export const subtitle = css`
  ${Font.md}
  ${Display.flex}
  ${JustifyContent.center}
  color: ${Color.GRAYSCALE.GRAY7};
  ${Spacing.my12}
`;

export const offerName = cx(
  css`
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: ${Color.GRAYSCALE.GRAY10};
  `,
);

export const offerLogo = css`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;
