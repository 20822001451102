export type TReduxAction = {
  type: string;
  payload: any;
  error?: any;
  message?: string;
};

export interface ToastMessage {
  key: string;
  severity: SeverityEnum;
  message: string;
  autoClose: boolean;
  isClearable: boolean;
}

export enum SeverityEnum {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export interface ResponseGenerator<T> {
  config?: any;
  data?: T;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
  attributes?: any;
  keyPrefix?: any;
  storage?: any;
}
