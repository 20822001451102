import React from 'react';

import { get } from 'lodash';
import { connect } from 'react-redux';

import { createOrdersRequested, getEnhancedSnapQuotesRequested } from '../../../../../../actions';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';
import { MModal } from '../../../../../../lib/MModal/MModal';
import { ModalSteps } from '../../../../../../models/modal-steps.model';
import { CreateOrderView, OrderPosition } from '../../../../../../models/orders.models';
import { EnhancedSnapQuotesData, SnapQuotes } from '../../../../../../models/quote-media.model';
import { SecuritiesModalStepsEnum } from '../../../../../../models/securities-modal-steps.enum';
import { ActionForm } from '../ActionForm/ActionForm';
import { ReviewForm } from '../ReviewForm/ReviewForm';
import SecuritiesDetails from '../SecuritiesDetails/SecuritiesDetails';
import { SubmissionForm } from '../SubmissionForm/SubmissionForm';

import { getSecuritiesModalSteps } from './SecuritiesModalSteps';
import * as Styles from './styles';

interface SecuritiesModalProps {
  securityDetails: OrderPosition;
  onModalClose: () => void;
  getEnhancedSnapQuotesRequested: (symbol: string) => void;
  createOrdersRequested: (orderToReview: CreateOrderView) => void;
  selectedSecurityData: SnapQuotes;
  selectedSecurityEnhacedData: EnhancedSnapQuotesData;
  isLoadingQuoteMediaEnhancedData: boolean;
  isMobile: boolean;
}

interface SecuritiesModalState {
  securitiesModalSteps: ModalSteps[];
  currentStep: ModalSteps;
}

class SecuritiesModal extends React.Component<SecuritiesModalProps, SecuritiesModalState> {
  constructor(props: SecuritiesModalProps) {
    super(props);
    const securitiesModalSteps: ModalSteps[] = getSecuritiesModalSteps({
      onClose: props.onModalClose,
      isMobile: props.isMobile,
    });
    const firstStep = this.fillStepWithAppropriateActions(
      securitiesModalSteps[0],
      props.onModalClose,
      () => this.navigateToStep(true),
      () => this.navigateToStep(),
    );
    const newSecuritiesModalSteps = securitiesModalSteps.map((step: ModalSteps) => {
      if (step.id === firstStep.id) return { ...firstStep };

      return { ...step };
    });
    this.state = {
      securitiesModalSteps: newSecuritiesModalSteps,
      currentStep: firstStep,
    };
  }

  componentDidMount() {
    const { securityDetails, getEnhancedSnapQuotesRequested } = this.props;
    getEnhancedSnapQuotesRequested(securityDetails.symbol);
  }

  public submitActionForm: (() => void) | undefined;
  public submitOrder: (() => void) | undefined;

  navigateToStep(isBackward: boolean = false) {
    const { currentStep, securitiesModalSteps } = this.state;
    const { onModalClose } = this.props;
    const prevStep = securitiesModalSteps.find((step: ModalSteps) => step.id === currentStep.previousStep);
    let nextStep = securitiesModalSteps.find((step: ModalSteps) => step.id === currentStep.nextStep);

    let newStep =
      isBackward && prevStep ? { ...prevStep } : nextStep ? { ...nextStep } : { ...securitiesModalSteps[0] };

    if (newStep && !newStep.previousStep && currentStep) {
      newStep.previousStep = currentStep.id;
      newStep = this.fillStepWithAppropriateActions(
        newStep,
        onModalClose,
        () => this.navigateToStep(true),
        () => this.navigateToStep(),
      );
    }

    this.setState({ currentStep: newStep, securitiesModalSteps: [...this.updateModalStep(newStep)] });
  }

  onDisclaimerClick(stepTo: number) {
    const { currentStep, securitiesModalSteps } = this.state;
    let nextStep = securitiesModalSteps.find((step: ModalSteps) => step.id === stepTo);

    if (nextStep && !nextStep.previousStep) {
      nextStep.previousStep = currentStep.id;
      nextStep = this.fillStepWithAppropriateActions(nextStep, () => this.navigateToStep(true));
    }

    this.setState({
      currentStep: nextStep || currentStep,
      securitiesModalSteps: [...this.updateModalStep(nextStep || currentStep)],
    });
  }

  bindOnSubmitActionForm = (submitForm: any) => {
    this.submitActionForm = submitForm;
  };

  bindOnSubmitOrder = (submitForm: any) => {
    this.submitOrder = submitForm;
  };

  handleSubmitMyForm = async () => {
    if (this.submitActionForm) {
      await this.submitActionForm();
    }
  };

  handleSubmitOrder = async () => {
    if (this.submitOrder) {
      await this.submitOrder();
      this.navigateToStep();
    }
  };

  handleValidatedSuccessForm = () => {
    this.navigateToStep();
  };

  private updateModalStep(newStep: ModalSteps) {
    const { securitiesModalSteps } = this.state;

    return securitiesModalSteps.map((step: ModalSteps) => {
      if (step.id === newStep.id) return { ...newStep };

      return { ...step };
    });
  }

  fillStepWithAppropriateActions(
    step: ModalSteps,
    onCloseButtonClick?: () => void,
    onSecondaryButtonClick?: () => void,
    onPrimaryButtonClick?: () => void,
  ): ModalSteps {
    let interactiveActions: Partial<ModalSteps> = {
      onClose: onCloseButtonClick,
      onTertiaryButtonClick: onCloseButtonClick,
      onSecondaryButtonClick,
      onPrimaryButtonClick,
    };
    const { securityDetails, selectedSecurityData, selectedSecurityEnhacedData } = this.props;

    switch (step.id) {
      case SecuritiesModalStepsEnum.SECURITIES_DETAILS:
        interactiveActions = {
          ...interactiveActions,
          renderChild: () => (
            <SecuritiesDetails
              selectedSecurity={securityDetails}
              securityData={selectedSecurityData}
              securityEnhacedData={selectedSecurityEnhacedData}></SecuritiesDetails>
          ),
        };
        break;
      case SecuritiesModalStepsEnum.BUY_FORM:
      case SecuritiesModalStepsEnum.SELL_FORM:
        interactiveActions = {
          ...interactiveActions,
          onPrimaryButtonClick: this.handleSubmitMyForm,
          renderChild: () => (
            <ActionForm
              action={step.id}
              selectedSecurity={securityDetails}
              securityData={selectedSecurityData}
              onDisclaimerClick={(step: number) => this.onDisclaimerClick(step)}
              bindOnSubmitActionForm={this.bindOnSubmitActionForm}
              onSubmitedSuccessForm={this.handleValidatedSuccessForm}
            />
          ),
        };
        break;
      case SecuritiesModalStepsEnum.REVIEW:
        interactiveActions = {
          ...interactiveActions,
          onPrimaryButtonClick: this.handleSubmitOrder,
          renderChild: () => (
            <ReviewForm
              onDisclaimerClick={(step: number) => this.onDisclaimerClick(step)}
              bindOnSubmitOrder={this.bindOnSubmitOrder}
            />
          ),
        };
        break;
      case SecuritiesModalStepsEnum.SUCCEEDED:
        interactiveActions = {
          ...interactiveActions,
          renderChild: () => (
            <SubmissionForm
              securityData={selectedSecurityData}
              selectedSecurity={securityDetails}
              onDisclaimerClick={(step: number) => this.onDisclaimerClick(step)}
            />
          ),
        };
        break;
      case SecuritiesModalStepsEnum.INFO_DISCLAIMER_1:
      case SecuritiesModalStepsEnum.INFO_DISCLAIMER_2:
      case SecuritiesModalStepsEnum.INFO_DISCLAIMER_3:
      case SecuritiesModalStepsEnum.INFO_DISCLAIMER_4:
      case SecuritiesModalStepsEnum.INFO_DISCLAIMER_5:
        interactiveActions = {
          ...interactiveActions,
        };
        break;
      default:
        interactiveActions = { ...interactiveActions };
    }

    return { ...step, ...interactiveActions };
  }

  render() {
    const { currentStep } = this.state;
    const { isLoadingQuoteMediaEnhancedData } = this.props;

    return (
      <React.Fragment key='security_modal'>
        {currentStep?.renderChild && (
          <MModal
            title={currentStep?.title || ''}
            visible
            primaryButtonText={currentStep?.primaryButtonText}
            secondaryButtonText={currentStep?.secondaryButtonText}
            tertiaryButtonText={currentStep?.tertiaryButtonText}
            isDisabledPrimaryButton={isLoadingQuoteMediaEnhancedData}
            isDisabledSecondaryButton={isLoadingQuoteMediaEnhancedData}
            customStyleModalContent={Styles.modalContent}
            onClose={currentStep?.onClose}
            onPrimaryButtonClick={currentStep?.onPrimaryButtonClick}
            onSecondaryButtonClick={currentStep?.onSecondaryButtonClick}
            onTertiaryButtonClick={currentStep?.onTertiaryButtonClick}
            primaryButtonType={currentStep?.primaryButtonType}
            secondaryButtonType={currentStep?.secondaryButtonType}
            tertiaryButtonType={currentStep?.tertiaryButtonType}
            customWidth={725}
            customPadding={36}
            closable={currentStep?.closable}
            centered={false}
            className={Styles.modalStyling}
            maskClosable={false}>
            {isLoadingQuoteMediaEnhancedData && <Spinner />}
            {!isLoadingQuoteMediaEnhancedData && currentStep?.renderChild()}
          </MModal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  selectedSecurityData: get(state, 'quoteMedia.quoteMediaData.data'),
  selectedSecurityEnhacedData: get(state, 'quoteMedia.quoteMediaEnhancedData.data'),
  isLoadingQuoteMediaEnhancedData: !!get(state, 'quoteMedia.quoteMediaEnhancedData.__requested'),
});

const mapDispatchToProps = {
  getEnhancedSnapQuotesRequested,
  createOrdersRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecuritiesModal);
