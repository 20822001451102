import { useEffect, useRef, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderPersonalInformation,
  doClearPatchAccountHolderPersonalInformation,
  doCreateAccountHolderPersonalInformation,
  doPatchAccountHolderPersonalInformation,
} from 'src/actions';
import { AccountHolderMaritalStatusDto } from 'src/dtos';
import {
  DateOfBirthPicker,
  IdentityVerificationInstructions,
  MFormInput,
  MFormInputPassword,
  MFormSaveButton,
  MFormSelect,
  MModal,
  MTooltip,
} from 'src/lib';
import { Account, AccountHolderMaritalStatusLabel } from 'src/models';
import { Font, Spacing } from 'src/styles';

import { SocialSecurityNumberTooltipContent } from './SocialSecurityNumberTooltipContent';
import {
  createAccountHolderPersonalInformationValidationSchema,
  patchAccountHolderPersonalInformationValidationSchema,
} from './validations';

export const MARITAL_STATUS_OPTION_LIST = [
  { value: AccountHolderMaritalStatusDto.Single, label: AccountHolderMaritalStatusLabel.Single },
  { value: AccountHolderMaritalStatusDto.Married, label: AccountHolderMaritalStatusLabel.Married },
  { value: AccountHolderMaritalStatusDto.Divorced, label: AccountHolderMaritalStatusLabel.Divorced },
  { value: AccountHolderMaritalStatusDto.Widowed, label: AccountHolderMaritalStatusLabel.Widowed },
];

interface AccountHolderPersonalInformationFormValues {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  dateOfBirth?: string;
  ssid?: string;
  maritalStatus?: string;
  numberOfDependents?: string;
}

export interface AccountHolderPersonalInformationFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const AccountHolderPersonalInformationForm = ({
  account,
  onCancel,
  onSave,
}: AccountHolderPersonalInformationFormProps) => {
  const dispatch = useDispatch();

  const isCreatePersonalInformationLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createPersonalInformation?.__requested),
  );
  const succeededCreatePersonalInformation = useSelector(
    (state: any) => state.accountHolders.createPersonalInformation?.__succeeded,
  );

  const isPatchPersonalInformationLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPersonalInformation.__requested),
  );
  const succeededPatchPersonalInformation = useSelector(
    (state: any) => state.accountHolders.patchPersonalInformation?.__succeeded,
  );

  const [shouldPatch, setShouldPatch] = useState<boolean>(false);
  const formRef = useRef<FormikProps<AccountHolderPersonalInformationFormValues> | null>(null);

  const [isConfirmCreateModalVisible, setIsConfirmCreateModalVisible] = useState<boolean>(false);

  const isUpsertPersonalInformationLoading = isPatchPersonalInformationLoading || isCreatePersonalInformationLoading;

  const isPersonalInformationAlreadySaved = () => Boolean(account?.primaryAccountHolder?.dateOfBirth);

  const _onCancel = (form: FormikProps<AccountHolderPersonalInformationFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderPersonalInformationFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    if (isPersonalInformationAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (succeededCreatePersonalInformation || succeededPatchPersonalInformation) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreatePersonalInformation, succeededPatchPersonalInformation]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderPersonalInformation());
      dispatch(doClearPatchAccountHolderPersonalInformation());
    };
  }, []);

  return (
    <>
      <Formik<AccountHolderPersonalInformationFormValues>
        validateOnChange
        validateOnBlur
        innerRef={ref => {
          formRef.current = ref;
        }}
        onSubmit={values => {
          if (shouldPatch && account?.accountId) {
            const cValues = patchAccountHolderPersonalInformationValidationSchema.cast(values);
            // TOOD: sanitize values (remove values which are not changed)
            dispatch(
              doPatchAccountHolderPersonalInformation({
                numberOfDependents: cValues.numberOfDependents,
                maritalStatus: cValues.maritalStatus,
              }),
            );

            return;
          }

          if (shouldPatch && !account?.accountId) {
            const cValues = createAccountHolderPersonalInformationValidationSchema.cast(values);
            // TOOD: sanitize values (remove values which are not changed)
            dispatch(
              doPatchAccountHolderPersonalInformation({
                firstName: cValues.firstName,
                middleName: cValues.middleName ? cValues.middleName : null,
                lastName: cValues.lastName,
                suffix: cValues.suffix ? cValues.suffix : null,
                dateOfBirth: cValues.dateOfBirth,
                ssid: cValues.ssid,
                numberOfDependents: cValues.numberOfDependents,
                maritalStatus: cValues.maritalStatus,
              }),
            );

            return;
          }
          setIsConfirmCreateModalVisible(true);
        }}
        initialValues={{
          firstName: account?.primaryAccountHolder?.firstName,
          middleName: account?.primaryAccountHolder?.middleName,
          lastName: account?.primaryAccountHolder?.lastName,
          suffix: account?.primaryAccountHolder?.suffix,
          dateOfBirth: account?.primaryAccountHolder?.dateOfBirth,
          numberOfDependents: account?.primaryAccountHolder?.numberOfDependents?.toString(),
          ssid: account?.primaryAccountHolder?.ssid,
          maritalStatus: account?.primaryAccountHolder?.maritalStatus?.value,
        }}
        validationSchema={
          Boolean(account?.accountId)
            ? patchAccountHolderPersonalInformationValidationSchema
            : createAccountHolderPersonalInformationValidationSchema
        }>
        {form => {
          return (
            <>
              <Col span={24} className={Spacing.mb12}>
                <MTooltip
                  icon='info'
                  title='Identity Verification Instructions'
                  label='Please enter your name as shown on a goverment-issued ID'
                  type='modal'
                  className={Font.md}>
                  <IdentityVerificationInstructions />
                </MTooltip>
              </Col>
              <Col span={24}>
                <MFormInput
                  label='First Name'
                  value={form.values.firstName}
                  defaultValue={account?.primaryAccountHolder?.firstName}
                  error={form.errors.firstName}
                  disabled={Boolean(account?.accountId)}
                  onChange={value => {
                    form.setFieldValue('firstName', value);
                  }}
                  testId={'account-first-name'}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Middle Initial or Middle Name'
                  placeholder={shouldPatch ? undefined : 'Enter'}
                  value={form.values.middleName}
                  defaultValue={account?.primaryAccountHolder?.middleName}
                  disabled={Boolean(account?.accountId)}
                  error={form.errors.middleName}
                  onChange={value => {
                    form.setFieldValue('middleName', value);
                  }}
                  testId={'account-middle-name'}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Last Name'
                  placeholder='Enter'
                  value={form.values.lastName}
                  defaultValue={account?.primaryAccountHolder?.lastName}
                  error={form.errors.lastName}
                  disabled={Boolean(account?.accountId)}
                  onChange={value => {
                    form.setFieldValue('lastName', value);
                  }}
                  testId={'account-last-name'}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Suffix (optional)'
                  placeholder={shouldPatch ? undefined : 'Enter'}
                  value={form.values.suffix}
                  defaultValue={account?.primaryAccountHolder?.suffix}
                  error={form.errors.suffix}
                  disabled={Boolean(account?.accountId)}
                  onChange={value => {
                    form.setFieldValue('suffix', value);
                  }}
                  testId={'account-suffix'}
                />
              </Col>

              <Col span={24}>
                <MFormInputPassword
                  label='Social Security Number'
                  tooltip={{
                    type: 'modal',
                    title: 'Why do we need this?',
                    content: <SocialSecurityNumberTooltipContent />,
                  }}
                  placeholder='Enter'
                  value={form.values.ssid}
                  defaultValue={account?.primaryAccountHolder?.maskedSsid}
                  error={form.errors.ssid}
                  onChange={value => {
                    form.setFieldValue('ssid', value);
                  }}
                  disabled={Boolean(account?.accountId)}
                  testId={'account-ssn'}
                />
              </Col>

              <Col span={24}>
                <DateOfBirthPicker
                  defaultValue={account?.primaryAccountHolder?.dateOfBirth}
                  error={form.errors.dateOfBirth}
                  disabled={Boolean(account?.accountId)}
                  onChange={value => {
                    form.setFieldValue('dateOfBirth', value);
                  }}
                  testId={'account-dob'}
                />
              </Col>

              <Col span={24}>
                <MFormSelect
                  label='Marital Status'
                  options={MARITAL_STATUS_OPTION_LIST}
                  defaultValue={account?.primaryAccountHolder?.maritalStatus?.value}
                  error={form.errors.maritalStatus}
                  onChange={value => {
                    form.setFieldValue('maritalStatus', value);
                  }}
                  testId={'account-marital-status'}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  label='Number of Dependents'
                  placeholder='Enter'
                  value={form.values.numberOfDependents}
                  defaultValue={account?.primaryAccountHolder?.numberOfDependents?.toString()}
                  error={form.errors.numberOfDependents}
                  onChange={value => {
                    form.setFieldValue('numberOfDependents', value);
                  }}
                  testId={'account-number-of-dependents'}
                />
              </Col>

              <Col span={24}>
                <MFormSaveButton<AccountHolderPersonalInformationFormValues>
                  loading={isUpsertPersonalInformationLoading}
                  onCancel={_onCancel}
                  onSave={_onSave}
                  isEditMode={shouldPatch}
                  form={form}
                  testId={'personal-information'}
                />
              </Col>
            </>
          );
        }}
      </Formik>
      <MModal
        title='Saving Personal Information'
        customWidth={520}
        visible={isConfirmCreateModalVisible}
        onClose={() => setIsConfirmCreateModalVisible(false)}
        primaryButtonText='Continue'
        secondaryButtonText='Edit'
        onPrimaryButtonClick={() => {
          setIsConfirmCreateModalVisible(false);
          dispatch(
            doCreateAccountHolderPersonalInformation({
              firstName: formRef.current?.values?.firstName,
              middleName: formRef.current?.values?.middleName ? formRef.current?.values?.middleName : null,
              lastName: formRef.current?.values?.lastName,
              suffix: formRef.current?.values.suffix ? formRef.current?.values?.suffix : null,
              dateOfBirth: formRef.current?.values?.dateOfBirth,
              ssid: formRef.current?.values?.ssid,
              maritalStatus: formRef.current?.values?.maritalStatus,
              numberOfDependents: formRef.current?.values?.numberOfDependents,
            }),
          );
        }}
        onSecondaryButtonClick={() => setIsConfirmCreateModalVisible(false)}>
        <>
          Once your acount is completed and your personal information is saved, you will not be able to edit your social
          security number, date of birth, middle initial or middle name and suffix. Please review your personal
          information to make sure everything is accurate.
          <br />
          <br />
          If the information is correct, please click “Continue”.
        </>
      </MModal>
    </>
  );
};
