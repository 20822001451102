import moment from 'moment';
import { SagaReturnType, call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import {
  offerOrdersCreateSucceeded,
  offerOrdersGetListSucceeded,
  doSucceededGetOfferOrderDetails,
  toastMessagesAdd,
  createOfferOrderCardPaymentIntentSucceeded,
  updateOfferOrderCardPaymentIntentSucceeded,
  doSucceededUpdateOfferOrder,
  deleteOfferOrderSucceeded,
  submitCardPayment,
  updateOfferOrderCardPaymentIntent,
  offerOrdersGetListRequested,
} from '../actions';
import { State, Type } from '../actions/utils';
import { getClientApi } from '../data-communication/ClientApi';
import { OfferOrdersApi } from '../data-communication/OfferOrdersApi';
import {
  CreateOfferOrderBodyDto,
  UpdateOfferOrderDto,
  DeleteOfferOrderDto,
  CreateOfferOrderCardPaymentIntentDto,
  UpdateOfferOrderCardPaymentIntentDto,
  OfferOrderPaymentTypeDto,
  OfferOrderStatusDto,
} from '../dtos/offerOrders.dtos';
import { mapGetOfferOrdersResponseDtoToModel, mapOfferOrderDetailsDtoToModel } from '../mappers/offerOrders.mappers';
import { OfferOrder, OfferOrderIntent } from '../models/offer-orders.model';
import { SeverityEnum, TReduxAction } from '../typings/commonTypes';
import { assertPropertyIsFound } from '../utils/assertPropertyIsFound';

import { depositFunds } from './cashiering';
import { safeSaga } from './utils';

export function* getOfferOrdersRequested() {
  const { authToken } = yield select(state => state.auth.data);
  const { data, total }: SagaReturnType<OfferOrdersApi['getMany']> = yield call(getClientApi().offerOrders.getMany, {
    authToken,
  });
  yield put(offerOrdersGetListSucceeded({ data: mapGetOfferOrdersResponseDtoToModel(data), total }));
}

export function* getOfferOrderDetails(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { id } = action.payload;
  const offerOrder: SagaReturnType<OfferOrdersApi['getOne']> = yield call(getClientApi().offerOrders.getOne, {
    params: { id },
    authToken,
  });
  yield put(doSucceededGetOfferOrderDetails(mapOfferOrderDetailsDtoToModel(offerOrder)));
}

const shouldUpdateOfferOrderCardPaymentIntent = (intent: OfferOrderIntent) =>
  intent?.paymentType?.isCard && intent.cardPaymentIntentTotalCost !== intent.totalInvestment;

const createOfferOrderBody = (intent: OfferOrderIntent): CreateOfferOrderBodyDto => {
  const base = {
    offerId: intent.offerId,
    isAccreditedInvestor: intent.isAccreditedInvestor,
    localeDate: moment().format('M/D/YYYY'),
  };

  if (intent.isConditional) {
    return {
      ...base,
      paymentType: intent.paymentType?.value as Exclude<OfferOrderPaymentTypeDto, OfferOrderPaymentTypeDto.Card>,
      totalInvestment: intent.totalInvestment,
    };
  } else {
    return {
      ...base,
      quantity: intent.quantity,
      paymentType: intent.paymentType?.value,
      cardPaymentIntentId: intent.paymentType?.isCard ? intent.cardPaymentIntentId : undefined,
    };
  }
};

export function* create(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const offerOrderIntent: OfferOrderIntent = action.payload;

  const accountId = offerOrderIntent.accountId;
  const body: CreateOfferOrderBodyDto = createOfferOrderBody(offerOrderIntent);

  if (shouldUpdateOfferOrderCardPaymentIntent(offerOrderIntent)) {
    assertPropertyIsFound(offerOrderIntent, 'cardPaymentIntentId');
    assertPropertyIsFound(offerOrderIntent, 'cardPaymentAccountExternalId');
    yield call(
      updateCardPaymentIntent,
      updateOfferOrderCardPaymentIntent({
        params: {
          id: offerOrderIntent.cardPaymentIntentId,
        },
        body: {
          quantity: offerOrderIntent.quantity,
          cardPaymentAccount: { externalId: offerOrderIntent.cardPaymentAccountExternalId },
        },
      }),
    );
  }

  if (offerOrderIntent.paymentType?.isAch && offerOrderIntent.achDeposit) {
    yield call(depositFunds, {
      type: Type.CASHIERING_DEPOSIT_FUNDS,
      payload: {
        accountId,
        body: {
          Amount: offerOrderIntent.achDeposit.amount,
          RelationshipId: offerOrderIntent.achDeposit.relationshipId,
        },
      },
    });
  }

  if (offerOrderIntent.isExternal && offerOrderIntent.securityDeliveryOption) {
    body.securityDeliveryOption = offerOrderIntent.securityDeliveryOption;
  }

  const response: SagaReturnType<OfferOrdersApi['create']> = yield call(getClientApi().offerOrders.create, {
    params: { accountId },
    body,
    authToken,
  });

  if (offerOrderIntent?.paymentType?.isCard) {
    yield put(submitCardPayment());
  }
  const model = mapOfferOrderDetailsDtoToModel(response);
  yield put(offerOrdersCreateSucceeded(model));

  const offerOrdersList: OfferOrder[] | undefined = yield select(state => state.offerOrders.list.data?.data);
  const total: number | undefined = yield select(state => state.offerOrders.list.data?.total);

  if (offerOrdersList && total !== undefined) {
    yield put(
      offerOrdersGetListSucceeded({
        data: [...offerOrdersList, model],
        total: total + 1,
      }),
    );
  }
}

export function* update(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const payload: UpdateOfferOrderDto = action.payload;
  const response: SagaReturnType<OfferOrdersApi['update']> = yield call(getClientApi().offerOrders.update, {
    ...payload,
    authToken,
  });

  if (payload.body.status === OfferOrderStatusDto.PendingFirmCancellation) {
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Offer order cancellation request was successfully submitted',
      }),
    );
  }

  yield put(offerOrdersGetListRequested());

  yield put(doSucceededUpdateOfferOrder(mapOfferOrderDetailsDtoToModel(response)));
}

export function* deleteOfferOrder(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const payload: DeleteOfferOrderDto = action.payload;
  yield call(getClientApi().offerOrders.delete, {
    ...payload,
    authToken,
  });

  yield put(deleteOfferOrderSucceeded());

  const offerOrdersList: OfferOrder[] | undefined = yield select(state => state.offerOrders.list.data?.data);
  const total: number | undefined = yield select(state => state.offerOrders.list.data?.total);

  if (offerOrdersList && total !== undefined) {
    yield put(
      offerOrdersGetListSucceeded({
        data: offerOrdersList.filter(anOfferOrder => anOfferOrder.id !== payload.params.id),
        total: total - 1,
      }),
    );
  }
}

export function* createCardPaymentIntent(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const payload: CreateOfferOrderCardPaymentIntentDto = action.payload;
  const response: SagaReturnType<OfferOrdersApi['createCardPaymentIntent']> = yield call(
    getClientApi().offerOrders.createCardPaymentIntent,
    {
      ...payload,
      authToken,
    },
  );

  yield put(createOfferOrderCardPaymentIntentSucceeded(response));
}

export function* updateCardPaymentIntent(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);

  const payload: UpdateOfferOrderCardPaymentIntentDto = action.payload;
  const response: SagaReturnType<OfferOrdersApi['updateCardPaymentIntent']> = yield call(
    getClientApi().offerOrders.updateCardPaymentIntent,
    {
      ...payload,
      authToken,
    },
  );

  yield put(updateOfferOrderCardPaymentIntentSucceeded(response));
}

/**
 * Offers sagas
 */
export default function* offerOrdersSagas() {
  yield takeEvery(
    State.actionRequested(Type.GET_OFFER_ORDERS),
    safeSaga(getOfferOrdersRequested, Type.GET_OFFER_ORDERS),
  );
  yield takeEvery(
    State.actionRequested(Type.GET_OFFER_ORDER_DETAILS),
    safeSaga(getOfferOrderDetails, Type.GET_OFFER_ORDER_DETAILS),
  );
  yield takeEvery(State.actionRequested(Type.CREATE_OFFER_ORDERS), safeSaga(create, Type.CREATE_OFFER_ORDERS));
  yield takeEvery(State.actionRequested(Type.UPDATE_OFFER_ORDER), safeSaga(update, Type.UPDATE_OFFER_ORDER));
  yield takeEvery(State.actionRequested(Type.DELETE_OFFER_ORDER), safeSaga(deleteOfferOrder, Type.DELETE_OFFER_ORDER));
  yield takeEvery(
    State.actionRequested(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT),
    safeSaga(createCardPaymentIntent, Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT),
  );
  yield takeEvery(
    State.actionRequested(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT),
    safeSaga(updateCardPaymentIntent, Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT),
  );
}
