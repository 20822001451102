import { css, cx } from '@emotion/css';
import { Color, Font, FontWeight, Spacing } from 'src/styles';

export const modifySignatureButton = cx(
  css`
    color: ${Color.BRAND.BRAND6};
    font-size: 14;
    margin-top: 8px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
);

export const drawnSignatureContainer = cx(
  css`
    display: flex;
    height: 40px;
    margin-bottom: 19px;
    align-items: center;
    padding-left: 10px;
  `,
);

export const fontSignatureContainer = css`
  display: flex;
  height: 40px;
  margin-bottom: 20px;
  align-items: center;
  font-size: 24px;
  padding-left: 12px;
  cursor: pointer;
`;

export const signatureContainer = css`
  height: 40px;
  width: 100%;
`;

export const signaturePadContainer = cx(
  css`
    border: 1px solid ${Color.BRAND.BRAND5};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: crosshair;
    margin-right: 8px;
  `,
);

export const drawSignature = css`
  font-weight: 600;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 16px;
  color: ${Color.BRAND.BRAND6};
`;

export const quillPen = {
  fontSize: 21,
  marginRight: 8,
  marginTop: 3,
  color: Color.BRAND.BRAND6,
};

export const penStyle = {
  fontSize: 17,
  marginRight: 8,
  color: Color.BRAND.BRAND6,
};

export const pleaseSelectSignature = css`
  ${FontWeight.semibold}
  ${Spacing.mt16}
  ${Font.lg}
`;
