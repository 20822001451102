import { css } from '@emotion/css';

import { ColorConstant, Images } from '../../styles';

export const categorySelector = css`
  color: #4a86bd;
  text-decoration: none;
  height: 3.75rem;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.875rem;
  border: 0.0625rem solid #4d88be;
  border-radius: 0.125rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(2, 85, 163, 0.15);
  background-color: #fff;
  font-size: 1.125rem;
  text-align: center;
  @media (max-width: 48rem) {
    width: 48%;
  }
  @media (max-width: 31.25rem) {
    width: 100%;
  }
  &:hover {
    box-shadow: 0 0.25rem 0.75rem 0 rgba(2, 85, 163, 0.2);
  }
`;

export const Home = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const levelingSection = css`
  width: 100%;
  padding-top: 10rem;
  padding-bottom: 10.9375rem;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Images.HomeBackground});
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  @media (min-width: 90.5rem) {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Images.HomeBackgroundWide});
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 43.75rem) {
    padding-top: 5.625rem;
    padding-bottom: 15.625rem;
  }
`;

export const howItWorksSectionMobile = css`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3.125rem;
  padding-bottom: 0rem;
`;

export const howItWorksSection = css`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3.75rem;
  padding-bottom: 4.5rem;
`;

export const centeredSectionContainer = css`
  width: 100%;
  max-width: 62rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const sectionContainer = css`
  width: 100%;
  max-width: 75rem;
`;

export const levelingCTABox = css`
  color: #fff;
  max-width: 32.75rem;
  margin-left: 7rem;
  @media (min-width: 90.5rem) {
    margin-left: 3.125rem;
  }
  @media (max-width: 51.875rem) {
    margin-left: 3.125rem;
  }
  @media (max-width: 43.75rem) {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0rem;
  }
`;

export const levelingTitle = css`
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.04375rem;
  @media (min-width: 22.5rem) {
    font-size: 1.5rem;
  }
`;

export const levelingSubtitle = css`
  max-width: 18rem;
  margin-bottom: 1.875rem;
  letter-spacing: 0.04375rem;
  line-height: normal;
`;

export const howItWorksTitle = css`
  margin-bottom: 1.875rem;
  font-size: 2.375rem;
  font-weight: 600;
  letter-spacing: 0.04375rem;
  @media (max-width: 48rem) {
    font-size: 1.875rem;
  }
`;

export const videoContainer = css`
  flex: 3;
  display: flex;
  justify-content: center;
  margin: 0.625rem 0;

  @media (max-width: 56.1875rem) {
    width: 100%;
  }

  @media (max-width: 48rem) {
    margin: 2.5rem 0;
  }

  @media (max-width: 43.75rem) {
    padding: 0 1.25rem;
  }
`;

export const videoSizeControl = css`
  max-width: 40rem;
  width: 100%;
  position: relative;
  padding-top: 45%;

  @media (max-width: 58.75rem) {
    padding-top: 50%;
  }

  @media (max-width: 48rem) {
    padding-top: 40%;
  }

  @media (max-width: 30rem) {
    max-width: 18.75rem;
    padding-top: 46.25%;
  }

  @media (min-width: 22.5rem) {
    padding-top: 56.25%;
  }
`;

export const aboutVideo = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const howItWorks = css`
  width: 100%;
`;

export const howItWorksBlock = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7rem;
  padding-right: 7rem;
  font-size: 1.5rem;
  letter-spacing: 0.04375rem;
  line-height: normal;
  @media (max-width: 29.375rem) {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
`;

export const descBlock = css`
  display: flex;
  align-items: center;
`;

export const descText = css`
  max-width: 21.875rem;
  text-align: left;
  padding-right: 1.875rem;
  @media (max-width: 48rem) {
    font-size: 1.125rem;
    font-weight: 500;
  }
  @media (max-width: 29.375rem) {
    font-size: 1.25rem;
  }
`;

export const descNum = css`
  width: 5rem;
  height: 5rem;
  min-width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.25rem;
  padding-left: 0.1875rem;
  padding-bottom: 0.125rem;
  background-color: #c2d6e9;
  border-radius: 2.5rem;
  font-size: 3.375rem;
  font-weight: 600;
  color: #fff;
  @media (max-width: 48rem) {
    width: 3.125rem;
    height: 3.125rem;
    min-width: 3.125rem;
    border-radius: 1.5625rem;
    font-size: 2rem;
  }
  @media (max-width: 29.375rem) {
    width: 3.75rem;
    height: 3.75rem;
    min-width: 3.75rem;
    border-radius: 1.875rem;
    font-size: 2.25rem;
  }
`;

export const howItWorksImage = css`
  width: 11.25rem;
  height: 11.25rem;
  @media (max-width: 48rem) {
    width: 8.125rem;
    height: 8.125rem;
  }
`;

export const getStartedHome = css`
  width: 100%;
  max-width: 75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  border-top: 0.0625rem solid #c2d6e9;
  @media (max-width: 56.1875rem) {
    flex-direction: column;
    padding-top: 1.875rem;
  }
`;

export const getStartedCol = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const getStartedBorderLeft = css`
  border-left: 0.0625rem solid #c2d6e9;
`;

export const handheldImage = css`
  width: 25rem;
`;

export const getStartedTitleHome = css`
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media (max-width: 56.1875rem) {
    margin-bottom: 0rem;
  }
  @media (max-width: 43.75rem) {
    margin-bottom: 1.25rem;
  }
`;

export const getStartedText = css`
  margin-top: 1.875rem;
  margin-bottom: 2.5rem;
  width: 100%;
  flex-direction: column;
  @media (max-width: 56.1875rem) {
    margin-top: 1.875rem;
    margin-bottom: 2.5rem;
    width: 100%;
    max-width: 40rem;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const howItWorksMobile = css`
  width: 100%;
  position: relative;
  padding: 0rem 1.25rem 1.25rem;
`;

export const blueLine = css`
  width: 0.25rem;
  height: 100%;
  background-color: #cadcec;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const blueLineTop = css`
  top: -0.125rem;
  right: 0.1875rem;
  width: 0.625rem;
  height: 0.625rem;
  position: relative;
  border-radius: 0.3125rem;
  background-color: #cadcec;
`;

export const mobileRow = css`
  display: flex;
  margin-bottom: 1.875rem;
`;

export const setupDescriptionSection = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.875rem;
  justify-content: center;
  @media (min-width: 25rem) {
  }
`;

export const setupImageBoxMobile = css`
  flex: 1;
  text-align: center;
  @media (min-width: 22.5rem) {
    text-align: left;
  }
`;

export const investImageBoxMobile = css`
  flex: 1;
  text-align: center;
  @media (min-width: 22.5rem) {
    text-align: left;
  }
`;

export const browseImageBoxMobile = css`
  flex: 1;
  text-align: center;
  @media (min-width: 22.5rem) {
    text-align: right;
  }
`;

export const howImage = css`
  width: 5.625rem;
  @media (min-width: 25rem) {
    width: 6.875rem;
  }
`;

export const stepNumberMobile = css`
  width: 1.625rem;
  height: 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.0625rem;
  background-color: #cadcec;
  border-radius: 0.8125rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
`;

export const middleSplit = css`
  width: 2.5rem;
  @media (min-width: 26.875rem) {
    width: 5rem;
  }
  @media (min-width: 22.5rem) {
    width: 3.75rem;
  }
`;

export const setupText = css`
  text-align: right;
  margin-top: 0.9375rem;
`;

export const browseDescriptionSection = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  justify-content: center;
  @media (min-width: 25rem) {
  }
`;

export const investDescriptionSection = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.875rem;
  justify-content: center;
  @media (min-width: 25rem) 
  }
`;

export const browseText = css`
  margin-top: 0.9375rem;
`;

export const signupBtn = css`
  width: 17.5rem;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0255a3;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 1.5625rem;
  border: 0.0625rem solid #0255a3;
  text-decoration: none;
  @media (min-width: 29.375rem) {
    width: 16.25rem;
  }
  &:hover {
    background-color: #fff;
    color: #0255a3;
  }
`;

export const investText = css`
  text-align: right;
  margin-top: 0.9375rem;
`;

export const banner = css`
  background-color: ${ColorConstant.BRAND2};
`;
