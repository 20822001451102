import accountDetails from './accountDetails';
import { accountHolderReducers } from './accountHolders.reducers';
import { accountsReducers } from './accounts.reducers';
import app from './app';
import { auth } from './auth';
import cashiering from './cashiering';
import configs from './configs';
import investigations from './investigations';
import offerOrders from './offerOrders.reducers';
import offers from './offers.reducers';
import offerWatches from './offerWatches.reducers';
import orders from './orders.reducers';
import quoteMedia from './quoteMedia';
import { statementsReducers } from './statements.reducers';
import toastMessages from './toastMessages';
import user from './users.reducers';

const reducer = {
  auth,
  user,
  accountDetails,
  accounts: accountsReducers,
  accountHolders: accountHolderReducers,
  statements: statementsReducers,
  cashiering,
  offers,
  offerWatches,
  investigations,
  configs,
  quoteMedia,
  orders,
  offerOrders,
  toastMessages,
  app,
};

export default reducer;
