import { css } from '@emotion/css';

export const Display = {
  block: css({
    display: 'block',
  }),
  flex: css({
    display: 'flex',
  }),
  inline: css({
    display: 'inline',
  }),
  inlineBlck: css({
    display: 'inline-block',
  }),
  inlineFlex: css({
    display: 'inline-flex',
  }),
  table: css({
    display: 'table',
  }),
  tableCell: css({
    display: 'table-cell',
  }),
};
