import { useEffect } from 'react';

import { cx } from '@emotion/css';
import { Col } from 'antd';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { doClearUpdateAchRelationshipNickName, doUpdateAchRelationshipNickName } from 'src/actions';
import { UnformattedAchRelationshipDto } from 'src/dtos';
import { MButton, MFormInput, MModalV2 } from 'src/lib';
import { BaseStyle, FlexDirection, Font, Spacing, TextAlign } from 'src/styles';
import * as Yup from 'yup';

import * as Styles from './EditNickNameModal.styles';

const editNicknameValidationSchema = Yup.object().shape({
  nickName: Yup.string().required('Nickname is required').max(20, 'Nickname can have a maximum of 20 characters'),
});

interface EditNickNameFormValues {
  nickName: string;
}

interface EditNickNameModalProps {
  open: boolean;
  onClose?: () => void;
  achRelationship: UnformattedAchRelationshipDto;
}

export const EditNickNameModal = ({ achRelationship, open, onClose }: EditNickNameModalProps) => {
  const dispatch = useDispatch();

  const isLoading: boolean = useSelector((state: any) => state.cashiering.editACHRelationship.isLoading);
  const succeeded = useSelector((state: any) => Boolean(state.cashiering.editACHRelationship.__succeeded));

  const form = useFormik<EditNickNameFormValues>({
    initialValues: {
      nickName: achRelationship.tradingBlockACHRelationship.nickName,
    },
    validationSchema: editNicknameValidationSchema,
    onSubmit: values => {
      dispatch(
        doUpdateAchRelationshipNickName({
          accountId: achRelationship.tradingBlockACHRelationship.accountId,
          relationshipId: achRelationship.tradingBlockACHRelationship.id,
          nickName: values.nickName,
        }),
      );
    },
  });

  const _onClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (succeeded) {
      _onClose();
    }
  }, [succeeded]);

  useEffect(() => {
    return () => {
      dispatch(doClearUpdateAchRelationshipNickName());
    };
  }, []);

  return (
    <MModalV2
      title='Edit Nickname'
      width={400}
      height={250}
      open={open}
      onClose={_onClose}
      body={
        <>
          <Col span={24} className={cx(BaseStyle.level, FlexDirection.column, TextAlign.center, Spacing.mb24)}>
            <i className={cx('ri-edit-line', Styles.editIcon)} />
            <span className={Font.md}>
              Edit your bank account nickname.
              <br /> This is the only aspect of your bank account which you are allowed to change.
            </span>
          </Col>
          <Col span={24}>
            <MFormInput
              label='Nickname'
              defaultValue={achRelationship.tradingBlockACHRelationship.nickName}
              value={form.values.nickName}
              onChange={value => {
                form.setFieldValue('nickName', value);
              }}
              error={form.errors.nickName}
              testId={'link-bank-modal-nickname'}
            />
          </Col>
        </>
      }
      footer={
        <Col span={24} className={cx(BaseStyle.level, FlexDirection.column)}>
          <MButton type='tertiary' onClick={_onClose} className={Spacing.my4}>
            Cancel
          </MButton>
          <MButton
            type='primary'
            onClick={form.submitForm}
            disabled={!form.isValid || isLoading}
            className={Spacing.my4}>
            Save
          </MButton>
        </Col>
      }
    />
  );
};
