import { DownloadOutlined } from '@ant-design/icons';

import { MButton } from '../../lib/FormComponents/MButton/MButton';

export interface DownloadAccountStatementButtonProps {
  onClick: () => void;
  loading: boolean;
}

export const DownloadAccountStatementButton = ({ onClick, loading }: DownloadAccountStatementButtonProps) => {
  return (
    <MButton type='tertiary' onClick={onClick} loading={loading}>
      <DownloadOutlined /> Download
    </MButton>
  );
};
