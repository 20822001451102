import { MButton } from 'src/lib/FormComponents/MButton/MButton';
import { Transfer, TransferStatusLabel } from 'src/models/transfers.models';

import * as Styles from './CancelTransferButton.styles';

interface CancelTransferButtonProps {
  value: Transfer;
  onClick: (transfer: Transfer) => void;
}

export const CancelTransferButton = ({ value, onClick }: CancelTransferButtonProps) => {
  if (value.status.label === TransferStatusLabel.PROCESSING || value.status.label === TransferStatusLabel.PENDING) {
    return (
      <MButton type='tertiary' danger className={Styles.cancelButton} onClick={() => onClick(value)}>
        Cancel
      </MButton>
    );
  }

  return null;
};
