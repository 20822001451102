import React, { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Divider, Row, Space } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { getChartSnapQuotesRequested, clearOrdersReview, getChartSnapQuotesClear } from '../../../../../../actions';
import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { OrderPosition } from '../../../../../../models/orders.models';
import {
  EnhancedChartData,
  EnhancedSnapQuotesData,
  EodData,
  IntervalData,
  PriceData,
  SnapQuotes,
} from '../../../../../../models/quote-media.model';
import { SecuritiesModalDataSectionEnum } from '../../../../../../models/securities-modal-data-sections.enum';
import SecuritiesChart from '../SecuritiesChart/SecuritiesChart';
import { SecuritiesStatistics } from '../SecuritiesStatistics/SecuritiesStatistics';

import * as Styles from './SecuritiesDetails.styles';

interface SecuritiesDetailsProps {
  selectedSecurity: OrderPosition;
  securityData: SnapQuotes;
  securityEnhacedData: EnhancedSnapQuotesData;
}

const SecuritiesDetails = ({ selectedSecurity, securityData, securityEnhacedData }: SecuritiesDetailsProps) => {
  const dispatch = useDispatch();
  const selectedSecurityChartData: EnhancedChartData = useSelector(
    (state: any) => state.quoteMedia.quoteMediaChartData?.data,
  );
  const isSecurityChartDataLoading = useSelector((state: any) =>
    Boolean(state.quoteMedia.quoteMediaChartData?.__requested),
  );
  let isLoadingQuoteMediaChartData = useSelector((state: any) => {
    return !!state.quoteMedia.quoteMediaChartData?.__requested;
  });
  const [activeFilterDate, setActiveFilterDate] = useState<string>('1w');
  const [dataCharts, setDataCharts] = useState<[number, number][]>([]);
  const [securityInfo, setSecurityInfo] = useState<string>();
  const [priceInfo, setPriceInfo] = useState<PriceData>({});
  const [activeSection, setActiveSection] = useState<SecuritiesModalDataSectionEnum>(
    SecuritiesModalDataSectionEnum.CHART,
  );

  useEffect(() => {
    dispatch(clearOrdersReview());
  }, [dispatch]);

  useEffect(() => {
    const quoteMediaSecurity = securityData?.quotedata?.find(data => data.symbol === selectedSecurity.symbol);
    const quoteData = quoteMediaSecurity ? quoteMediaSecurity : { longname: '' };
    const quoteMediaEnhacedSecurity = securityEnhacedData?.quote?.find(
      data => data.symbolstring === selectedSecurity.symbol,
    );
    const quoteEnhancedData = quoteMediaEnhacedSecurity ? quoteMediaEnhacedSecurity : { pricedata: {} };
    setSecurityInfo(quoteData?.longname);
    setPriceInfo(quoteEnhancedData?.pricedata);
  }, [securityData, securityEnhacedData]);

  useEffect(() => {
    const quoteMediaIntraDataSelected = selectedSecurityChartData?.intraday?.find(
      data => data.symbolstring === selectedSecurity.symbol,
    );
    const quoteMediaHistoryDataSelected = selectedSecurityChartData?.history?.find(
      data => data.symbolstring === selectedSecurity.symbol,
    );
    const lastAvailableDate =
      activeFilterDate === '1d'
        ? Math.max(...(quoteMediaIntraDataSelected?.interval.map(data => moment(data.startdatetime).valueOf()) || []))
        : null;
    const objectWithData =
      activeFilterDate === '1d'
        ? quoteMediaIntraDataSelected?.interval.filter(
            data => moment(data.startdatetime) > moment(lastAvailableDate).startOf('day'),
          ) || []
        : quoteMediaHistoryDataSelected?.eoddata || [];
    const data: [number, number][] = objectWithData.map((chartValue: EodData | IntervalData) => [
      'date' in chartValue ? new Date(chartValue.date).valueOf() : new Date(chartValue.startdatetime).valueOf(),
      chartValue.close,
    ]);
    setDataCharts(data);
  }, [selectedSecurityChartData]);

  useEffect(() => {
    if (!isSecurityChartDataLoading) {
      dispatch(getChartSnapQuotesRequested(selectedSecurity.symbol, '1w'));
    }
  }, []);

  const onFilterChange = (dateFilter: string) => {
    setActiveFilterDate(dateFilter);
    dispatch(getChartSnapQuotesClear());
    dispatch(getChartSnapQuotesRequested(selectedSecurity.symbol, dateFilter === '1d' ? '1w' : dateFilter));
  };

  return (
    <>
      <Row gutter={[, 28]}>
        <Col span={24} className={Styles.alignCenter}>
          <Space size={14}>
            <span className={Styles.securityHeader}>{securityInfo}</span>
          </Space>
        </Col>
        <Col span={24} className={Styles.alignCenter}>
          <Space size={14} direction='vertical'>
            <CurrencyField value={selectedSecurity?.value || 0} className={Styles.totalValueDetail} />
            <span className={Styles.totalTextDetail}>Last Price</span>
          </Space>
        </Col>
      </Row>
      <Row justify='center' className={Styles.chartWrapper} gutter={[, 8]}>
        <Space size={36} className={Styles.swiperContainer}>
          <i
            className={cx(
              'ri-arrow-left-s-line',
              Styles.arrowsStyling,
              activeSection === SecuritiesModalDataSectionEnum.CHART ? Styles.arrowsDisabledStyling : '',
            )}
            onClick={() => setActiveSection(SecuritiesModalDataSectionEnum.CHART)}></i>
          <Row
            gutter={activeSection === SecuritiesModalDataSectionEnum.CHART ? [0, 0] : [32, 32]}
            className={Styles.rowWrapper}>
            {activeSection === SecuritiesModalDataSectionEnum.CHART ? (
              <SecuritiesChart
                data={dataCharts}
                change={selectedSecurity?.change || 0}
                isLoading={isLoadingQuoteMediaChartData}
                onFilterChange={onFilterChange}></SecuritiesChart>
            ) : (
              <SecuritiesStatistics data={priceInfo} change={selectedSecurity?.change || 0}></SecuritiesStatistics>
            )}
          </Row>
          <i
            className={cx(
              'ri-arrow-right-s-line',
              Styles.arrowsStyling,
              activeSection === SecuritiesModalDataSectionEnum.STATISTIC_TABLE ? Styles.arrowsDisabledStyling : '',
            )}
            onClick={() => setActiveSection(SecuritiesModalDataSectionEnum.STATISTIC_TABLE)}></i>
        </Space>
      </Row>
      <Row gutter={[, 8]}>
        <Col span={12} className={Styles.textDetail}>
          Share Quantity
        </Col>
        <Col span={12} className={cx(Styles.textDetail, Styles.valueDetail)}>
          {selectedSecurity?.quantity || 0}
        </Col>
        <Col span={24}>
          <Divider className={Styles.divider} />
        </Col>
        <Col span={12} className={Styles.textDetail}>
          Share Purchase Price
        </Col>
        <Col span={12} className={cx(Styles.textDetail, Styles.valueDetail)}>
          <CurrencyField value={selectedSecurity?.purchasePrice || 0} />
        </Col>
        <Col span={24}>
          <Divider className={Styles.divider} />
        </Col>
        <Col span={12} className={Styles.textDetail}>
          Total Cost
        </Col>
        <Col span={12} className={cx(Styles.textDetail, Styles.valueDetail)}>
          <CurrencyField value={selectedSecurity?.totalCost || 0} />
        </Col>
        <Col span={24}>
          <Divider className={Styles.divider} />
        </Col>
        <Col span={12} className={Styles.textDetail}>
          Current Value
        </Col>
        <Col span={12} className={cx(Styles.textDetail, Styles.valueDetail)}>
          <CurrencyField value={selectedSecurity?.totalValue || 0} />
        </Col>
        <Col span={24}>
          <Divider className={Styles.divider} />
        </Col>
      </Row>
    </>
  );
};

export default SecuritiesDetails;
