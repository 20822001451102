import { MFormTextField } from 'src/lib';
import { Account } from 'src/models';

import {
  ANNUAL_INCOME_TOOLTIP_TEXT,
  LIQUID_NET_WORTH_TOOLTIP_TEXT,
  TOTAL_NET_WORTH_TOOLTIP_TEXT,
} from './tooltips.constants';

export interface FinancialInformationAssetsViewProps {
  account?: Account;
}

export const FinancialInformationAssetsView = ({ account }: FinancialInformationAssetsViewProps) => {
  return (
    <>
      <MFormTextField
        label='Annual Income'
        value={account?.primaryAccountHolder?.financialInformation?.annualIncome?.label}
        tooltip={{
          type: 'popover',
          content: ANNUAL_INCOME_TOOLTIP_TEXT,
          icon: 'info',
        }}
      />
      <MFormTextField
        label='Total Net Worth'
        value={account?.primaryAccountHolder?.financialInformation?.totalNetWorth?.label}
        tooltip={{
          type: 'popover',
          content: TOTAL_NET_WORTH_TOOLTIP_TEXT,
          icon: 'info',
        }}
      />
      <MFormTextField
        label='Liquid Net Worth'
        value={account?.primaryAccountHolder?.financialInformation?.liquidNetWorth?.label}
        tooltip={{
          type: 'popover',
          content: LIQUID_NET_WORTH_TOOLTIP_TEXT,
          icon: 'info',
        }}
      />
      <MFormTextField
        label='Tax Bracket'
        value={account?.primaryAccountHolder?.financialInformation?.taxBracket?.label}
      />
    </>
  );
};
