import { OfferOptionTypeDto } from 'src/dtos';

import { DropdownItemType } from '../../../lib/FormComponents/Dropdown/constants';
import { Offer } from '../../../models/offers.models';

export const ALL = '_all_';

export const orderByFilter: Array<DropdownItemType> = [
  { value: OfferOptionTypeDto.Status, text: 'Offer Status' },
  { value: OfferOptionTypeDto.Type, text: 'Offer Type' },
  { value: OfferOptionTypeDto.SecurityType, text: 'Security Type' },
  { value: OfferOptionTypeDto.Category, text: 'Offer Category' },
  { value: OfferOptionTypeDto.Industry, text: 'Industry Type' },
];

export const filterOffers = (offer: Offer, orderBy: OfferOptionTypeDto, show: string): boolean => {
  if (show === ALL) return true;

  switch (orderBy) {
    case OfferOptionTypeDto.Status:
      return offer.status === show;
    case OfferOptionTypeDto.Type:
      return offer.offerType === show;
    case OfferOptionTypeDto.Category:
      return offer.category === show;
    case OfferOptionTypeDto.SecurityType:
      return offer.securityType === show;
    case OfferOptionTypeDto.Industry:
      return offer.industry === show;
    default:
      return true;
  }
};

export const sortOffers = (offer1: Offer, offer2: Offer, orderBy: OfferOptionTypeDto): number => {
  switch (orderBy) {
    case OfferOptionTypeDto.Status:
      return offer1.status.localeCompare(offer2.status);
    case OfferOptionTypeDto.Type:
      return offer1.offerType.localeCompare(offer2.offerType);
    case OfferOptionTypeDto.Category:
      return offer1.category.localeCompare(offer2.category);
    case OfferOptionTypeDto.SecurityType:
      return offer1.securityType.localeCompare(offer2.securityType);
    case OfferOptionTypeDto.Industry:
      return offer1.industry.localeCompare(offer2.industry);
    default:
      return offer1.status.localeCompare(offer2.status);
  }
};
