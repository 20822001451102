import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  logout,
  toastMessagesAdd,
  updateOrderFromEvent,
  refreshOrderPositionList,
  refreshAccountBalance,
} from '../actions';
import { EnvConstant } from '../configs/env.configs';
import { FromTradingBlockServerEvent, FromTradingBlockServerEventDto } from '../events/FromTradingBlockServerEvent';
import { mapOrderUpdateEventDtoToModel } from '../mappers/orders.mappers';
import { Logger } from '../utils/Logger';
import { parseTradingBlockMessageEvent } from '../utils/parseTradingBlockMessageEvent';

import { useAccountSelector } from './useAccountSelector';
import { useOrderPositions } from './useOrderPositions';
import { useOrders } from './useOrders';

export const useTradingBlockWebSocket = () => {
  const dispatch = useDispatch();

  const tbToken: string = useSelector((state: any) => state.auth.data.tbToken);
  const { account } = useAccountSelector();

  const { isOrderPositionListFetched, hasOrderPositionListError } = useOrderPositions({ skip: true });
  const { isOrderListFetched, hasOrderListError } = useOrders({ skip: true });

  const orderListRef = useRef<{ isFetched: boolean; hasError?: boolean }>({
    isFetched: isOrderListFetched,
    hasError: hasOrderListError,
  });
  const orderPositionListRef = useRef<{ isFetched: boolean; hasError: boolean }>({
    isFetched: isOrderPositionListFetched,
    hasError: hasOrderPositionListError,
  });

  const updateOrderListIfNecessary = (event: FromTradingBlockServerEvent.OrderUpdateEvent) => {
    if (event.payload.id && orderListRef.current.isFetched && !orderListRef.current.hasError) {
      dispatch(updateOrderFromEvent(event.payload));
    }
  };

  const refreshDashboardIfNecessary = (event: FromTradingBlockServerEvent.OrderUpdateEvent) => {
    if (!account?.accountId || !orderPositionListRef.current.isFetched || orderPositionListRef.current.hasError) {
      return;
    }

    if (event.payload.status.isCompleted || event.payload.isPartial) {
      dispatch(refreshOrderPositionList(account.accountId));
      dispatch(refreshAccountBalance(account.accountId));

      return;
    }

    if (event.payload.id && event.payload.status.isCancelled && event.payload.action.isBuy) {
      dispatch(refreshAccountBalance(account.accountId));

      return;
    }

    if (event.payload.id && event.payload.status.isPendingNew && event.payload.action.isBuy) {
      dispatch(refreshAccountBalance(account.accountId));

      return;
    }
  };

  const handleOrderUpdateEvent = (event: FromTradingBlockServerEvent.OrderUpdateEvent) => {
    updateOrderListIfNecessary(event);
    refreshDashboardIfNecessary(event);
  };

  const handleSessionExpireEvent = () => {
    dispatch(logout());
    dispatch(
      toastMessagesAdd({
        key: uuid(),
        message: 'Your session has expired. Please login again.',
        severity: 'error',
      }),
    );
  };

  useEffect(() => {
    orderListRef.current = { isFetched: isOrderListFetched, hasError: hasOrderListError };
    orderPositionListRef.current = { isFetched: isOrderPositionListFetched, hasError: hasOrderPositionListError };
  }, [isOrderListFetched, hasOrderListError, isOrderPositionListFetched, hasOrderPositionListError]);

  useEffect(() => {
    if (account?.accountId && EnvConstant.TRADING_BLOCK_WEB_SOCKET_URL) {
      // Create a WebSocket connection
      const socket = new WebSocket(EnvConstant.TRADING_BLOCK_WEB_SOCKET_URL);

      socket.addEventListener('open', () => {
        Logger.info('TradingBlock WebSocket connection is open. Sending initial subscribe message...');
        socket.send(`0=${tbToken}|1=${account?.accountId}|9=1|13=1`);
        Logger.info('TradingBlock WebSocket initial subscribe message was sent.');
      });

      socket.addEventListener('close', () => {
        Logger.info('TradingBlock WebSocket connection is closed.');
      });

      socket.addEventListener('message', (event: MessageEvent<string>) => {
        try {
          const eventDto = parseTradingBlockMessageEvent(event);

          if (eventDto[1] === FromTradingBlockServerEventDto.EventDtoType.ORDER_UPDATE) {
            Logger.debug('Received TradingBlock WebSocket order update event:', JSON.stringify(event.data));
            handleOrderUpdateEvent(mapOrderUpdateEventDtoToModel(eventDto));
          }

          if (eventDto[1] === FromTradingBlockServerEventDto.EventDtoType.SESSION_EXPIRE) {
            Logger.debug('Received TradingBlock WebSocket session expire event:', JSON.stringify(event.data));
            handleSessionExpireEvent();
          }
        } catch (error) {
          Logger.error('Error handling TradingBlock event. Received message: ', event.data);
          Logger.error(error);
        }
      });

      socket.addEventListener('error', event => {
        Logger.error('TradingBlock WebSocket error:', event);
      });

      return () => {
        socket.close();
      };
    }
  }, [tbToken, account?.accountId]);
};
