import { useSelector } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router';

import { OfferOrderIntent } from '../../models/offer-orders.model';
import { MAIN_PATH } from '../../pages/Main';

import { OFFER_ORDERS_PATH } from './NewOfferOrderGuard';

export const OfferOrderIntentGuard = () => {
  const params = useParams<{ offerId: string }>();
  const offerOrderIntent: OfferOrderIntent | null = useSelector((state: any) => state.offerOrders.intent);

  if (!offerOrderIntent) {
    if (params.offerId) {
      return <Navigate to={`/${MAIN_PATH}/${OFFER_ORDERS_PATH}/${params.offerId}/orders/new`}></Navigate>;
    }

    return <Navigate to={`/offers`}></Navigate>;
  }

  return <Outlet />;
};
