import { css } from '@emotion/css';
import {
  AlignItems,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from 'src/styles';

export const offerName = css`
  ${FontWeight.bold}
  ${Font.h1}
  color: ${ColorConstant.GRAY10};
`;

export const offerLogo = css`
  width: 31px;
  height: 31px;
  ${Spacing.mr16};
`;

export const totalInvestment = css`
  ${Font.h1}
  ${TextAlign.center}
  ${Spacing.my12}
  color: ${ColorConstant.GRAY8};
`;

export const totalInvestmentValue = css`
  ${FontWeight.bold}
  ${Spacing.pl8}
`;

export const linkStyle = css`
  color: ${ColorConstant.BRAND6};
  ${FontWeight.bold}
  cursor: pointer;
`;

export const nonAccreditContainer = css`
  ${Spacing.my12}
`;

export const policy = css`
  ${Spacing.mt16}
  color: ${ColorConstant.GRAY7};
`;

export const termsAndConditions = css`
  ${Spacing.mt24}
  ${Spacing.mb8}
  ${TextAlign.center}
  ${JustifyContent.center}
  ${Font.md}
  color: ${ColorConstant.GRAY7};
`;

export const sectionTitle = css`
  ${FontWeight.bold}
  ${Font.md}
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.BRAND6};
  ${Spacing.my12}
`;

export const signatureWrapper = css`
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    .ant-card-body {
      ${Spacing.p8}
    }
  }
`;

export const signature = css`
  height: 2.5rem; !important;
  max-width: 100%;
  width: 100%;
`;

export const otherFactors = css`
  color: ${ColorConstant.BRAND6};
  ${FontWeight.bold}
  cursor: pointer;
`;
