import { css } from '@emotion/css';

import { Color } from '../../../../styles';

export const container = css`
  columns: 2 auto;
`;

export const documentLogo = {
  fontSize: '2.125rem',
  marginRight: '0.75rem',
  color: Color.BRAND.BRAND5,
};

export const documentLabel = css`
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: ${Color.BRAND.BRAND5};
`;

export const documentLink = css`
  display: flex;
  align-items: center;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  width: fit-content;
`;
