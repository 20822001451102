import { css } from '@emotion/css';

import { Spacing } from '../../../styles';

export const contentWrapper = css`
  background: #ffffff;
  min-height: 745px !important;
`;

export const withMaxWidth = css`
  max-width: 75rem;
  width: 100%;
  ${Spacing.px8}
`;

export const layoutWrapper = css`
  background: #ffffff;
  display: flex;
  align-items: center;
`;
