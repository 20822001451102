import { cx } from '@emotion/css';
import { Col } from 'antd';
import { useHasAnyRequestedInvestigationDocuments, useInvestigationListSelector } from 'src/hooks';
import { InvestigationStatusTag } from 'src/lib';
import { AlignItems, Display, FlexDirection, Spacing } from 'src/styles';

import * as Styles from './AccountStatus.styles';

export const AccountStatusHeader = () => {
  const { status } = useInvestigationListSelector();
  const isUserActionNeeded = useHasAnyRequestedInvestigationDocuments();

  const description = () => {
    if (!status) {
      return null;
    }

    if (status.isActive) {
      return (
        <>
          Your account is now active and you may now fund your account and begin trading. If you have any questions,
          please contact us at your convenience. We look forward to working with you.
        </>
      );
    }

    if (status.isRejected && !isUserActionNeeded) {
      return (
        <>Based on the information provided in your application, we are unable to open your account at this time.</>
      );
    }

    if (status.isPending && !isUserActionNeeded) {
      return (
        <>
          Your account application has been submitted. It may take one or two days to review it. Until then your account
          status will be pending. If you have any questions about your application, please contact Customer Support.
          We&apos;re here to help.
        </>
      );
    }

    return (
      <>
        Before we can process your application, we will need the additional information requested below. If you need any
        assistance with providing the requested information or have questions about your application, please contact
        Customer Support. We&apos;re here to help.
      </>
    );
  };

  return (
    <>
      <Col span={24} className={cx(Display.flex, AlignItems.center, Spacing.my12)}>
        <span className={Styles.tagLabel}>Current Status</span>
        {status && <InvestigationStatusTag value={status?.label} />}
      </Col>
      <Col span={24} className={cx(Display.flex, FlexDirection.column, Spacing.my12)}>
        <div className={Styles.description}>{description()}</div>
        <div>Phone: (844) 226-0640</div>
        <div>
          Email: <a href={`mailto:clientservices@myipo.com`}>clientservices@myipo.com</a>
        </div>
      </Col>
    </>
  );
};
