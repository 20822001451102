import { MButton } from 'src/lib/FormComponents/MButton/MButton';
import { Order } from 'src/models/orders.models';

import * as Styles from '../CancelTransferButton/CancelTransferButton.styles';

interface CancelOrderButtonProps {
  value: Order;
  onClick: (offerOrder: Order) => void;
}

export const CancelOrderButton = ({ value, onClick }: CancelOrderButtonProps) => {
  if (value.status.isCancellable()) {
    return (
      <MButton type='tertiary' danger className={Styles.cancelButton} onClick={() => onClick(value)}>
        Cancel
      </MButton>
    );
  }

  return null;
};
