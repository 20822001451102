import { css } from '@emotion/css';

import { BorderRadiusSize } from './variables.styles';

export const BaseStyle = {
  clearFix: css({
    overflow: 'auto',
  }),
  pullLeft: css({
    float: 'left',
  }),
  pullRight: css({
    float: 'right',
  }),
  clip: css({
    overflow: 'hidden',
  }),
  radiusless: css({
    borderRadius: 'none',
  }),
  shadowless: css({
    boxShadow: 'none',
  }),
  unselectable: css({
    userSelect: 'none',
  }),
  clickable: css({
    cursor: 'pointer !important',
  }),
  relative: css({
    position: 'relative',
  }),
  level: css({
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  }),
};

const getBaseBorderRadius = (property: string) => ({
  none: css({
    [property]: 0,
  }),
  xxxs: css({
    [property]: `${BorderRadiusSize.xxxs} !important`,
  }),
  xxs: css({
    [property]: `${BorderRadiusSize.xxs} !important`,
  }),
  xs: css({
    [property]: `${BorderRadiusSize.xs} !important`,
  }),
  md: css({
    [property]: `${BorderRadiusSize.md} !important`,
  }),
  lg: css({
    [property]: `${BorderRadiusSize.lg} !important`,
  }),
});

export const BorderRadius = {
  topLeft: getBaseBorderRadius('borderTopLeftRadius'),
  bottomRight: getBaseBorderRadius('borderBottomRightRadius'),
  bottomLeft: getBaseBorderRadius('borderBottomLeftRadius'),
  topRight: getBaseBorderRadius('borderTopRightRadius'),
  ...getBaseBorderRadius('borderRadius'),
};
