import { css } from '@emotion/css';

import { Color, ScreenBreakpoint, Spacing, Font } from '../../../../styles';

export const noteStyling = css`
  ${Font.h1};
  color: ${Color.GRAYSCALE.GRAY7};
  ${Spacing.pt32};
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    ${Spacing.pt48};
    ${Spacing.px16};
    ${Spacing.mb24};
  }
`;

export const mainWrapper = css`
  ${Spacing.pb64};
`;
