import { Col, Row } from 'antd';
import moment from 'moment';
import { DateConstant } from 'src/constants';
import { ModalField, TransferDetailsDisclaimer } from 'src/lib';
import CurrencyField from 'src/lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { TransferStatusTag } from 'src/lib/TransferStatusTag/TransferStatusTag';
import { Transfer } from 'src/models/transfers.models';

import { MModal } from '../MModal/MModal';

import * as Styles from './TransferDetailsModal.styles';

export interface TransferDetailsModalProps {
  value: Transfer;
  isOpen: boolean;
  onClose: () => void;
}

export const TransferDetailsModal = ({ value, isOpen, onClose }: TransferDetailsModalProps) => {
  const renderContent = () => {
    return (
      <>
        <Row>
          <ModalField
            label='Status'
            value={<TransferStatusTag value={value.status.label} />}
            testId={'transfer-details-status'}
          />
          <ModalField label='Type' value={value.description} testId={'transfer-details-type'} />
          <ModalField
            label='Amount'
            value={
              <CurrencyField
                value={value.amount}
                prefix={`${value.direction.isWithdrawal ? '-' : ''}$`}
                className={Styles.amountValue({ isNegative: value.direction.isWithdrawal })}
              />
            }
            testId={'transfer-details-amount'}
          />
          {value.from && (
            <ModalField label='Transfer From' value={value.from} testId={'transfer-details-transfer-from'} />
          )}
          {value.to && <ModalField label='Transfer To' value={value.to} testId={'transfer-details-transfer-to'} />}
          <ModalField
            label='Date of Creation'
            value={<span>{moment(value.createdAt).format(DateConstant.DEFAULT_DATETIME_FORMAT)}</span>}
            testId={'transfer-details-date-of-creation'}
          />
        </Row>
        <Row className={Styles.disclaimerContainer}>
          <Col>
            <TransferDetailsDisclaimer value={value} />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <MModal
      title={`${value.description} Details`}
      visible={isOpen}
      onClose={onClose}
      tertiaryButtonText='Close'
      onTertiaryButtonClick={onClose}>
      {renderContent()}
    </MModal>
  );
};
