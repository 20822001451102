export const getMaskForRawValue = (maskDefinition: string, rawValue: string) => {
  let i = -1;
  const valLength = rawValue === null || rawValue === undefined ? 0 : rawValue?.length;
  var maskedValue = [...maskDefinition].reduce((aggregatedValue, nextChar) => {
    if (i === valLength - 1) {
      return aggregatedValue;
    }

    switch (nextChar) {
      case '9':
        i++;

        return aggregatedValue + rawValue[i];
      default:
        return aggregatedValue + nextChar;
    }
  }, '');

  return maskedValue;
};

export type MaskAndRaw = {
  mask?: string;
  raw?: string;
};

export const getMaskAndRawValue = (maskDefinition: string, maskedValue: string): MaskAndRaw => {
  let i = -1;
  const maskedValueLength = maskedValue.length;
  let rawValue = '';
  var newMaskedValue = [...maskDefinition].reduce((aggregatedValue, nextChar) => {
    i++;

    if (i >= maskedValueLength) {
      return aggregatedValue;
    }

    switch (nextChar) {
      case '9':
        rawValue += maskedValue[i];

        return aggregatedValue + maskedValue[i];
      default:
        if (i < maskedValueLength && nextChar !== maskedValue[i]) {
          rawValue += maskedValue[i];

          return aggregatedValue + nextChar + maskedValue[i];
        }

        return aggregatedValue + nextChar;
    }
  }, '');

  return {
    mask: newMaskedValue,
    raw: rawValue,
  };
};

export const formatNumber = (value: string) => {
  return value.replace(/[^0-9]/g, '');
};
