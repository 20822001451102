import { type RcFile } from 'antd/lib/upload';
import { countryCodes, InvestigationConstant } from 'src/constants';
import { FileSizeExceededError, NotSupportedFileError } from 'src/errors';
import { mask } from 'src/utils';
import * as Yup from 'yup';

export const formatValidationSchemaError = (
  error: Yup.ValidationError | any,
): Record<string, string | string[]> | any => {
  if (error instanceof Yup.ValidationError) {
    return error.inner.reduce((acc: any, error: any) => {
      acc[error.path] = error.errors;

      return acc;
    }, {});
  }

  return error;
};

export const phoneNumberValidator = Yup.array()
  .of(
    Yup.object({
      prefix: Yup.string().required('Phone Number country code is required'),
      value: Yup.string()
        .required('Phone Number is required')
        .test('compare', 'Phone Number is invalid', (value: any, schema: any) => {
          if (isNaN(Number(value))) {
            return false;
          }
          const country = countryCodes.find(anItem => anItem.dialCode === schema?.parent?.prefix);
          const masked = mask({ value, mask: country?.phoneMask ?? '', substitute: '9' });

          return masked?.length == country?.phoneMask?.length;
        }),
      type: Yup.string().required('Phone Number type is required'),
    }),
  )
  .required('At least a valid Phone Number is required');

export const validateInvestigationFile = (file: RcFile) => {
  const fileSizeRatioToMaxFileSize = (file.size / InvestigationConstant.MAX_FILE_SIZE).toFixed(2);
  const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
  const isSupportedFileSize = Number(fileSizeRatioToMaxFileSize) <= 1;
  const isSupportedFileType = ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type);

  if (!isSupportedFileSize) {
    return Promise.reject(
      new FileSizeExceededError({ maximum: InvestigationConstant.MAX_FILE_SIZE, actual: Number(fileSizeMB) }),
    );
  }

  if (!isSupportedFileType) {
    return Promise.reject(new NotSupportedFileError({ allowed: ['png', 'jpeg', 'jpeg', 'pdf'], actual: file.type }));
  }

  return;
};
