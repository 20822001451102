import { useEffect, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { getInvestigations, logout } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { InvestigationStatus } from 'src/models';
import { MAIN_PATH } from 'src/pages/Main';
import { HISTORY_PATH } from 'src/pages/Main/pages/History/History';
import { SUMMARY_PATH } from 'src/pages/Main/pages/Summary/Summary';
import { ColorConstant, Spacing } from 'src/styles';

import { logo } from '../../styles/images';
import { MDivider } from '../MDivider/MDivider';
import { MDrawer } from '../MDrawer/MDrawer';

import { AccountStatusBanner } from './AccountStatusBanner';
import { AccountValue } from './AccountValue';
import * as Styles from './Header.styles';
import './index.css';
import ResourceLinks from './ResourceLinks';
import { UserInfo } from './UserInfo';

export type MobileHeaderProps = {
  authToken?: string;
  pathName: string;
  status?: InvestigationStatus;
};

export const MobileHeader = ({ authToken, status }: MobileHeaderProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { account } = useAccountSelector();

  const getMyAccountStatusText = () => {
    if (status?.isActive) {
      return <>Account Status</>;
    }

    return (
      <Row align='middle' justify='space-between' className={Styles.menuItemTextContainer}>
        <span>Account Status</span>
        <span className={Spacing.mr24}>
          <i className={Styles.inactiveAccountMenuItemTextIcon(status?.isPending ?? false)} />
        </span>
      </Row>
    );
  };

  useEffect(() => {
    if (account?.accountId) dispatch(getInvestigations(account.accountId));
  }, [account?.accountId]);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <div>
      <Row justify='center'>
        <Col span={24}>
          <div className='mobileHeader'>
            <Link to='/'>
              <img className='logo' src={logo} alt='My IPO' />
            </Link>
            <Button
              icon={<MenuOutlined style={{ fontSize: '20px', color: ColorConstant.BRAND6 }} />}
              onClick={() => setIsDrawerOpen(true)}
              className='mobileNavBtn'
            />
            <MDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              {!authToken ? (
                <div className='loginContainer'>
                  <Link to='/login' className='mobileLinks'>
                    Log in
                  </Link>
                  <Link to='/register' className='mobileLinks'>
                    Sign up
                  </Link>
                </div>
              ) : (
                <div className={Styles.mobileUserInfoWrapper}>
                  <UserInfo />
                  <AccountValue />
                  <div className={Styles.accountSubMenu}>
                    <Link to={`/accounts/${account?.id}/personal-information`} className={Styles.subMenuLink}>
                      My account
                    </Link>
                    {account?.accountId && (
                      <>
                        <Link to={`/accounts/${account.id}/money-transfers`} className={Styles.subMenuLink}>
                          Money Transfers & Preferences
                        </Link>
                        <Link to={`/accounts/${account.id}/account-status`} className={Styles.subMenuLink}>
                          {getMyAccountStatusText()}
                        </Link>
                        <Link to={`/accounts/${account.id}/statements`} className={Styles.subMenuLink}>
                          Statements & Documents
                        </Link>
                        <Link to='/user/settings' className={Styles.subMenuLink}>
                          Change Email Address & Phone Number
                        </Link>
                      </>
                    )}
                    <Link to='/user/indications-of-interest' className={Styles.subMenuLink}>
                      Indication(s) of Interest
                    </Link>
                  </div>
                </div>
              )}
              <div />
              {authToken ? (
                <div className='linkContainer'>
                  <NavLink to={`/${MAIN_PATH}/${SUMMARY_PATH}`} className='mobileLinks'>
                    Summary
                  </NavLink>

                  <NavLink to='/offers' className='mobileLinks'>
                    Offers
                  </NavLink>

                  <NavLink to={`${MAIN_PATH}/${HISTORY_PATH}`} className='mobileLinks'>
                    History
                  </NavLink>
                </div>
              ) : null}
              {authToken ? <MDivider /> : null}
              <ResourceLinks className='mobileLinks' isMobile isAuthenticated={Boolean(authToken)} />
              {authToken ? <MDivider /> : null}
              {authToken && (
                <div className='mobileLinks mobileLogOutLink' onClick={() => dispatch(logout())}>
                  Log out
                </div>
              )}
            </MDrawer>
          </div>
        </Col>
        <AccountStatusBanner />
      </Row>
    </div>
  );
};
