import React from 'react';

import { Col, Row } from 'antd';

import { MTooltip } from '../MTooltip/MTooltip';

import * as Styles from './MFormRadioGroupField.styles';

interface MFormRadioGroupFieldProps {
  label: string;
  value?: JSX.Element | string | number;
  description?: string;
  weight?: 'light' | 'medium';
  alignment: 'horizontal' | 'vertical';
  tooltip?: {
    type: 'popover' | 'modal';
    title?: string;
    content?: string;
  };
}

export const MFormRadioGroupField = ({
  label,
  value,
  description,
  weight = 'medium',
  tooltip,
}: MFormRadioGroupFieldProps) => (
  <Row className={Styles.container}>
    <Col span={24} className={Styles.label(weight)}>
      {label}
    </Col>
    <Col span={24} className={Styles.value}>
      <span>{value}</span>
      {tooltip && (
        <MTooltip type={tooltip.type} title={tooltip.title}>
          {tooltip.content}
        </MTooltip>
      )}
    </Col>
    {description && (
      <Col span={24} className={Styles.description}>
        {description}
      </Col>
    )}
  </Row>
);
