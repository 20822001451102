import { useEffect } from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { DefinitionConstant } from 'src/constants/common.constants';
import { useAccountSelector } from 'src/hooks';
import { useFindMinimalDepositAmountForInvestment } from 'src/hooks/useFindMinimalDepositAmountForInvestment';
import { MFormCurrencyField } from 'src/lib';
import CardComponent from 'src/lib/Miscellaneous/Card';
import { OfferOrderIntent } from 'src/models';

import * as Styles from './CheckPayment.styles';

export interface CheckPaymentProps {
  isValid: (value: boolean) => void;
}

export const CheckPayment = ({ isValid }: CheckPaymentProps) => {
  const { account } = useAccountSelector();
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  const renderOfferCheckInstructions = () => (
    <>
      <span className={Styles.subTitle} data-testid={'check-transfer-instructions'}>
        Make check(s) payable to:
      </span>
      <p className={Styles.paragraph}>
        Apex Clearing FBO ({account?.primaryAccountHolder?.lastName}, {account?.primaryAccountHolder?.firstName},{' '}
        {account?.accountNumber})
      </p>
      <span className={Styles.subTitle}>Mail checks to:</span>
      <p className={Styles.paragraph}>
        <span>TradingBlock</span>
        <br />
        <span>Attn. Cashiering</span>
        <br />
        <span>311 S Wacker Dr, Suite 1775</span>
        <br />
        <span>Chicago, IL 60606</span>
        <br />
      </p>
    </>
  );

  const renderExternalOfferCheckInstructions = () => (
    <>
      <span className={Styles.subTitle} data-testid={'check-transfer-instructions'}>
        Make check(s) payable to:
      </span>
      <p className={Styles.paragraph}>{offerOrderIntent.checkInstructions?.payableTo}</p>
      <span className={Styles.subTitle}>Mail checks to:</span>
      <p className={Styles.paragraph}>
        <span>{offerOrderIntent.checkInstructions?.bankName}</span>
        <br />
        <span>
          {offerOrderIntent.checkInstructions?.address.address1}{' '}
          {offerOrderIntent.checkInstructions?.address.address2
            ? `, ${offerOrderIntent.checkInstructions?.address.address2}`
            : ''}
        </span>
        <br />
        <span>
          {offerOrderIntent.checkInstructions?.address.city}, {offerOrderIntent.checkInstructions?.address.state}{' '}
          {offerOrderIntent.checkInstructions?.address.postalCode}
        </span>
        <br />
      </p>
    </>
  );

  useEffect(() => {
    if (!offerOrderIntent.isExternal && !account?.accountNumber) {
      isValid(false);

      return;
    }
    isValid(true);
  }, []);

  return (
    <div className={Styles.container} data-testid={'confirm-order-modal-check'}>
      <CardComponent className={Styles.card}>
        {!offerOrderIntent.isExternal && (
          <Col span={24}>
            <MFormCurrencyField
              label='Cash Available'
              value={offerOrderIntent.cashAvailable}
              tooltip={{ type: 'popover', content: DefinitionConstant.CASH_AVAILABLE, icon: 'question' }}
              testId={'check-cash-available'}
            />
          </Col>
        )}
        <Col span={24}>
          <MFormCurrencyField label='Transfer Amount' value={minimalDepositAmount} testId={'check-transfer-amount'} />
        </Col>
        {offerOrderIntent.isExternal ? renderExternalOfferCheckInstructions() : renderOfferCheckInstructions()}
      </CardComponent>

      <Row className={Styles.acceptableAndUnaccaptableRow} justify='space-between'>
        <Col span={11} xs={24} md={11} className={Styles.checkInfoCol}>
          <Row>
            <Col span={24}>
              <CheckCircleOutlined className={Styles.checkIcon} />
            </Col>
            <Col span={24}>
              <span
                className={Styles.acceptableAndUnaccaptableSubtitle}
                data-testid={'confirm-order-acceptable-checks'}>
                Acceptable Checks
              </span>
            </Col>
            <Col span={24}>
              <ul className={Styles.acceptableAndUnaccaptableList}>
                <li>Checks must be payable in U.S. dollars and through a U.S. bank.</li>
                <li>
                  Personal checks must be drawn from a bank account in an account owner’s name and must include
                  &quot;Jr&quot; or &quot;Sr&quot; if applicable.
                </li>
                {offerOrderIntent.isExternal ? (
                  <li>
                    If you are investing as an individual a check needs to come from a individual or joint bank account,
                    not from a Trust or other entity type.
                  </li>
                ) : (
                  <>
                    <li>
                      Checks from joint checking accounts may be deposited into either checking account owner’s My IPO
                      account.
                    </li>
                    <li>
                      Checks from an individual checking account may be deposited into a My IPO joint account if that
                      person is one of the account owners.
                    </li>
                    <li>
                      Investment Club checks should be drawn from a checking account in the name of the Investment Club.
                      If a member of the Investment Club remits a personal check, the check must be payable to:
                      &quot;Apex Clearing / FBO the Investment Club name.&quot; (&quot;FBO&quot; stands for &quot;For
                      the Benefit Of&quot;)
                    </li>
                  </>
                )}
              </ul>
            </Col>
          </Row>
        </Col>
        <Col span={11} xs={24} md={11} className={Styles.checkInfoCol}>
          <Row>
            <Col span={24}>
              <CloseCircleOutlined className={Styles.closeIcon} />
            </Col>
            <Col span={24}>
              <span
                className={Styles.acceptableAndUnaccaptableSubtitle}
                data-testid={'confirm-order-unacceptable-checks'}>
                Unacceptable Checks
              </span>
            </Col>
            <Col span={24}>
              <ul className={Styles.acceptableAndUnaccaptableList}>
                <li>Cashier’s check</li>
                <li>Money Orders</li>
                <li>Foreign instruments</li>
                <li>Thrift withdrawal orders</li>
                <li>Domestic drafts</li>
                <li>Checks that have been double-endorsed (checks with more than one signature on the back)</li>
                <li>
                  Third-party checks{' '}
                  {offerOrderIntent.isExternal ? (
                    ''
                  ) : (
                    <>
                      not properly made out and endorsed per the rules stated in the &quot;Acceptable Deposits&quot;
                      section above
                    </>
                  )}
                </li>
                <li>Checks from minors</li>
                <li>Check dated over six months old</li>
                <li>Travelers checks</li>
                <li>Credit card checks</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
