import { useNavigate } from 'react-router';
import { useAccountSelector, useInvestigationListSelector, useHasAnyRequestedInvestigationDocuments } from 'src/hooks';
import { MAlert } from 'src/lib';

export interface YourAchBankLinkNotApprovedAlertMessageProps {
  isAchBankLinkPending: boolean;
}

export const YourAchBankLinkNotApprovedAlertMessage = ({
  isAchBankLinkPending,
}: YourAchBankLinkNotApprovedAlertMessageProps) => {
  const navigate = useNavigate();

  const { status } = useInvestigationListSelector();
  const { account } = useAccountSelector();

  const hasRequestedDocuments = useHasAnyRequestedInvestigationDocuments();

  if (!status?.isPending) {
    return null;
  }

  if (isAchBankLinkPending && hasRequestedDocuments) {
    return (
      <MAlert
        type='warning'
        description={
          <span>
            Your ACH bank link cannot be approved until your account status has been resolved to active. Please visit{' '}
            <a onClick={() => (account?.id ? navigate(`/accounts/${account?.id}/account-status`) : null)}>
              Account Status
            </a>{' '}
            to submit the required information.
          </span>
        }
      />
    );
  }

  if (isAchBankLinkPending && !hasRequestedDocuments) {
    return (
      <MAlert
        type='warning'
        description={
          <span>
            Your ACH bank link cannot be approved until your account status has been resolved to active. You can find
            more information at{' '}
            <a onClick={() => (account?.id ? navigate(`/accounts/${account?.id}/account-status`) : null)}>
              Account Status
            </a>
          </span>
        }
      />
    );
  }

  return null;
};
