import * as Url from '../constants/url';
import {
  AccountBalanceDetailsDto,
  GetAccountBalanceListBodyDto,
  GetAccountBalanceListResponseDto,
} from '../dtos/accountBalances.dtos';
import { replacePlaceholders } from '../sagas/utils';
import { httpRequest } from '../utils/httpRequest';

export interface GetAccountBalanceParamsDto {
  accountId: number;
}

export interface GetAccountBalanceArgs {
  params: GetAccountBalanceParamsDto;
  authToken: string | null;
}

export interface GetAccountBalanceListArgs {
  body: GetAccountBalanceListBodyDto;
  authToken: string | null;
}

export class BalancesApi {
  async retrieve({ params, authToken }: GetAccountBalanceArgs): Promise<AccountBalanceDetailsDto> {
    const { accountId } = params;

    const url = replacePlaceholders(Url.GET_ACCOUNT_BALANCE, { accountId });

    return httpRequest<AccountBalanceDetailsDto>({ method: 'GET', url, authToken });
  }

  async list({ body, authToken }: GetAccountBalanceListArgs): Promise<GetAccountBalanceListResponseDto> {
    return httpRequest<GetAccountBalanceListResponseDto>({
      method: 'POST',
      url: Url.GET_ACCOUNT_BALANCE_LIST,
      authToken,
      body,
    });
  }
}
