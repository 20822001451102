import { css } from '@emotion/css';
import { AlignItems, ColorConstant, Display, Font, FontWeight, JustifyContent, Spacing, TextAlign } from 'src/styles';

export const container = css`
  width: 100%;
  ${Display.flex}
  ${JustifyContent.spaceBetween}
  ${AlignItems.center}
  ${Spacing.py16}
  ${Spacing.px0}
  ${Font.lg}
`;

export const label = css`
  ${FontWeight.semibold}
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.GRAY8};
`;

export const value = css`
  ${FontWeight.normal}
  color: ${ColorConstant.GRAY7};
  ${Display.flex}
  ${TextAlign.right}
  ${JustifyContent.flexEnd}
`;
