import { css } from '@emotion/css';

import { Color, FontWeight, ScreenBreakpoint, Spacing, Font } from '../../../../../../styles';

export const titleHeader = css`
  ${FontWeight.bold};
  ${Font.h3};
  color: ${Color.BRAND.BRAND6};
  ${Spacing.mt32};
  ${Spacing.mb16};
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    ${Spacing.pt48}
    ${Spacing.px16}
    ${Spacing.pb24}
    ${Spacing.my0}
  }
`;
