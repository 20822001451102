import { css } from '@emotion/css';
import { AlignItems, Display, FlexDirection, JustifyContent } from 'src/styles';

export const container = css`
  height: 100vh;
  ${FlexDirection.column}
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
`;
