import { ErrorCode } from 'src/constants';

import { BaseError } from './BaseError';

export class UnknownError extends BaseError {
  constructor(code: ErrorCode) {
    super(`'Unknown error occurred (Error code ${code}). Please try again later or contact My IPO client
    services.`);
  }
}
