import { PropsWithChildren } from 'react';

import { Button } from 'antd';
import type { ButtonType } from 'antd/lib/button';

import * as Styles from './MButton.styles';

export type MButtonSize = 'small' | 'middle' | 'large';

export type MButtonType = 'primary' | 'secondary' | 'tertiary';

export interface MButtonProps {
  value?: string | number;
  disabled?: boolean;
  type?: MButtonType;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  size?: MButtonSize;
  htmlType?: 'button' | 'submit' | 'reset';
  danger?: boolean;
  href?: string;
  target?: string;
  testId?: string;
}

export const MButton = ({
  htmlType = 'button',
  disabled = false,
  loading = false,
  type = 'primary',
  size = 'middle',
  danger = false,
  onClick,
  className,
  value,
  children,
  href,
  target,
  testId,
}: PropsWithChildren<MButtonProps>) => {
  const getBaseClassName = () => {
    switch (type) {
      case 'primary': {
        return Styles.primary({ size, danger });
      }

      case 'secondary': {
        return Styles.secondary({ size, danger });
      }

      case 'tertiary': {
        return Styles.tertiary({ size, danger });
      }

      default: {
        return Styles.primary({ size, danger });
      }
    }
  };

  const mapType = (): ButtonType => {
    switch (type) {
      case 'tertiary': {
        return 'link';
      }

      default: {
        return 'primary';
      }
    }
  };

  return (
    <Button
      tabIndex={0}
      className={`${getBaseClassName()} ${className ?? ''}`}
      value={value}
      onClick={onClick}
      shape='round'
      type={mapType()}
      htmlType={htmlType}
      href={href}
      target={target}
      disabled={disabled}
      loading={loading}
      data-testid={testId}>
      {children}
    </Button>
  );
};
