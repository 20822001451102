import { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { FinancialInformationEmploymentForm } from './FinancialInformationEmploymentForm';
import { FinancialInformationEmploymentView } from './FinancialInformationEmploymentView';

export const FinancialInformationEmploymentSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isFinancialInformationEmoloymentAlreadySaved = () =>
    !isUndefined(account?.primaryAccountHolder?.financialInformation?.employmentStatus);

  useEffect(() => {
    if (isFinancialInformationEmoloymentAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.financialInformation]);

  return (
    <MFormSection
      title='Employment Information'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId={'employment-information'}>
      {isFinancialInformationEmoloymentAlreadySaved() && !isEditMode ? (
        <FinancialInformationEmploymentView account={account} />
      ) : (
        <FinancialInformationEmploymentForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
