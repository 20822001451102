import { PropsWithChildren } from 'react';

import { Tag } from 'antd';

import * as Styles from './MTag.styles';

export type MTagType = 'lightblue' | 'blue' | 'orange' | 'gray' | 'lightgreen' | 'green' | 'lightred' | 'red';

interface MTagProps {
  type: MTagType;
  closable?: boolean;
  icon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onClose?: () => void;
  className?: string;
  testId?: string;
}

export const MTag = ({
  type,
  closable = false,
  icon,
  closeIcon,
  onClose,
  onClick,
  children,
  className = '',
  testId,
}: PropsWithChildren<MTagProps>) => {
  return (
    <Tag
      className={Styles.tag(type)
        .concat(' ' + className)
        .trim()}
      closable={closable}
      onClick={onClick}
      onClose={onClose}
      icon={icon}
      closeIcon={closeIcon}
      data-testid={testId}>
      {children}
    </Tag>
  );
};
