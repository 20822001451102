import { OrderConstant } from '../constants/orders.constants';
import {
  OrderDto,
  OrderPositionDto,
  TradingBlockOrderStatusEnum,
  TradingBlockOrderTypeNumberEnum,
} from '../dtos/orders.dtos';
import { PrivateQuoteDto, PrivateQuoteSnapDto } from '../dtos/privateQuotes.dtos';
import { FromTradingBlockServerEvent, FromTradingBlockServerEventDto } from '../events/FromTradingBlockServerEvent';
import { Order, OrderAction, OrderPosition, OrderStatus, OrderType } from '../models/orders.models';
import { QuoteData } from '../models/quote-media.model';
import { Logger } from '../utils/Logger';

const incompleteMarketOrderPrice = (order: OrderDto, lastValue: number = 0): number => {
  let price = lastValue;

  if (
    order.OrderStatus === TradingBlockOrderStatusEnum.Cancelled ||
    order.OrderStatus === TradingBlockOrderStatusEnum.Rejected ||
    order.OrderStatus === TradingBlockOrderStatusEnum.Expired
  ) {
    price = 0; // NOTE: TB market order price is not saved for unfilled orders;
  }

  return price;
};

const calculatePrice = (order: OrderDto, lastValue: number = 0): number => {
  if (order.AverageFillPrice !== undefined && order.AverageFillPrice > 0) {
    return order.AverageFillPrice;
  }

  if (order.OrderStatus === TradingBlockOrderStatusEnum.Filled) {
    return order.AverageFillPrice ?? 0;
  }

  try {
    switch (order.OrderType) {
      case TradingBlockOrderTypeNumberEnum.Undefined: {
        return order.AverageFillPrice ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Market: {
        return incompleteMarketOrderPrice(order, lastValue);
      }

      case TradingBlockOrderTypeNumberEnum.Limit: {
        return order.Price ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Stop_Market: {
        return order.Stop ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Stop_Limit: {
        return order.Price ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Market_On_Close: {
        return incompleteMarketOrderPrice(order, lastValue);
      }

      default: {
        throw new Error(`Expected order type "TradingBlockOrderTypeNumberEnum". Received: "${order?.OrderType}"`);
      }
    }
  } catch (error) {
    Logger.error('Error calculating order price:', error);
    Logger.info('Fallback: return zero');

    return 0;
  }
};

const isPartialOrder = ({ quantity, filledQuantity }: { quantity: number; filledQuantity: number }): boolean =>
  Boolean(filledQuantity < quantity && filledQuantity > 0);

function addTotalCostToPartialOrder<T extends Order | FromTradingBlockServerEvent.OrderUpdateEventPayload>(
  order: T,
): T {
  let totalCost = order.filledQuantity * order.price;

  if (order.action?.isBuy) {
    totalCost = -(totalCost + order.commission);
  }

  if (order.action?.isSell) {
    totalCost = totalCost - order.commission;
  }

  return { ...order, totalCost };
}

function addTotalCostToCompletedOrder<T extends Order | FromTradingBlockServerEvent.OrderUpdateEventPayload>(
  order: T,
): T {
  let totalCost = order.quantity * order.price;

  if (order.action?.isBuy) {
    totalCost = -(totalCost + order.commission);
  }

  if (order.action?.isSell) {
    totalCost = totalCost - order.commission;
  }

  return { ...order, totalCost };
}

function addTotalCostToIncompleteOrder<T extends Order | FromTradingBlockServerEvent.OrderUpdateEventPayload>(
  order: T,
): T {
  let totalCost = order.quantity * order.price;

  if (order.isPartial) {
    return addTotalCostToPartialOrder(order);
  }

  if (order.action?.isBuy) {
    totalCost = -(totalCost + OrderConstant.ESTIMATED_SERVICE_FEE);
  }

  if (order.action?.isSell) {
    totalCost = totalCost - OrderConstant.ESTIMATED_SERVICE_FEE;
  }

  return { ...order, totalCost };
}

const addTotalCost = (order: Order): Order => {
  let totalCost = order.quantity * order.price;

  if (totalCost === 0) {
    return order;
  }

  return order.status.isCompleted ? addTotalCostToCompletedOrder(order) : addTotalCostToIncompleteOrder(order);
};

function addCommission<T extends Order | FromTradingBlockServerEvent.OrderUpdateEventPayload>(order: T): T {
  if (order.status.isCompleted || order.isPartial) {
    return order;
  }

  return { ...order, commission: OrderConstant.ESTIMATED_SERVICE_FEE };
}

export const mapOrderDtoToModel = (dto: OrderDto, quote?: QuoteData): Order => {
  let result: Order = {
    id: dto.OrderId.toString(),
    updatedAt: dto.Date,
    quantity: dto.Quantity,
    filledQuantity: dto.FillQuantity ?? 0,
    isPartial: isPartialOrder({ quantity: dto.Quantity ?? 0, filledQuantity: dto.FillQuantity ?? 0 }),
    price: calculatePrice(dto, quote?.pricedata.last),
    totalCost: 0,
    type: new OrderType(dto.OrderType),
    symbol: dto.UnderlyingSymbol ?? '',
    description: quote?.longname ?? dto.UnderlyingSymbol ?? '',
    action: new OrderAction(dto.Legs?.[0]?.Action),
    status: new OrderStatus(dto.OrderStatus),
    commission: dto.CommissionAssessed,
    limit: dto.Price,
    stop: dto.Stop,
  };
  result = addTotalCost(result);
  result = addCommission(result);

  return result;
};

export const mapGetOrdersListResponseDtoToModel = (dtos: OrderDto[], quotes: QuoteData[]): Order[] =>
  dtos.map(orderDto =>
    mapOrderDtoToModel(
      orderDto,
      quotes.find(aQuote => aQuote.symbol === orderDto.UnderlyingSymbol),
    ),
  );

const enrichNonTradableOrderPosition = (model: OrderPosition, privateQuote: PrivateQuoteDto): OrderPosition => {
  let result: OrderPosition = {
    ...model,
    isTradable: false,
    value: privateQuote.currentPrice,
    totalValue: model.quantity * privateQuote.currentPrice,
  };

  if (result.purchasePrice === 0) {
    result.purchasePrice = privateQuote.purchasePrice;
  }

  return result;
};

const addNonTradableOrderPositionSnapIfFound = (model: OrderPosition, snap: PrivateQuoteSnapDto[]): OrderPosition => {
  const privateQuoteSnap = snap?.find(aPrivateQuoteSnap => aPrivateQuoteSnap.symbol === model.symbol);

  if (!privateQuoteSnap) {
    return model;
  }
  let result = { ...model };

  if (!isNaN(privateQuoteSnap.lastPrice) && privateQuoteSnap.lastPrice !== 0) {
    result.value = privateQuoteSnap.lastPrice;
    result.totalValue = result.quantity * privateQuoteSnap.lastPrice;
  }

  return result;
};

const makeNonTradableOrderPositionIfFound = (
  model: OrderPosition,
  privateQuotes: PrivateQuoteDto[],
  snap: PrivateQuoteSnapDto[],
): OrderPosition => {
  const privateQuote = privateQuotes.find(aPrivateQuote => aPrivateQuote.symbol === model.symbol);

  if (!privateQuote) {
    return model;
  }
  let result = enrichNonTradableOrderPosition(model, privateQuote);
  result = addNonTradableOrderPositionSnapIfFound(result, snap);

  return result;
};

export const mapOrderPositionDtoToModel = (
  dto: OrderPositionDto,
  privateQuotes: PrivateQuoteDto[],
  snap: PrivateQuoteSnapDto[],
): OrderPosition => {
  let result: OrderPosition = {
    id: dto.OrderId.toString(),
    symbol: dto.Symbol,
    name: dto.Description,
    quantity: dto.OpenQuantity,
    purchasePrice: dto.OpenPrice,
    isTradable: true,
    totalCost: dto.CostBasis,
  };

  result = makeNonTradableOrderPositionIfFound(result, privateQuotes, snap);

  return result;
};

export const mapGetOrdersPositionsDtoToModel = (
  dtos: OrderPositionDto[],
  privateQuotes: PrivateQuoteDto[],
  snap: PrivateQuoteSnapDto[],
): OrderPosition[] => dtos.map(dto => mapOrderPositionDtoToModel(dto, privateQuotes, snap));

export const mapOrderUpdateEventDtoToModel = (
  dto: FromTradingBlockServerEventDto.OrderUpdateEventDto,
): FromTradingBlockServerEvent.OrderUpdateEvent => {
  const result: FromTradingBlockServerEvent.OrderUpdateEvent = {
    type: FromTradingBlockServerEvent.EventType.ORDER_UPDATE,
    payload: {
      updatedAt: dto[4],
      symbol: dto[5],
      quantity: Number(dto[6]),
      filledQuantity: Number(dto[7]),
      price: Number(dto[8]),
      action: new OrderAction(Number(dto[9])),
      status: new OrderStatus(Number(dto[10])),
      isPartial: isPartialOrder({ quantity: Number(dto[6]), filledQuantity: Number(dto[7]) }),
      commission: Number(dto[15]),
    },
  };

  if (result.payload.status.isCompleted) {
    result.payload = addTotalCostToCompletedOrder(result.payload);
  }

  if (result.payload.isPartial) {
    result.payload = addTotalCostToPartialOrder(result.payload);
  }
  result.payload = addCommission(result.payload);

  if (dto[3] !== '0') {
    result.payload.id = dto[3];
  }

  return result;
};
