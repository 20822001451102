import { css } from '@emotion/css';

import { ColorConstant, Font, FontWeight, Spacing } from '../../../styles';

export const checkboxWrapper = css`
  margin-right: 0px !important;
  > span {
    padding-right: 0px !important;
  }

  .ant-checkbox-inner {
    width: 16px;
    height: 16px;

    &::after {
      width: 4px;
      height: 8px;
    }
  }
  .ant-checkbox {
    ${FontWeight.normal}
    ${Font.sm}
    color: ${ColorConstant.GRAY9};
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${ColorConstant.GRAY9};
    border-color: ${ColorConstant.GRAY9};
  }
`;

export const labelWrapper = css`
  ${Font.sm}
  ${FontWeight.normal}
  color: ${ColorConstant.GRAY9};
`;

export const label = css`
  ${Font.lg}
  ${FontWeight.bold}
  ${Spacing.mb12}
`;
