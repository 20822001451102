import { AnyAction } from 'redux';

import { OnboardStep } from '../models/app.models';

import { createAction, Type } from './utils';

export const redirectToOfferAdd = (payload: any): AnyAction => createAction(Type.APP_REDIRECT_TO_OFFER_ADD, payload);

export const redirectToOfferRemove = (): AnyAction => createAction(Type.APP_REDIRECT_TO_OFFER_REMOVE);

export const redirectToOfferOrderAdd = (payload: any): AnyAction =>
  createAction(Type.APP_REDIRECT_TO_OFFER_ORDER_ADD, payload);

export const redirectToOfferOrderRemove = (): AnyAction => createAction(Type.APP_REDIRECT_TO_OFFER_ORDER_REMOVE);

export const doAddRedirectToEmailConfirm = (payload: { confirm: string; updateId: number }): AnyAction =>
  createAction(Type.APP_REDIRECT_TO_EMAIL_CONFIRM_ADD, payload);

export const doRemoveRedirectToEmailConfirm = (): AnyAction => createAction(Type.APP_REDIRECT_TO_EMAIL_CONFIRM_REMOVE);

export const updateOnboardStep = (payload: OnboardStep): AnyAction => createAction(Type.UPDATE_ONBOARD_STEP, payload);

export const doAddOfferInvestReferrer = (payload: { id: string; name: string; logoUrl?: string }): AnyAction =>
  createAction(Type.ADD_OFFER_INVEST_REFERRER, payload);

export const doRemoveOfferInvestReferrer = (): AnyAction => createAction(Type.REMOVE_OFFER_INVEST_REFERRER);
