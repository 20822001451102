import { useState } from 'react';

import { Form, Select } from 'antd';

import { Color } from '../../../styles';

import { DropdownCompType } from './constants';
import './Dropdown.css';

const { Option } = Select;

const DropdownComp = ({
  name,
  placeholder = '',
  value,
  options,
  disabled = false,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  preChangeHook,
  postChangeHook,
  customStyles,
  hideBorder = false,
  customSetFieldValue,
  tabIndex,
  showSearch = false,
}: DropdownCompType) => {
  const [autoFocusInput, setAutoFocusInput] = useState(false);

  const conditionalProps = value ? { defaultValue: value } : {};

  const handleChange = (value: string) => {
    if (preChangeHook) {
      preChangeHook(value);
    }

    setFieldTouched(name, true, true);

    if (customSetFieldValue) {
      customSetFieldValue(value);
    } else {
      setFieldValue(name, value, true);
    }

    if (postChangeHook) {
      postChangeHook(value);
    }
  };

  return (
    <Form.Item
      validateStatus={!!errors && errors[name] && 'error'}
      help={!!errors && touched[name] && errors[name]}
      colon={false}
      style={{
        flexDirection: 'column',
        margin: 0,
        ...customStyles,
      }}>
      <Select
        showSearch={showSearch}
        getPopupContainer={trigger => trigger.parentElement}
        filterOption={(input, option) => {
          const label = option?.label?.toString();

          if (label) {
            return label.toLowerCase().includes(input.toLowerCase());
          }

          return false;
        }}
        onFocus={() => {
          if (!disabled) {
            setAutoFocusInput(true);
          }
        }}
        onBlur={() => {
          setAutoFocusInput(false);
        }}
        autoFocus={tabIndex === 0 ? true : autoFocusInput}
        style={{
          border: hideBorder ? 'none' : undefined,
          borderBottomColor: autoFocusInput ? '#0255a3' : undefined,
          backgroundColor: errors && errors[name] && touched && touched[name] ? Color.RED.RED1 : undefined,
          marginBottom: errors && errors[name] && touched && touched[name] ? 2 : 0,
        }}
        {...conditionalProps}
        placeholder={placeholder}
        bordered={false}
        value={value || undefined}
        disabled={disabled}
        onChange={handleChange}
        data-testid={'account-financial-' + name}>
        {options.map((option: any) => (
          <Option key={`menu_item_${option.value}`} value={option.value} label={option.text}>
            {option.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default DropdownComp;
