import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';

import initialState from './initial';
import { failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const configsList = (state: any = initialState.configs.configsList, action: any) => {
  switch (action.type) {
    case State.actionRequested(Type.GET_ALL_CONFIGS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ALL_CONFIGS):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_ALL_CONFIGS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  configsList,
});
