import React, { useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

export const PrivacyPolicy = () => {
  const [, setTitle] = useOutletContext<any>();

  useEffect(() => {
    setTitle('NOTICE OF MY IPO, LLC PRIVACY POLICY');
  }, []);

  return (
    <div>
      <p>
        OUR COMMITMENT TO YOUR PRIVACY:MY IPO, LLC (My IPO) has a long-standing policy of protecting the confidentiality
        and security of information we collect about our customers. We will not share non-public information about you
        (“Information”) with third parties without your consent, except for the specific purposes described below. This
        notice describes the Information we may gather and the circumstances under which we may share it.{' '}
      </p>
      <p>
        WHY WE COLLECT AND HOW WE USE INFORMATION: We limit the collection and use of Information to the minimum we
        require to deliver superior service to you. Such service includes maintaining your accounts with us, processing
        transactions requested by you and administering our business.
      </p>
      <p>
        HOW WE GATHER INFORMATION: We get most Information directly from you when you apply for, access and use
        financial products and services offered by Cambria Capital, LLC dba My IPO– whether in person, by telephone or
        electronically. We may verify this information or get additional information from consumer reporting agencies or
        public sources. This Information may relate to your finances, employment, avocations or other personal
        characteristics, as well as interactions with or through personnel of My IPO or others.
      </p>
      <p>
        HOW WE PROTECT INFORMATION: We may disclose any Information as directed by you or when we believe it necessary
        for the conduct of our business, or where law requires disclosure. For example, information may be disclosed for
        audit or research purposes, to attorneys or other professionals, or to law enforcement and regulatory agencies,
        to help us prevent fraud.{' '}
      </p>
      <p>
        In addition, we may disclose Information to third party service providers (i) to enable them to provide business
        services for us, such as performing computer related or data maintenance or processing services for us (ii) to
        facilitate the processing of transactions requested by you, (iii), to assist us in offering products and
        services to you, (iv) for credit review and reporting purposes. Except in those specific, limited situations,
        without your consent, we do not make disclosures of Information to other companies who may want to sell their
        products or services to you. For example, we do not sell customer lists and we will not sell your name to a
        catalogue company. It is Cambria’s policy to require all third parties other than your broker, which are to
        receive any Information to sign strict confidentiality agreements.
      </p>
      <p>
        TO WHOM THIS POLICY APPLIES: This Privacy Policy applies to financial products or services provided by CAMBRIA
        used primarily for personal, family or household purposes (not business purposes) by our customers.
      </p>
      <p>
        Access to and Correction of Information: If you desire to review any file we may maintain for your personal
        Information, please contact your broker. If your broker or you notify us that any Information is incorrect, we
        will review it. If we agree, we will correct our records. If we do not agree, you may submit a short statement
        of dispute, which we will include in future disclosures of the disputed Information. Information collected in
        connection with, or in anticipation of, any claim or legal proceeding will not be made available.
      </p>
      <p>
        SHARING INFORMATION WITH OPTIONIQ, LLC and IQ CAPITAL MANAGEMENT, LLC: Cambria Capital dba My IPO, OptioniQ, LLC
        and IQ Capital Management, LLC (the “Subject Parties”) have certain registered representatives in common and
        sometimes pool resources for account opening, administration and regulatory compliance purposes. Notwithstanding
        anything to the contrary contained in this policy, the Subject Parties may share any information that they
        collect from you for purposes of account opening, account administration and for regulatory compliance purposes.
      </p>
      <p>
        Further Information: We reserve the right to change this Privacy Policy. The examples contained within this
        Privacy Policy are illustrations and they are not intended to be exclusive. This notice complies with a recently
        enacted Federal law and new SEC regulations regarding privacy. You may have additional rights under other
        foreign or domestic laws that may apply to you.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
