import React, { ReactNode } from 'react';

import { cx } from '@emotion/css';
import { Col, Row, Space } from 'antd';

import { ConfirmPanelType } from '../../../models/confirm-panel.enum';

import * as Styles from './styles';

interface ConfirmPanelProps {
  title: string;
  description: ReactNode | string;
  iconSource: string;
  type: ConfirmPanelType;
}

const ConfirmPanel = ({ title, description, iconSource, type }: ConfirmPanelProps): JSX.Element => {
  return (
    <Row justify={'center'}>
      <Col>
        <Space direction='vertical'>
          <Row justify={'center'}>
            <Col
              className={cx(
                Styles.logoCircle,
                type === ConfirmPanelType.ERROR ? Styles.errorLight : Styles.successLight,
              )}>
              <i
                className={cx(
                  iconSource,
                  Styles.logo,
                  type === ConfirmPanelType.ERROR ? Styles.errorColor : Styles.successColor,
                )}
              />
            </Col>
          </Row>
          <p
            className={cx(
              Styles.checkEmailLabel,
              type === ConfirmPanelType.ERROR ? Styles.errorColor : Styles.successColor,
            )}>
            {title}
          </p>
          <p className={Styles.descriptionLabel}>{description}</p>
        </Space>
      </Col>
    </Row>
  );
};

export default ConfirmPanel;
