import { Row } from 'antd';
import moment from 'moment';
import { DateConstant } from 'src/constants';
import { ModalField, OrderStatusTag } from 'src/lib';
import CurrencyField from 'src/lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { Order } from 'src/models/orders.models';

import { MModal } from '../MModal/MModal';
import * as Styles from '../TransferDetailsModal/TransferDetailsModal.styles';

export interface OrderDetailsModalProps {
  value: Order;
  isOpen: boolean;
  onClose: () => void;
}

export const OrderDetailsModal = ({ value, isOpen, onClose }: OrderDetailsModalProps) => {
  const renderContent = () => {
    return (
      <Row>
        <ModalField label='Status' value={<OrderStatusTag value={value.status.label} />} />
        <ModalField label='Type' value={`Security ${value.action.isBuy ? '(Buy)' : '(Sell)'}`} />
        <ModalField label='Security Name' value={value.description} />
        <ModalField label='Security Symbol' value={value.symbol} />
        <ModalField label='Order Type' value={value.type.label} />
        <ModalField label='Number of Share(s)' value={value.quantity} />
        <ModalField label='Number of Filled Share(s)' value={value.filledQuantity} />
        <ModalField label='Price per Share' value={<CurrencyField value={value.price} />} />
        <ModalField label='Service Fee' value={<CurrencyField value={value.commission} />} />
        <ModalField
          label='Total Amount'
          value={
            <CurrencyField value={value.totalCost} className={Styles.amountValue({ isNegative: value.action.isBuy })} />
          }
        />
        <ModalField
          label='Date'
          value={<span>{moment(value.updatedAt).format(DateConstant.DEFAULT_DATETIME_FORMAT)}</span>}
        />
      </Row>
    );
  };

  return (
    <MModal
      title={`${value.description} Details`}
      visible={isOpen}
      onClose={onClose}
      tertiaryButtonText='Close'
      onTertiaryButtonClick={onClose}>
      {renderContent()}
    </MModal>
  );
};
