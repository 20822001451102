import { Space, Tag } from 'antd';
import { TransferStatusLabel } from 'src/models/transfers.models';

import * as Styles from './TransferStatusTag.styles';

interface TransferStatusTagProps {
  value: TransferStatusLabel;
}

export const TransferStatusTag = ({ value }: TransferStatusTagProps) => (
  <Tag className={Styles.tag(value)}>
    <Space direction='horizontal' size={8}>
      {value}
    </Space>
  </Tag>
);
