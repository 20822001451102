export type InputCompType = {
  name: string;
  label?: string;
  value?: string;
  errors?: any;
  touched?: any;
  handleSubmit?: Function;
  setFieldValue: Function;
  setFieldTouched: Function;
  handleBlur?: Function;
  onChangeFunction?: Function;
  type?: InputType;
  disabled?: boolean;
  mask?: MaskType;
  values?: any;
  step?: any;
  min?: number;
  max?: number;
  placeholder?: string;
  customMask?: string;
  maxLength?: number;
  val?: any;
  preChangeHook?: any;
  postChangeHook?: any;
  customStyles?: any;
  antInputCustomStyles?: any;
  prefix?: any;
  suffix?: any;
  hideBorder?: boolean;
  textAlign?: TextAlign;
  customSetFieldValue?: Function;
  hideErrorMessage?: boolean;
  tabIndex?: number;
  dataTestId?: string;
};

export enum MaskType {
  None = 'NONE',
  Ssn = 'SSN',
  Phone = 'PHONE',
  Custom = 'CUSTOM',
}

export enum TextAlign {
  Left = 'left',
  Right = 'right',
}

export enum InputType {
  Password = 'password',
  PhoneNumber = 'phoneNumber',
  Number = 'number',
  Text = 'text',
}

export const MaskDefinition = {
  NONE: '',
  SSN: '999-99-9999',
  PHONE: '(999) 999-9999',
};

export const getValue = (value: string): string => {
  if (value && value.length > 0) {
    if (/^\d+$/.test(value[value.length - 1])) {
      return value;
    } else {
      return value.slice(0, -1);
    }
  }

  return '';
};
