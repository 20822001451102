import { useEffect, useRef, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderPhysicalAddress,
  doClearPatchAccountHolderPhysicalAddress,
  doCreateAccountHolderPhysicalAddress,
  doPatchAccountHolderPhysicalAddress,
  toastMessagesAdd,
  toastMessagesRemove,
} from 'src/actions';
import { US_COUNTRY_LABEL, US_COUNTRY_STATE_LIST, US_COUNTRY_VALUE } from 'src/constants';
import { MFormSaveButton, MFormInput, MFormSelect } from 'src/lib';
import { Account } from 'src/models';
import { SeverityEnum } from 'src/typings/commonTypes';
import { getCountryOptionList, renderAddressMismatchErrorMessage } from 'src/utils';
import { v4 as uuid } from 'uuid';

import { upsertAccountHolderPhysicalAddressValidation } from './validations';

interface AccountHolderPhysicalAddressFormValues {
  address1?: string;
  address2?: string;
  country?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  citizenshipCountry?: string;
  birthCountry?: string;
}

export interface AccountHolderPhysicalAddressFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const citizenshipOptions = [
  {
    label: 'U.S. Citizen',
    value: 'USA',
  },
];

export const countryOptions = [{ label: US_COUNTRY_LABEL, value: US_COUNTRY_VALUE }];

export const birthCountryOptions = getCountryOptionList();

export const AccountHolderPhysicalAddressForm = ({
  account,
  onCancel,
  onSave,
}: AccountHolderPhysicalAddressFormProps) => {
  const dispatch = useDispatch();

  const isCreatePhysicalAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createPhysicalAddress?.__requested),
  );
  const failedCreatePhysicalAddressMessage = useSelector(
    (state: any) => state.accountHolders.createPhysicalAddress?.message,
  );
  const succeededCreatePhysicalAddress = useSelector(
    (state: any) => state.accountHolders.createPhysicalAddress?.__succeeded,
  );
  const failedCreatePhysicalAddress = useSelector((state: any) =>
    Boolean(state.accountHolders.createPhysicalAddress?.__failed),
  );

  const isPatchPhysicalAddressLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPhysicalAddress?.__requested),
  );
  const succeededPatchPhysicalAddress = useSelector(
    (state: any) => state.accountHolders.patchPhysicalAddress?.__succeeded,
  );
  const failedPatchPhysicalAddress = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPhysicalAddress?.__failed),
  );
  const failedPatchPhysicalAddressMessage = useSelector(
    (state: any) => state.accountHolders.patchPhysicalAddress?.message,
  );

  const [initialValues, setInitialValues] = useState<AccountHolderPhysicalAddressFormValues>({});
  const [shouldPatch, setShouldPatch] = useState<boolean>(false);
  const physicalAddressFormRef = useRef<FormikProps<AccountHolderPhysicalAddressFormValues> | null>(null);
  const [failedUpsertPhysicalAddressToastId, setFailedUpsertPhysicalAddressToastId] = useState<string | null>(null);

  const isPhysicalAddressAlreadySaved = () => !isEmpty(account?.primaryAccountHolder?.physicalAddress);

  const removeUpsertPhysicalAddressToastMessageIfFound = () => {
    if (failedUpsertPhysicalAddressToastId) {
      dispatch(toastMessagesRemove({ key: failedUpsertPhysicalAddressToastId }));
    }
  };

  const onPopulatePhysicalAddress = () => {
    if (physicalAddressFormRef.current && failedCreatePhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      physicalAddressFormRef.current.setFieldValue('address1', failedCreatePhysicalAddressMessage.address1);
      physicalAddressFormRef.current.setFieldValue('address2', failedCreatePhysicalAddressMessage.address2);
      physicalAddressFormRef.current.setFieldValue('city', failedCreatePhysicalAddressMessage.city);
      physicalAddressFormRef.current.setFieldValue('state', failedCreatePhysicalAddressMessage.state);
      physicalAddressFormRef.current.setFieldValue('postalCode', failedCreatePhysicalAddressMessage.postalCode);
    }

    if (physicalAddressFormRef.current && failedPatchPhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      physicalAddressFormRef.current.setFieldValue('address1', failedPatchPhysicalAddressMessage.address1);
      physicalAddressFormRef.current.setFieldValue('address2', failedPatchPhysicalAddressMessage.address2);
      physicalAddressFormRef.current.setFieldValue('city', failedPatchPhysicalAddressMessage.city);
      physicalAddressFormRef.current.setFieldValue('state', failedPatchPhysicalAddressMessage.state);
      physicalAddressFormRef.current.setFieldValue('postalCode', failedPatchPhysicalAddressMessage.postalCode);
    }
    removeUpsertPhysicalAddressToastMessageIfFound();
  };

  const _onCancel = (form: FormikProps<AccountHolderPhysicalAddressFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderPhysicalAddressFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    setInitialValues({
      address1: account?.primaryAccountHolder?.physicalAddress?.address1,
      address2: account?.primaryAccountHolder?.physicalAddress?.address2,
      city: account?.primaryAccountHolder?.physicalAddress?.city,
      country: account?.primaryAccountHolder?.physicalAddress?.country?.value ?? US_COUNTRY_VALUE,
      state: account?.primaryAccountHolder?.physicalAddress?.state?.value,
      postalCode: account?.primaryAccountHolder?.physicalAddress?.postalCode,
      citizenshipCountry: account?.primaryAccountHolder?.citizenshipCountry?.value,
      birthCountry: account?.primaryAccountHolder?.birthCountry?.value,
    });
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (isPhysicalAddressAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (succeededCreatePhysicalAddress || succeededPatchPhysicalAddress) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreatePhysicalAddress, succeededPatchPhysicalAddress]);

  useEffect(() => {
    if (failedCreatePhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertPhysicalAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedCreatePhysicalAddressMessage,
            onClick: onPopulatePhysicalAddress,
            section: 'physical',
          }),
        }),
      );
      setFailedUpsertPhysicalAddressToastId(toastId);
    }
  }, [failedCreatePhysicalAddress]);

  useEffect(() => {
    if (failedPatchPhysicalAddressMessage?.error === 'UspsAddressMismatchError') {
      removeUpsertPhysicalAddressToastMessageIfFound();
      const toastId = uuid();
      dispatch(
        toastMessagesAdd({
          key: toastId,
          severity: SeverityEnum.Error,
          isClearable: true,
          autoClose: false,
          message: renderAddressMismatchErrorMessage({
            dto: failedPatchPhysicalAddressMessage,
            onClick: onPopulatePhysicalAddress,
            section: 'physical',
          }),
        }),
      );
      setFailedUpsertPhysicalAddressToastId(toastId);
    }
  }, [failedPatchPhysicalAddress]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderPhysicalAddress());
      dispatch(doClearPatchAccountHolderPhysicalAddress());
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={upsertAccountHolderPhysicalAddressValidation}
      innerRef={ref => (physicalAddressFormRef.current = ref)}
      onSubmit={values => {
        const cValues = upsertAccountHolderPhysicalAddressValidation.cast(values);

        if (shouldPatch) {
          // TOOD: sanitize values (remove values which are not changed)
          dispatch(
            doPatchAccountHolderPhysicalAddress({
              address1: cValues.address1,
              address2: cValues.address2,
              city: cValues.city,
              state: cValues.state,
              postalCode: cValues.postalCode,
              country: cValues.country,
              birthCountry:
                cValues.birthCountry === account?.primaryAccountHolder?.birthCountry?.value
                  ? undefined
                  : cValues.birthCountry,
            }),
          );

          return;
        }
        dispatch(doCreateAccountHolderPhysicalAddress(cValues));
      }}>
      {form => {
        return (
          <>
            <Col span={24}>
              <MFormInput
                testId={'account-physical-address1'}
                label='Address Line 1'
                placeholder='Enter'
                value={form.values.address1}
                defaultValue={initialValues.address1}
                error={form.errors.address1}
                onChange={value => {
                  form.setFieldValue('address1', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-physical-address2'}
                label='Address Line 2 (Opt.)'
                placeholder='Enter'
                value={form.values.address2}
                defaultValue={initialValues.address2}
                error={form.errors.address2}
                onChange={value => {
                  form.setFieldValue('address2', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-physical-country'}
                label='Country'
                placeholder='Select'
                defaultValue={account?.primaryAccountHolder?.physicalAddress?.country?.value ?? US_COUNTRY_VALUE}
                options={countryOptions}
                error={form.errors.country}
                onChange={value => {
                  form.setFieldValue('country', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-physical-city'}
                label='City'
                placeholder='Enter'
                value={form.values.city}
                defaultValue={initialValues.city}
                error={form.errors.city}
                onChange={value => {
                  form.setFieldValue('city', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-physical-state'}
                label='State'
                placeholder='Select'
                value={form.values.state}
                defaultValue={account?.primaryAccountHolder?.physicalAddress?.state?.value}
                options={US_COUNTRY_STATE_LIST}
                error={form.errors.state}
                onChange={value => {
                  form.setFieldValue('state', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormInput
                testId={'account-physical-postal-code'}
                label='Postal Code'
                placeholder='Enter'
                value={form.values.postalCode}
                defaultValue={initialValues.postalCode}
                error={form.errors.postalCode}
                onChange={value => {
                  form.setFieldValue('postalCode', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-physical-citizenship'}
                label='Citizenship'
                placeholder='Select'
                defaultValue={account?.primaryAccountHolder?.citizenshipCountry?.value}
                options={citizenshipOptions}
                error={form.errors.citizenshipCountry}
                onChange={value => {
                  form.setFieldValue('citizenshipCountry', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-physical-birth-country'}
                label='Birth Country'
                placeholder='Select'
                defaultValue={account?.primaryAccountHolder?.birthCountry?.value}
                options={birthCountryOptions}
                error={form.errors.birthCountry}
                onChange={value => {
                  form.setFieldValue('birthCountry', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSaveButton<AccountHolderPhysicalAddressFormValues>
                loading={isCreatePhysicalAddressLoading || isPatchPhysicalAddressLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={'physical-address'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
