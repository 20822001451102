import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../styles';

export const drawerContainer = css`
  .ant-drawer-header {
    @media (max-width: ${ScreenBreakpoint.laptop.max}) {
      ${Spacing.pl20}
    }
  }

  .ant-drawer-body {
    @media (max-width: ${ScreenBreakpoint.laptop.max}) {
      ${Spacing.pl20}
    }
  }

  .ant-drawer-header-title button {
    ${Spacing.mr0}
    ${Spacing.pr16}
    ${Spacing.pl0}
    ${Spacing.py8}
  }

  .ant-drawer-title {
    ${FontWeight.semibold}
  }
`;

export const drawerCloseIconContainer = css`
  ${Font.h1}
  ${Display.flex}
  ${FlexDirection.row}
  ${AlignItems.center}
  color: ${ColorConstant.GRAY7};
`;

export const drawerFooterContainer = css`
  ${Display.flex}
  ${JustifyContent.center}
  ${AlignItems.center}
`;
