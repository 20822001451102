import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { BaseStyle } from 'src/styles';

import * as Styles from './Banner.styles';

interface BannerProps {
  type: 'info' | 'warning' | 'error';
  message: string | JSX.Element;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

export const Banner = ({ type, message, size = 'medium', className }: BannerProps) => {
  return (
    <Row className={Styles.container(size)} justify='center'>
      <Col className={cx(BaseStyle.level, Styles.col, className)} span={24}>
        <i className={cx('ri-information-line', Styles.icon(type))} />
        {message}
      </Col>
    </Row>
  );
};
