import { OfferCardPaymentAccountStatusDto } from '../dtos/offers.dtos';

export namespace OfferConstant {
  export const REG_A_PLUS_SUBSTRING = 'Reg A+';
  export const REG_D_TYPE_C_SUBSTRING = 'Reg D: 506(c)';

  export const ACCEPTED_CARD_PAYMENT_ACCOUNT_STATUS_LIST = [
    OfferCardPaymentAccountStatusDto.Enabled,
    OfferCardPaymentAccountStatusDto.Complete,
  ];
}
