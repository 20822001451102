import { cx } from '@emotion/css';
import { Spin } from 'antd';

import * as Styles from './styles';

interface SpinnerProps {
  customSpinnerStyle?: string;
}

const Spinner = ({ customSpinnerStyle = '' }: SpinnerProps) => {
  return (
    <div className={cx(Styles.spinnerContainer, customSpinnerStyle)}>
      <Spin />
    </div>
  );
};

export default Spinner;
