import { css } from '@emotion/css';

import {
  AlignItems,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../styles';

export const customerAgreementContainer = css`
  width: 100%;
  height: 100%;
  ${Display.flex}
  ${FlexDirection.column}
  ${AlignItems.center}
`;

export const top = css`
  ${Display.inlineBlck}
  width: 100%;
  height: 55px;
  max-width: 1200px;
  ${Spacing.mb80}
  ${Spacing.mt48}
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    ${Spacing.mb24}
  }
`;

export const homeButton = css`
  ${Display.flex}
  ${AlignItems.center}
  ${Spacing.ml24}
  ${Font.h1}
  color: ${ColorConstant.BRAND5};
  text-decoration: none;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${ColorConstant.BRAND6};
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    ${Spacing.mb24}
  }
`;

export const logo = css`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    ${Spacing.mt12}
  }
`;

export const container = css`
  width: 100%;
  height: calc(100vh - 110px);
  position: relative;
  ${Display.flex}
  ${FlexDirection.column}
  ${JustifyContent.center}
  ${AlignItems.center}
  ${Spacing.mt80}
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    height: auto;
  }
`;

export const certificationMain = css`
  width: 100%;
  max-width: 600px;
  ${Spacing.p48}
`;

export const certificationLink = css`
  ${Spacing.mb12}
  color: ${ColorConstant.BRAND6};
`;

export const arbitrationThanks = css`
  ${Spacing.mt24}
`;

export const mainText = css`
  ${Spacing.mb24}
  ${Font.h4}
  ${FontWeight.semibold}
  color: ${ColorConstant.BRAND6};
`;

export const subText = css`
  ${Font.h1}
  color: ${ColorConstant.GRAY8};
  ${Spacing.mb24}
  max-width: 650px;
  ${TextAlign.center}
`;

export const linkStyle = css`
  color: inherit;
`;

export const backArrow = css`
  ${Font.h3}
  ${Spacing.mr24}
`;
