import { css } from '@emotion/css';

import { ScreenBreakpoint, Spacing } from '../../../../../../styles';

export const container = css`
  ${Spacing.mx64}
  ${Spacing.my32}

@media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mx32}
    ${Spacing.my16}
  }
`;
