import { css } from '@emotion/css';
import { AlignItems, Display, Font, FontWeight } from 'src/styles';

export const container = (weight: 'light' | 'medium') => css`
  width: 100%;
  ${Display.flex}
  ${weight === 'light' ? Font.md : Font.lg}
  ${weight === 'light' ? FontWeight.normal : FontWeight.semibold}
`;

export const value = css`
  ${Display.flex}
  ${AlignItems.center}
`;
