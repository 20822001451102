import React, { Fragment, useEffect, useState } from 'react';

import { Col, Row, Skeleton } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { FilterBarConstant } from 'src/constants';
import { useAccountSelector, useAccountBalance, useOrderPositions } from 'src/hooks';
import { OfferOrderStatusLabel } from 'src/models/offer-orders.model';
import { Transfer, TransferStatusLabel } from 'src/models/transfers.models';

import {
  doGetAccountBalance,
  accountsSetValues,
  clearOrdersReview,
  getOrdersPositionsRequested,
  getSnapQuotesRequested,
  getTransfers,
  offerOrdersGetListRequested,
} from '../../../../actions';
import { MModal } from '../../../../lib/MModal/MModal';
import { OrderPosition } from '../../../../models/orders.models';
import { PortofolioSummaryInfo } from '../../../../models/portofolio-info.model';
import { Color, ScreenBreakpoint } from '../../../../styles';
import { HISTORY_PATH } from '../History/History';

import SecuritiesModal from './components/SecuritiesModal/SecuritiesModal';
import SummaryCard from './components/SummaryCard/SummaryCard';
import SummaryDisclaimer from './components/SummaryDisclaimer/SummaryDisclaimer';
import SummarySection from './components/SummarySection/SummarySection';
import SummaryTable from './components/SummaryTable/SummaryTable';
import * as Styles from './Summary.styles';

export const SUMMARY_PATH = 'summary';

const Summary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [portofolioData, setPortofolioData] = useState<PortofolioSummaryInfo>({});
  const [showModal, setshowModal] = useState<boolean>(false);
  const [selectedSecurity, setSelectedSecurity] = useState<OrderPosition>();
  const [securitiesList, setSecuritiesList] = useState<OrderPosition[]>([]);

  const [pendingTransfersTotal, setPendingTransfersTotal] = useState<number | typeof NaN>(0);
  const [nonTradedPositionsTotal, setNonTradedPositionsTotal] = useState<number | typeof NaN>(0);
  const [tradedPositionsTotal, setTradedPositionsTotal] = useState<number | typeof NaN>(0);

  const quoteMediaData = useSelector((state: any) => state.quoteMedia.quoteMediaData?.data);
  const isLoadingQuoteMedia = useSelector((state: any) => !!state.quoteMedia.quoteMediaData?.__requested);
  const hasQuoteMediaFailed = useSelector((state: any) => !!state.quoteMedia.quoteMediaData?.__failed);

  const transfersData: Transfer[] | undefined = useSelector((state: any) => state.cashiering.transfers?.data);
  const isLoadingTransfers = useSelector((state: any) => Boolean(state.cashiering.transfers?.__requested));
  const hasMoneyTransfersFailed = useSelector((state: any) => Boolean(state.cashiering.transfers?.__failed));

  const { cashBalance, cashAvailable, pendingOfferOrdersTotal, isAccountBalanceLoading } = useAccountBalance({
    skip: true,
  });
  const { orderPositionList, isOrderPositionListLoading, hasOrderPositionListError } = useOrderPositions({
    skip: true,
  });
  const { account } = useAccountSelector();

  const totalValue = useSelector((state: any) => state.accountDetails?.accountValue?.data?.totalValue);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const tradableOrderPositions = orderPositionList.filter(anOrderPosition => anOrderPosition.isTradable);
  const nonTradableOrderPositions = orderPositionList.filter(anOrderPosition => !anOrderPosition.isTradable);

  const findPendingTransfersTotal = () => {
    const pendingMoneyTransactions = Array.isArray(transfersData)
      ? transfersData?.filter(transfer => transfer.status.isProcessing || transfer.status.isPending)
      : [];

    return hasMoneyTransfersFailed
      ? NaN
      : pendingMoneyTransactions.reduce((acc: number, transfer: Transfer) => {
          if (transfer.direction.isDeposit) return acc + transfer.amount;

          return acc - transfer.amount;
        }, 0);
  };

  const findTradedPositionsTotal = () => {
    return hasOrderPositionListError || hasQuoteMediaFailed
      ? NaN
      : securitiesList?.reduce((acc: number, object: OrderPosition) => acc + (object?.totalValue || 0), 0) || 0;
  };

  const findNonTradedPositionsTotal = () => {
    return hasOrderPositionListError
      ? NaN
      : nonTradableOrderPositions?.reduce((acc: number, object: OrderPosition) => acc + (object?.totalValue || 0), 0);
  };

  const refreshPage = () => {
    dispatch(offerOrdersGetListRequested());
    dispatch(doGetAccountBalance(account?.accountId));
    dispatch(getOrdersPositionsRequested(account?.accountId));
    dispatch(getTransfers(account?.accountId));
  };

  const onRowClick = (record: OrderPosition) => {
    setSelectedSecurity(record);
    setshowModal(true);
  };

  const onModalClose = () => {
    dispatch(clearOrdersReview());
    setshowModal(false);
  };

  const handleDisclaimer = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (_.isEmpty(tradableOrderPositions)) {
      return;
    }
    const symbols = tradableOrderPositions.map(aTradablePosition => aTradablePosition.symbol);
    dispatch(getSnapQuotesRequested(symbols));
  }, [orderPositionList]);

  useEffect(() => {
    if (!quoteMediaData || isLoadingQuoteMedia) return;
    const securitiesList: OrderPosition[] = tradableOrderPositions.map((position: OrderPosition) => {
      const dataSecurities = quoteMediaData?.quotedata;
      const quoteMediaSecurityData = dataSecurities.find((data: any) => data.symbol === position.symbol);
      const security: OrderPosition = {
        ...position,
        value: quoteMediaSecurityData?.pricedata?.last || 0,
        totalValue: position.quantity * (quoteMediaSecurityData?.pricedata?.last || 0),
        change: quoteMediaSecurityData?.pricedata?.changepercent,
      };

      if (quoteMediaSecurityData?.longname) {
        security.name = quoteMediaSecurityData?.longname;
      }

      return security;
    });
    setSecuritiesList(securitiesList);
  }, [quoteMediaData, isLoadingQuoteMedia]);

  useEffect(() => {
    setPendingTransfersTotal(findPendingTransfersTotal());
  }, [transfersData]);

  useEffect(() => {
    setTradedPositionsTotal(findTradedPositionsTotal());
  }, [securitiesList]);

  useEffect(() => {
    setNonTradedPositionsTotal(findNonTradedPositionsTotal());
  }, [nonTradableOrderPositions]);

  useEffect(() => {
    const accountValue = cashBalance + tradedPositionsTotal + nonTradedPositionsTotal;
    dispatch(
      accountsSetValues({ totalValue: accountValue, pendingCashTransactions: pendingTransfersTotal, cashAvailable }),
    );
  }, [cashBalance, tradedPositionsTotal, nonTradedPositionsTotal, pendingTransfersTotal, cashAvailable]);

  useEffect(() => {
    setPortofolioData({
      cashAvailable: {
        title: 'Cash Available',
        color: Color.GREEN.GREEN7,
        totalValue: cashAvailable,
        isExpandible: false,
        alwaysShow: true,
      },
      pendingTransations: {
        title: 'Pending Money Transactions',
        color: Color.ORANGE.ORANGE7,
        totalValue: pendingTransfersTotal,
        isExpandible: true,
        onClick: () =>
          navigate(
            `../${HISTORY_PATH}?show=${JSON.stringify([
              FilterBarConstant.ShowFilterCategory.DepositTransfer,
              FilterBarConstant.ShowFilterCategory.WithdrawalTransfer,
            ])}&filterBy=${JSON.stringify([
              `${TransferStatusLabel.PENDING}_${FilterBarConstant.ByFilterCategory.MoneyTransfer}`,
              `${TransferStatusLabel.PROCESSING}_${FilterBarConstant.ByFilterCategory.MoneyTransfer}`,
            ])}`,
          ),
      },
      pendingNonTradedHoldings: {
        title: 'Pending Investment(s)',
        color: Color.RED.RED7,
        totalValue: pendingOfferOrdersTotal,
        isExpandible: true,
        onClick: () =>
          navigate(
            `../${HISTORY_PATH}?show=${JSON.stringify([
              FilterBarConstant.ShowFilterCategory.OfferOrder,
            ])}&filterBy=${JSON.stringify([
              `${OfferOrderStatusLabel.PendingFunds}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
              `${OfferOrderStatusLabel.PendingOfferClose}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
              `${OfferOrderStatusLabel.PendingAction}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
              `${OfferOrderStatusLabel.PendingFirmCancellation}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
              `${OfferOrderStatusLabel.Approved}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
            ])}`,
          ),
      },
      securities: {
        title: 'Securities Holdings',
        color: Color.BRAND.BRAND7,
        isExpandible: false,
        totalValue: tradedPositionsTotal,
      },
      nonTradedHoldings: {
        title: 'Non-Traded Holdings',
        color: Color.BRAND.BRAND9,
        totalValue: nonTradedPositionsTotal,
        isExpandible: false,
      },
    });
  }, [cashAvailable, pendingTransfersTotal, pendingOfferOrdersTotal, tradedPositionsTotal, nonTradedPositionsTotal]);

  useEffect(() => {
    refreshPage();
  }, [dispatch]);

  return (
    <Skeleton loading={isOrderPositionListLoading || isLoadingTransfers || isAccountBalanceLoading}>
      <Row className={Styles.mainWrapper}>
        <Col span={24}>
          <SummarySection
            title='Summary'
            isLoading={isOrderPositionListLoading || isLoadingTransfers || isAccountBalanceLoading}>
            <SummaryCard
              accountValue={totalValue}
              infoTitle='Account Details'
              portofolio={portofolioData}
              onRefresh={() => refreshPage()}></SummaryCard>
          </SummarySection>
        </Col>
        {securitiesList?.length > 0 && (
          <Col span={24} id='securities-section'>
            <SummarySection title='Securities Holdings' isLoading={isOrderPositionListLoading}>
              <SummaryTable
                name='Securities'
                data={securitiesList}
                onRowClick={onRowClick}
                loading={isOrderPositionListLoading}></SummaryTable>
            </SummarySection>
          </Col>
        )}
        {nonTradableOrderPositions?.length > 0 && (
          <Fragment>
            <Col span={24} id='non-trade-holdings-section'>
              <SummarySection title='Non-Traded Holdings' isLoading={isOrderPositionListLoading}>
                <SummaryTable
                  name='NonTradableHoldings'
                  data={nonTradableOrderPositions}
                  loading={isOrderPositionListLoading}></SummaryTable>
              </SummarySection>
            </Col>
            <Col className={Styles.noteStyling} span={24}>
              <span>
                Please note: values for non-traded holdings may display a lower value than the price you paid.{' '}
                <a onClick={() => handleDisclaimer()}>Click here</a> for more information regarding the value.
              </span>
            </Col>
          </Fragment>
        )}
      </Row>

      {showModal && selectedSecurity && (
        <SecuritiesModal securityDetails={selectedSecurity} onModalClose={() => onModalClose()} isMobile={isMobile} />
      )}

      <MModal
        customWidth={725}
        visible={isModalVisible}
        title='Terms and Conditions'
        tertiaryButtonText='Close'
        onTertiaryButtonClick={() => {
          setIsModalVisible(false);
        }}
        onClose={() => {
          setIsModalVisible(false);
        }}>
        <SummaryDisclaimer />
      </MModal>
    </Skeleton>
  );
};

export default Summary;
