import { SagaReturnType, call, put, select, takeEvery } from 'redux-saga/effects';

import { getChartSnapQuotesSucceeded, getEnhancedSnapQuotesSucceeded, getSnapQuotesSucceeded } from '../actions';
import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { getClientApi } from '../data-communication/ClientApi';
import { QuoteMediaApi } from '../data-communication/QuoteMediaApi';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, TReduxAction } from '../typings/commonTypes';

import { HttpClient, replacePlaceholders, replaceQueryParams, safeSaga } from './utils';

export function* getQuoteMedia(action: TReduxAction) {
  const { symbols } = action.payload;
  const { authToken } = yield select(state => state.auth.data);
  const response: SagaReturnType<QuoteMediaApi['snap']> = yield call(getClientApi().quoteMedia.snap, {
    symbols,
    authToken,
  });
  yield put(getSnapQuotesSucceeded(response));
}

export function* getEnhancedQuoteMedia(action: TReduxAction) {
  const { symbol } = action.payload;
  let url = replacePlaceholders(Url.QUOTE_MEDIA_ENHANCED_DATA_URL, { symbol });
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, null, authToken);
  yield put(getEnhancedSnapQuotesSucceeded(response.data?.results));
}

export function* getChartQuoteMedia(action: TReduxAction) {
  const { symbol, interval } = action.payload;
  let url = replacePlaceholders(Url.QUOTE_MEDIA_CHART_DATA_URL, { symbol });
  url = replaceQueryParams(url, { interval });
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, null, authToken);
  yield put(getChartSnapQuotesSucceeded(response.data));
}

/**
 * Quote Media
 */
export default function* quoteMediaData() {
  yield takeEvery(State.actionRequested(Type.GET_QUOTE_MEDIA), safeSaga(getQuoteMedia, Type.GET_QUOTE_MEDIA));
  yield takeEvery(
    State.actionRequested(Type.GET_ENHANCED_QUOTE_MEDIA),
    safeSaga(getEnhancedQuoteMedia, Type.GET_ENHANCED_QUOTE_MEDIA),
  );
  yield takeEvery(
    State.actionRequested(Type.GET_CHART_QUOTE_MEDIA),
    safeSaga(getChartQuoteMedia, Type.GET_CHART_QUOTE_MEDIA),
  );
}
