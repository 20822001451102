import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  doGetAccountBalance,
  createACHRelationshipSucceeded,
  getACHRelationshipsSucceeded,
  toastMessagesAdd,
  getACHRelationships as getACHRelationshipsActions,
  getTransfers as getTransfersAction,
} from 'src/actions';
import { UnformattedAchRelationshipDto, TransferDto } from 'src/dtos';
import { mapTransferDtoToModel } from 'src/mappers';
import { v4 as uuidv4 } from 'uuid';

import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, SeverityEnum, TReduxAction } from '../typings/commonTypes';

import { HttpClient, replacePlaceholders, replaceQueryParams, safeSaga } from './utils';

export function* getPlaidLinkToken(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.GET_PLAID_LINK_TOKEN_URL, {
    accountId: action.payload,
  });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);

  yield put({
    type: State.actionSucceeded(Type.CASHIERING_GET_PLAID_LIK_TOKEN),
    payload: response.data,
  });
}

export function* getACHRelationships(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  let url = replacePlaceholders(Url.GET_ACH_RELATIONSHIPS_URL, {
    accountId: action.payload.accountId,
  });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);
  const allowedStates = ['PendingCreateApproval', 'Pending', 'Approved'];

  const payload = response.data?.filter((x: any) => allowedStates.includes(x?.tradingBlockACHRelationship?.state));
  yield put(getACHRelationshipsSucceeded(payload));
}

export function* createACHRelationship(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const { accountId } = action.payload;
  const url = replacePlaceholders(Url.CREATE_ACH_RELATIONSHIPS_URL, {
    accountId,
  });
  yield call(HttpClient, 'POST', url, action.payload.body, authToken);

  yield put(createACHRelationshipSucceeded());
}

export function* unlinkBankAccount(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const { accountId } = action.payload;
  let url = replacePlaceholders(Url.UNLINK_BANK_ACCOUNT_URL, {
    accountId,
  });

  url = replaceQueryParams(url, {
    relationshipId: action.payload.relationshipId,
  });

  yield call(
    HttpClient,
    'PUT',
    url,
    {
      comment: 'Canceled by user',
    },
    authToken,
  );

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Bank account was successfully unlinked',
    }),
  );
  yield put(getACHRelationshipsActions(accountId));
}

export function* editNickname(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const { accountId, relationshipId, nickName } = action.payload;

  let url = replacePlaceholders(Url.EDIT_NICKNAME_URL, {
    accountId,
  });

  url = replaceQueryParams(url, {
    relationshipId,
  });

  yield call(HttpClient, 'PUT', url, { nickName }, authToken);

  const achBankLinks: UnformattedAchRelationshipDto[] = yield select(state => state.cashiering.achRelationships.data);
  const updatedBankLinks = achBankLinks.map((anItem: UnformattedAchRelationshipDto) => {
    if (anItem.tradingBlockACHRelationship.id === relationshipId) {
      return {
        ...anItem,
        tradingBlockACHRelationship: {
          ...anItem.tradingBlockACHRelationship,
          nickName,
        },
      };
    }

    return anItem;
  });

  yield put({
    type: State.actionSucceeded(Type.CASHIERING_EDIT_NICKNAME),
  });

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Linked bank account nickname was successfully saved',
    }),
  );
  yield put(getACHRelationshipsSucceeded(updatedBankLinks));
}

export function* depositFunds(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { accountId } = action.payload;

  const url = replacePlaceholders(Url.DEPOSIT_FUNDS_URL, {
    accountId,
  });

  yield call(HttpClient, 'POST', url, action.payload.body, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Deposit transfer was successfully created',
    }),
  );
  yield put(getTransfersAction(accountId));
}

export function* withdrawFunds(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { accountId } = action.payload;

  const url = replacePlaceholders(Url.WITHDRAW_FUNDS_URL, {
    accountId,
  });

  yield call(HttpClient, 'POST', url, action.payload.body, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Withdrawal transfer was successfully created',
    }),
  );
  yield put(getTransfersAction(accountId));
  yield put(doGetAccountBalance(accountId));
}

export function* getTransfers(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  let url = replacePlaceholders(Url.GET_TRANSFERS_URL, {
    accountId: action.payload.accountId,
  });
  const queryParams = action.payload.queryParams;
  url = replaceQueryParams(url, queryParams);
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);

  const models = response.data.transactions.map((anItem: { tradingBlockTransfer: TransferDto }) =>
    mapTransferDtoToModel({ ...anItem.tradingBlockTransfer }),
  );

  yield put({
    type: State.actionSucceeded(Type.CASHIERING_GET_TRANSFERS),
    payload: models,
  });
}

export function* getTransactionDetails(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.GET_TRANSACTION_DETAILS_URL, {
    accountId: action.payload.accountId,
    transactionId: action.payload.transactionId,
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined, authToken);

  yield put({
    type: State.actionSucceeded(Type.CASHIERING_GET_TRANSACTION_DETAILS),
    payload: response.data,
  });
}

export function* cancelDeposit(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const { accountId, transactionId } = action.payload;
  let url = replacePlaceholders(Url.CANCEL_DEPOSIT_URL, {
    accountId,
    transactionId,
  });

  yield call(
    HttpClient,
    'PUT',
    url,
    {
      comment: 'Canceled by user',
    },
    authToken,
  );

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Deposit transfer was successfully cancelled',
    }),
  );
  yield put(getTransfersAction(accountId));
}

export function* cancelWithdraw(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const { accountId, transactionId } = action.payload;
  let url = replacePlaceholders(Url.CANCEL_WITHDRAW_URL, {
    accountId,
    transactionId,
  });

  yield call(
    HttpClient,
    'PUT',
    url,
    {
      comment: 'Canceled by user',
    },
    authToken,
  );
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Withdrawal transfer was successfully cancelled',
    }),
  );
  yield put(getTransfersAction(accountId));
  yield put(doGetAccountBalance(accountId));
}

/**
 * Cashiering sagas
 */
export default function* cashieringSaga() {
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_GET_ACH_RELATIONSHIPS),
    safeSaga(getACHRelationships, Type.CASHIERING_GET_ACH_RELATIONSHIPS),
  );
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_CREATE_ACH_RELATIONSHIP),
    safeSaga(createACHRelationship, Type.CASHIERING_CREATE_ACH_RELATIONSHIP, false),
  );
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_GET_PLAID_LIK_TOKEN),
    safeSaga(getPlaidLinkToken, Type.CASHIERING_GET_PLAID_LIK_TOKEN),
  );
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_UNLINK_ACH_RELATIONSHIP),
    safeSaga(unlinkBankAccount, Type.CASHIERING_UNLINK_ACH_RELATIONSHIP),
  );
  yield takeLatest(
    State.actionRequested(Type.CASHIERING_EDIT_NICKNAME),
    safeSaga(editNickname, Type.CASHIERING_EDIT_NICKNAME),
  );
  yield takeLatest(
    State.actionRequested(Type.CASHIERING_DEPOSIT_FUNDS),
    safeSaga(depositFunds, Type.CASHIERING_DEPOSIT_FUNDS),
  );
  yield takeLatest(
    State.actionRequested(Type.CASHIERING_WITHDRAW_FUNDS),
    safeSaga(withdrawFunds, Type.CASHIERING_WITHDRAW_FUNDS),
  );
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_GET_TRANSFERS),
    safeSaga(getTransfers, Type.CASHIERING_GET_TRANSFERS),
  );
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_GET_TRANSACTION_DETAILS),
    safeSaga(getTransactionDetails, Type.CASHIERING_GET_TRANSACTION_DETAILS),
  );
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_CANCEL_DEPOSIT),
    safeSaga(cancelDeposit, Type.CASHIERING_CANCEL_DEPOSIT),
  );
  yield takeEvery(
    State.actionRequested(Type.CASHIERING_CANCEL_WITHDRAW),
    safeSaga(cancelWithdraw, Type.CASHIERING_CANCEL_WITHDRAW),
  );
}
