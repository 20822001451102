import React, { ReactNode } from 'react';

import { Col, Row, Skeleton } from 'antd';

import * as Styles from './SummarySection.styles';

interface Props {
  children?: ReactNode;
  title: string;
  isLoading: boolean;
}

const SummarySection = ({ children, title, isLoading }: Props) => {
  return (
    <Skeleton loading={isLoading}>
      <Row className={Styles.titleHeader}>{title}</Row>
      <Row justify='center'>
        <Col span={24}>{children}</Col>
      </Row>
    </Skeleton>
  );
};

export default SummarySection;
