import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  doGetOfferOrderDetails,
  doClearGetOfferOrderDetails,
  clearConfirmCardPayment,
  clearCreateOfferOrder,
  clearSubmitCardPayment,
  clearUpdateOfferOrder,
  removeOfferOrderIntent,
  doUpdateOfferOrder,
  doGetAccountBalance,
} from 'src/actions';
import { UpdateOfferOrderBodyDto } from 'src/dtos';
import { useAccountSelector } from 'src/hooks';
import {
  MFormCurrencyField,
  MFormCurrencyRangeField,
  MFormDateField,
  MFormTextField,
  SecurityDeliveryOption,
  MModal,
  SubmissionResult,
} from 'src/lib';
import { OfferDetails, OfferOrderDetails } from 'src/models';
import { BaseStyle, Images } from 'src/styles';

import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import CurrencyField from '../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from '../../../../../lib/Miscellaneous/Spinner';
import { OtherFactorsDisclaimer } from '../../../../../lib/OtherFactorsDisclaimer/OtherFactorsDisclaimer';
import * as Styles from '../NewOfferOrders.styles';

export const NewOfferOrderSucceeded = () => {
  const dispatch = useDispatch();
  const params = useParams<{ offerId: string; offerOrderId: string }>();

  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);

  const offerOrderDetails: OfferOrderDetails | undefined = useSelector((state: any) => state.offerOrders.details.data);
  const isOfferOrderDetailsLoading: Boolean = useSelector((state: any) => state.offerOrders.details.isLoading);
  const createOfferOrderSucceeded = useSelector((state: any) => Boolean(state.offerOrders.create.__succeeded));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { account } = useAccountSelector();

  const isFunded = () => offerOrderDetails?.paymentType.isCard || !offerOrderDetails?.paymentType.value;

  const shouldSendSubscriptionEvent = () => !offerOrderDetails?.analytics.isSubscriptionEventSent;

  const shouldSendFundEvent = () => isFunded() && !offerOrderDetails?.analytics.isFundEventSent;

  const shouldConfirmCardPayment = () =>
    offerOrderDetails?.paymentType.isCard && !offerOrderDetails?.cardPayment?.isConfirmed;

  const renderYourOrderWillRemainDisclaimer = () => (
    <div style={{ textAlign: 'center' }}>
      Your {offerDetails.isConditional ? 'conditional' : ''} order will remain pending until the investment is accepted
      on the offer’s closing date. Due to demand and
      <span onClick={() => setIsModalVisible(true)} className={Styles.otherFactors}>
        &nbsp;other factors&nbsp;
      </span>
      it is possible your investment may not get accepted.
    </div>
  );

  useEffect(() => {
    if (offerOrderDetails) {
      let body: UpdateOfferOrderBodyDto = {};

      if (shouldConfirmCardPayment()) {
        body.isCardPaymentConfirmed = true;
      }

      if (shouldSendSubscriptionEvent()) {
        body.analytics = {
          isSubscriptionEventSent: true,
        };
      }

      if (shouldSendFundEvent()) {
        body.analytics = {
          ...body.analytics,
          isFundEventSent: true,
        };
      }

      if (!isEmpty(body)) {
        dispatch(
          doUpdateOfferOrder({
            params: { id: offerOrderDetails.id },
            body,
          }),
        );
      }
    }
  }, [offerOrderDetails]);

  useEffect(() => {
    if (offerOrderDetails && shouldSendSubscriptionEvent()) {
      window.gtag('event', 'offer_order', {
        offer_id: offerDetails.id,
        offer_name: offerOrderDetails.offerName,
        account_id: offerOrderDetails.accountId,
        offer_order_id: offerOrderDetails.id,
        offer_order_payment_type: offerOrderDetails.paymentType.value,
        offer_order_quantity: offerOrderDetails.quantity,
        offer_order_price: offerOrderDetails.purchasePrice,
        offer_order_value: offerOrderDetails.value,
      });

      if (offerOrderDetails && shouldSendFundEvent()) {
        window.gtag('event', 'offer_order_fund', {
          offer_id: offerDetails.id,
          offer_name: offerOrderDetails.offerName,
          account_id: offerOrderDetails.accountId,
          offer_order_id: offerOrderDetails.id,
          offer_order_payment_type: offerOrderDetails.paymentType.value,
          offer_order_fund_value: offerOrderDetails.value,
        });
      }
    }
  }, [offerOrderDetails]);

  useEffect(() => {
    return () => {
      if (offerOrderDetails && !offerOrderDetails.paymentType.isCard && createOfferOrderSucceeded) {
        dispatch(doGetAccountBalance(offerOrderDetails.accountId));
      }
    };
  }, [offerOrderDetails]);

  useEffect(() => {
    if (params.offerOrderId) {
      dispatch(doGetOfferOrderDetails(params.offerOrderId));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(removeOfferOrderIntent());
      dispatch(clearCreateOfferOrder());
      dispatch(doClearGetOfferOrderDetails());
      dispatch(clearUpdateOfferOrder());
      dispatch(clearConfirmCardPayment());
      dispatch(clearSubmitCardPayment());
    };
  }, []);

  if (isOfferOrderDetailsLoading) return <Spinner />;

  return (
    <NewOfferOrderLayout
      step='create-succeeded'
      description={renderYourOrderWillRemainDisclaimer()}
      offerId={offerDetails.id}>
      <SubmissionResult type='success' />
      <Col span={24} className={BaseStyle.level}>
        <img
          className={Styles.offerLogo}
          src={offerDetails.logoUrl || Images.DefaultOffering}
          alt={`${offerDetails.name}_logo`}
        />
        <span className={Styles.offerName}>{offerDetails.name}</span>
      </Col>
      <Col span={24}>
        <MFormTextField
          label='Investor Name'
          value={`${account?.primaryAccountHolder?.firstName} ${account?.primaryAccountHolder?.lastName}`}
        />
      </Col>
      <Col span={24}>
        <MFormTextField
          label='Address'
          value={`${account?.primaryAccountHolder?.physicalAddress?.address1}, ${account?.primaryAccountHolder?.physicalAddress?.city}, ${account?.primaryAccountHolder?.physicalAddress?.postalCode}, ${account?.primaryAccountHolder?.physicalAddress?.country?.value}`}
        />
      </Col>
      <Col span={24}>
        <MFormDateField label='Date' value={offerOrderDetails?.createdAt} />
      </Col>
      <Col span={24}>
        {offerOrderDetails?.conditional ? (
          <MFormCurrencyRangeField
            label='Share Range'
            min={offerOrderDetails?.conditional?.minSharePrice}
            max={offerOrderDetails?.conditional?.maxSharePrice}
          />
        ) : (
          <>
            {offerOrderDetails?.purchasePrice && (
              <MFormCurrencyField label='Share Price' value={offerOrderDetails.purchasePrice} />
            )}
            <MFormTextField label='Share Quantity' value={offerOrderDetails?.quantity} />
          </>
        )}
      </Col>
      {offerOrderDetails?.isExternal && offerOrderDetails?.externalDetails?.securityDeliveryOption && (
        <Col span={24}>
          <MFormTextField
            label='Security Delivery Option'
            value={
              <SecurityDeliveryOption
                label={offerOrderDetails.externalDetails.securityDeliveryOption.label}
                tooltip={offerOrderDetails.externalDetails.securityDeliveryOption.tooltip}
              />
            }
          />
        </Col>
      )}

      <Col span={24}>
        <div className={Styles.totalInvestment}>
          <span>Total Investment</span>
          {offerDetails.isConditional ? (
            <CurrencyField
              value={offerOrderDetails?.conditional?.totalInvestment}
              className={Styles.totalInvestmentValue}
            />
          ) : (
            <CurrencyField value={offerOrderDetails?.value} className={Styles.totalInvestmentValue} />
          )}
        </div>
      </Col>
      <MModal
        visible={isModalVisible}
        title='Other Factors'
        tertiaryButtonText='Close'
        onTertiaryButtonClick={() => {
          setIsModalVisible(false);
        }}
        onClose={() => {
          setIsModalVisible(false);
        }}>
        <OtherFactorsDisclaimer />
      </MModal>
    </NewOfferOrderLayout>
  );
};
