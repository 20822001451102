import { useEffect, useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, DatePicker, Row, Space } from 'antd';
import { Moment } from 'moment';
import { useMediaQuery } from 'react-responsive';

import { ScreenBreakpoint } from '../../../styles';
import { MDivider } from '../../MDivider/MDivider';
import { RadioFilter } from '../RadioFilter/RadioFilter';

import * as Styles from './TimelineFilter.styles';

const { RangePicker } = DatePicker;

export enum FixedTimelineFilterValueType {
  THIS_YEAR = 0,
  THIS_MONTH,
  THIS_WEEK,
}

export const FixedTimelineFilterLabel = new Map<number, string>([
  [FixedTimelineFilterValueType.THIS_YEAR, 'This Year'],
  [FixedTimelineFilterValueType.THIS_MONTH, 'This Month'],
  [FixedTimelineFilterValueType.THIS_WEEK, 'This Week'],
]);

export interface TimelineFilterValueType {
  fixedTimeline?: FixedTimelineFilterValueType;
  customTimeline?: [any, any];
}

interface TimelineFilterProps {
  onSelect: (value: TimelineFilterValueType) => any;
  selectedValue?: TimelineFilterValueType;
  defaultValue?: TimelineFilterValueType;
}

export const TimelineFilter = ({ onSelect, selectedValue, defaultValue }: TimelineFilterProps) => {
  const [value, setValue] = useState<TimelineFilterValueType>({
    ...defaultValue,
    ...selectedValue,
  });
  const [savedFilters, setSavedFilters] = useState<TimelineFilterValueType>({ ...defaultValue, ...selectedValue });
  const [disabled, setDisabled] = useState<boolean>(false);
  const [toggleReset, setToggleReset] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const fixedTimeline = Object.keys(FixedTimelineFilterValueType)
    .filter(v => isNaN(Number(v)))
    .map((key: any) => ({
      label: FixedTimelineFilterLabel.get(Number(FixedTimelineFilterValueType[key])),
      value: Number(FixedTimelineFilterValueType[key]),
    }));

  useEffect(() => {
    onReset();
  }, []);

  useEffect(() => {
    if (!value.fixedTimeline) setDisabled(true);
    if (!value.customTimeline) setDisabled(false);
    if (JSON.stringify(value) !== JSON.stringify(savedFilters)) onSubmitFilters();
  }, [value, savedFilters]);

  useEffect(() => {
    onSubmitFilters();
  }, [toggleReset]);

  useEffect(() => {
    setValue({ ...value, ...selectedValue });
    setSavedFilters({ ...value, ...selectedValue });
  }, [selectedValue]);

  const onFixedTimelineFilterSelected = (selectedValue: number) => {
    setValue({ ...value, fixedTimeline: selectedValue, customTimeline: undefined });
  };

  const onCustomTimelineFilterSelected = (selectedValue: any) => {
    if (!selectedValue)
      return setValue({
        ...value,
        fixedTimeline: defaultValue?.fixedTimeline,
        customTimeline: undefined,
      });

    const [startDate, endDate]: [Moment, Moment] = selectedValue;

    return setValue({
      ...value,
      fixedTimeline: undefined,
      customTimeline: [startDate.startOf('day'), endDate.endOf('day')],
    });
  };

  const onReset = () => {
    setToggleReset(!toggleReset);
    setValue({ ...defaultValue });
    onSubmitFilters();
  };

  const onSubmitFilters = () => {
    setSavedFilters({ ...value });
    onSelect({ ...value });
  };

  const showTimelineLabel = () => {
    if (savedFilters.fixedTimeline !== undefined)
      return (
        <Space size={4} direction='horizontal'>
          <LeftOutlined />
          <span>{`${FixedTimelineFilterLabel.get(savedFilters.fixedTimeline)}`}</span>
        </Space>
      );

    if (savedFilters.customTimeline !== undefined) {
      const [startDate, endDate] = savedFilters.customTimeline;

      return (
        <Space size={4} direction='horizontal'>
          <LeftOutlined />
          <span>{`${startDate.format('ll')} to ${endDate.format('ll')}`}</span>
          <RightOutlined />
        </Space>
      );
    }

    return (
      <Space size={4} direction='horizontal'>
        <LeftOutlined />
        <span>Filter By Date</span>
      </Space>
    );
  };

  return (
    <RadioFilter
      title='Fixed Timeline'
      label={isMobile ? <></> : showTimelineLabel()}
      disabled={disabled}
      direction={isMobile ? 'vertical' : 'horizontal'}
      options={fixedTimeline}
      onSelect={onFixedTimelineFilterSelected}
      selectedValue={value?.fixedTimeline}
      defaultValue={defaultValue?.fixedTimeline}
      resetOnToggle={toggleReset}>
      <Row className={Styles.customTimelineWrapper}>
        {isMobile && <MDivider />}
        <Col className={Styles.label}>Custom Timeline</Col>
        <Col>
          <RangePicker
            className={Styles.timeline}
            onChange={onCustomTimelineFilterSelected}
            value={value.customTimeline}
          />
        </Col>
      </Row>
    </RadioFilter>
  );
};
