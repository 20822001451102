import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountSelector } from 'src/hooks';

import { downloadMonthlyStatement } from '../../actions';
import { MonthlyStatementDto } from '../../dtos/statements.dtos';
import { AccountStatementDownload } from '../../models/statements.models';
import { getAccountStatementFilename } from '../../utils/getAccountStatementFilename';

import { DownloadAccountStatementButton } from './DownloadAccountStatementButton';

export interface DownloadMonthlyStatementButtonProps {
  statement: MonthlyStatementDto;
}

export const DownloadMonthlyStatementButton = ({ statement }: DownloadMonthlyStatementButtonProps) => {
  const dispatch = useDispatch();

  const monthlyStatementsDownloads: AccountStatementDownload<MonthlyStatementDto>[] = useSelector(
    (state: any) => state.statements.monthlyStatements.downloads,
  );
  const { account } = useAccountSelector();

  const findMonthlyStatementDownloadUrl = () => {
    const monthlyStatementDownload = monthlyStatementsDownloads?.find(
      aDownload => aDownload.data.date === statement.date,
    );

    return monthlyStatementDownload?.data?.url;
  };

  const isButtonLoading = () => {
    const taxDocumentDownload = monthlyStatementsDownloads.find(aDownload => aDownload.data.date === statement.date);

    return Boolean(taxDocumentDownload?.isLoading);
  };

  const onClick = () => {
    const fileUrl = findMonthlyStatementDownloadUrl();

    if (fileUrl) {
      saveAs(fileUrl, getAccountStatementFilename(statement));

      return;
    }

    dispatch(downloadMonthlyStatement({ accountId: account?.accountId, date: statement.date }));
  };

  return <DownloadAccountStatementButton onClick={onClick} loading={isButtonLoading()} />;
};
