import { css, cx } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../../../../styles';

export const container = css`
  ${Spacing.px64}
  ${Spacing.py32}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.px32}
    ${Spacing.py16}
  }
`;

export const linkBankText = css`
  ${Spacing.mr12}
  p {
    ${FontWeight.normal}
    ${Font.sm}
    ${Display.flex}
    ${AlignItems.center}
    color: ${ColorConstant.GRAY8};
  }
`;

export const maxTranferValidationMessage = cx(
  'ant-form-item-explain-error',
  css`
    font-size: 14px;
    @media (max-width: ${ScreenBreakpoint.mobile.max}) {
      ${Font.sm}
      ${Spacing.mt4}
      ${Spacing.ml4}
    }
  `,
);

export const linkBankButton = css`
  ${Spacing.ml12}
`;

export const cardContainer = cx(
  css`
    background: ${ColorConstant.RED1};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.35);
    ${BorderRadius.xxs}
  `,
);

export const linkBankContainer = css`
  ${Display.flex}
  ${FlexDirection.row}
  ${AlignItems.flexStart}
  ${JustifyContent.spaceBetween}
`;

export const depositTitle = css`
  ${FontWeight.normal}
  ${Font.md}
  color: ${ColorConstant.GRAY10};
`;

export const fieldTransactionLabel = css`
  ${FontWeight.semibold}
  ${Font.md}
  color: ${ColorConstant.GRAY8};
  ${Display.flex}
`;

export const transactionSection = css`
  width: 100%;
  ${Display.flex}
  ${FlexDirection.row}
  ${JustifyContent.spaceBetween}
  ${AlignItems.center}
  
  @media (max-width: ${ScreenBreakpoint.mobile.min}) {
    ${FlexDirection.column}
    ${AlignItems.flexStart}
  }
`;
