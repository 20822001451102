import { css } from '@emotion/css';
import { ColorConstant, Display, FlexDirection, Font, FontWeight, Spacing } from 'src/styles';

export const sectionTitle = css`
  ${Spacing.my16}
  ${Font.sm}
  color: ${ColorConstant.GRAY6};
`;

export const policyText = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY6};
  ${Spacing.mb16}
`;

export const nickname = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY6};
`;

export const senderReceiverTopLabel = css`
  ${FontWeight.semibold}
  text-transform: uppercase;
  color: ${ColorConstant.GRAY6};
`;

export const bankLogo = css`
  width: 1.5rem;
  height: 1.5rem;
`;

export const noAvailableFundsWrapper = css`
  ${Display.flex}
  ${FlexDirection.column};
`;

export const spinner = (height: number) => css`
  height: ${height}px;
`;

export const label = css`
  ${FontWeight.semibold};
  ${Font.h2};
  color: ${ColorConstant.GRAY8};
  ${Spacing.my16};
`;

export const tableContainer = css`
  width: 100%;
`;

export const viewDetailsButton = css`
  ${Font.sm}
  ${FontWeight.semibold}
`;

export const amountValue = ({ isNegative }: { isNegative: boolean }) => css`
  ${FontWeight.bold}
  ${Font.lg}
  color: ${isNegative ? ColorConstant.RED8 : ColorConstant.GREEN8};
`;
