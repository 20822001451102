import React from 'react';

import { cx } from '@emotion/css';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { Header } from '../../Header/Header';

import * as Styles from './styles';

const { Content } = Layout;

interface HeaderLayoutProps {
  allowWideScreen?: boolean;
}

export const HeaderLayout = ({ allowWideScreen = false }: HeaderLayoutProps) => {
  return (
    <Layout className={allowWideScreen ? '' : Styles.layoutWrapper}>
      <Header />
      <Content className={cx(Styles.contentWrapper, allowWideScreen ? '' : Styles.withMaxWidth)}>
        <Outlet />
      </Content>
    </Layout>
  );
};
