import { css, cx } from '@emotion/css';
import { ColorConstant, Font } from 'src/styles';

export const passingRule = css`
  ${Font.md}
  color: ${ColorConstant.GRAY8};
`;

export const errorRule = cx(
  passingRule,
  css`
    color: ${ColorConstant.RED6};
  `,
);
