import _ from 'lodash';

import * as Url from '../constants/url';
import { QuoteData } from '../models/quote-media.model';
import { replacePlaceholders } from '../sagas/utils';
import { httpRequest } from '../utils/httpRequest';

export interface QuoteMediaSnapArgs {
  symbols: string[];
  authToken: string;
}

export class QuoteMediaApi {
  async snap({ symbols, authToken }: QuoteMediaSnapArgs): Promise<{ quotedata: QuoteData[] }> {
    if (_.isEmpty(symbols)) {
      return {
        quotedata: [],
      };
    }

    const url = replacePlaceholders(Url.QUOTE_MEDIA_DATA_URL, {
      symbol: _.uniq(symbols)
        .filter(aSymbol => Boolean(aSymbol))
        .join(','),
    });

    return httpRequest<{ quotedata: QuoteData[] }>({
      method: 'GET',
      url,
      authToken,
    });
  }
}
