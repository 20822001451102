import { Col, Row } from 'antd';

import { MCheckboxGroupOption } from '../MCheckboxGroup/MCheckboxGroup';
import { MTooltip } from '../MTooltip/MTooltip';

import * as Styles from './MFormCheckboxGroupField.styles';

interface MFormCheckboxGroupItem {
  title: string;
  options: MCheckboxGroupOption[];
}

interface MFormCheckboxGroupFieldProps {
  label: string;
  groups: MFormCheckboxGroupItem[];
  tooltip?: {
    type: 'modal' | 'popover';
    title?: string;
    content?: string | JSX.Element;
  };
}

export const MFormCheckboxGroupField = ({ label, groups, tooltip }: MFormCheckboxGroupFieldProps) => {
  const renderGroups = () => {
    return groups.map((aGroup: MFormCheckboxGroupItem) => {
      return (
        <Row key={aGroup.title} className={Styles.groupContainer}>
          <Col span={24} className={Styles.title}>
            {aGroup.title}
          </Col>
          {aGroup.options.map(option => (
            <Row key={option.value} className={Styles.option}>
              <Col span={10}>{option.label}</Col>
              <Col span={14} className={Styles.optionValue}>
                {option.value}
              </Col>
            </Row>
          ))}
        </Row>
      );
    });
  };

  return (
    <Row className={Styles.container}>
      <Col span={24} className={Styles.label}>
        {label}
        {tooltip && (
          <MTooltip title={tooltip.title} type='popover' icon='info' showIcon>
            {tooltip.content}
          </MTooltip>
        )}
      </Col>
      {renderGroups()}
    </Row>
  );
};
