import { css } from '@emotion/css';

import { BaseStyle, BorderRadius, ColorConstant, Font, FontWeight, Spacing } from '../../../styles';

export const tagDefaultStyle = ({
  color = ColorConstant.GRAY9,
  background = ColorConstant.BRAND1,
}: {
  color?: string;
  background?: string;
}) => css`
  color: ${color};
  background-color: ${background};
  ${FontWeight.semibold}
  ${Font.sm}
  border: 1px solid ${background};
  ${BorderRadius.xxs}
  vertical-align: middle;
  ${Spacing.py4}
  ${Spacing.px8}
`;

export const tagFilterByStyle = css`
  ${BorderRadius.xxs}
  ${FontWeight.semibold}
  ${Font.sm}
  vertical-align: middle !important;
  ${Spacing.py4}
  ${Spacing.px8}  
  height: auto !important;
`;

export const closeStyle = css`
  ${Font.md}
  vertical-align: middle;
`;

export const filterItemContainer = ({ isMobile }: { isMobile: boolean }) => css`
  ${isMobile ? undefined : Spacing.py8}
  ${isMobile ? 'width: 100%' : 'inherit'};
`;

export const filterButton = css`
  ${BaseStyle.pullRight}
  color: ${ColorConstant.GRAY8};
  border-color: ${ColorConstant.GRAY8};
  ${BorderRadius.lg}
`;
