import { Modal, Row } from 'antd';

import * as Styles from './MModalv2.styles';

export type MModalV2Props = {
  title: string;
  open: boolean;
  onClose?: () => void;
  width?: number;
  height?: number;
  className?: string;
  maskClosable?: boolean;
  closable?: boolean;
  centered?: boolean;
  body?: React.ReactNode;
  footer?: React.ReactNode;
};

export const MModalV2 = ({
  title,
  open,
  onClose,
  width,
  height,
  maskClosable,
  closable = true,
  centered = true,
  className = '',
  footer,
  body,
}: MModalV2Props) => {
  return (
    <Modal
      data-testid={`ant-modal-${title.toLowerCase().replace(/ /g, '-')}`}
      width={width}
      bodyStyle={{ height }}
      destroyOnClose
      centered={centered}
      title={title}
      open={open}
      onCancel={onClose}
      closable={closable}
      className={className}
      footer={
        footer ? (
          <Row justify='end' align='middle'>
            {footer}
          </Row>
        ) : null
      }
      maskClosable={maskClosable}>
      <Row className={Styles.container}>{body}</Row>
    </Modal>
  );
};
