import { applyMiddleware, combineReducers, legacy_createStore as createStore, Middleware } from 'redux';
import { getPersistConfig } from 'redux-deep-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducers';
import initialRootReducer from './reducers/initial';
import sagas from './sagas';

/**
 * React reducer
 */
export const rootReducer = combineReducers({
  ...reducer,
});

const sagaMiddleware = createSagaMiddleware();

/**
 * Redux persistance configuration
 */

const persistConfig = getPersistConfig({
  key: 'myipowebfunnel',
  storage, // whatever storage you use
  whitelist: ['auth.data'],
  rootReducer, // your root reducer must be also passed here
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Definition of cross-environment middlewares
 */
const middleware: Middleware[] = [sagaMiddleware];

/**
 * Development environment middlewares
 */
if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger({ collapsed: true }));
}

/**
 * Create redux-store with
 * - reducers
 * - initialReducers
 *
 */
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

/**
 * Run all sagas
 */
if (sagas && sagas.length)
  sagas.forEach(saga => {
    sagaMiddleware.run(saga);
  });

export const persistedStore = persistStore(store);
export const initialReducer = initialRootReducer;

export default store;
