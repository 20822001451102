import { Col, Row } from 'antd';
import { MFormTextField } from 'src/lib';
import { Account } from 'src/models';
import { mask } from 'src/utils';

import * as Styles from './TrustedContact.styles';

export interface TrustedContactViewProps {
  account?: Account;
}

export const TrustedContactView = ({ account }: TrustedContactViewProps) => {
  const renderPhoneNumberList = () => {
    return (
      account?.primaryAccountHolder?.trustedContact?.phones?.map(phone => {
        return (
          <Col span={24} key={phone.type}>
            {`+1${mask({ value: phone.phoneNumber, mask: '(999) 999-9999', substitute: '9' })} (${phone.type})`}
          </Col>
        );
      }) ?? <Col span={24} />
    );
  };

  return (
    <>
      <MFormTextField label='First Name' value={account?.primaryAccountHolder?.trustedContact?.firstName} />
      <MFormTextField label='Last Name' value={account?.primaryAccountHolder?.trustedContact?.lastName} />
      <MFormTextField label='Email' value={account?.primaryAccountHolder?.trustedContact?.email} />
      <MFormTextField label='Phone Number(s)' value={<Row>{renderPhoneNumberList()}</Row>} />
      <Col span={24} className={Styles.title}>
        Address
      </Col>
      <MFormTextField label='Address Line 1' value={account?.primaryAccountHolder?.trustedContact?.address1} />
      <MFormTextField label='Address Line 2 (Opt.)' value={account?.primaryAccountHolder?.trustedContact?.address2} />
      <MFormTextField label='Country' value={account?.primaryAccountHolder?.trustedContact?.country?.label} />
      <MFormTextField label='City' value={account?.primaryAccountHolder?.trustedContact?.city} />
      <MFormTextField label='State' value={account?.primaryAccountHolder?.trustedContact?.state?.label} />
      <MFormTextField label='Postal Code' value={account?.primaryAccountHolder?.trustedContact?.postalCode} />
    </>
  );
};
