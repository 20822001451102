import { css } from '@emotion/css';

import { AlignItems, Color, Display, Spacing } from '../../../../styles';

export const cardWrapper = css`
  background: ${Color.GRAYSCALE.GRAY1};
  box-shadow: 0rem 0.125rem 0.25rem rgba(2, 85, 163, 0.25);
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  &:hover {
    box-shadow: 0 4px 12px 0 rgba(2, 85, 163, 0.25);
  }
  .ant-card-cover {
    overflow: hidden;
    width: 100%;
  }
  .ant-card-body {
    padding: 1rem;
  }
  .ant-card-meta-title {
    white-space: normal;
    font-size: 1rem;
  }
`;

export const featuredOfferName = css`
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.375rem;
  min-height: 3.125rem;
  color: ${Color.GRAYSCALE.GRAY10};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const featuredOfferSubDetails = css`
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const minimalInvestment = css`
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.25rem;
  color: ${Color.ORANGE.ORANGE6};
  min-height: 1.25rem;
`;

export const featuredOfferImage = css`
  object-fit: cover;
  object-position: 0 0;
  position: relative;
  aspect-ratio: 21/9;
`;

export const divider = css`
  display: block;
  height: 0.0625rem;
  border: 0;
  border-top: 0.0625rem solid #f2f5f7;
  margin: 0rem;
  margin-bottom: 1rem;
`;

export const featuredOfferTags = css`
  left: 1rem;
  top: calc(100% - 20rem);
  position: absolute;
  width: 100%;
  height: 100%;
`;

// Note: duplicateed styles
export const accreditedInvestorsOnly = css`
  ${Display.flex}
  ${AlignItems.center}
`;

// Note: duplicateed styles
export const accreditedInvestorsOnlyText = css`
  ${Spacing.mr4}
`;
