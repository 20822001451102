import { getCurrencyFormatter } from 'src/utils';
import * as Yup from 'yup';

export const getStandardOfferOrderValidationSchema = ({
  pricePerShare,
  minimumInvestCurrencyAmt,
  allowRestrictedPersons,
}: {
  pricePerShare: number;
  minimumInvestCurrencyAmt: number;
  allowRestrictedPersons: boolean;
}) => {
  let schema = Yup.object().shape({
    quantity: Yup.number()
      .integer('Quantity must be an integer')
      .typeError('Quantity must be an integer')
      .positive('Quantity must be a positive integer')
      .required('Quantity is required')
      .test(
        'compare',
        `The minimum investment quantity is ${Math.ceil(minimumInvestCurrencyAmt / pricePerShare)}`,
        function (quantity: string | any) {
          if (!isNaN(Number(quantity))) {
            return Number(quantity) * pricePerShare >= minimumInvestCurrencyAmt;
          } else return false;
        },
      ),
  });

  if (!allowRestrictedPersons) {
    schema = schema.concat(
      Yup.object().shape({
        isNotRestrictedPerson: Yup.boolean()
          .test('isTrue', 'The offer does not allow investing from restricted persons', value => value === true)
          .required('Restricted Person is required.'),
      }),
    );
  }

  return schema;
};

export const getConditionalOfferOrderValidationSchema = ({
  minimumInvestCurrencyAmt,
  allowRestrictedPersons,
}: {
  minimumInvestCurrencyAmt: number;
  allowRestrictedPersons: boolean;
}) => {
  let schema = Yup.object().shape({
    totalInvestment: Yup.number()
      .typeError('Total Investment must be a number')
      .positive('Total Investment must be a positive number')
      .required('Total Investment is required')
      .test(
        'compare',
        `The minimum investment amount is ${getCurrencyFormatter().format(Math.ceil(minimumInvestCurrencyAmt))}`,
        function (value: string | any) {
          if (!isNaN(Number(value))) {
            return Number(value) >= minimumInvestCurrencyAmt;
          } else return false;
        },
      ),
  });

  if (!allowRestrictedPersons) {
    schema = schema.concat(
      Yup.object().shape({
        isNotRestrictedPerson: Yup.boolean()
          .test('isTrue', 'The offer does not allow investing from restricted persons', value => value === true)
          .required('Restricted Person is required.'),
      }),
    );
  }

  return schema;
};
