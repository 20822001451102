import { OrderAction, OrderStatus } from '../models/orders.models';

export namespace FromTradingBlockServerEventDto {
  export enum EventDtoType {
    HEART_BEAT = '0',
    ORDER_UPDATE = '3',
    SESSION_EXPIRE = '90',
  }

  export interface HeartBeatEventDto {
    '0': '0';
    '1': EventDtoType.HEART_BEAT;
  }

  export interface OrderUpdateEventDto {
    '0': string; // success
    '1': EventDtoType.ORDER_UPDATE;
    '2': string; // account id
    '3': string; // id
    '4': string; // updated at
    '5': string; // symbol
    '6': string; // quantity
    '7': string; // filled quantity
    '8': string; // price
    '9': string; // order action
    '10': string; // order status
    '15': string; // commission
  }

  export interface SessionExpireEventDto {
    '0': '0';
    '1': EventDtoType.SESSION_EXPIRE;
    '2': string; // code
    '3': string; // reason
  }

  export type WebSocketEventDto = HeartBeatEventDto | OrderUpdateEventDto | SessionExpireEventDto;
}

export namespace FromTradingBlockServerEvent {
  export enum EventType {
    ORDER_UPDATE = 'ORDER_UPDATE_EVENT',
    SESSION_EXPIRE = 'SESSION_EXPIRE_EVENT',
  }

  export interface OrderUpdateEventPayload {
    id?: string;
    status: OrderStatus;
    symbol: string;
    filledQuantity: number;
    quantity: number;
    price: number;
    action: OrderAction;
    isPartial: boolean;
    updatedAt: string;
    commission: number;
    totalCost?: number;
  }

  export interface OrderUpdateEvent {
    type: EventType.ORDER_UPDATE;
    payload: OrderUpdateEventPayload;
  }

  export interface SessionExpireEventPayload {
    code: number;
    reason: string;
  }

  export interface SessionExpireEvent {
    type: EventType.SESSION_EXPIRE;
    payload: SessionExpireEventPayload;
  }

  export type WebSocketEvent = OrderUpdateEvent | SessionExpireEvent;
}
