import { PropsWithChildren } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Col, Form, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { MAlert } from 'src/lib';
import { ScreenBreakpoint } from 'src/styles';

import { MFormItemLabel } from '../MFormItemLabel/MFormItemLabel';

import * as Styles from './MFormItem.styles';

export interface MFormItemProps {
  label: string;
  tooltip?: {
    type: 'modal' | 'popover';
    title?: string;
    content: string | JSX.Element;
    icon?: 'info' | 'question';
  };
  align?: 'horizontal' | 'vertical';
  weight?: 'light' | 'medium';
  alert?: string;
  className?: string;
  childrenClassName?: string;
  testId?: string;
}

export const MFormItem = ({
  label,
  tooltip,
  children,
  align = 'horizontal',
  weight = 'medium',
  alert,
  className,
  childrenClassName = '',
  testId,
}: PropsWithChildren<MFormItemProps>) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  return (
    <Form.Item colon={false} className={className}>
      <Row className={Styles.contentContainer(align)}>
        <Col span={isMobile ? 24 : align === 'horizontal' ? 12 : 24} className={Styles.col}>
          <MFormItemLabel value={label} tooltip={tooltip} weight={weight} testId={testId} />
        </Col>
        <Col
          span={isMobile ? 24 : align === 'horizontal' ? 12 : 24}
          className={cx(Styles.col, childrenClassName).trimEnd()}>
          {children}
        </Col>
        <Col span={24} className={Styles.col}>
          {alert && (
            <Col span={24}>
              <MAlert type='error' description={alert} icon={<ExclamationCircleOutlined />} />
            </Col>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};
