import { css } from '@emotion/css';
import {
  AlignItems,
  BaseStyle,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  Spacing,
  TextAlign,
} from 'src/styles';

export const leftFormItem = (isMobile: boolean) => css`
  ${isMobile ? Spacing.pr0 : Spacing.pr12}
  ${Spacing.my12}
`;

export const rightFormItem = (isMobile: boolean) => css`
  ${isMobile ? Spacing.pl0 : Spacing.pl12}
  ${Spacing.my12}
`;

export const center = css`
  ${Spacing.my16}
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
`;

export const space = css`
  ${Spacing.mr4}
`;

export const alreadyHaveAccountLabel = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
  ${Font.lg}
  ${TextAlign.center}
  ${Spacing.mt16}
`;

export const linkButton = css`
  ${Font.lg}
  ${BaseStyle.clickable}
  ${FontWeight.bold}
  color: ${ColorConstant.BRAND6};
`;
