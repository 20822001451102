import React from 'react';

import { Col, Row, Space } from 'antd';

import * as Styles from './styles';

interface Props {
  title?: string;
  info: string | React.ReactNode;
}

const SecuritiesDisclaimer = ({ info, title }: Props): JSX.Element => {
  return (
    <Row className={Styles.titleWrapper}>
      <Col span={24}>
        <Space size={24} direction='vertical'>
          {title && <h1>{title}</h1>}
          <span>{info}</span>
        </Space>
      </Col>
    </Row>
  );
};

export default SecuritiesDisclaimer;
