import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import moment from 'moment';
import { DateConstant } from 'src/constants';

import { MFormItem } from '../MFormItem/MFormItem';
import { MFormItemError } from '../MFormItemError/MFormItemError';
import { MSelect, MSelectProps } from '../MSelect/MSelect';

import * as Styles from './DateOfBirthPicker.styles';

export const getDays = () =>
  [...Array(31 - 1 + 1).keys()].map(i => {
    return {
      value: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
      label: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
    };
  });

export const getMonths = () =>
  [...Array(12 - 1 + 1).keys()].map(i => {
    return {
      value: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
      label: i + 1 < 10 ? `0${i + 1}` : `${i + 1}`,
    };
  });

export const getYears = () =>
  [...Array(new Date().getFullYear() - 1910 + 1).keys()].map(i => {
    return { value: `${i + 1910}`, label: `${i + 1910}` };
  });

export interface DateOfBirthPickerProps extends Omit<MSelectProps, 'placeholder' | 'options'> {}

export const DateOfBirthPicker = ({ onChange, error, defaultValue, disabled, testId }: DateOfBirthPickerProps) => {
  const [_day, setDay] = useState<string>('');
  const [_month, setMonth] = useState<string>('');
  const [_year, setYear] = useState<string>('');

  const format = (value: string) => {
    return moment(new Date(value)).format(DateConstant.BIRTH_DATE);
  };

  const getDate = () => {
    return `${_year}-${_month}-${_day}`;
  };

  const extractYear = (value?: string) => {
    if (value) {
      return format(value).split('-')?.[0];
    }
  };

  const extractDay = (value?: string) => {
    if (value) {
      return format(value).split('-')?.[2];
    }
  };

  const extractMonth = (value?: string) => {
    if (value) {
      return format(value).split('-')?.[1];
    }
  };

  const onMonthChange = (value: string) => {
    setMonth(value);
  };

  const onDayChange = (value: string) => {
    setDay(value);
  };

  const onYearChange = (value: string) => {
    setYear(value);
  };

  useEffect(() => {
    if (_month !== '' && _day !== '' && _year !== '') {
      onChange(getDate());
    }
  }, [_month, _day, _year]);

  useEffect(() => {
    if (defaultValue) {
      setDay(extractDay(defaultValue) ?? '');
      setMonth(extractMonth(defaultValue) ?? '');
      setYear(extractYear(defaultValue) ?? '');
    }
  }, []);

  return (
    <MFormItem label='Date of Birth' testId={testId + '-' + 'label'}>
      <Row>
        <Col span={24}>
          <Row className={Styles.dropdownListContainer} data-testid={testId + '-' + 'option'}>
            <Col span={8}>
              <MSelect
                placeholder='MM'
                defaultValue={extractMonth(defaultValue)}
                options={getMonths()}
                onChange={onMonthChange}
                disabled={disabled}
                className={Styles.dropdown}
              />
            </Col>
            <Col span={8}>
              <MSelect
                placeholder='DD'
                defaultValue={extractDay(defaultValue)}
                options={getDays()}
                onChange={onDayChange}
                disabled={disabled}
                className={Styles.dropdown}
              />
            </Col>
            <Col span={8}>
              <MSelect
                placeholder='YYYY'
                defaultValue={extractYear(defaultValue)}
                options={getYears()}
                onChange={onYearChange}
                disabled={disabled}
                className={Styles.dropdown}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <MFormItemError value={error} />
            </Col>
          </Row>
        </Col>
      </Row>
    </MFormItem>
  );
};
