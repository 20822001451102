import { css } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../styles';

export const mainContainer = css`
  ${Spacing.mt32}
`;

export const divider = css`
  ${Display.block}
  height: 0.0625rem;
  border: 0;
  border-top: 0.0625rem solid ${ColorConstant.GRAY3};
  ${Spacing.my16}
  ${Spacing.mx0}
`;

export const offerDetailsLabel = css`
  ${FontWeight.semibold}
  ${Font.h1}
`;

export const featuredOfferImage = css`
  object-fit: cover;
  object-position: 0 0;
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
`;

export const cardWrapper = css`
  background: ${ColorConstant.GRAY1};
  box-shadow: 0rem 0.125rem 0.25rem rgba(2, 85, 163, 0.25);
  ${BorderRadius.xs}
  overflow: hidden;
  width: 100%;
  .ant-card-cover {
    overflow: hidden;
    width: 100%;
  }
  .ant-card-body {
    ${Spacing.py32}
    ${Spacing.px24}
  }
  .ant-card-meta-title {
    white-space: normal;
    ${Font.sm}
  }
`;

export const featuredOfferName = css`
  ${FontWeight.bold}
  ${Font.h3}
  color: ${ColorConstant.GRAY10};
  min-height: 3.125rem;
  ${Spacing.pt8}
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const featuredOfferSubtextOnClosed = css`
  ${FontWeight.normal}
  ${Font.sm}
  color: ${ColorConstant.GRAY10}
  min-height: 1.375rem;
`;

export const featuredOfferSubDetails = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY7}
`;

export const tabsContainer = css`
  width: 100%;
  min-height: 14.6875rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  ${Spacing.pt8}
  ${Spacing.px32}
  ${Spacing.pb32}
  .ant-tabs {
    ${Font.sm}
  }
`;

export const disclaimerStyle = css`
  ${Font.sm}
  color: ${ColorConstant.GRAY7};
  ${Spacing.mb32}
`;

export const closedOfferBadge = css`
  font-style: italic;
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
  ${Spacing.mb32}
  background: ${ColorConstant.RED1};
  ${Spacing.p16}
  ${BorderRadius.xs}
`;

export const closedOfferTitle = css`
  ${FontWeight.semibold}
  ${Font.h2}
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.RED7};
`;

export const escrowBannerLabel = css`
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.BRAND6};
`;

export const interestIndicationBannerLabel = css`
  line-height: 1.375rem;
  color: ${ColorConstant.GREEN8};
`;

export const pendingBannerLabel = css`
  line-height: 1.375rem;
  color: ${ColorConstant.ORANGE8};
`;

export const annualYieldLabel = css`
  ${offerDetailsLabel}
  flex: 1 !important;
  color: ${ColorConstant.ORANGE6};
  ${Spacing.mr8}
  ${FontWeight.bold}
`;

export const minimumInvestmentLabel = css`
  ${offerDetailsLabel}
  color: ${ColorConstant.BRAND6};
  ${FontWeight.bold}
`;

export const offerLogoWrapper = css`
  max-height: 6rem;
  max-width: 6rem;
`;

export const offerLogo = css`
  object-fit: contain;
  object-position: center;
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid ${ColorConstant.GRAY3};
`;

export const tagsContainer = css`
  position: absolute;
  left: 1.5rem;
  top: 19.5rem;
  ${Display.flex}
`;

export const actionButton = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.right}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${JustifyContent.left}
    ${Spacing.mt8}
  }
`;

export const indicationLogo = css`
  ${Font.lg}
  color: ${ColorConstant.GREEN8};
  ${Spacing.mr8}
  ${FontWeight.normal}
`;

export const goToSummary = css`
  text-decoration: underline;
  color: ${ColorConstant.ORANGE8};
  ${FontWeight.bold}
`;

export const offerWatchAlert = css`
  ${FontWeight.bold}
  color: ${ColorConstant.GREEN8};
  background-color: ${ColorConstant.GREEN1};
`;

export const pendingOfferOrdersAlert = css`
  ${FontWeight.bold}
  color: ${ColorConstant.ORANGE8};
  background-color: ${ColorConstant.ORANGE2};
`;

export const accreditedInvestorsOnly = css`
  ${Display.flex}
  ${AlignItems.center}
`;

export const accreditedInvestorsOnlyText = css`
  ${Spacing.mr4}
`;
