import { css } from '@emotion/css';

import {
  BorderRadius,
  Color,
  Display,
  FlexDirection,
  Font,
  FontFamily,
  FontWeight,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../../styles';

export const completeAccountContainer = css`
  ${Spacing.p20}
  ${TextAlign.center}
`;

export const containerWrapper = css`
  width: 100%;
  ${Spacing.mt64}
`;

export const welcomeLabel = css`
  ${FontFamily.sourceSansPro}
  ${FontWeight.bold}
  ${Font.h5}
  ${TextAlign.center}
  color: ${Color.BRAND.BRAND6};
`;

export const welcomeDescription = css`
  ${FontFamily.sourceSansPro}
  ${FontWeight.normal}
  ${Font.md}
  ${TextAlign.center}
  color: ${Color.GRAYSCALE.GRAY8}
  width: 100%;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 88%;
    ${TextAlign.center}
    ${Spacing.mb40}
    ${Spacing.p0}
  }
`;

export const communicationPreferencesLink = css`
  ${FontWeight.normal}
  color: ${Color.BRAND.BRAND6};
  ${TextAlign.center}
  cursor: pointer;
  ${Spacing.mb24}
`;

export const cardContent = css`
  width: 956px;
  ${Spacing.mAuto}
  ${Spacing.py40}
  background: ${Color.GRAYSCALE.GRAY1};
  box-shadow: 0px 12px 16px rgba(2, 85, 163, 0.15);
  ${BorderRadius.md}
  ${Spacing.mt40}
  ${Spacing.mb160}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 100%;
  }
`;

export const alignCenter = css`
  ${TextAlign.center}
`;

export const almostFinished = css`
  text-align: center;
  h1 {
    ${FontFamily.sourceSansPro}
    ${FontWeight.bold}
    ${Font.h3}
    color: ${Color.BRAND.BRAND6};
  }
  span {
    ${FontWeight.normal}
    ${Font.md}
    ${Spacing.px128}
    color: ${Color.GRAYSCALE.GRAY7};
    min-width: 434px;

    @media (max-width: ${ScreenBreakpoint.mobile.max}) {
      width: 90%;
      ${Spacing.px0}
    }
  }
`;

export const accountOptions = css`
  ${Display.flex}
  ${FlexDirection.row}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column}
  }
`;

export const button = css`
  min-width: 200px !important;
`;
