import type { ReactNode } from 'react';

import { Alert } from 'antd';

import * as styles from './MAlert.styles';

export interface MAlertProps {
  type: 'success' | 'info' | 'warning' | 'error';
  description: string | ReactNode;
  closable?: boolean;
  testId?: string;
  onClick?: () => void;
  showIcon?: boolean;
  icon?: ReactNode;
  className?: string;
}

export const MAlert = ({
  type,
  description,
  closable = false,
  onClick,
  showIcon = true,
  testId,
  className = '',
  icon,
}: MAlertProps) => {
  return (
    <Alert
      type={type}
      showIcon={showIcon}
      icon={icon}
      description={description}
      className={styles.alert.concat(' ' + className).trim()}
      closable={closable}
      onClick={onClick}
      data-testid={testId}
    />
  );
};
