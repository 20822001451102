import * as ConfigsActions from './configs';
import * as QuoteMediaActions from './quoteMedia';

/**
 * Login Forms
 */
export * from './auth';

export * from './accounts.actions';
export * from './accountHolders.actions';
/**
 * User Forms
 */
export * from './users.actions';

/**
 * Account Details Actions
 */
export * from './accountDetails';

/**
 * Acconut Statement Actions;
 */
export * from './statements.actions';

export * from './cashiering';

/**
 * Offers actions
 */
export * from './offers.actions';

/**
 * Offer Watches
 */
export * from './offerWatches.actions';

/**
 * Investigations actions
 */
export * from './investigations';

/**
 * Configs actions
 */
export const getConfigs = ConfigsActions.configsRequested;

/**
 * Quote Media
 */
export const getSnapQuotesRequested = QuoteMediaActions.getSnapQuotesRequested;
export const getEnhancedSnapQuotesRequested = QuoteMediaActions.getEnhancedSnapQuotesRequested;
export const getChartSnapQuotesClear = QuoteMediaActions.getChartSnapQuotesClear;
export const getChartSnapQuotesRequested = QuoteMediaActions.getChartSnapQuotesRequested;
export const getSnapQuotesSucceeded = QuoteMediaActions.getSnapQuotesSucceeded;
export const getEnhancedSnapQuotesSucceeded = QuoteMediaActions.getEnhancedSnapQuotesSucceeded;
export const getChartSnapQuotesSucceeded = QuoteMediaActions.getChartSnapQuotesSucceeded;

/**
 * Orders
 */
export * from './orders.actions';

/**
 * Offer Orders
 */
export * from './offerOrders.actions';

/**
 * ToastMessages actions
 */
export * from './toastMessages';

/**
 * App actions
 */
export * from './app';
