import { AccountDto } from 'src/dtos';
import {
  Account,
  AccountHolderAddress,
  AccountHolderDisclosures,
  AccountHolderFinancialInformation,
  AccountHolderMaritalStatus,
  AccountHolderSuitabilityInformation,
  AccountHolderTrustedContact,
} from 'src/models';

import {
  mapAccountHolderBirthCountryDtoToModel,
  mapAccountHolderCitizenshipCountryDtoToModel,
} from './accountHolders.mappers';

export const mapAccountDtoToModel = (dto: AccountDto): Account => ({
  id: dto.id,
  type: dto.type,
  accountId: dto.accountId,
  accountNumber: dto.accountNumber,
  primaryAccountHolder: dto.primaryAccountHolder && {
    ...dto.primaryAccountHolder,
    citizenshipCountry: dto.primaryAccountHolder.citizenshipCountry
      ? mapAccountHolderCitizenshipCountryDtoToModel(dto.primaryAccountHolder.citizenshipCountry)
      : undefined,
    birthCountry: dto.primaryAccountHolder.birthCountry
      ? mapAccountHolderBirthCountryDtoToModel(dto.primaryAccountHolder.birthCountry)
      : undefined,
    physicalAddress: dto.primaryAccountHolder.physicalAddress
      ? new AccountHolderAddress(dto.primaryAccountHolder.physicalAddress)
      : undefined,
    mailingAddress: dto.primaryAccountHolder.mailingAddress
      ? new AccountHolderAddress(dto.primaryAccountHolder.mailingAddress)
      : undefined,
    maritalStatus: dto.primaryAccountHolder.maritalStatus
      ? new AccountHolderMaritalStatus(dto.primaryAccountHolder.maritalStatus)
      : undefined,
    trustedContact: dto.primaryAccountHolder.trustedContact
      ? new AccountHolderTrustedContact(dto.primaryAccountHolder.trustedContact)
      : undefined,
    suitabilityInformation: dto.primaryAccountHolder.suitabilityInformation
      ? new AccountHolderSuitabilityInformation(dto.primaryAccountHolder.suitabilityInformation)
      : undefined,
    financialInformation: dto.primaryAccountHolder.financialInformation
      ? new AccountHolderFinancialInformation(dto.primaryAccountHolder.financialInformation)
      : undefined,
    disclosure: dto.primaryAccountHolder.disclosure
      ? new AccountHolderDisclosures(dto.primaryAccountHolder.disclosure)
      : undefined,
  },
});
