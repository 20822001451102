import React, { useEffect, useState } from 'react';

import DonutChart from '../../../../../../lib/Miscellaneous/DonutChart/DonutChart';
import { PortofolioInfo, PortofolioSummaryInfo } from '../../../../../../models/portofolio-info.model';
import { Color } from '../../../../../../styles';

interface SummaryChartProps {
  accountValue: number;
  portofolio: PortofolioSummaryInfo;
}

const SummaryChart = ({ portofolio, accountValue }: SummaryChartProps) => {
  const [totalValues, setTotalValues] = useState<number[]>([]);
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    const totalValues: number[] = [];
    const colors: string[] = [];
    Object.entries(portofolio)?.forEach(([, info]: [string, PortofolioInfo]) => {
      totalValues.push(
        info?.totalValue !== null && !Number.isNaN(info?.totalValue) && info?.totalValue !== undefined
          ? info.totalValue
          : 0,
      );
      colors.push(info.color);
    });

    renderEmptyState(totalValues, colors);
    setTotalValues(totalValues);
    setColors(colors);
  }, [portofolio]);

  const renderEmptyState = (totalValues: number[], colors: string[]) => {
    if (totalValues.every(item => item === 0)) {
      totalValues.push(1);
      colors.push(Color.GRAYSCALE.GRAY6);
    }
  };

  return <DonutChart totalValueData={accountValue} data={totalValues} colors={colors} />;
};

export default SummaryChart;
