import { css } from '@emotion/css';

import { Color } from '../../../../../../styles';

export const fieldLabel = css`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  padding: 15px 0px;
  color: ${Color.GRAYSCALE.GRAY8};
  i {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const fieldValue = css`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding: 15px 0px;
  color: ${Color.GRAYSCALE.GRAY10};
`;

export const rowWrapper = css`
  .ant-col {
    height: 56px;
    span {
      display: table-cell;
      vertical-align: middle;
    }
  }
`;

export const alignRight = css`
  display: table;
  text-align: right;
`;

export const alignCenter = css`
  text-align: center;
`;

export const borderBottom = css`
  border-bottom: 1px solid #f0f0f0;
`;

export const titleWrapper = css`
  margin-bottom: 36px;

  h1 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: ${Color.BRAND.BRAND6};
  }

  span {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: ${Color.GRAYSCALE.GRAY7};
  }
`;

export const disclaimerStyle = css`
  color: ${Color.BRAND.BRAND5};
  vertical-align: middle;
  cursor: pointer;
`;

export const inputBottom = css`
  .ant-form-item-control {
    display: inline-grid;
    vertical-align: bottom;
  }
`;
