import { cx } from '@emotion/css';

import { Font, Spacing } from '../styles';

export const renderTradingBlockSamePasswordErrorMessage = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <div>
      Please click{' '}
      <a className={cx(Spacing.p4, Font.lg)} onClick={onClick}>
        here
      </a>{' '}
      to reset your password again. You cannot use the same password more than once.
    </div>
  );
};
