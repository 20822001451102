import Spinner from '../Miscellaneous/Spinner';
import { MModal } from '../MModal/MModal';

export interface ConfirmActionModalProps {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  title: string;
  message?: string;
}

export const ConfirmActionModal = ({ title, isOpen, isLoading, onCancel, onOk, message }: ConfirmActionModalProps) => {
  const renderContent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return <div>{message}</div>;
  };

  return (
    <MModal
      closable
      onClose={onCancel}
      title={title}
      visible={isOpen}
      primaryButtonText='Yes'
      secondaryButtonText='No'
      primaryButtonType='primary'
      secondaryButtonType='tertiary'
      onPrimaryButtonClick={onOk}
      onSecondaryButtonClick={onCancel}>
      {renderContent()}
    </MModal>
  );
};
