import { ConfigConstant } from 'src/constants';

import { getLocalStorageService } from './LocalStorageService';

const localStorage = getLocalStorageService();

export class ConfigService {
  get authToken() {
    return localStorage.find(ConfigConstant.AUTH_TOKEN_KEY);
  }
}

const configService = new ConfigService();

export const getConfigService = () => {
  return configService;
};
