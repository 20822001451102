export const ScreenBreakpoint = {
  mobile: {
    min: '360px',
    max: '767px',
  },
  laptop: {
    min: '768px',
    max: '1024px',
  },
  desktop: {
    min: '1025px',
    max: '1200px',
  },
};

const SPACE_BASE_UNIT = 1;
export const SpaceSize = {
  is4: `${SPACE_BASE_UNIT * 0.25}rem`,
  is8: `${SPACE_BASE_UNIT * 0.5}rem`,
  is12: `${SPACE_BASE_UNIT * 0.75}rem`,
  is16: `${SPACE_BASE_UNIT * 1}rem`,
  is20: `${SPACE_BASE_UNIT * 1.125}rem`,
  is24: `${SPACE_BASE_UNIT * 1.5}rem`,
  is32: `${SPACE_BASE_UNIT * 2}rem`,
  is40: `${SPACE_BASE_UNIT * 2.5}rem`,
  is48: `${SPACE_BASE_UNIT * 3}rem`,
  is60: `${SPACE_BASE_UNIT * 3.75}rem`,
  is64: `${SPACE_BASE_UNIT * 4}rem`,
  is80: `${SPACE_BASE_UNIT * 5}rem`,
  is96: `${SPACE_BASE_UNIT * 6}rem`,
  is128: `${SPACE_BASE_UNIT * 8}rem`,
  is160: `${SPACE_BASE_UNIT * 10}rem`,
  is192: `${SPACE_BASE_UNIT * 12}rem`,
  is224: `${SPACE_BASE_UNIT * 14}rem`,
  is256: `${SPACE_BASE_UNIT * 16}rem`,
};

export const RADIUS_BASE_UNIT = 1;
export const BorderRadiusSize = {
  none: '0',
  xxxs: `${RADIUS_BASE_UNIT * 0.125}rem`,
  xxs: `${RADIUS_BASE_UNIT * 0.25}rem`,
  xs: `${RADIUS_BASE_UNIT * 0.5}rem`,
  md: `${RADIUS_BASE_UNIT}rem`,
  lg: `${RADIUS_BASE_UNIT * 1.5}rem`,
};
