import moment from 'moment';
import { OfferConstant } from 'src/constants';

import { Offer, OfferDetails } from '../models/offers.models';

const CLOSED = 'Closed';
export const isOfferClosed = (offer: Offer) => offer.status === CLOSED;

export const isRegAPlusOffer = (offer: Offer | OfferDetails) =>
  offer.offerType.includes(OfferConstant.REG_A_PLUS_SUBSTRING);

export const isRegDTypeCOffer = (offer: Offer | OfferDetails) =>
  offer.offerType.includes(OfferConstant.REG_D_TYPE_C_SUBSTRING);

export const shouldDisplayTotalCapitalRaised = (offer: Offer | OfferDetails) =>
  isOfferClosed(offer) && (offer?.totalCapitalRaised ?? 0) > 0;

export const findNextOfferCloseDate = (offer?: Offer | OfferDetails): string | null => {
  if (!offer) {
    return null;
  }

  const today = moment();
  const nextClosingDates = offer.closeDates.filter(aCloseDate => moment(aCloseDate).isAfter(today));

  if (!nextClosingDates.length) {
    return null;
  }

  const nextClosingDate = moment.min(nextClosingDates.map(item => moment(item)));

  return nextClosingDate.toISOString();
};

export const findOfferCloseDate = (offer?: Offer | OfferDetails): string | null => {
  if (offer?.closeDate) {
    return offer.closeDate;
  }

  const nextCloseDate = findNextOfferCloseDate(offer);

  if (nextCloseDate) {
    return moment(nextCloseDate).format('MM/DD/YYYY');
  }

  return null;
};

export const findOfferClosedDate = (offer?: Offer | OfferDetails): string | null => {
  if (!offer) {
    return null;
  }

  if (offer.closeDate) {
    return offer.closeDate;
  }

  const today = moment();
  const closedDates = offer.closeDates.filter(date => moment(date).isBefore(today));

  if (!closedDates.length) {
    return null;
  }
  const lastClosedDate = moment.max(closedDates.map(aDate => moment(aDate)));

  return lastClosedDate.format('MM/DD/YYYY');
};

export const isOfferCloseDateValid = (value: string | null): boolean => moment(value, true).isValid();

export const findOfferRemainingDays = (offer?: Offer | OfferDetails): number | null => {
  if (!offer) {
    return null;
  }

  const today = moment();

  const nextOfferCloseDate = findNextOfferCloseDate(offer);

  if (nextOfferCloseDate) {
    return moment(nextOfferCloseDate).diff(today, 'days');
  }

  return null;
};
