import { css, cx } from '@emotion/css';
import {
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from 'src/styles';

export const accountSubMenu = css`
  ${Display.flex};
  ${JustifyContent.flexStart};
  ${Spacing.p0};
  ${FlexDirection.column};
  @media (min-width: ${ScreenBreakpoint.laptop.min}) {
    display: none;
  }
`;

export const subMenuLink = css`
  ${Font.lg};
  color: ${ColorConstant.GRAY10};
  ${Spacing.mb24};
`;

export const mobileUserInfoWrapper = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 24px;
`;

// Desktop menu item text
export const menuItemTextContainer = css`
  width: 100%;
`;

export const inactiveAccountMenuItemTextIconContainer = css`
  height: 32px;
  width: 32px;
  ${Spacing.mr24}
`;

export const inactiveAccountMenuItemTextIcon = (isPending: boolean) =>
  cx(
    'ri-error-warning-fill',
    css`
      color: ${isPending ? ColorConstant.ORANGE6 : ColorConstant.RED6};
      font-size: 32px;
    `,
  );

// AccountValue component
export const accountValueContainer = css`
  ${Font.h1}
`;

export const accountValue = css`
  ${FontWeight.semibold}
  color: ${ColorConstant.GREEN7};
`;

export const accountLabel = css`
  color: ${ColorConstant.GRAY7};
`;

// AccountHolderFullName component
export const fullName = css`
  ${Font.h1}
  ${FontWeight.semibold}
`;

export const accountType = css`
  ${Font.md}
  color: ${ColorConstant.GRAY8};
`;

// DesktopMenuLinkItem
export const desktopMenuLink = css`
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: #353d4a;
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 8;
  display: flex;
  align-items: center;

  :hover {
    background: #f9fbfc;
  }
`;

export const desktopActiveMenuLink = css`
  font-weight: bold;
  color: #0255a3;
  background: #e6eef6;
`;

// UserInfo
export const iconContainer = css`
  width: 32px;
  height: 32px;
  ${FontWeight.normal}
`;
