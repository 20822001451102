import React from 'react';

import { NumericFormat } from 'react-number-format';

export interface CurrencyFieldProps {
  value?: number;
  fixedDecimals?: number;
  className?: string;
  prefix?: string;
  testId?: string;
}

const CurrencyField = ({ value, fixedDecimals = 2, className, prefix, testId }: CurrencyFieldProps) => {
  return (
    <NumericFormat
      value={value?.toFixed(fixedDecimals) || 0}
      thousandSeparator
      prefix={prefix ? prefix : '$'}
      displayType={'text'}
      decimalScale={fixedDecimals}
      className={className}
      data-testid={testId}
    />
  );
};

export default CurrencyField;
