import * as Url from '../constants/url';
import {
  BaseOrdersParams,
  CancelOrderParams,
  CreateOrderDto,
  GetOrderParams,
  GetOrdersPositionsResponseDto,
  GetOrdersQueryParams,
  GetOrdersResponseDto,
  OrderDto,
} from '../dtos/orders.dtos';
import { replacePlaceholders } from '../sagas/utils';
import { httpRequest } from '../utils/httpRequest';

export interface CreateOrderArgs {
  params: BaseOrdersParams;
  dto: CreateOrderDto;
  authToken: string;
}

export interface GetManyOrdersArgs {
  params: BaseOrdersParams;
  authToken: string;
  queryParams?: GetOrdersQueryParams;
}

export interface GetOneOrderArgs {
  params: GetOrderParams;
  authToken: string;
}

export interface CancelOrderArgs {
  params: CancelOrderParams;
  authToken: string;
}

export interface OrderPositionsArgs {
  params: BaseOrdersParams;
  authToken: string;
}

export class OrdersApi {
  async create({ params, dto, authToken }: CreateOrderArgs): Promise<OrderDto> {
    const { accountId } = params;
    const url = replacePlaceholders(Url.ORDERS_BY_ACCOUNT_URL, { accountId });

    return httpRequest<OrderDto>({
      method: 'POST',
      url,
      body: dto,
      authToken,
    });
  }

  async getMany({ params, authToken, queryParams }: GetManyOrdersArgs): Promise<GetOrdersResponseDto> {
    const { accountId } = params;

    const url = replacePlaceholders(Url.ORDERS_BY_ACCOUNT_URL, { accountId });

    return httpRequest<GetOrdersResponseDto>({
      method: 'GET',
      url,
      authToken,
      queryParams,
    });
  }

  async getOne({ params, authToken }: GetOneOrderArgs): Promise<OrderDto> {
    const { id, accountId } = params;

    const url = replacePlaceholders(Url.ORDER_URL, { id, accountId });

    return httpRequest<OrderDto>({
      method: 'GET',
      url,
      authToken,
    });
  }

  async cancel({ params, authToken }: CancelOrderArgs): Promise<void> {
    const { accountId, id } = params;

    const url = replacePlaceholders(Url.ORDER_CANCEL_URL, { id, accountId });

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken,
    });
  }

  async positions({ params, authToken }: OrderPositionsArgs): Promise<GetOrdersPositionsResponseDto> {
    const { accountId } = params;
    const url = replacePlaceholders(Url.ORDERS_POSITIONS_URL, { accountId });

    return httpRequest<GetOrdersPositionsResponseDto>({
      method: 'GET',
      url,
      authToken,
    });
  }
}
