import { css } from '@emotion/css';

import { Color } from '../../../../../../styles';

export const alignCenter = css`
  text-align: center;
`;

export const labelStyling = css`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${Color.GRAYSCALE.GRAY9};
`;

export const valueStyling = css`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: ${Color.BRAND.BRAND6};
`;

export const negativeValue = css`
  color: ${Color.RED.RED6};
`;

export const pozitiveValue = css`
  color: ${Color.GREEN.GREEN8};
`;
