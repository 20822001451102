import React from 'react';

const ReadMoreDisclaimer = (): JSX.Element => {
  return (
    <div>
      Price can change quickly in fast market conditions, resulting in an execution price different from the quote
      displayed at order entry. We cannot guarantee prices on market orders. In addition, there is the risk that outages
      involving your access to the Internet or our own systems may interfere with your ability to access your online
      account.
      <br />
      <br />
      Please be advised that there are risks associated with the trading of low price or low volume securities,
      including but not limited to wide variance of execution price, delay in execution, and the possibility of partial
      or no execution. As a result of these risks, you may lose part or all of your investment.
      <br />
      <br />
      In addition, there may be a delay in posting securities executions to any account with open orders for low price
      or low volume securities. By placing orders for low price or low volume securities, you assume responsibility for
      the above risks and acknowledge that My IPO, Cambria Capital and Apex Clearing Corporation is not responsible for
      any losses or liabilities that may occur as a result of these risks.
    </div>
  );
};

export default ReadMoreDisclaimer;
