import { css } from '@emotion/css';

import {
  BaseStyle,
  Display,
  FlexDirection,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
  Color,
  Font,
  BorderRadius,
  FontWeight,
} from '../../styles';

export const faqContainer = css`
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    ${Spacing.px20}
  }
`;

export const sectionContainer = css`
  ${Display.flex};
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    ${FlexDirection.column}
  }
`;

export const categoryWrapper = css`
  @media (max-width: ${ScreenBreakpoint.laptop.min}) {
    max-width: 100%;
  }
`;

export const categorySelector = css`
  height: 60px;
  ${BaseStyle.level}
  ${Spacing.mb32};
  border: 1px solid ${Color.BRAND.BRAND5};
  ${BorderRadius.xxxs}
  box-shadow: 0 2px 4px 0 rgba(2, 85, 163, 0.15);
  background-color: ${Color.GRAYSCALE.GRAY1};
  ${Font.lg}
  ${TextAlign.center};
  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    height: 50px;
    ${Spacing.mb20};
  }
`;

export const title = css`
  ${Spacing.mb4};
  ${Font.h4};
  ${FontWeight.semibold};
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const subtitle = css`
  ${Font.sm};
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const titleWrapper = css`
  ${Spacing.mb32};
  ${Spacing.mt48};
`;

export const questionContainer = css`
  ${Spacing.mb32};
`;

export const question = css`
  ${Spacing.mb8};
  ${Font.h2};
  ${FontWeight.semibold};
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const answer = css`
  ${Font.md};
  color: ${Color.GRAYSCALE.GRAY8};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Font.md};
  }
`;

export const sectionHeader = css`
  ${Spacing.mb16};
  ${Spacing.pb8};
  ${Font.h3}
  color: ${Color.GRAYSCALE.GRAY8};
  border-bottom: 1px solid ${Color.GRAYSCALE.GRAY7};
`;

export const faqSectionBox = css`
  ${Spacing.pt20};
`;
