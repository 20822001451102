import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const doCreateAccountHolderPersonalInformation = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_PERSONAL_INFORMATION), {
    data: payload,
  });
};

export const doClearCreateAccountHolderPersonalInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_PERSONAL_INFORMATION));

export const doPatchAccountHolderPersonalInformation = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION), {
    data: payload,
  });
};

export const doClearPatchAccountHolderPersonalInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION));

export const doCreateAccountHolderPhysicalAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS), { data: payload });
};

export const doClearCreateAccountHolderPhysicalAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS));

export const doPatchAccountHolderPhysicalAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS), { data: payload });
};

export const doClearPatchAccountHolderPhysicalAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS));

export const doCreateAccountHolderMailingAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS), { data: payload });
};

export const doClearCreateAccountHolderMailingAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS));

export const doPatchAccountHolderMailingAddress = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS), { data: payload });
};

export const doClearPatchAccountHolderMailingAddress = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS));

// Trusted Contacts
export const doCreateAccountHolderTrustedContact = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT), {
    data: payload,
  });
};

export const doClearCreateAccountHolderTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT));

export const doPatchAccountHolderTrustedContact = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT), {
    data: payload,
  });
};

export const doClearPatchAccountHolderTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT));

export const doDeleteAccountHolderTrustedContact = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT), {
    data: payload,
  });
};

export const doClearDeleteAccountHolderTrustedContact = (): AnyAction =>
  createAction(State.actionCleared(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT));

// Suitability Information
export const doCreateAccountHolderSuitabilityInformation = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION), {
    data: payload,
  });
};

export const doClearCreateAccountHolderSuitabilityInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION));

export const doPatchAccountHolderSuitabilityInformation = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION), {
    data: payload,
  });
};

export const doClearPatchAccountHolderSuitabilityInformation = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION));

// Financial Information
export const doCreateAccountHolderFinancialInformationAssets = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS), {
    data: payload,
  });
};

export const doClearCreateAccountHolderFinancialInformationAssets = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS));

export const doPatchAccountHolderFinancialInformationAssets = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS), {
    data: payload,
  });
};

export const doClearPatchAccountHolderFinancialInformationAssets = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS));

export const doCreateAccountHolderFinancialInformationEmployment = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT), {
    data: payload,
  });
};

export const doClearCreateAccountHolderFinancialInformationEmployment = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT));

export const doPatchAccountHolderFinancialInformationEmployment = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT), {
    data: payload,
  });
};

export const doClearPatchAccountHolderFinancialInformationEmployment = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT));

// Disclosures
export const doCreateAccountHolderDisclosures = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES), {
    data: payload,
  });
};

export const doClearCreateAccountHolderDisclosures = (): AnyAction =>
  createAction(State.actionCleared(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES));

export const doPatchAccountHolderDisclosures = (payload: any): AnyAction => {
  return createAction(State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES), {
    data: payload,
  });
};

export const doClearPatchAccountHolderDisclosures = (): AnyAction =>
  createAction(State.actionCleared(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES));
