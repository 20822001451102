import { css } from '@emotion/css';
import { ColorConstant, Font, FontWeight, Spacing } from 'src/styles';

export const titleContainer = css`
  p {
    ${Spacing.my0}
    ${Spacing.mx0}
  }
  ${Spacing.mb0}
`;

export const titleViewContainer = css([
  titleContainer,
  css`
    ${Spacing.mt16}
  `,
]);

export const title = css`
  ${FontWeight.semibold}
  ${Font.h1}
  ${Spacing.mb0}
  color: ${ColorConstant.GRAY8};
`;

export const subtitle = css`
  ${FontWeight.normal}
  ${Font.md}
  color: ${ColorConstant.GRAY7};
  ${Spacing.my0}
`;
