import {
  TradingBlockOrderActionEnum,
  TradingBlockOrderActionNumberEnum,
  TradingBlockOrderDurationEnum,
  TradingBlockOrderStatusEnum,
  TradingBlockOrderTypeEnum,
  TradingBlockOrderTypeNumberEnum,
} from '../dtos/orders.dtos';

export enum OrderStatusLabel {
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}
export class OrderStatus {
  private _value: TradingBlockOrderStatusEnum;
  private _label: OrderStatusLabel;

  constructor(status?: TradingBlockOrderStatusEnum) {
    this._value = status ?? TradingBlockOrderStatusEnum.Undefined;
    this._label = this.findLabel();
  }

  private findLabel(): OrderStatusLabel {
    switch (this._value) {
      case TradingBlockOrderStatusEnum.New:
      case TradingBlockOrderStatusEnum.PartiallyFilled:
      case TradingBlockOrderStatusEnum.Calculated:
      case TradingBlockOrderStatusEnum.DoneForDay:
      case TradingBlockOrderStatusEnum.Saved:
      case TradingBlockOrderStatusEnum.Acknowledged:
        return OrderStatusLabel.PROCESSING;
      case TradingBlockOrderStatusEnum.PendingNew:
      case TradingBlockOrderStatusEnum.PendingCancel:
      case TradingBlockOrderStatusEnum.Scheduled:
      case TradingBlockOrderStatusEnum.CancelInitiated:
      case TradingBlockOrderStatusEnum.Stopped:
        return OrderStatusLabel.PENDING;
      case TradingBlockOrderStatusEnum.Rejected:
      case TradingBlockOrderStatusEnum.ReplaceRejected:
      case TradingBlockOrderStatusEnum.CancelRejected:
      case TradingBlockOrderStatusEnum.Suspended:
      case TradingBlockOrderStatusEnum.Expired:
        return OrderStatusLabel.REJECTED;
      case TradingBlockOrderStatusEnum.Filled:
        return OrderStatusLabel.COMPLETED;
      case TradingBlockOrderStatusEnum.Cancelled:
      case TradingBlockOrderStatusEnum.PartiallyCancelled:
        return OrderStatusLabel.CANCELLED;
      default:
        return OrderStatusLabel.PROCESSING;
    }
  }

  get value(): TradingBlockOrderStatusEnum {
    return this._value;
  }

  get label(): OrderStatusLabel {
    return this._label;
  }

  get isPending() {
    return this._label === OrderStatusLabel.PENDING;
  }

  get isCompleted() {
    return this._label === OrderStatusLabel.COMPLETED;
  }

  get isCancelled() {
    return this._label === OrderStatusLabel.CANCELLED;
  }

  get isPendingNew() {
    return this._value === TradingBlockOrderStatusEnum.PendingNew;
  }

  get isRejected() {
    return this._value === TradingBlockOrderStatusEnum.Rejected;
  }

  // TODO: introduce new order status Partially Cancelled
  get isPartiallyCancelled() {
    return this._value === TradingBlockOrderStatusEnum.PartiallyCancelled;
  }

  isCancellable() {
    return CANCEL_ORDER_STATUS_LIST.includes(this._value);
  }
}

export class OrderType {
  private _value: TradingBlockOrderTypeNumberEnum;

  constructor(type: TradingBlockOrderTypeNumberEnum) {
    this._value = type;
  }

  get value(): TradingBlockOrderTypeNumberEnum {
    return this._value;
  }

  get label(): string {
    return OrderTypeNumberLabelMapper.get(this.value) ?? 'NA';
  }
}

export class OrderAction {
  private _value: TradingBlockOrderActionNumberEnum;

  constructor(action?: TradingBlockOrderActionNumberEnum) {
    this._value = action ?? TradingBlockOrderActionNumberEnum.Undefined;
  }

  get value(): TradingBlockOrderActionNumberEnum {
    return this._value;
  }

  get label(): string {
    return OrderActionNumberLabelMapper.get(this.value) ?? 'NA';
  }

  get isBuy(): boolean {
    return this._value === TradingBlockOrderActionNumberEnum.Buy;
  }

  get isSell(): boolean {
    return this._value === TradingBlockOrderActionNumberEnum.Sell;
  }
}

export interface OrderPosition {
  id: string;
  symbol: string;
  name?: string;
  quantity: number;
  purchasePrice: number;
  totalCost: number;
  isTradable: boolean;
  value?: number;
  totalValue?: number;
  change?: number;
}

export interface Order {
  id: string;
  symbol: string;
  description: string;
  updatedAt: string;
  quantity: number;
  filledQuantity: number;
  isPartial: boolean;
  price: number;
  totalCost: number;
  type: OrderType;
  action: OrderAction;
  status: OrderStatus;
  commission: number;
  limit?: number;
  stop?: number;
}

export interface CreateOrderView {
  orderType: TradingBlockOrderTypeEnum;
  quantity: string;
  marketPrice: string;
  limitPrice: string;
  stopPrice: string;
  shares: string;
  serviceFee: string;
  orderDuration: TradingBlockOrderDurationEnum;
  estimatedTotal: string;
  action: TradingBlockOrderActionEnum;
  symbol: string;
}

// TODO: confirm pending order statuses
export const CANCEL_ORDER_STATUS_LIST = [
  TradingBlockOrderStatusEnum.New,
  TradingBlockOrderStatusEnum.PendingNew,
  TradingBlockOrderStatusEnum.PendingReplace,
  TradingBlockOrderStatusEnum.Scheduled,
  TradingBlockOrderStatusEnum.Initiated,
  TradingBlockOrderStatusEnum.ReplaceInitiated,
];

export const OrderTypeNumberLabelMapper = new Map<TradingBlockOrderTypeNumberEnum, string>([
  [TradingBlockOrderTypeNumberEnum.Undefined, 'NA'],
  [TradingBlockOrderTypeNumberEnum.Market, 'Market Order'],
  [TradingBlockOrderTypeNumberEnum.Limit, 'Limit Order'],
  [TradingBlockOrderTypeNumberEnum.Stop_Market, 'Stop Market Order'],
  [TradingBlockOrderTypeNumberEnum.Stop_Limit, 'Stop Limit Order'],
  [TradingBlockOrderTypeNumberEnum.Market_On_Close, 'Market on Close Order'],
]);

export const OrderActionNumberLabelMapper = new Map<TradingBlockOrderActionNumberEnum, string>([
  [TradingBlockOrderActionNumberEnum.Undefined, 'NA'],
  [TradingBlockOrderActionNumberEnum.Buy, 'Buy'],
  [TradingBlockOrderActionNumberEnum.Sell, 'Sell'],
]);
