import moment, { Moment } from 'moment';
import { DateConstant, FilterBarConstant } from 'src/constants';
import { GetOrdersQueryParams } from 'src/dtos';
import { FilterBarSample, FixedTimelineFilterValueType } from 'src/lib';

export const onFixedTimelineFilterSelected = (value: FixedTimelineFilterValueType) => {
  let startDate: Moment;
  let endDate: Moment;

  switch (value) {
    case FixedTimelineFilterValueType.THIS_YEAR:
      startDate = moment().startOf('year');
      endDate = moment().endOf('year');
      break;
    case FixedTimelineFilterValueType.THIS_MONTH:
      startDate = moment().startOf('month');
      endDate = moment().endOf('month');
      break;
    case FixedTimelineFilterValueType.THIS_WEEK:
      startDate = moment().startOf('week');
      endDate = moment().endOf('week');
      break;
    default:
      startDate = moment().startOf('year');
      endDate = moment().endOf('year');
      break;
  }

  return { startDate, endDate };
};

export const getTransferSearch = (filters: FilterBarSample) => {
  const defaultTimeline = onFixedTimelineFilterSelected(FixedTimelineFilterValueType.THIS_YEAR);
  let { startDate, endDate, sortBy } = {
    startDate: defaultTimeline.startDate.toLocaleString(),
    endDate: defaultTimeline.endDate.toLocaleString(),
    sortBy: filters.sortBy === FilterBarConstant.SortByCategory.Newest ? 'ascending' : 'descending',
  };

  if (filters.timeline !== undefined) {
    const timelineDate = onFixedTimelineFilterSelected(filters.timeline);
    startDate = timelineDate.startDate.toLocaleString();
    endDate = timelineDate.endDate.toLocaleString();
  }

  if (filters.customTimeline !== undefined) {
    const [customStartDate, customEndDate] = filters.customTimeline;
    startDate = customStartDate.toLocaleString();
    endDate = customEndDate.toLocaleString();
  }

  return {
    startDate,
    endDate,
    sortBy,
  };
};

export const mapFilterToGetOrderQueryParamsDto = (filters: FilterBarSample): GetOrdersQueryParams => {
  const defaultTimeline = onFixedTimelineFilterSelected(FixedTimelineFilterValueType.THIS_YEAR);
  let { startDate, endDate } = {
    startDate: defaultTimeline.startDate.format(DateConstant.ISO_1806_FORMAT),
    endDate: defaultTimeline.endDate.format(DateConstant.ISO_1806_FORMAT),
  };

  if (filters.timeline !== undefined) {
    const timelineDate = onFixedTimelineFilterSelected(filters.timeline);
    startDate = timelineDate.startDate.format(DateConstant.ISO_1806_FORMAT);
    endDate = timelineDate.endDate.format(DateConstant.ISO_1806_FORMAT);
  }

  if (filters.customTimeline !== undefined) {
    const [customStartDate, customEndDate] = filters.customTimeline;
    startDate = customStartDate.format(DateConstant.ISO_1806_FORMAT);
    endDate = customEndDate.format(DateConstant.ISO_1806_FORMAT);
  }

  return {
    dateFrom: startDate,
    dateTo: endDate,
  };
};
