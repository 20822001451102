import { useState } from 'react';

import { Tooltip } from 'antd';
import { useAccountSelector } from 'src/hooks';
import { MFormSection, MFormTextField } from 'src/lib';

import * as Styles from './PersonalInformation.styles';

export const AccountNumberSection = () => {
  const [showTooltip, setShowTooltip] = useState<NodeJS.Timeout>();

  const { account } = useAccountSelector();

  const copyToClipboard = () => {
    clearTimeout(showTooltip);
    navigator.clipboard.writeText(account?.accountNumber ?? '');
    const timeout = setTimeout(() => setShowTooltip(undefined), 2000);
    setShowTooltip(timeout);
  };

  if (!account?.accountId) {
    return null;
  }

  return (
    <MFormSection isEditMode={false} isEditable={false}>
      <MFormTextField
        label='Account Number'
        value={
          <Tooltip
            placement='topRight'
            title={
              <>
                <i className={`ri-checkbox-circle-fill`} />
                <span>Account number copied to clipboard</span>
              </>
            }
            open={Boolean(showTooltip)}
            overlayClassName={Styles.copiedTooltip}>
            <div className={Styles.displayValue} onClick={copyToClipboard}>
              {account?.accountNumber}
            </div>
          </Tooltip>
        }
      />
    </MFormSection>
  );
};
