import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { GetOrdersQueryParams } from 'src/dtos';

import { getOrdersRequested } from '../actions';
import { CustomHookBaseOptions } from '../models/common.model';
import { Order } from '../models/orders.models';

import { useAccountSelector } from './useAccountSelector';

interface UseOrdersReturnType {
  orderList: Order[];
  isOrderListLoading: boolean;
  hasOrderListError?: boolean;
  orderListError?: string;
  isOrderListFetched: boolean;
}

export interface UseOrdersOptions extends CustomHookBaseOptions {
  queryParamsDto?: GetOrdersQueryParams;
}

const EMPTY_ORDERS: Order[] = [];

export const useOrders = ({ skip, queryParamsDto }: UseOrdersOptions = { skip: false }): UseOrdersReturnType => {
  const dispatch = useDispatch();
  const orderList: Order[] | undefined = useSelector((state: any) => state.orders.getOrders?.data?.data);
  const isOrderListLoading = useSelector((state: any) => Boolean(state.orders?.getOrders?.__requested));
  const hasOrderListFailed: boolean = useSelector((state: any) => state.orders.getOrders?.__failed);
  const orderListError: string = useSelector((state: any) => state.orders.getOrders?.__message);
  const isFetched: boolean = useSelector((state: any) => Boolean(state.orders.getOrders?.__isFetched));
  const { account } = useAccountSelector();

  useEffect(() => {
    if (!orderList && !isOrderListLoading && !skip && !hasOrderListFailed && account?.accountId) {
      dispatch(getOrdersRequested({ accountId: account.accountId, queryParams: queryParamsDto }));
    }
  }, [orderList, isOrderListLoading, dispatch, account?.accountId]);

  return {
    orderList: Array.isArray(orderList) ? orderList : EMPTY_ORDERS,
    isOrderListLoading,
    hasOrderListError: hasOrderListFailed,
    orderListError,
    isOrderListFetched: isFetched,
  };
};
