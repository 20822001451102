import { css } from '@emotion/css';
import { ColorConstant, Font } from 'src/styles';

export const description = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
`;

export const bankName = css`
  ${Font.md}
  color: ${ColorConstant.GRAY6};
`;
