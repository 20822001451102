import { useSelector } from 'react-redux';
import { InvestigationStatus, TradingBlockApplication, TradingBlockInvestigation } from 'src/models';

const EMPTY_INVESTIGATION_LIST_ARRAY: TradingBlockInvestigation[] = [];
const EMPTY_APPLICATION_LIST_ARRAY: TradingBlockApplication[] = [];

export const useInvestigationListSelector = () => {
  const investigationState: InvestigationStatus | undefined = useSelector(
    (state: any) => state.investigations.list.data?.status,
  );
  const applications: TradingBlockApplication[] = useSelector(
    (state: any) => state.investigations.list.data?.applications,
  );
  const investigations: TradingBlockInvestigation[] = useSelector(
    (state: any) => state.investigations.list.data?.investigations,
  );
  const isLoadingInvestigations: boolean = useSelector((state: any) => state.investigations.list.isLoading);

  return {
    status: investigationState,
    applications: Array.isArray(applications) ? applications : EMPTY_APPLICATION_LIST_ARRAY,
    investigations: Array.isArray(investigations) ? investigations : EMPTY_INVESTIGATION_LIST_ARRAY,
    isInvestigationListLoading: isLoadingInvestigations,
  };
};
