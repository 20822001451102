import produce from 'immer';
import { combineReducers } from 'redux';

import * as State from '../actions/types';
import { Type } from '../actions/utils';
import { TReduxAction } from '../typings/commonTypes';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

export const signUp = (state: any = initialState.user.signUp, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.SIGN_UP):
      return clearedDefaults(state);
    case State.actionRequested(Type.SIGN_UP):
      return requestedDefaults(state);
    case State.actionFailed(Type.SIGN_UP):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.SIGN_UP):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.signUp;
    default:
      return state;
  }
};

export const patchAddress = (state: any = initialState.user.patchAddress, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.PATCH_USER_ADDRESS):
      return clearedDefaults(state);
    case State.actionRequested(Type.PATCH_USER_ADDRESS):
      return requestedDefaults(state);
    case State.actionFailed(Type.PATCH_USER_ADDRESS):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.PATCH_USER_ADDRESS):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.patchAddress;
    default:
      return state;
  }
};

export const toggleMfaConfig = (state: any = initialState.user.toggleMfaConfig, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.TOGGLE_MFA_CONFIG):
      return clearedDefaults(state);
    case State.actionRequested(Type.TOGGLE_MFA_CONFIG):
      return requestedDefaults(state);
    case State.actionFailed(Type.TOGGLE_MFA_CONFIG):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.TOGGLE_MFA_CONFIG):
      return succeededDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.toggleMfaConfig;
    default:
      return state;
  }
};

export const login = (state: any = initialState.user.login, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.LOGIN):
      return clearedDefaults(state);
    case State.actionRequested(Type.LOGIN):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.LOGIN):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.LOGIN):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.login;
    default:
      return state;
  }
};

export const authenticated = (state: any = initialState.user.authenticated, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_AUTHENTICATED_USER):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_AUTHENTICATED_USER):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_AUTHENTICATED_USER):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_AUTHENTICATED_USER):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.UPDATE_AUTHENTICATED_USER):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.data = { ...draftState.data, ...action.payload };
      })(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.authenticated;
    default:
      return state;
  }
};

export const confirmMfaCode = (state: any = initialState.user.confirmMfaCode, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.CONFIRM_MFA):
      return clearedDefaults(state);
    case State.actionRequested(Type.CONFIRM_MFA):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.CONFIRM_MFA):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.CONFIRM_MFA):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.confirmMfaCode;
    default:
      return state;
  }
};

export const resendMfaCode = (state: any = initialState.user.resendMfaCode, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.RESEND_MFA_CODE):
      return clearedDefaults(state);
    case State.actionRequested(Type.RESEND_MFA_CODE):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.RESEND_MFA_CODE):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.RESEND_MFA_CODE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.resendMfaCode;
    default:
      return state;
  }
};

export const forgotPassword = (state: any = initialState.user.forgotPassword, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.FORGOT_PASSWORD):
      return clearedDefaults(state);
    case State.actionRequested(Type.FORGOT_PASSWORD):
      return requestedDefaults(state);
    case State.actionFailed(Type.FORGOT_PASSWORD):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.FORGOT_PASSWORD):
      return succeededDefaults(state, action);
    default:
      return state;
  }
};

export const resetPassword = (state: any = initialState.user.resetPassword, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.RESET_PASSWORD):
      return clearedDefaults(state);
    case State.actionRequested(Type.RESET_PASSWORD):
      return requestedDefaults(state);
    case State.actionFailed(Type.RESET_PASSWORD):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.RESET_PASSWORD):
      return succeededDefaults(state, action);
    default:
      return state;
  }
};

export const emailChange = (state: any = initialState.user.emailChange, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.EMAIL_CHANGE):
      return clearedDefaults(state);
    case State.actionRequested(Type.EMAIL_CHANGE):
      return requestedDefaults(state);
    case State.actionFailed(Type.EMAIL_CHANGE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.EMAIL_CHANGE):
      return succeededDefaults(state, action);
    default:
      return state;
  }
};

export const emailConfirm = (state: any = initialState.user.emailConfirm, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.EMAIL_CONFIRM):
      return clearedDefaults(state);
    case State.actionRequested(Type.EMAIL_CONFIRM):
      return requestedDefaults(state);
    case State.actionFailed(Type.EMAIL_CONFIRM):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.EMAIL_CONFIRM):
      return succeededDefaults(state, action);
    default:
      return state;
  }
};

export const updatePhoneNumber = (state: any = initialState.user.updatePhoneNumber, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.UPDATE_PHONE_NUMBER):
      return clearedDefaults(state);
    case State.actionRequested(Type.UPDATE_PHONE_NUMBER):
      return requestedDefaults(state);
    case State.actionFailed(Type.UPDATE_PHONE_NUMBER):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.UPDATE_PHONE_NUMBER):
      return succeededDefaults(state, action);
    default:
      return state;
  }
};

export const getSignature = (state: any = initialState.user.getSignature, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_SIGNATURE):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_SIGNATURE):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_SIGNATURE):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.__requested = null;
        draftState.__succeeded = new Date();
        draftState.__failed = null;
        draftState.data = { signature: action.payload };
        draftState.message = action?.message;
      })(state, action);
    case State.actionFailed(Type.GET_SIGNATURE):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.getSignature;
    default:
      return state;
  }
};

export const signAccountAgreement = (state: any = initialState.user.signAccountAgreement, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.SIGN_ACCOUNT_AGREEMENT):
      return clearedDefaults(state);
    case State.actionRequested(Type.SIGN_ACCOUNT_AGREEMENT):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.SIGN_ACCOUNT_AGREEMENT):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.SIGN_ACCOUNT_AGREEMENT):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.user.signAccountAgreement;
    default:
      return state;
  }
};

export default combineReducers({
  signUp,
  patchAddress,
  toggleMfaConfig,
  login,
  authenticated,
  confirmMfaCode,
  resendMfaCode,
  forgotPassword,
  resetPassword,
  emailChange,
  emailConfirm,
  updatePhoneNumber,
  getSignature,
  signAccountAgreement,
});
