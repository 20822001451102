import { AccountHolderInvestmentObjectivesDto, AccountHolderTimeHorizonDto } from 'src/dtos';
import { AccountHolderInvestmentObjectivesLabel, AccountHolderTimeHorizonLabel } from 'src/models';

export const INVESTMENT_OBJECTIVES_OPTION_LIST = [
  {
    label: AccountHolderInvestmentObjectivesLabel.CapitalPreservation,
    value: AccountHolderInvestmentObjectivesDto.CapitalPreservation,
    description: 'Preservation of capital with primary consideration on income.',
    alert:
      'Capital Preservation has Low Risk Tolerance. In order to qualify for My IPO Offers your Investment Objectives must be "Growth" or "Speculation"',
  },
  {
    label: AccountHolderInvestmentObjectivesLabel.Income,
    value: AccountHolderInvestmentObjectivesDto.Income,
    description: 'A balance between capital appreciation and income with a primary consideration being income.',
    alert:
      'Income has Low Risk Tolerance. In order to qualify for My IPO Offers your Investment Objectives must be "Growth" or "Speculation"',
  },
  {
    label: AccountHolderInvestmentObjectivesLabel.GrowthAndIncome,
    value: AccountHolderInvestmentObjectivesDto.GrowthAndIncome,
    description:
      'Dual Investment strategy that seeks both capital appreciation and current income generated through dividends or interest.',
    alert:
      'Growth and Income has Medium Risk Tolerance. In order to qualify for My IPO Offers your Investment Objectives must be "Growth" or "Speculation"',
  },
  {
    label: AccountHolderInvestmentObjectivesLabel.Growth,
    value: AccountHolderInvestmentObjectivesDto.Growth,
    description: 'Capital appreciation through quality equity investment and little or no income.',
  },
  {
    label: AccountHolderInvestmentObjectivesLabel.Speculation,
    value: AccountHolderInvestmentObjectivesDto.Speculation,
    description: 'Maximum capital appreciation with higher risk and little to no income',
  },
];

export const TIME_HORIZON_OPTION_LIST = [
  {
    label: AccountHolderTimeHorizonLabel.Short,
    value: AccountHolderTimeHorizonDto.Short,
    description:
      'I plan on keeping this account open and investing for less than 3 years to achieve my financial goals.',
    alert: 'In order to qualify for My IPO Offers your Time Horizon must be "Average" or "Long"',
  },
  {
    label: AccountHolderTimeHorizonLabel.Average,
    value: AccountHolderTimeHorizonDto.Average,
    description:
      'I plan on keeping this account open and investing for more than 4 years, but less than 7 years to achieve my financial goals',
  },
  {
    label: AccountHolderTimeHorizonLabel.Long,
    value: AccountHolderTimeHorizonDto.Long,
    description:
      'I plan on keeping this account open and investing for more than 8+ years to achieve my financial goals.',
  },
];
