import React from 'react';

import { Card, Row, Space } from 'antd';
import { Outlet } from 'react-router-dom';

import * as Styles from './InformationPanel.styles';

export const InformationPanel = () => {
  const [title, setTitle] = React.useState<string>();

  return (
    <Row justify={'center'}>
      <Space direction='vertical' size={30}>
        <h1 className={Styles.title}>{title}</h1>
        <Card className={Styles.cardWrapper}>
          <Outlet context={[title, setTitle]} />
        </Card>
      </Space>
    </Row>
  );
};

export default InformationPanel;
