import { MModal } from '../MModal/MModal';

interface WhatsAccreditedInvestorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WhatsAccreditedInvestorModal = ({ isOpen, onClose }: WhatsAccreditedInvestorModalProps) => {
  return (
    <MModal
      title='What is an Accredited Investor?'
      visible={isOpen}
      onClose={onClose}
      tertiaryButtonText='OK'
      onTertiaryButtonClick={onClose}>
      <p>
        Accredited investors are persons who, due to their income and/or net worth, are deemed to have a level of
        financial sophistication such that they require a lesser level of protection under the federal securities laws.
        With regard to individuals, a person shall be deemed to be accredited if they meet at least one of the following
        categories:
      </p>
      <ul>
        <li>
          Any director, executive officer, or general partner of the issuer of the securities being offered or sold, or
          any director, executive officer, or general partner of a general partner of that issuer;
        </li>
        <li>
          Any natural person whose individual net worth, or joint net worth with that person’s spouse, at the time of
          his purchase exceeds $1,000,000, exclusive of residence; or
        </li>
        <li>
          Any natural person who had an individual income in excess of $200,000 in each of the two most recent years or
          joint income with that person’s spouse in excess of $300,000 in each of those years and has a reasonable
          expectation of reaching the same income level in the current year.
        </li>
      </ul>
    </MModal>
  );
};
