import { css } from '@emotion/css';

import { AlignItems, BaseStyle, FlexDirection, JustifyContent, ScreenBreakpoint } from '../../styles';

export const container = css`
  ${BaseStyle.level}
  ${AlignItems.center}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column};
    ${JustifyContent.spaceAround}
  }
`;
