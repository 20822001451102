export const OtherFactorsDisclaimer = () => (
  <div>
    <p style={{ fontWeight: 'bold' }}>Other factors your investment might not get accepted:</p>
    <ul>
      <li>
        Unless the offering statement or registration statement has been qualified or declared effective by the
        Securities and Exchange Commission, as applicable, no money or other consideration is being solicited, and if
        sent, will not be accepted.
      </li>
      <li>
        No sales will be made or commitments to purchase accepted until the offering statement or registration statement
        relating to this offering has been qualified or declared effective by the Securities and Exchange Commission.
      </li>
      <li>A prospective purchaser’s indication of interest is non-binding.</li>
    </ul>
  </div>
);
