export const SecAndTafFeesDescription = () => (
  <>
    The SEC and FINRA Trading Activity Fee (TAF) are regulatory fees charged on the sale of any security. These fees are
    automatically debited from the proceeds of any security sale. These minor fees only occur on the sale of a security
    and are equal to:
    <br />
    <br />
    &#8208;&nbsp;SEC Fee&nbsp;&#61;&nbsp;$8 for every $1,000,000 in shares sold
    <br />
    &#8208;&nbsp;TAF Fee&nbsp;&#61;&nbsp;number of shares sold times $0.000166 per share with a maximum of $5.95
    <br />
    <br />
    Both fees are rounded up to the nearest penny.
  </>
);
