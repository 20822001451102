import { TransferDetails } from 'src/models/transfers.models';

export interface TransferDetailsDisclaimerProps {
  value: TransferDetails;
}

export const TransferDetailsDisclaimer = ({ value }: TransferDetailsDisclaimerProps) => {
  if (value.status.isPending) {
    return (
      <span>
        The pending ACH {value.direction.isDeposit ? 'Deposit' : 'Withdrawal'} will typically take 1 to 2 business days
        to be deposited into your {value.direction.isDeposit ? 'My IPO account' : 'bank account'}.
      </span>
    );
  }

  if (value.status.isProcessing) {
    return (
      <span>
        Your ACH {value.direction.isDeposit ? 'Deposit' : 'Withdrawal'} is in process and will typically take 1 to 2
        business days to be deposited into your {value.direction.isDeposit ? 'My IPO account' : 'bank account'}. After
        the processing window has passed, this transaction will be completed or rejected.
      </span>
    );
  }

  if (value.status.isCancelled) {
    return (
      <span>
        If you need more information on why your ACH {value.direction.isDeposit ? 'Deposit' : 'Withdrawal'} was
        cancelled, please contact your bank or My IPO at{' '}
        <a href='mailto:clientservices@myipo.com' target='_top'>
          clientservices@myipo.com
        </a>
        .
      </span>
    );
  }

  if (value.status.isRejected) {
    return (
      <span>
        If you need more information on why your ACH {value.direction.isDeposit ? 'Deposit' : 'Withdrawal'} was
        rejected, please contact your bank or My IPO at{' '}
        <a href='mailto:clientservices@myipo.com' target='_top'>
          clientservices@myipo.com
        </a>
        .
      </span>
    );
  }

  if (value.status.isCompleted) {
    return (
      <span>
        The {value.direction.isDeposit ? 'deposit' : 'withdrawal'} amount has been transferred to your{' '}
        {value.direction.isDeposit ? 'My IPO account' : 'bank account'} and should be reflected by the following
        business day.
      </span>
    );
  }

  return null;
};
