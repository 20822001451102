import React, { useEffect, useRef } from 'react';

import drawAreaChart from './drawAreaChart';

interface Props {
  data: [number, number][];
  width: number;
  height: number;
  options?: { xAxis?: { tickInterval?: d3.CountableTimeInterval; formatTick?: string; tickStep?: number } };
}

const AreaChart = ({ data, width, height, options }: Props) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) drawAreaChart(ref.current, data, width, height, options);
  }, [ref, data, options]);

  return (
    <div className='container'>
      <div className='graph' ref={ref} />
    </div>
  );
};

export default React.memo(AreaChart);
