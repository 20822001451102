import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { doClearGetPlaidLinkToken, getACHRelationships } from 'src/actions';
import { PlaidLinkOnSuccessResponseDto } from 'src/dtos';
import { useAccountSelector } from 'src/hooks';

import { NewAchRelationshipFinishModal } from './NewAchRelationshipFinishModal';
import { NewAchRelationshipStartModal } from './NewAchRelationshipStartModal';
import { OpenPlaidLinkBankAccount } from './OpenPlaidLinkBankAccount';

interface NewAchRelationshipModalProps {
  open: boolean;
  onClose: () => void;
  onExit?: () => void;
  onFinish?: () => void;
}

export const NewAchRelationshipModal = ({ open, onClose, onExit, onFinish }: NewAchRelationshipModalProps) => {
  const dispatch = useDispatch();

  const [bankLinkPayload, setBankLinkPayload] = useState<{
    plaidPublicToken: string;
    institutionId?: string;
    bankAccounts: Array<{ bankAccountType: string; plaidAccountId: string; bankAccountMask: string }>;
  } | null>(null);
  const [isNewAchRealtionshipStartModelOpen, setIsNewAchRealtionshipStartModelOpen] = useState<boolean>(false);
  const [isNewAchRelationshipFinishModelOpen, setIsNewAchRelationshipFinishModelOpen] = useState<boolean>(false);
  const plaidLinkToken = useSelector((state: any) => state.cashiering.plaidLinkToken.data?.linkToken);
  const [shouldOpenPlaid, setShouldOpenPlaid] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onPlaidSuccess = ({ metadata, publicToken }: PlaidLinkOnSuccessResponseDto) => {
    const { accounts } = metadata;

    const plaidAccountList = accounts.map(account => ({
      bankAccountType: `${account.subtype[0].toUpperCase()}${account.subtype.slice(1)}`,
      plaidAccountId: account.id,
      bankAccountMask: account.mask,
    }));

    setBankLinkPayload({
      plaidPublicToken: publicToken,
      institutionId: metadata.institution?.institution_id,
      bankAccounts: plaidAccountList,
    });
    setShouldOpenPlaid(false);
    dispatch(doClearGetPlaidLinkToken());
    setIsNewAchRelationshipFinishModelOpen(true);
  };

  const onPlaidExit = () => {
    setShouldOpenPlaid(false);
    dispatch(doClearGetPlaidLinkToken());

    if (onExit) {
      onExit();
    }
  };

  useEffect(() => {
    if (open) {
      setIsNewAchRealtionshipStartModelOpen(true);
    }
  }, [open]);

  if (open) {
    return (
      <>
        <NewAchRelationshipStartModal
          accountId={account?.accountId}
          open={isNewAchRealtionshipStartModelOpen}
          onOk={() => {
            setIsNewAchRealtionshipStartModelOpen(false);
            setShouldOpenPlaid(true);
          }}
          onClose={() => {
            onClose();
            setIsNewAchRealtionshipStartModelOpen(false);
          }}
        />

        {plaidLinkToken && shouldOpenPlaid && (
          <OpenPlaidLinkBankAccount token={plaidLinkToken} onExit={onPlaidExit} onSuccess={onPlaidSuccess} />
        )}

        <NewAchRelationshipFinishModal
          accountId={account?.accountId}
          bankLinkPayload={bankLinkPayload}
          open={isNewAchRelationshipFinishModelOpen}
          onOk={() => {
            setIsNewAchRelationshipFinishModelOpen(false);
            setBankLinkPayload(null);
            dispatch(getACHRelationships(account?.accountId));

            if (onFinish) {
              onFinish();
            }
          }}
          onClose={() => {
            onClose();
            setIsNewAchRelationshipFinishModelOpen(false);
            setBankLinkPayload(null);
          }}
        />
      </>
    );
  }

  return null;
};
