import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const getSnapQuotesRequested = (symbols: string[]): AnyAction => {
  return createAction(State.actionRequested(Type.GET_QUOTE_MEDIA), { symbols });
};

export const getEnhancedSnapQuotesRequested = (symbol: string): AnyAction =>
  createAction(State.actionRequested(Type.GET_ENHANCED_QUOTE_MEDIA), { symbol });

export const getChartSnapQuotesClear = (): AnyAction => createAction(State.actionCleared(Type.GET_CHART_QUOTE_MEDIA));

export const getChartSnapQuotesRequested = (symbol: string, interval: string): AnyAction =>
  createAction(State.actionRequested(Type.GET_CHART_QUOTE_MEDIA), { symbol, interval });

export const getSnapQuotesSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_QUOTE_MEDIA), payload);

export const getEnhancedSnapQuotesSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_ENHANCED_QUOTE_MEDIA), payload);

export const getChartSnapQuotesSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_CHART_QUOTE_MEDIA), payload);
