import { css } from '@emotion/css';

import { ScreenBreakpoint, Spacing } from '../../styles';

export const divider = css`
  ${Spacing.my16}
  ${Spacing.mx0}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mx0}
    ${Spacing.my12}
  }
`;
