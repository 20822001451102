import { css, cx } from '@emotion/css';

import { AlignItems, ColorConstant, Display, Font, FontWeight, Spacing } from '../../styles';

export const button = css`
  color: ${ColorConstant.BRAND6};
  ${Display.flex}
  ${AlignItems.center}
  ${Spacing.mx0}
  ${Spacing.px0}
  cursor: pointer;

  &:hover {
    color: ${ColorConstant.BRAND6};
  }

  &:focus {
    color: ${ColorConstant.BRAND6};
  }
`;

export const icon = (icon: 'question' | 'info') =>
  cx(
    icon === 'question' ? 'ri-question-line' : 'ri-information-line',
    css`
      color: ${ColorConstant.BRAND5};
      vertical-align: middle;
      cursor: pointer;
      ${Font.md}
      ${FontWeight.normal}
    `,
  );
