import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useAccountSelector, useHasAnyRequestedInvestigationDocuments, useInvestigationListSelector } from 'src/hooks';

import { getInvestigations } from '../../../actions';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import Spinner from '../../../lib/Miscellaneous/Spinner';

import * as Styles from './AccountStatus.styles';
import { AccountStatusHeader } from './AccountStatusHeader';
import { ApplicationCipFailureForm } from './ApplicationCipFailureForm';
import { InvestigationCipFailureForm } from './InvestigationCipFailureForm';

export const AccountStatus = () => {
  const dispatch = useDispatch();

  const { status, applications, investigations, isInvestigationListLoading } = useInvestigationListSelector();
  const hasRequestedDocuments = useHasAnyRequestedInvestigationDocuments();
  const { account } = useAccountSelector();

  useEffect(() => {
    if (account?.accountId) dispatch(getInvestigations(account?.accountId));
  }, [account?.accountId]);

  if (isInvestigationListLoading || !status) {
    return <Spinner />;
  }

  return (
    <MyAccountLayout sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.AccountStatus} title='Account status'>
      <Row>
        <Col>
          <div className={Styles.formCard}>
            <AccountStatusHeader />
            {(status.isPending || status.isRejected) && (
              <>
                {hasRequestedDocuments && (
                  <Col span={24} className={Styles.statusLabel}>
                    Additional Information
                  </Col>
                )}
                {applications.map(application =>
                  application.appCategories
                    .filter(category => !isEmpty(category.requestedDocuments))
                    .map(appCategory => {
                      return (
                        <ApplicationCipFailureForm
                          key={`${appCategory.name}_key`}
                          category={appCategory}
                          uploadedDocuments={application.uploadedDocuments ?? []}
                          accountHolderId={application.accountHolderId}
                          accountId={account?.accountId}
                        />
                      );
                    }),
                )}

                {investigations.map(investigation =>
                  investigation.cipCategories
                    .filter(category => !isEmpty(category.requestedDocuments))
                    .map(cipCategory => {
                      return (
                        <div key={`${cipCategory?.name}_key`}>
                          <InvestigationCipFailureForm
                            investigationId={investigation.investigationId}
                            category={cipCategory}
                            uploadedDocuments={investigation.uploadedDocuments ?? []}
                            accountId={account?.accountId}
                          />
                        </div>
                      );
                    }),
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </MyAccountLayout>
  );
};
