import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { forgotPassword, clearForgotPassword } from 'src/actions';
import { MInput, MButton, CardLayout } from 'src/lib';
import { Spacing } from 'src/styles';
import * as Yup from 'yup';

import * as Styles from '../BaseLayout.styles';

import { mailOpenIcon } from './ForgotPassword.styles';

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
});

interface ForgotPasswordFormValues {
  email: string;
}

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSucceeded = useSelector((state: any) => Boolean(state.user.forgotPassword.__succeeded));
  const isRequested = useSelector((state: any) => Boolean(state.user.forgotPassword.__requested));

  const renderForgotPasswordForm = (isRequested: boolean, forgotPassword: (email: string) => void) => {
    return (
      <Formik<ForgotPasswordFormValues>
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => forgotPassword(values.email)}>
        {form => {
          return (
            <>
              <Row justify='center'>
                <span className={Styles.formTitle}>Forgot Password</span>
              </Row>
              <Row className={Spacing.my16}>
                <Col span={24}>
                  <MInput
                    placeholder='Enter your email'
                    onChange={(value: string) => form.setFieldValue('email', value)}
                    value={form.values.email}
                    error={form.errors.email}
                    autoComplete='off'
                  />
                </Col>
              </Row>

              <Row justify='center' className={Spacing.my16}>
                <MButton
                  type='secondary'
                  loading={isRequested}
                  disabled={!form.isValid}
                  onClick={() => {
                    form.submitForm();
                  }}>
                  Reset Password
                </MButton>
              </Row>
            </>
          );
        }}
      </Formik>
    );
  };

  const renderForgotPasswordSubmitted = (navigate: NavigateFunction) => {
    return (
      <>
        <Row justify='center'>
          <div className={Styles.iconContainer}>
            <i className={`ri-mail-open-line ${mailOpenIcon}`} />
          </div>
        </Row>
        <Row justify='center'>
          <p className={Styles.afterActionTitle}>Check your email</p>
        </Row>
        <Row justify='center'>
          <p className={Styles.afterActionSubtitle}>We have sent you an email with password reset instructions</p>
        </Row>

        <Row justify='center'>
          <MButton
            type='secondary'
            onClick={() => {
              navigate('/login');
            }}>
            Go To Log In
          </MButton>
        </Row>
      </>
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearForgotPassword());
    };
  }, []);

  return (
    <CardLayout
      subtitle=' Please enter the email address for your account. An email with instructions will be sent to you. Once you have
    received the email, you will be able to choose a new password for your account.'>
      {!isSucceeded
        ? renderForgotPasswordForm(isRequested, (email: string) => dispatch(forgotPassword({ email })))
        : renderForgotPasswordSubmitted(navigate)}
    </CardLayout>
  );
};
