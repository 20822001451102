import { css } from '@emotion/css';
import { OfferOrderStatusLabel } from 'src/models/offer-orders.model';
import { Display, Font, FontWeight } from 'src/styles';
import { getOfferOrderStatusTagColor } from 'src/utils';

export const tag = (transfer: OfferOrderStatusLabel) => {
  const schema = getOfferOrderStatusTagColor(transfer);

  return css`
    ${Font.lg}
    ${Display.flex}
    ${FontWeight.semibold}
    width: fit-content;
    padding: 0.25rem 0.5rem;
    border: 0.0625rem solid ${schema.background};
    border-radius: 0.5rem;
    color: ${schema.color};
    background-color: ${schema.background};
    height: 1.5rem;
  `;
};
