import { call, put, select, takeEvery, SagaReturnType } from 'redux-saga/effects';
import { doGetAccount, doSucceededGetAccount, toastMessagesAdd } from 'src/actions';
import { mapAccountDtoToModel } from 'src/mappers';
import { AuthReducedState } from 'src/typings/auth.types';
import { SeverityEnum, TReduxAction } from 'src/typings/commonTypes';
import { v4 as uuid } from 'uuid';

import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { AccountsApi } from '../data-communication/AccountsApi';
import { getClientApi } from '../data-communication/ClientApi';

import { signAccountAgreement } from './users.sagas';
import { HttpClient, replacePlaceholders, safeSaga } from './utils';

export function* getAccount() {
  const { authToken } = yield select(state => state.auth.data);

  const response: SagaReturnType<AccountsApi['getOne']> = yield call(getClientApi().accounts.getOne, { authToken });

  yield put(doSucceededGetAccount(mapAccountDtoToModel(response)));
}

export function* submit(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const signature = action.payload.signature;

  if (signature) {
    yield call(signAccountAgreement, { type: Type.SIGN_ACCOUNT_AGREEMENT, payload: { signature } });
  }

  let url = replacePlaceholders(Url.SUBMIT_ACCOUNT_APPLICATION, {
    accountIdentifier: 'individual',
  });
  yield call(HttpClient, 'PUT', url, undefined, authToken);

  yield put({
    type: State.actionSucceeded(Type.SUBMIT_ACCOUNT),
  });
  yield put(
    toastMessagesAdd({
      key: uuid(),
      severity: SeverityEnum.Success,
      message: 'Account Application was successfully submitted',
    }),
  );

  // TODO: When server returns the account object from submit account endpoint, then replace it.
  yield put(doGetAccount());
}

export function* registerAccountSagas() {
  yield takeEvery(State.actionRequested(Type.GET_ACCOUNT), safeSaga(getAccount, Type.GET_ACCOUNT));
  yield takeEvery(State.actionRequested(Type.SUBMIT_ACCOUNT), safeSaga(submit, Type.SUBMIT_ACCOUNT));
}
