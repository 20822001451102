import { Tooltip } from 'antd';

import { Color } from '../../../styles';

import { TooltipComponentType } from './constants';

const TooltipComp = ({ text }: TooltipComponentType) => {
  return (
    <Tooltip
      getTooltipContainer={(triggerNode: any) => triggerNode.parentNode}
      placement='top'
      color={Color.GRAYSCALE.GRAY1}
      title={text}>
      <i className='ri-question-line' style={{ fontSize: '1rem', color: Color.BRAND.BRAND5 }} />
    </Tooltip>
  );
};

export default TooltipComp;
