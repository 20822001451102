import { useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

import { OtherFactorsDisclaimer } from '../../../lib/OtherFactorsDisclaimer/OtherFactorsDisclaimer';

export const OtherFactors = () => {
  const [, setTitle] = useOutletContext<any>();

  useEffect(() => {
    setTitle('Other Factors');
  }, []);

  return <OtherFactorsDisclaimer />;
};

export default OtherFactors;
