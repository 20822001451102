import { css } from '@emotion/css';

import { Color, ColorConstant, Font, ScreenBreakpoint, Spacing, TextAlign } from '../../../styles';

export const bcLink = css`
  width: fit-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml0};
  }
`;

export const label = css`
  ${Font.lg};
  ${TextAlign.left};
  color: ${Color.BRAND.BRAND6};
  ${Spacing.ml20};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml8};
  }
`;

export const backArrow = css`
  ${Font.h3};
  color: ${ColorConstant.BRAND6};
`;
