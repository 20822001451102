import React, { Fragment } from 'react';

import { Col, Row, Space } from 'antd';
import { Link } from 'react-router-dom';

import * as Styles from './About.styles';

export const About = () => {
  return (
    <Fragment>
      <Row className={Styles.missionSection}>
        <Col span={24} className={Styles.sectionContainer}>
          <Space direction='vertical' className={Styles.missionContainer}>
            <span className={Styles.missionHeader}>About My IPO</span>
            <span className={Styles.missionSubtext}>
              At My IPO, our mission is to provide open access to selected IPOs and other Offerings.
            </span>
          </Space>
        </Col>
      </Row>
      <Row className={Styles.aboutTextSection} justify='center'>
        <Col span={24} className={Styles.aboutTextSectionContainer}>
          <div className={Styles.aboutTextContainer}>
            <p>
              Until now, only the privileged clients of investment banks, institutional investors or the wealthy had
              access to IPOs and other Offerings. My IPO has changed this by creating a marketplace of selected IPOs and
              other Offerings that everyone can invest in.
            </p>
            <p>
              We believe that access to wealth creation should be universal and not just available to those that already
              have wealth or privileged access to these types of Offerings. My IPO was created to give all investors the
              opportunity to invest in some of the most exciting IPOs and other Offerings. It’s easy to sign up, easy to
              use and equally easy to find Offerings to invest in.
            </p>
            <p>Please join us as we continue to fight for equal access to wealth creation.</p>
          </div>
          <div className={Styles.aboutTextContainer}>
            <p className={Styles.aboutTextContainerTitle}>Contact Us</p>
            <p>
              If you have any questions please email us at&nbsp;
              <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>&nbsp;or give us a call at the
              telephone number below. We’re available to help from 7:30am-4pm MST, Monday through Friday.
            </p>
            <p>
              My IPO
              <br />
              488 East Winchester Street, Suite 200
              <br />
              Salt Lake City, UT 84107
              <br />
              Toll Free: (844) 226-0640
              <br />
              <a href='.'>www.myipo.com</a>
            </p>
          </div>
        </Col>
      </Row>
      <Row className={Styles.signupSection}>
        <Col span={24} className={Styles.signupContainer}>
          <span className={Styles.signupTextHeader}>Get started today!</span>
          <Link className={Styles.signupBtn} to={'/register'}>
            Sign up
          </Link>
        </Col>
      </Row>
    </Fragment>
  );
};

export default About;
