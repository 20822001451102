import { css } from '@emotion/css';
import {
  AlignItems,
  AlignSelf,
  Color,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  SpaceSize,
  Spacing,
  TextAlign,
} from 'src/styles';

export const logoCircle = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
  ${AlignSelf.center}

  width: ${SpaceSize.is64};
  height: ${SpaceSize.is64};
  border-radius: 5rem;
  ${Spacing.mb4}
  ${Spacing.mt16}
`;

export const logo = css`
  ${Font.h1}
`;

export const descriptionLabel = css`
  ${Font.md}
  ${TextAlign.center}
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const checkEmailLabel = css`
  ${Font.lg}
  ${FontWeight.bold}
  ${TextAlign.center}
`;

export const errorLight = css`
  background-color: ${Color.RED.RED1};
`;

export const successLight = css`
  background-color: ${Color.GREEN.GREEN1};
`;

export const errorColor = css`
  color: ${Color.RED.RED6};
`;

export const successColor = css`
  color: ${Color.GREEN.GREEN6};
`;
