import { ErrorCode } from '../constants/common.constants';

export class BeforeCardPaymentError extends Error {
  code = ErrorCode.BEFORE_CONFIRM_CARD_PAYMENT;

  constructor() {
    super(
      `Your credit card payment failed due to an unknown error (Error code: ${ErrorCode.BEFORE_CONFIRM_CARD_PAYMENT}). If you would like to try again, please re-enter an Order with the same payment information or try a different payment method.`,
    );
  }
}
