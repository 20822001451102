import { useEffect } from 'react';

import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { PlaidLinkOnSuccessResponseDto } from 'src/dtos';

export interface OpenPlaidLinkBankAccountProps {
  token: string;
  onSuccess: ({ publicToken, metadata }: PlaidLinkOnSuccessResponseDto) => void;
  onExit: () => void;
}

export const OpenPlaidLinkBankAccount = ({ token, onSuccess, onExit }: OpenPlaidLinkBankAccountProps) => {
  const plaidConfig = {
    onSuccess: (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      onSuccess({ publicToken, metadata });
    },
    onExit: () => {
      onExit();
    },
    token,
  };

  const { open, ready } = usePlaidLink(plaidConfig);
  useEffect(() => {
    open();
  }, [ready]);

  return null;
};
