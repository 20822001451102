import { Col } from 'antd';
import { AccountHolderEmploymentStatusDto } from 'src/dtos';
import { MFormTextField } from 'src/lib';
import { Account } from 'src/models';

import * as Styles from './FinancialInformation.styles';

export interface FinancialInformationEmploymentViewProps {
  account?: Account;
}

export const FinancialInformationEmploymentView = ({ account }: FinancialInformationEmploymentViewProps) => {
  const isEmployed = () =>
    account?.primaryAccountHolder?.financialInformation?.employmentStatus?.value ===
    AccountHolderEmploymentStatusDto.Employed;

  return (
    <>
      <MFormTextField
        label='Employment Status'
        value={account?.primaryAccountHolder?.financialInformation?.employmentStatus?.label}
      />
      {isEmployed() && (
        <>
          <MFormTextField
            label='Employer Name'
            value={account?.primaryAccountHolder?.financialInformation?.employerName}
          />
          <MFormTextField label='Job Title' value={account?.primaryAccountHolder?.financialInformation?.jobTitle} />
          <MFormTextField
            label='Years Employed'
            value={account?.primaryAccountHolder?.financialInformation?.yearsEmployed?.label}
          />
          <Col span={24} className={Styles.title}>
            Employment Address
          </Col>
          <MFormTextField
            label='Address Line 1'
            value={account?.primaryAccountHolder?.financialInformation?.employerAddress?.address1}
          />
          <MFormTextField
            label='Address Line 2 (Opt.)'
            value={account?.primaryAccountHolder?.financialInformation?.employerAddress?.address2}
          />
          <MFormTextField
            label='Country'
            value={account?.primaryAccountHolder?.financialInformation?.employerAddress?.country?.label}
          />
          <MFormTextField
            label='City'
            value={account?.primaryAccountHolder?.financialInformation?.employerAddress?.city}
          />
          <MFormTextField
            label='State'
            value={account?.primaryAccountHolder?.financialInformation?.employerAddress?.state?.label}
          />
          <MFormTextField
            label='Postal Code'
            value={account?.primaryAccountHolder?.financialInformation?.employerAddress?.postalCode}
          />
        </>
      )}
    </>
  );
};
