import { css, cx } from '@emotion/css';

import { BaseStyle, ColorConstant, Font, FontWeight, SpaceSize, Spacing, TextAlign } from '../../../styles';

import { MButtonSize } from './MButton';

const getButtonSize = ({ size }: { size: MButtonSize }) => {
  switch (size) {
    case 'small': {
      return css`
        height: ${SpaceSize.is32} !important;
      `;
    }

    case 'middle': {
      return css`
        height: ${SpaceSize.is48} !important;
      `;
    }

    case 'large': {
      return css`
        height: ${SpaceSize.is60} !important;
      `;
    }

    default: {
      return css`
        height: ${SpaceSize.is48} !important;
      `;
    }
  }
};

const getButtonFont = ({ size }: { size: MButtonSize }) => {
  switch (size) {
    case 'small': {
      return Font.sm;
    }

    case 'middle': {
      return Font.md;
    }

    case 'large': {
      return Font.lg;
    }

    default: {
      return Font.md;
    }
  }
};

const baseStyle = ({ size }: { size: MButtonSize }) => css`
  ${TextAlign.center}
  ${BaseStyle.level}
  ${Spacing.p8}
  width: fit-content !important;
  min-width: 9.375rem;
  border-radius: 4rem !important;
  ${FontWeight.bold}
  ${getButtonSize({ size })}
  ${getButtonFont({ size })}
`;

export const primary = ({ size, danger }: { size: MButtonSize; danger: boolean }) =>
  cx(
    baseStyle({ size }),
    css`
      background-color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
      color: ${ColorConstant.GRAY1} !important;
      border-color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;

      &:focus {
        background-color: ${danger ? ColorConstant.RED8 : ColorConstant.BRAND7} !important;
        color: ${ColorConstant.GRAY1} !important;
        border-color: ${ColorConstant.BRAND7} !important;
      }

      &:hover:not(:focus) {
        background-color: ${danger ? ColorConstant.RED8 : ColorConstant.BRAND7} !important;
        color: ${ColorConstant.GRAY1} !important;
        border-color: ${ColorConstant.BRAND7} !important;
      }

      &:disabled {
        background-color: ${ColorConstant.GRAY6} !important;
        color: ${ColorConstant.GRAY1} !important;
        border-color: ${ColorConstant.GRAY6} !important;
        pointer-events: none;
      }
    `,
  );

export const secondary = ({ size, danger }: { size: MButtonSize; danger: boolean }) =>
  cx(
    baseStyle({ size }),
    css`
      background-color: ${ColorConstant.GRAY1} !important;
      color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
      border-color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;

      &:focus {
        background-color: ${danger ? ColorConstant.RED2 : ColorConstant.BRAND1} !important;
        color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
        border-color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
      }

      &:hover:not(:focus) {
        background-color: ${ColorConstant.BRAND1} !important;
        color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
        border-color:${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
      }

      &:disabled {
        background-color: ${ColorConstant.GRAY3} !important;
        color: ${ColorConstant.GRAY6} !important;
        border-color: ${ColorConstant.GRAY6} !important;
        pointer-events: none;
  `,
  );

export const tertiary = ({ size, danger }: { size: MButtonSize; danger: boolean }) =>
  cx(
    baseStyle({ size }),
    css`
      background: transparent !important;
      color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
      box-shadow: none;

      &:focus {
        background-color: ${danger ? ColorConstant.RED2 : ColorConstant.BRAND1} !important;
        color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
      }

      &:hover:not(:focus) {
        background-color: ${danger ? ColorConstant.RED2 : ColorConstant.BRAND1} !important;
        color: ${danger ? ColorConstant.RED6 : ColorConstant.BRAND6} !important;
      }

      &:disabled {
        background-color: transparent !important;
        color: ${ColorConstant.GRAY6} !important;
        pointer-events: none;
      }
    `,
  );
