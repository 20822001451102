import { css } from '@emotion/css';

import { BorderRadius, ColorConstant, Font, FontWeight, Spacing } from '../../../styles';

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem ${ColorConstant.BRAND6}1A;
  ${Spacing.p24};
  ${Spacing.my24};
  ${BorderRadius.xs};
`;

export const statusLabel = css`
  ${FontWeight.bold};
  ${Font.h2};
  ${Spacing.mt24}
  color: ${ColorConstant.GRAY10};
`;

export const investigationTitle = css`
  ${FontWeight.bold};
  ${Font.lg};
  color: ${ColorConstant.GRAY8};
`;

export const investigationDescription = css`
  ${Font.md};
  color: ${ColorConstant.GRAY7};
`;

// AccountStatus styles
export const tagLabel = css`
  ${FontWeight.bold};
  ${Font.h1};
  ${Spacing.mr12};
  color: ${ColorConstant.GRAY10};
`;

export const description = css`
  color: ${ColorConstant.GRAY7};
`;

export const upload = css`
  .ant-upload-list-item-info {
    ${Spacing.mt0}
  }
`;
