import { Fragment, useEffect, useState } from 'react';

import { Checkbox, Col, Row, Space } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useMediaQuery } from 'react-responsive';
import { v4 as uuid } from 'uuid';

import { ScreenBreakpoint } from '../../../styles';
import { DropdownFilterButton } from '../DropdownFilterButton/DropdownFilterButton';

import * as Styles from './CheckboxFilter.styles';

interface CheckboxGroupOption {
  label: string | JSX.Element;
  value: string;
}

export interface CheckboxGroup {
  title?: string;
  options: CheckboxGroupOption[];
}

interface CheckboxFilterProps {
  label: string | JSX.Element;
  groups: CheckboxGroup[];
  onSelect: (values: CheckboxValueType[]) => void;
  selectedValues?: CheckboxValueType[];
  testId?: string;
}

export const CheckboxFilter = ({ groups, onSelect, selectedValues = [], label, testId }: CheckboxFilterProps) => {
  const [values, setValues] = useState<CheckboxValueType[]>(selectedValues);
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setValues(checkedValues);
    onSelect(checkedValues);
  };

  useEffect(() => {
    setValues(selectedValues);
  }, [selectedValues]);

  const renderOptions = (options: CheckboxGroupOption[]) => (
    <Row data-testid={testId}>
      {options.map((option: CheckboxGroupOption) => (
        <Col key={uuid()} span={24}>
          <Checkbox value={option.value} className={Styles.checkboxWrapper}>
            <span className={Styles.labelWrapper}>{option?.label}</span>
          </Checkbox>
        </Col>
      ))}
    </Row>
  );

  return (
    <DropdownFilterButton isDirty={selectedValues.length > 0} label={label}>
      <Checkbox.Group style={{ width: '100%' }} value={values} onChange={onChange}>
        {isMobile && (
          <Col span={24}>
            <div className={Styles.label}>{label}</div>
          </Col>
        )}
        <Space size={8} direction='vertical'>
          {groups.map((aGroup: CheckboxGroup) => (
            <Fragment key={uuid()}>
              {groups?.length > 1 && <span className={Styles.labelWrapper}>{aGroup.title}</span>}
              {renderOptions(aGroup.options)}
            </Fragment>
          ))}
        </Space>
      </Checkbox.Group>
    </DropdownFilterButton>
  );
};
