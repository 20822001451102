import { Fragment, useCallback, useEffect, useState } from 'react';

import { type FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AccountHolderMaritalStatusDto } from 'src/dtos';
import { useAccountSelector } from 'src/hooks';
import { MAlert, MRadioGroup, MTooltip } from 'src/lib';
import { OfferDetails } from 'src/models';
import { isRegDTypeCOffer } from 'src/utils';

import Spinner from '../../../../../lib/Miscellaneous/Spinner';
import * as Styles from '../NewOfferOrders.styles';

import { NewOfferOrderForm, InvestorType } from './NewOfferOrder';

export const ACCREDITED_INVESTOR_TOTAL_NET_WORTH_MIN = 1000000;
export const SINGLE_ACCREDITED_INVESTOR_ANNUAL_INCOME_MIN = 200000;
export const MARRIED_ACCREDITED_INVESTOR_ANNUAL_INCOME_MIN = 300000;

export interface AccrediationProps {
  form: FormikProps<NewOfferOrderForm>;
  isAccreditationValid: (isValid: boolean) => void;
}

export const Accreditation = ({ form, isAccreditationValid }: AccrediationProps): JSX.Element | null => {
  const navigate = useNavigate();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const [isAssetsRangeExceeded, setIsAssetsRangeExceeded] = useState<boolean>(false);
  const [isLiquidityNetWorthExceeded, setIsLiquidityNetWorthExceeded] = useState<boolean>(false);
  const [isAccreditedInvestor, setIsAccreditedInvestor] = useState<boolean | null>(null);
  const [isAccreditedInvestorWhenNonAccreditedSelected, setIsAccreditedInvestorWhenNonAccreditedSelected] =
    useState<boolean>(false);
  const [isNonAccreditedInvestorWhenAccreditedSelected, setIsNonAccreditedInvestorWhenAccreditedSelected] =
    useState<boolean>(false);
  const [isNonAccreditedInvestmentDenied, setIsNonAccreditedInvestmentDenied] = useState<boolean>(false);
  const [isPreselectionLoading, setIsPreselectionLoading] = useState<boolean>(true);
  const { account } = useAccountSelector();

  const findIsAccreditedInvestor = () => {
    if (
      account?.primaryAccountHolder?.financialInformation?.totalNetWorth &&
      account?.primaryAccountHolder?.financialInformation?.annualIncome
    ) {
      const totalNetWorthMax = account.primaryAccountHolder.financialInformation.totalNetWorth.range.max;
      const annualIncomeMax = account.primaryAccountHolder.financialInformation.annualIncome.range.max;
      const maritalStatus = account?.primaryAccountHolder?.maritalStatus;

      if (totalNetWorthMax >= ACCREDITED_INVESTOR_TOTAL_NET_WORTH_MIN) {
        setIsAccreditedInvestor(true);

        return;
      }

      if (
        maritalStatus?.value === AccountHolderMaritalStatusDto.Single &&
        annualIncomeMax >= SINGLE_ACCREDITED_INVESTOR_ANNUAL_INCOME_MIN
      ) {
        setIsAccreditedInvestor(true);

        return;
      }

      if (
        maritalStatus?.value === AccountHolderMaritalStatusDto.Married &&
        annualIncomeMax >= MARRIED_ACCREDITED_INVESTOR_ANNUAL_INCOME_MIN
      ) {
        setIsAccreditedInvestor(true);

        return;
      }

      setIsAccreditedInvestor(false);
    }
  };

  const validateAccreditationStatement = (anInvestorType: InvestorType) => {
    if (anInvestorType === InvestorType.Accredited && !isAccreditedInvestor) {
      setIsNonAccreditedInvestorWhenAccreditedSelected(true);
    } else {
      setIsNonAccreditedInvestorWhenAccreditedSelected(false);
    }

    if (anInvestorType === InvestorType.NonAccredited && isAccreditedInvestor) {
      setIsAccreditedInvestorWhenNonAccreditedSelected(true);
    } else {
      setIsAccreditedInvestorWhenNonAccreditedSelected(false);
    }
  };

  const findIsAssetsRangeExceeded = (totalInvestment: number) => {
    if (
      account?.primaryAccountHolder?.financialInformation?.annualIncome &&
      account?.primaryAccountHolder?.financialInformation?.totalNetWorth &&
      form.values.investorType === InvestorType.NonAccredited
    ) {
      const annualIncomeMax = account.primaryAccountHolder.financialInformation.annualIncome.range.max * 0.1;
      const totalNetWorthMax = account.primaryAccountHolder.financialInformation.totalNetWorth.range.max * 0.1;
      setIsAssetsRangeExceeded(totalInvestment > annualIncomeMax && totalInvestment > totalNetWorthMax);
    }
  };

  const findIsLiquidityNetWorthExceeded = (totalInvestment: number) => {
    if (account?.primaryAccountHolder?.financialInformation?.liquidNetWorth) {
      const liquidNetWorthMax = account.primaryAccountHolder.financialInformation.liquidNetWorth.range.max;
      setIsLiquidityNetWorthExceeded(totalInvestment > liquidNetWorthMax);
    }
  };

  const findIsNonAccreditedInvestmentDenied = () => {
    if (!isRegDTypeCOffer(offerDetails)) {
      setIsNonAccreditedInvestmentDenied(false);

      return;
    }

    if (isAccreditedInvestor) {
      setIsNonAccreditedInvestmentDenied(false);
    } else {
      setIsNonAccreditedInvestmentDenied(true);
    }
  };

  const setIsAccreditedInvestorValidationErrorIfFound = async () => {
    if (
      isAssetsRangeExceeded ||
      isLiquidityNetWorthExceeded ||
      isAccreditedInvestorWhenNonAccreditedSelected ||
      isNonAccreditedInvestorWhenAccreditedSelected ||
      isNonAccreditedInvestmentDenied
    ) {
      isAccreditationValid(false);

      return;
    }

    isAccreditationValid(true);
  };

  const preSelectAccreditation = () => {
    if (isAccreditedInvestor !== null) {
      form.values.investorType = isAccreditedInvestor ? InvestorType.Accredited : InvestorType.NonAccredited;
      setIsPreselectionLoading(false);
    }
  };

  const getNonAccreditedButtonLabel = () => {
    if (isRegDTypeCOffer(offerDetails)) {
      return 'I am a non-accredited investor';
    }

    return (
      'I am a non-accredited investor and aggregate purchase price for the Securities I am purchasing in ' +
      'the Offering does not exceed 10% of my net worth or annual income, whichever is greater.'
    );
  };

  const renderAssetsRangeExceeded = () => {
    if (form.values.investorType === InvestorType.NonAccredited && isAssetsRangeExceeded) {
      return (
        <MAlert
          type='error'
          description={
            <p>
              The requested investment amount exceeds your total net worth and/or 10% of your annual income. Please
              reduce the amount of your order or you must update your &quot;Financial Information&quot; by clicking{' '}
              <a onClick={() => (account?.id ? navigate(`/accounts/${account.id}/financial-information`) : null)}>
                here{' '}
              </a>
              before placing an order.
            </p>
          }
        />
      );
    }

    return null;
  };

  const renderLiquidityNetWorthExceeded = () => {
    if (isLiquidityNetWorthExceeded) {
      return (
        <MAlert
          type='error'
          description={
            <p>
              The requested investment amount exceeds your liquid net worth. Please reduce the amount of your order or
              you must update your &quot;Financial Information&quot; by clicking{' '}
              <a onClick={() => (account?.id ? navigate(`/accounts/${account.id}/financial-information`) : null)}>
                here{' '}
              </a>
              before placing an order.
            </p>
          }
        />
      );
    }
  };

  const renderNonAccreditedValidationMessageIfFound = () => {
    if (isNonAccreditedInvestmentDenied && form.values.investorType === InvestorType.NonAccredited) {
      return (
        <p>
          Reg D: 506(c) offer type requires all investors to be accredited. Based on your &quot;Financial
          Information&quot; you appear to NOT qualify as an accredited investor. If this is inaccurate then you must
          update your &quot;Financial Information&quot; by clicking{' '}
          <a onClick={() => (account?.id ? navigate(`/accounts/${account.id}/financial-information`) : null)}>here </a>.
        </p>
      );
    }

    if (isAccreditedInvestorWhenNonAccreditedSelected) {
      return isRegDTypeCOffer(offerDetails) ? (
        <p>
          Reg D: 506(c) offer type requires all investors to be accredited. Based on your &quot;Financial
          Information&quot; you appear to qualify as an accredited investor. If this is inaccurate then you must update
          your &quot;Financial Information&quot; by clicking{' '}
          <a onClick={() => (account?.id ? navigate(`/accounts/${account.id}/financial-information`) : null)}>here </a>.
        </p>
      ) : (
        <p>
          Based on your &quot;Financial Information&quot; you appear to qualify as an accredited investor. If so, please
          click on the &quot;I am an accredited investor &quot; box or if this is inaccurate then you must update your
          &quot;Financial Information&quot; by clicking{' '}
          <a onClick={() => (account?.id ? navigate(`/accounts/${account.id}/financial-information`) : null)}>here </a>
          before placing an order.
        </p>
      );
    }
  };

  const renderAccreditedValidationMessageIfFound = () => {
    if (isNonAccreditedInvestorWhenAccreditedSelected) {
      return isRegDTypeCOffer(offerDetails) ? (
        <p>
          Reg D: 506(c) offer type requires all investors to be accredited. Based on your &quot;Financial
          Information&quot; you appear to NOT qualify as an accredited investor. If this is inaccurate then you must
          update your &quot;Financial Information&quot; by clicking{' '}
          <a onClick={() => (account?.id ? navigate(`/accounts/${account.id}/financial-information`) : null)}>here </a>.
        </p>
      ) : (
        <p>
          Based on your &quot;Financial Information&quot; you appear to NOT qualify as an accredited investor. If so,
          please click on the &quot;I am an non-accredited investor &quot; box or if this is inaccurate then you must
          update your &quot;Financial Information&quot; by clicking{' '}
          <a onClick={() => (account?.id ? navigate(`/accounts/${account.id}/financial-information`) : null)}>here </a>
          before placing an order.
        </p>
      );
    }
  };

  const getGroupOptionList = useCallback(() => {
    return [
      {
        label: getNonAccreditedButtonLabel(),
        value: InvestorType.NonAccredited,
        description: (
          <MTooltip
            type='modal'
            title='What is a non-accredited investor?'
            label='What is a non-accredited investor?'
            icon='info'>
            <div>
              An investor who does not meet the net worth requirements for an accredited investor under the Securities &
              Exchange Commission&apos;s Reg D. A non-accredited investor is one who has a net worth of less than $1
              million (including spouse) and who earned less than $200,000 annually ($300,000 with spouse) in the
              current calendar year.
            </div>
          </MTooltip>
        ),
        alert: renderNonAccreditedValidationMessageIfFound(),
      },
      {
        label: 'I am an accredited investor',
        value: InvestorType.Accredited,
        description: (
          <MTooltip
            type='modal'
            title='What is an accredited investor?'
            label='What is an accredited investor?'
            icon='info'>
            <div>
              To be considered an accredited investor, one must have a net worth of at least $1 million excluding the
              value of one&apos;s primary residence, or have income of at least $200,000 each year for the last two
              years (or $300,000 combined income if married) and have the expectation to make the same amount in the
              current calendar year.
            </div>
          </MTooltip>
        ),
        alert: renderAccreditedValidationMessageIfFound(),
      },
    ];
  }, [isNonAccreditedInvestmentDenied, isNonAccreditedInvestorWhenAccreditedSelected, form.values.investorType]);

  useEffect(() => {
    const quantity = Number(form.values.quantity);
    const totalInvestment = offerDetails?.pricePerShare * quantity;
    findIsAssetsRangeExceeded(totalInvestment);
    findIsLiquidityNetWorthExceeded(totalInvestment);
  }, [offerDetails, form, account?.primaryAccountHolder?.financialInformation]);

  useEffect(() => {
    findIsAccreditedInvestor();
  }, [account?.primaryAccountHolder?.financialInformation, account?.primaryAccountHolder?.maritalStatus]);

  useEffect(() => {
    preSelectAccreditation();
  }, [isAccreditedInvestor]);

  useEffect(() => {
    findIsNonAccreditedInvestmentDenied();
  }, [isAccreditedInvestor, offerDetails]);

  useEffect(() => {
    setIsAccreditedInvestorValidationErrorIfFound();
  }, [
    isAssetsRangeExceeded,
    isLiquidityNetWorthExceeded,
    isAccreditedInvestorWhenNonAccreditedSelected,
    isNonAccreditedInvestorWhenAccreditedSelected,
    isNonAccreditedInvestmentDenied,
  ]);

  if (isPreselectionLoading) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {renderAssetsRangeExceeded()}
      {renderLiquidityNetWorthExceeded()}
      <div className={Styles.nonAccreditContainer}>
        <MRadioGroup
          defaultValue={isAccreditedInvestor ? InvestorType.Accredited : InvestorType.NonAccredited}
          value={form.values.investorType}
          align='vertical'
          options={getGroupOptionList()}
          onChange={value => {
            form.setFieldValue('investorType', value);
            validateAccreditationStatement(value as InvestorType);
          }}
          testId={'investor'}
        />
      </div>
    </Fragment>
  );
};
