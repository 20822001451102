import { ColumnsType } from 'antd/lib/table';
import { LinkText } from 'src/lib';

import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { OfferOrder } from '../../../../../../models/offer-orders.model';

const NonTradedHoldingsColumns: ColumnsType<OfferOrder> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: value => <LinkText value={value} />,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Purchase Price',
    dataIndex: 'purchasePrice',
    render: (purchasePrice: number) => <CurrencyField value={purchasePrice} />,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    render: (value: number) => <CurrencyField value={value} />,
  },
  {
    title: 'Total Cost',
    dataIndex: 'totalCost',
    render: (totalCost: number) => <CurrencyField value={totalCost} />,
  },
  {
    title: 'Total Value',
    dataIndex: 'totalValue',
    render: (totalValue: number) => <CurrencyField value={totalValue} />,
  },
];

export default NonTradedHoldingsColumns;
