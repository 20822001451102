import type { PropsWithChildren } from 'react';

import { Col, Row } from 'antd';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { Banner } from 'src/lib';

import { Images } from '../../../styles';
import Card from '../../Miscellaneous/Card';

import * as Styles from './CardLayout.styles';

interface CardLayoutProps {
  banner?: {
    type: 'info' | 'warning' | 'error';
    message: string | JSX.Element;
    size: 'small' | 'medium' | 'large';
  };
  title?: string;
  subtitle?: string | JSX.Element;
  subtitleClassName?: string;
  activeTab?: string;
  tabs?: string[];
  footer?: JSX.Element;
  cardTitle?: string;
  cardSubtitle?: string | JSX.Element;
  cardClassName?: string;
  logoUrl?: string;
  logoPath?: string;
  rightSlot?: JSX.Element;
}

export const CardLayout = ({
  children,
  activeTab,
  banner,
  title,
  subtitle,
  subtitleClassName = '',
  tabs = [],
  footer,
  cardTitle,
  cardSubtitle,
  cardClassName = '',
  logoPath,
  logoUrl,
  rightSlot,
}: PropsWithChildren<CardLayoutProps>) => {
  const renderBannerIfFound = () => {
    return banner ? <Banner message={banner.message} size={banner.size} type={banner.type} /> : null;
  };

  const renderTabsIfFound = () => {
    const tabElements = tabs.map((aTab: string) => {
      return (
        <span className={activeTab === aTab ? Styles.activeTab : Styles.tab} key={aTab}>
          {aTab}
        </span>
      );
    });

    if (!_.isEmpty(tabElements)) {
      return <div className={Styles.tabs}> {tabElements}</div>;
    }

    return null;
  };

  const renderTitle = (): JSX.Element | null =>
    title ? (
      <Row justify='center'>
        <p className={Styles.title} data-testid='login-title-testId'>
          {title ?? null}
        </p>
      </Row>
    ) : null;

  const renderSubtitle = (): JSX.Element | null =>
    subtitle ? (
      <div className={Styles.subtitle.concat(` ${subtitleClassName}`).trim()} data-testid='login-subtitle-testId'>
        {subtitle}
      </div>
    ) : null;

  const renderFooterIfFound = (): JSX.Element | null => (footer ? <div className={Styles.footer}>{footer}</div> : null);

  const renderCardTitleIfFound = (): JSX.Element | null =>
    cardTitle ? <div className={Styles.cardTitle}>{cardTitle}</div> : null;

  const renderCardSubtitleIfFound = (): JSX.Element | null =>
    cardSubtitle ? <div className={Styles.cardSubtitle}>{cardSubtitle}</div> : null;

  return (
    <div>
      <div>{renderBannerIfFound()}</div>
      <div className={Styles.container}>
        <div className={Styles.main}>
          <div className={Styles.logoContainer}>
            <Link to={logoPath ?? '/'}>
              <img
                src={logoUrl ?? Images.MyIPOLogo}
                alt='My IPO'
                className={Styles.logo}
                data-testid='card-layout-page-img-logo-testId'
              />
            </Link>
          </div>
          {renderTitle()}
          {renderSubtitle()}
          {renderTabsIfFound()}
          <Row className={Styles.cardContainer} justify='center' align='middle'>
            <Card className={Styles.card.concat(` ${cardClassName}`).trim()}>
              <div className={Styles.cardContentContainer}>
                {renderCardTitleIfFound()}
                {renderCardSubtitleIfFound()}
                {children}
              </div>
            </Card>
            <Col className={Styles.rightSlot}>{rightSlot}</Col>
          </Row>
          {renderFooterIfFound()}
        </div>
      </div>
    </div>
  );
};
