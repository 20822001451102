import { css } from '@emotion/css';

export const card = css`
  justify-content: 'center' !important;
  border: none;
  -webkit-box-shadow: 0rem 0.25rem 1rem rgb(2 85 163 / 15%);
  box-shadow: 0rem 0.25rem 1rem rgb(2 85 163 / 15%);
  margin: auto;
  display: table;
  margin: 0 auto;
  border-radius: 1rem;

  @media (max-width: 489px) {
    display: undefined;
  }
`;
