import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderFinancialInformationAssets,
  doClearPatchAccountHolderFinancialInformationAssets,
  doCreateAccountHolderFinancialInformationAssets,
  doPatchAccountHolderFinancialInformationAssets,
} from 'src/actions';
import {
  AccountHolderAnnualIncomeDto,
  AccountHolderLiquidNetWorthDto,
  AccountHolderTaxBracketDto,
  AccountHolderTotalNetWorthDto,
} from 'src/dtos';
import { MFormSaveButton, MFormSelect } from 'src/lib';
import { Account } from 'src/models';

import {
  ANNUAL_INCOME_OPTION_LIST,
  TOTAL_NET_WORTH_OPTION_LIST,
  LIQUID_NET_WORTH_OPTION_LIST,
  TAX_BRACKET_OPTION_LIST,
} from './optionList.constants';
import {
  ANNUAL_INCOME_TOOLTIP_TEXT,
  LIQUID_NET_WORTH_TOOLTIP_TEXT,
  TOTAL_NET_WORTH_TOOLTIP_TEXT,
} from './tooltips.constants';
import { upsertAccountHolderFinancialInformationAssetsValidation } from './validations';

export interface FinancialInformationAssetsFormValues {
  annualIncome?: AccountHolderAnnualIncomeDto;
  totalNetWorth?: AccountHolderTotalNetWorthDto;
  liquidNetWorth?: AccountHolderLiquidNetWorthDto;
  taxBracket?: AccountHolderTaxBracketDto;
}

export interface FinancialInformationAssetsFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const FinancialInformationAssetsForm = ({ account, onCancel, onSave }: FinancialInformationAssetsFormProps) => {
  const dispatch = useDispatch();

  const isCreateFinancialInformationAssetsLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createFinancialInformationAssets.__requested),
  );
  const succeededCreateFinancialInformationAssets = useSelector((state: any) =>
    Boolean(state.accountHolders.createFinancialInformationAssets.__succeeded),
  );

  const isPatchFinancialInformationAssetsLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchFinancialInformationAssets.__requested),
  );
  const succeededPatchFinancialInformationAssets = useSelector((state: any) =>
    Boolean(state.accountHolders.patchFinancialInformationAssets.__succeeded),
  );

  const [initialValues, setInitialValues] = useState<FinancialInformationAssetsFormValues>({});
  const [shouldPatch, setShouldPatch] = useState<boolean>(false);

  const isFinancialInformationAssetsAlreadySaved = () =>
    !isEmpty(account?.primaryAccountHolder?.financialInformation?.annualIncome);

  const _onCancel = (form: FormikProps<FinancialInformationAssetsFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<FinancialInformationAssetsFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    setInitialValues({
      annualIncome: account?.primaryAccountHolder?.financialInformation?.annualIncome?.value,
      liquidNetWorth: account?.primaryAccountHolder?.financialInformation?.liquidNetWorth?.value,
      totalNetWorth: account?.primaryAccountHolder?.financialInformation?.totalNetWorth?.value,
      taxBracket: account?.primaryAccountHolder?.financialInformation?.taxBracket?.value,
    });
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (isFinancialInformationAssetsAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (succeededCreateFinancialInformationAssets || succeededPatchFinancialInformationAssets) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreateFinancialInformationAssets, succeededPatchFinancialInformationAssets]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderFinancialInformationAssets());
      dispatch(doClearPatchAccountHolderFinancialInformationAssets());
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={values => {
        const cValues = upsertAccountHolderFinancialInformationAssetsValidation.cast(values);

        if (shouldPatch) {
          // TODO: sanitize the dto values
          dispatch(doPatchAccountHolderFinancialInformationAssets(cValues));

          return;
        }

        dispatch(doCreateAccountHolderFinancialInformationAssets(cValues));
      }}
      initialValues={initialValues}
      validationSchema={upsertAccountHolderFinancialInformationAssetsValidation}>
      {form => {
        return (
          <>
            <Col span={24}>
              <MFormSelect
                testId={'account-annual-income'}
                label='Annual Income'
                placeholder='Select'
                defaultValue={account?.primaryAccountHolder?.financialInformation?.annualIncome?.value}
                options={ANNUAL_INCOME_OPTION_LIST}
                tooltip={{
                  type: 'popover',
                  content: ANNUAL_INCOME_TOOLTIP_TEXT,
                  icon: 'info',
                }}
                error={form.errors.annualIncome}
                onChange={value => {
                  form.setFieldValue('annualIncome', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-total-net-worth'}
                label='Total Net Worth'
                options={TOTAL_NET_WORTH_OPTION_LIST}
                defaultValue={account?.primaryAccountHolder?.financialInformation?.totalNetWorth?.value}
                tooltip={{
                  type: 'popover',
                  content: TOTAL_NET_WORTH_TOOLTIP_TEXT,
                  icon: 'info',
                }}
                error={form.errors.totalNetWorth}
                onChange={value => {
                  form.setFieldValue('totalNetWorth', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-liquid-net-worth'}
                label='Liquid Net Worth'
                options={LIQUID_NET_WORTH_OPTION_LIST}
                defaultValue={account?.primaryAccountHolder?.financialInformation?.liquidNetWorth?.value}
                tooltip={{
                  type: 'popover',
                  content: LIQUID_NET_WORTH_TOOLTIP_TEXT,
                  icon: 'info',
                }}
                error={form.errors.liquidNetWorth}
                onChange={value => {
                  form.setFieldValue('liquidNetWorth', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSelect
                testId={'account-tax-bracket'}
                label='Tax Bracket'
                options={TAX_BRACKET_OPTION_LIST}
                defaultValue={account?.primaryAccountHolder?.financialInformation?.taxBracket?.value}
                error={form.errors.taxBracket}
                onChange={value => {
                  form.setFieldValue('taxBracket', value);
                }}
              />
            </Col>
            <Col span={24}>
              <MFormSaveButton<FinancialInformationAssetsFormValues>
                loading={isCreateFinancialInformationAssetsLoading || isPatchFinancialInformationAssetsLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={'income-and-assets'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
