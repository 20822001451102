import { PropsWithChildren } from 'react';

import { Radio } from 'antd';

import { MCheckbox } from '../MCheckbox/MCheckbox';

import * as Styles from './MCheckboxGroup.styles';

const { Group } = Radio;

export interface MCheckboxGroupOption {
  label: string;
  value: string | number;
}

export interface MCheckboxGroupProps {
  value?: (string | number)[];
  defaultValue?: (string | number)[];
  options: MCheckboxGroupOption[];
  onChange?: (values: (string | number)[]) => void;
  disabled?: boolean;
  error?: string;
  align?: 'horizontal' | 'vertical';
}

export const MCheckboxGroup = ({
  value,
  defaultValue,
  options,
  onChange,
  align = 'horizontal',
}: PropsWithChildren<MCheckboxGroupProps>) => {
  const _onChange = (event: { checked: boolean; value?: string | number }) => {
    const isAlready = value?.find(aValue => aValue === event.value);

    if (!onChange) {
      return;
    }

    if (event.checked) {
      if (isAlready) {
        return;
      }

      if (value && event.value) {
        onChange([...value, event.value]);

        return;
      }
    }

    if (value) {
      if (!isAlready) {
        return;
      }
      onChange(value.filter(aValue => aValue !== event.value));
    }
  };

  return (
    <Group className={Styles.group(align)} defaultValue={defaultValue} value={value}>
      {options.map(anOption => (
        <MCheckbox
          key={anOption.label}
          value={anOption.value}
          checked={Boolean(value?.find(aValue => aValue === anOption.value))}
          onChange={_onChange}
          className={Styles.checkbox}>
          {anOption.label}
        </MCheckbox>
      ))}
    </Group>
  );
};
