import { AnyAction } from 'redux';

import { OrderConstant } from '../constants/orders.constants';
import { GetOrdersQueryParams } from '../dtos/orders.dtos';
import { FromTradingBlockServerEvent } from '../events/FromTradingBlockServerEvent';

import { createAction, State, Type } from './utils';

export const getOrdersRequested = ({
  accountId,
  fetchAll = false,
  queryParams,
}: {
  accountId?: number;
  fetchAll?: boolean;
  queryParams?: GetOrdersQueryParams;
}): AnyAction =>
  createAction(State.actionRequested(Type.GET_ORDERS), {
    accountId,
    fetchAll,
    queryParams: queryParams ?? {
      take: OrderConstant.DEFAULT_TAKE_QUERY_PARAM,
      skip: OrderConstant.DEFAULT_SKIP_QUERY_PARAM,
    },
  });

export const getOrdersSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_ORDERS), payload);

export const getOrdersByIdRequested = (id: string, accountId: string): AnyAction =>
  createAction(State.actionRequested(Type.GET_ORDERS_ID), { id, accountId });

export const getOrdersByIdSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_ORDERS_ID), payload);

export const getOrdersPositionsRequested = (accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.GET_ORDERS_POSITIONS), { accountId });

export const refreshOrderPositionList = (accountId: number): AnyAction =>
  createAction(Type.REFRESH_ORDER_POSITIONS, { accountId });

export const getOrdersPositionsSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_ORDERS_POSITIONS), payload);

export const clearOrdersReview = (): AnyAction => createAction(State.actionCleared(Type.CREATE_ORDERS_REVIEW));

export const createOrdersReview = (payload: any): AnyAction =>
  createAction(State.actionSucceeded(Type.CREATE_ORDERS_REVIEW), payload);

export const createOrdersRequested = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.CREATE_ORDERS), payload);

export const createOrdersSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.CREATE_ORDERS), payload);

export const cancelOrdersRequested = (payload: any): AnyAction =>
  createAction(State.actionRequested(Type.UPDATE_ORDERS), payload);

export const cancelOrdersSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.UPDATE_ORDERS), payload);

export const updateOrderFromEvent = (payload: FromTradingBlockServerEvent.OrderUpdateEventPayload): AnyAction =>
  createAction(Type.UPDATE_ORDER_FROM_EVENT, payload);
