import { call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import {
  getOrdersPositionsSucceeded,
  getOrdersSucceeded,
  createOrdersSucceeded,
  getOrdersByIdSucceeded,
  toastMessagesAdd,
} from '../actions';
import { State, Type } from '../actions/utils';
import { OrderConstant } from '../constants/orders.constants';
import { getClientApi } from '../data-communication/ClientApi';
import { OrdersApi } from '../data-communication/OrdersApi';
import { PrivateQuotesApi } from '../data-communication/PrivateQuotesApi';
import { QuoteMediaApi } from '../data-communication/QuoteMediaApi';
import { CreateOrderDto, OrderDto } from '../dtos/orders.dtos';
import { GetPrivateQuotesSnapResponseDto } from '../dtos/privateQuotes.dtos';
import {
  mapGetOrdersListResponseDtoToModel,
  mapOrderDtoToModel,
  mapGetOrdersPositionsDtoToModel,
} from '../mappers/orders.mappers';
import { CreateOrderView, Order } from '../models/orders.models';
import { TReduxAction, SeverityEnum } from '../typings/commonTypes';
import { Logger } from '../utils/Logger';

import { safeSaga } from './utils';

const findRemainingPages = ({ take, skip, total }: { take: number; skip: number; total: number }): number[] => {
  const result: number[] = [];
  const pages = Math.ceil(total / take);
  const nextPage = (skip + take) / take;

  for (let counter = nextPage; counter < pages; counter += 1) {
    result.push(counter);
  }

  return result;
};

const getRemainingOrderList = async ({
  pages,
  accountId,
  authToken,
  take,
}: {
  pages: number[];
  accountId: number;
  authToken: string;
  take: number;
}): Promise<OrderDto[]> => {
  try {
    const responses = await Promise.all(
      pages.map(aPage =>
        getClientApi().orders.getMany({
          params: { accountId },
          queryParams: {
            take,
            skip: aPage * take,
          },
          authToken,
        }),
      ),
    );

    return responses.reduce((all: OrderDto[], aResponse) => all.concat(aResponse.data), []);
  } catch (error) {
    Logger.error(`Error getting remaining order list:`, error);
    Logger.info('Fallback: use empty list');

    return [];
  }
};

export function* getOrdersListRequested(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId, fetchAll, queryParams } = action?.payload;

  const { take = OrderConstant.DEFAULT_TAKE_QUERY_PARAM, skip = OrderConstant.DEFAULT_SKIP_QUERY_PARAM } = queryParams;
  let { data, total }: SagaReturnType<OrdersApi['getMany']> = yield call(getClientApi().orders.getMany, {
    params: { accountId },
    authToken,
    queryParams: {
      ...queryParams,
      take,
      skip,
    },
  });

  if (fetchAll) {
    const remainingOrders: SagaReturnType<typeof getRemainingOrderList> = yield call(getRemainingOrderList, {
      pages: findRemainingPages({ total, take, skip }),
      authToken,
      accountId,
      take,
    });
    data = data.concat(remainingOrders);
  }

  const symbols: string[] = data.reduce((all: string[], current) => {
    if (current.UnderlyingSymbol) {
      all.push(current.UnderlyingSymbol);
    }

    return all;
  }, []);
  let quotes: SagaReturnType<QuoteMediaApi['snap']> = { quotedata: [] };

  try {
    quotes = yield call(getClientApi().quoteMedia.snap, { symbols, authToken });
  } catch (error) {
    Logger.error(`Error getting quote media snap list:`, error);
    Logger.info('Fallback: use empty quote media snap list');
  }
  const orders: Order[] = mapGetOrdersListResponseDtoToModel(data, quotes.quotedata);
  yield put(getOrdersSucceeded({ data: orders, total }));
}

export function* getOrdersByIdRequested(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { id, accountId } = action?.payload;
  const order: SagaReturnType<OrdersApi['getOne']> = yield call(getClientApi().orders.getOne, {
    params: { accountId, id },
    authToken,
  });

  let quotes: SagaReturnType<QuoteMediaApi['snap']> = { quotedata: [] };

  try {
    if (order.UnderlyingSymbol) {
      quotes = yield call(getClientApi().quoteMedia.snap, { symbols: [order.UnderlyingSymbol], authToken });
    }
  } catch (error) {
    Logger.error(`Error getting quote media snap list:`, error);
    Logger.info('Fallback: use empty quote snap list');
  }
  const newOrder: Order = mapOrderDtoToModel(order, quotes.quotedata[0]);
  yield put(getOrdersByIdSucceeded(newOrder));
}

export function* getOrdersPositionsRequested(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { accountId } = action?.payload;
  const { data, total }: SagaReturnType<OrdersApi['positions']> = yield call(getClientApi().orders.positions, {
    params: { accountId },
    authToken,
  });
  const privateQuotesResponse: SagaReturnType<PrivateQuotesApi['getMany']> = yield call(
    getClientApi().privateQuotes.getMany,
    authToken,
  );

  let privateQuotesSnap: GetPrivateQuotesSnapResponseDto = { data: [], total: 0 };

  try {
    const symbols = privateQuotesResponse.data.map(aPrivateQuoteDto => aPrivateQuoteDto.symbol);
    privateQuotesSnap = yield call(getClientApi().privateQuotes.snap, symbols, authToken);
  } catch (error) {
    Logger.error(`Error getting private quote snap list:`, error);
    Logger.info('Fallback: use empty private quote snap list');
  }

  const orderPositions = mapGetOrdersPositionsDtoToModel(data, privateQuotesResponse.data, privateQuotesSnap.data);
  yield put(getOrdersPositionsSucceeded({ data: orderPositions, total }));
}

export function* createOrdersRequested(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { order, accountId }: { order: CreateOrderView; accountId: string } = action?.payload;
  const dto: CreateOrderDto = {
    duration: order.orderDuration,
    orderType: order.orderType,
    action: order.action,
    price: order.limitPrice === '' ? undefined : Number(order.limitPrice),
    stop: order.stopPrice === '' ? undefined : Number(order.stopPrice),
    quantity: Number(order.shares),
    symbol: order.symbol,
  };
  const newOrder: SagaReturnType<OrdersApi['create']> = yield call(getClientApi().orders.create, {
    params: { accountId: Number(accountId) },
    dto,
    authToken,
  });

  yield put(createOrdersSucceeded(newOrder));

  const orderList: Order[] | undefined = yield select(state => state.orders.getOrders?.data?.data);
  const total: number | undefined = yield select(state => state.orders.getOrders?.data?.total);

  if (orderList && total !== undefined) {
    let quotes: SagaReturnType<QuoteMediaApi['snap']> = { quotedata: [] };

    try {
      if (newOrder.UnderlyingSymbol) {
        quotes = yield call(getClientApi().quoteMedia.snap, { symbols: [newOrder.UnderlyingSymbol], authToken });
      }
    } catch (error) {
      Logger.error(`Error getting quote media snap list:`, error);
      Logger.info('Fallback: use empty quote snap list');
    }
    const newOrderModel = mapOrderDtoToModel(newOrder, quotes.quotedata[0]);
    yield put(getOrdersSucceeded({ data: [...orderList, newOrderModel], total: total + 1 }));
  }
}

export function* cancelOrdersRequested(action: TReduxAction) {
  const { authToken } = yield select(state => state.auth.data);
  const { id, accountId } = action?.payload;
  yield call(getClientApi().orders.cancel, { params: { accountId, id }, authToken });
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Order was successfully cancelled.',
    }),
  );
}

/**
 * Orders sagas
 */
export default function* orderSagas() {
  yield takeEvery(State.actionRequested(Type.GET_ORDERS), safeSaga(getOrdersListRequested, Type.GET_ORDERS));
  yield takeEvery(State.actionRequested(Type.GET_ORDERS_ID), safeSaga(getOrdersByIdRequested, Type.GET_ORDERS_ID));
  yield takeEvery(
    State.actionRequested(Type.GET_ORDERS_POSITIONS),
    safeSaga(getOrdersPositionsRequested, Type.GET_ORDERS_POSITIONS),
  );
  yield takeEvery(State.actionRequested(Type.CREATE_ORDERS), safeSaga(createOrdersRequested, Type.CREATE_ORDERS));
  yield takeEvery(State.actionRequested(Type.UPDATE_ORDERS), safeSaga(cancelOrdersRequested, Type.UPDATE_ORDERS));
  yield takeEvery(Type.REFRESH_ORDER_POSITIONS, safeSaga(getOrdersPositionsRequested, Type.REFRESH_ORDER_POSITIONS));
}
