import { css } from '@emotion/css';

export const SourceSansPro: string = 'Source Sans Pro';

export const FontWeight = {
  light: css({
    fontWeight: 300,
  }),
  normal: css({
    fontWeight: 400,
  }),
  semibold: css({
    fontWeight: 600,
  }),
  bold: css({
    fontWeight: 700,
  }),
};

export const FontFamily = {
  sourceSansPro: css({
    fontFamily: 'Source Sans Pro, sans-serif !important',
  }),
};

const FONT_SIZE_BASE_UNIT = 1;
const LINE_HEIGHT_BASE_UNIT = 1;
export const Font = {
  xxs: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 0.6875}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 0.935}rem !important`,
  }),
  xs: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 0.75}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 1.0625}rem !important`,
  }),
  sm: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 0.875}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 1.25}rem !important`,
  }),
  md: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 1}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 1.375}rem !important`,
  }),
  lg: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 1.125}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 1.562}rem !important`,
  }),
  h1: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 1.25}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 1.75}rem !important`,
  }),
  h2: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 1.5}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 2.125}rem !important`,
  }),
  h3: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 1.75}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 2.437}rem !important`,
  }),
  h4: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 2}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 2.812}rem !important`,
  }),
  h5: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 2.25}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 3.125}rem !important`,
  }),
  h6: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 2.625}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 3.687}rem !important`,
  }),
  h7: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 3}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 4.187}rem !important`,
  }),
  h8: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 3.375}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 4.75}rem !important`,
  }),
  h9: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 3.75}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 5.25}rem !important`,
  }),
  h10: css({
    fontSize: `${FONT_SIZE_BASE_UNIT * 4.25}rem !important`,
    lineHeight: `${LINE_HEIGHT_BASE_UNIT * 5.93}rem !important`,
  }),
};

export const TextAlign = {
  center: css({
    textAlign: 'center',
  }),
  justify: css({
    textAlign: 'justify',
  }),
  left: css({
    textAlign: 'left',
  }),
  right: css({
    textAlign: 'right',
  }),
};
