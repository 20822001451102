import { BaseListResponseDto } from './common.dtos';

export enum AccountStatementType {
  TradeConfirmation = 'TradeConfirmation',
  MonthlyStatement = 'MonthlyStatement',
  TaxForm = 'TaxForm',
}

export interface BaseAccountStatementDto<T extends AccountStatementType> {
  date: string;
  type: T;
}

export interface TradeConfirmationDto extends BaseAccountStatementDto<AccountStatementType.TradeConfirmation> {}

export interface GetTradeConfirmationListResponseDto extends BaseListResponseDto<TradeConfirmationDto[]> {}

export interface MonthlyStatementDto extends BaseAccountStatementDto<AccountStatementType.MonthlyStatement> {}

export interface GetMonthlyStatementListResponseDto extends BaseListResponseDto<MonthlyStatementDto[]> {}

export enum TaxDocumentForm {
  Form1099 = 'Form1099',
  Form1099R = 'Form1099R',
  Form5498 = 'Form5498',
  Form5498ESA = 'Form5498ESA',
  Form1042S = 'Form1042S',
  Form2439 = 'Form2439',
  Form408 = 'Form408',
}

export interface TaxDocumentDto extends BaseAccountStatementDto<AccountStatementType.TaxForm> {
  form: TaxDocumentForm;
}

export interface GetTaxDocumentListResponseDto extends BaseListResponseDto<TaxDocumentDto[]> {}
