import { css } from '@emotion/css';
import { ColorConstant, Spacing } from 'src/styles';

export const checkbox = css`
  ${Spacing.mx0}

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0255a3 !important;
    border: 1px solid #0255a3 !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${ColorConstant.GRAY6} !important;
    border: 1px solid ${ColorConstant.GRAY6} !important;
  }
`;
