import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getSnapQuotesRequested } from '../actions';
import { QuoteMediaConstant } from '../constants/quoteMedia';

import { useAccountSelector } from './useAccountSelector';
import { useOrderPositions } from './useOrderPositions';

export const useRefreshQuoteMedia = () => {
  const dispatch = useDispatch();
  const { orderPositionList } = useOrderPositions({ skip: true });
  const isLoadingQuoteMedia = useSelector((state: any) => Boolean(state.quoteMedia.quoteMediaData?.__requested));
  const timer = useRef<null | ReturnType<typeof setInterval>>(null);
  const { account } = useAccountSelector();

  const clearTimerIfExists = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const refresh = () => {
    const tradableSymbols = orderPositionList
      .filter(aPosition => aPosition.isTradable)
      .map(aPosition => aPosition.symbol);

    clearTimerIfExists();

    timer.current = setInterval(() => {
      if (tradableSymbols.length && !isLoadingQuoteMedia) {
        dispatch(getSnapQuotesRequested(tradableSymbols));
      }
    }, QuoteMediaConstant.REFRESH_INTERVAL);
  };

  useEffect(() => {
    if (account?.accountId) {
      refresh();
    }

    return clearTimerIfExists;
  }, [orderPositionList, account?.accountId, dispatch]);
};
