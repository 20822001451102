import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Col, Radio, RadioChangeEvent, Space } from 'antd';

import { DropdownFilterButton } from '../DropdownFilterButton/DropdownFilterButton';

import * as Styles from './RadioFilter.styles';

interface RadioFilterProps {
  label?: string | JSX.Element;
  options: { label: string | ReactNode | undefined; value: number }[];
  onSelect: (value: number) => void;
  selectedValue: any;
  title?: string;
  defaultValue?: any;
  resetOnToggle?: boolean;
  direction?: 'horizontal' | 'vertical' | undefined;
  disabled?: boolean;
  testId?: string;
}

export const RadioFilter = ({
  label,
  options,
  onSelect,
  selectedValue,
  children,
  title,
  defaultValue,
  resetOnToggle = false,
  direction = 'vertical',
  disabled = false,
  testId,
}: PropsWithChildren<RadioFilterProps>) => {
  const [value, setValue] = useState(selectedValue);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    onSelect(e.target.value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [resetOnToggle]);

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  return (
    <DropdownFilterButton isDirty={Boolean(selectedValue)} label={label}>
      <Col span={24}>
        <div className={Styles.label}>{title}</div>
      </Col>
      <Radio.Group onChange={onChange} value={value} defaultValue={defaultValue}>
        <Space direction={direction} data-testid={testId}>
          {options.map((option: { label: string | ReactNode | undefined; value: number }) => (
            <Radio
              key={`${option?.label}_${option?.value}`}
              value={option?.value}
              className={Styles.radioWrapper}
              disabled={disabled}>
              <span className={disabled ? Styles.labelDisableWrapper : Styles.labelWrapper}>{option?.label}</span>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      {children}
    </DropdownFilterButton>
  );
};
