import axios, { AxiosError } from 'axios';
import { put } from 'redux-saga/effects';
import { ErrorResponseDto } from 'src/dtos';
import { v4 as uuid } from 'uuid';

import { logout, toastMessagesAdd } from '../actions';
import { State } from '../actions/utils';
import { SeverityEnum, TReduxAction } from '../typings/commonTypes';
import { Logger } from '../utils/Logger';

export const Methods = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
};

/**
 * Http Client
 */
export const HttpClient: any = async (method: any, url: string, body?: any, authToken?: string) => {
  if (url.search(/\{\w+\}/) >= 0) {
    throw new TypeError(`HttpClient invalid argument: url=${url} contains a placeholder`);
  }

  return axios({
    method,
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken ?? '',
      // 'X-Platform-Code': PLATFORM_CODE,
    },
    data: body || undefined,
  });
};

/** Deprecated: Use replacePlaceholders from src/utils */
export const replacePlaceholders = (str: string, mapObj: any) =>
  str.replace(
    new RegExp(
      Object.keys(mapObj)
        .map(key => `{${key}}`)
        .join('|'),
      'gi',
    ),
    matched => mapObj[matched.substring(1, matched.length - 1)],
  );

export const replaceQueryParams = (url: string, params: any): string =>
  `${url}?${new URLSearchParams(params).toString()}`;

export function getAuthToken() {
  return localStorage.getItem('authToken');
}

export const getErrorMessage = (error: AxiosError<ErrorResponseDto> | any) => {
  Logger.error(error);

  const message = error?.response?.data?.message;

  if (error instanceof AxiosError) {
    if (error.code === 'ERR_NETWORK') {
      return `Network Error occurred. Action was not completed (Code: ${error.code}).`;
    }
  }

  if (Array.isArray(message)) {
    return message?.join('\r\n');
  }

  return message ?? 'Unknown error occurred. Please try again later or contact My IPO at clientservices@myipo.com.';
};

export function safeSaga(func: any, actionType?: string, shouldAddToast = true) {
  return function* (action: TReduxAction) {
    try {
      yield* func(action);
    } catch (error: AxiosError<ErrorResponseDto> | any) {
      yield put({
        type: State.actionFailed(actionType as string),
        message: getErrorMessage(error),
      });

      if (error?.response?.status === 401) {
        yield put(logout());

        yield put(
          toastMessagesAdd({
            key: uuid(),
            message: 'Your session has expired. Please login again.',
            severity: SeverityEnum.Error,
          }),
        );

        return;
      }

      if (shouldAddToast) {
        yield put(
          toastMessagesAdd({
            key: uuid(),
            severity: SeverityEnum.Error,
            message: getErrorMessage(error),
          }),
        );
      }
    }
  };
}
