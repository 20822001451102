import { getCurrencyFormatter } from 'src/utils';

const currencyFormatter = getCurrencyFormatter();

interface CurrencyRangeFieldProps {
  min?: number;
  max?: number;
}

export const CurrencyRangeField = ({ min, max }: CurrencyRangeFieldProps) => {
  if (min === undefined || max === undefined) {
    return null;
  }

  return <>{`${currencyFormatter.format(min)}-${currencyFormatter.format(max)}`}</>;
};
