import { ErrorCode } from '../../constants/common.constants';

export interface UnknownErrorMessageProps {
  errorCode: ErrorCode;
  message?: string;
}

export const UnknownErrorMessage = ({ message, errorCode }: UnknownErrorMessageProps) => (
  <p>
    {message ?? 'Unknown error occurred'} (Error code {errorCode}). Please try again later or contact My IPO client
    services.
  </p>
);
