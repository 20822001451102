import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { AccountHolderMailingAddressForm } from './AccountHolderMailingAddressForm';
import { AccountHolderMailingAddressView } from './AccountHolderMailingAddressView';

export const AccountHolderMailingAddress = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isMailingAddressAlreadySaved = () => !isEmpty(account?.primaryAccountHolder?.mailingAddress);

  useEffect(() => {
    if (isMailingAddressAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.mailingAddress]);

  return (
    <MFormSection
      title='Mailing Address'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId={'mailing-address'}>
      {isMailingAddressAlreadySaved() && !isEditMode ? (
        <AccountHolderMailingAddressView account={account} />
      ) : (
        <AccountHolderMailingAddressForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
