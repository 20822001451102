import { css } from '@emotion/css';
import { Color, Display, Font, Spacing } from 'src/styles';

export const agreementLink = css`
  ${Spacing.mb8}
  color: ${Color.BRAND.BRAND6};
  ${Display.flex}
  display: flex;
`;

export const agreementAcceptText = css`
  ${Font.md}
  ${Spacing.my16}
`;

export const linksContainer = css`
  ${Spacing.mt16}
`;

export const link = css`
  color: inherit;
  ${Spacing.ml4}
`;
