import type { PropsWithChildren } from 'react';

import { Col, Row } from 'antd';

import { BacklinkButton } from '../../Miscellaneous/BacklinkButton/BacklinkButton';

import * as Styles from './MyAccountLayout.styles';
import { MyAccountSidebar, MyAccountSidebarMenuItemKey } from './MyAccountSidebar';

export interface MyAccountLayoutProps {
  title?: string;
  backlink?: { to: string; label: string };
  sidebarMenuItemKey: MyAccountSidebarMenuItemKey;
}

export const MyAccountLayout = ({
  title,
  children,
  sidebarMenuItemKey,
  backlink,
}: PropsWithChildren<MyAccountLayoutProps>) => {
  return (
    <Row>
      <MyAccountSidebar menuItemKey={sidebarMenuItemKey} />
      <Col span={16} sm={24} xs={24} md={15} lg={16}>
        {backlink && (
          <div className={Styles.backlinkButtonContainer}>
            <BacklinkButton to={backlink.to} label={backlink.label} />
          </div>
        )}
        {title && <div className={Styles.title({ hasBacklink: Boolean(backlink) })}>{title}</div>}
        <div className={Styles.mainContainer}>{children}</div>
      </Col>
    </Row>
  );
};
