import { useEffect, useState } from 'react';

import { isUndefined } from 'lodash';
import { useAccountSelector } from 'src/hooks';
import { MFormSection } from 'src/lib';

import { FinancialInformationAssetsForm } from './FinancialInformationAssetsForm';
import { FinancialInformationAssetsView } from './FinancialInformationAssetsView';

export const FinancialInformationAssetsSection = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const { account } = useAccountSelector();

  const onEdit = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  const isFinancialInformationAssetsAlreadySaved = () =>
    !isUndefined(account?.primaryAccountHolder?.financialInformation?.annualIncome);

  useEffect(() => {
    if (isFinancialInformationAssetsAlreadySaved()) {
      setIsEditable(true);
    }
  }, [account?.primaryAccountHolder?.suitabilityInformation]);

  return (
    <MFormSection
      title='Income & Assets'
      onEdit={onEdit}
      isEditable={isEditable}
      isEditMode={isEditMode}
      testId={'income-and-assets'}>
      {isFinancialInformationAssetsAlreadySaved() && !isEditMode ? (
        <FinancialInformationAssetsView account={account} />
      ) : (
        <FinancialInformationAssetsForm account={account} onCancel={onCancel} />
      )}
    </MFormSection>
  );
};
