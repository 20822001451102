import { css } from '@emotion/css';

import {
  AlignItems,
  BorderRadius,
  Color,
  ColorConstant,
  Display,
  FlexDirection,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from '../../../../styles';

export const cardWrapper = css`
  background: ${ColorConstant.GRAY1};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  ${BorderRadius.xs};
  .ant-card-body {
    ${Spacing.p16};
  }
  ${Spacing.my8}
`;

export const bankImageContainer = css`
  ${Spacing.ml20};
  ${Spacing.mr8};
  ${AlignItems.center};
  ${JustifyContent.center};
`;

export const actionRow = css`
  ${Display.flex};
  ${AlignItems.center};
  ${Spacing.py8};
  ${Spacing.pl20};
  &:hover {
    background-color: ${ColorConstant.GRAY3} !important;
    cursor: pointer;
  }
`;

export const popoverTitle = css`
  ${FontWeight.bold};
  ${Font.h1};
  color: ${ColorConstant.GRAY9};
  ${Spacing.pl16};
  ${Spacing.pt24};
`;

export const actionImage = css`
  ${Font.md};
  ${Spacing.mr12};
`;

export const bankImage = css`
  width: 2.5rem;
  height: 2.5rem;
`;

export const popoverContent = css`
  width: 21.563rem;
  background-color: ${Color.GRAYSCALE.GRAY1};
  box-shadow: 0px 4px 16px rgba(2, 85, 163, 0.15);
  ${BorderRadius.xs};
`;

export const endTextAlignment = css`
  text-align: end;
  .ant-space {
    @media (max-width: ${ScreenBreakpoint.mobile.min}) {
      ${Display.flex};
      ${FlexDirection.column};
    }
  }
`;

export const bankAccountLabel = css`
  ${Font.md};
  color: ${ColorConstant.GRAY7};
  ${FontWeight.bold};
  ${Spacing.mt4};
`;

export const nicknameLabel = css`
  ${Font.md};
  color: ${ColorConstant.GRAY6};
  ${Spacing.ml4};
`;

export const bankAccountTypeLabel = css`
  ${Font.md};
  color: ${ColorConstant.GRAY8};
`;

export const actionRowContainer = css`
  ${Spacing.py4};
`;

export const moreVerticalImage = css`
  ${Font.sm};
  cursor: 'pointer';
  color: ${ColorConstant.GRAY7};
`;
