import { getCurrencyFormatter } from 'src/utils';

import { MFormTextField, MFormTextFieldProps } from '../MFormTextField/MFormTextField';

const currencyFormatter = getCurrencyFormatter();

interface MFormCurrencyFieldProps extends MFormTextFieldProps {
  label: string;
  value: number;
  testId?: string;
}

export const MFormCurrencyField = ({ label, value, tooltip, testId }: MFormCurrencyFieldProps) => (
  <MFormTextField label={label} value={currencyFormatter.format(value)} tooltip={tooltip} testId={testId} />
);
