import { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { doClearGetPlaidLinkToken, getPlaidLinkToken } from 'src/actions';
import { useApplicationConfigs } from 'src/hooks';
import { MButton, MModalV2, MSpinner } from 'src/lib';
import { BaseStyle, FlexDirection, Font, FontWeight, Images, Spacing } from 'src/styles';

const spinner = css`
  height: 230px;
`;

interface NewAchRelationshipFinishModalProps {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
  accountId?: number;
}

export const NewAchRelationshipStartModal = ({
  accountId,
  open,
  onClose,
  onOk,
}: NewAchRelationshipFinishModalProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => Boolean(state.cashiering.plaidLinkToken?.isLoading));
  const success = useSelector((state: any) => Boolean(state.cashiering.plaidLinkToken?.__succeeded));

  const { configList, bankLink, isConfigListLoading } = useApplicationConfigs();

  const _onClose = () => {
    dispatch(doClearGetPlaidLinkToken());
    onClose();
  };

  const _onContinue = () => {
    dispatch(getPlaidLinkToken(accountId));
  };

  const renderModal = () => {
    if (isConfigListLoading || !configList || bankLink.isDisabled) {
      return {
        onClose: () => {
          onClose();
        },
        height: 250,
        body: isConfigListLoading ? (
          <Col span={24}>
            <MSpinner className={spinner} />
          </Col>
        ) : (
          bankLink.disclaimer
        ),
        footer: (
          <>
            <MButton
              type='tertiary'
              onClick={() => {
                onClose();
              }}
              className={Spacing.my4}>
              Close
            </MButton>
          </>
        ),
      };
    }

    return {
      onClose: _onClose,
      body: (
        <>
          <Col span={24} className={cx(BaseStyle.level)}>
            <img
              alt='bank'
              src={Images.BankPlaceholder}
              style={{
                marginTop: 16,
                marginBottom: 16,
                width: 40,
                height: 40,
              }}
            />
          </Col>
          <Col span={24} className={Font.md}>
            <p data-testid={'link-bank-disclaimer-text'}>
              By entering your bank account information, you are authorizing My IPO to communicate your bank account
              information to TradingBlock/Apex Clearing, Inc. (&quot;APEX&quot;), My IPO&apos;s chosen custodian.
              <br />
              You also authorize My IPO as a chosen medium to make transfers between this bank account and your account
              that is held at APEX.
            </p>
            <p>
              <span className={FontWeight.bold}>My IPO does not accept “third party” funding of any kind</span>. This
              means that the name on your bank account must match the name on your My IPO account exactly. For example,
              money transfers from a trust or business bank account (owned by you) to an individual or joint My IPO
              account are considered third-party and <span className={FontWeight.bold}>will not be accepted.</span>
            </p>
          </Col>
        </>
      ),
      footer: (
        <Col span={24} className={cx(BaseStyle.level, FlexDirection.column)}>
          <MButton
            type='primary'
            onClick={_onContinue}
            disabled={isLoading}
            loading={isLoading}
            className={Spacing.my4}
            testId={'account-modal-btn-continue'}>
            Continue
          </MButton>
          <MButton type='tertiary' onClick={_onClose} className={Spacing.my4} testId={'account-modal-btn-cancel'}>
            Cancel
          </MButton>
        </Col>
      ),
    };
  };

  useEffect(() => {
    if (success) {
      onOk();
    }
  }, [success]);

  return <MModalV2 title='Bank Account Link' open={open} width={400} {...renderModal()} />;
};
