import { OfferOrderRefundFailureReasonDto } from 'src/dtos/offerOrders.dtos';
import { OfferOrderRefund, OfferOrderStatus } from 'src/models';

import { ClientServicesLink } from '../ClientServicesLink/ClientServicesLink';

interface OfferOrderRefundDisclaimerProps {
  value: OfferOrderRefund;
  status: OfferOrderStatus;
}

export const OfferOrderRefundDisclaimer = ({ value, status }: OfferOrderRefundDisclaimerProps) => {
  const renderIfYouHaveQuestionsDisclaimer = () => {
    if (status.isCancelled) {
      return (
        <>
          If you have any questions on why your order was cancelled please contact My IPO at{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>.
        </>
      );
    }

    return null;
  };

  const renderFailedRefundDisclaimer = () => {
    if (value.failureReason === OfferOrderRefundFailureReasonDto.LostOrStolenCard) {
      return (
        <span>
          Your credit card refund payment failed because it was reported as lost/stolen. Please contact your credit card
          company and/or My IPO at <ClientServicesLink />.
        </span>
      );
    }

    if (value.failureReason === OfferOrderRefundFailureReasonDto.ExpiredOrCanceledCard) {
      return (
        <span>
          Your credit card refund payment failed because it was reported as an expired or canceled card. Please contact
          your credit card company and/or My IPO at <ClientServicesLink />.
        </span>
      );
    }

    if (value.failureReason === OfferOrderRefundFailureReasonDto.ChargeForPendingRefundDisputed) {
      return (
        <span>
          Your credit card refund payment failed because you disputed the charge/payment while the refund was. Please
          contact your credit card company and/or My IPO at <ClientServicesLink />.
        </span>
      );
    }

    if (value.failureReason === OfferOrderRefundFailureReasonDto.InsufficientFunds) {
      return (
        <span>
          Your refund is pending. Please contact My IPO at <ClientServicesLink /> with any questions.
        </span>
      );
    }

    if (value.failureReason === OfferOrderRefundFailureReasonDto.Declined) {
      return (
        <span>
          Your credit card refund was declined. Please contact your credit card company and/or My IPO at{' '}
          <ClientServicesLink />.
        </span>
      );
    }

    if (value.failureReason === OfferOrderRefundFailureReasonDto.MerchantRequest) {
      return (
        <span>
          Your credit card refund was declined. Please contact your credit card company and/or My IPO at{' '}
          <ClientServicesLink />.
        </span>
      );
    }

    return (
      <span>
        Your credit card refund failed for unknown reasons. Please contact your credit card company and/or My IPO at{' '}
        <ClientServicesLink />.
      </span>
    );
  };

  const renderCardRefundDisclaimerByStatus = () => {
    if (value.status.isPending) {
      return (
        <span>
          Your refund is pending. Once the refund has been successfully issued, it may take 5-10 business days for the
          funds to appear on your credit card account statement.
        </span>
      );
    }

    if (value.status.hasSucceeded) {
      return (
        <span>
          A refund has been successfully issued. It may take 5-10 business days for the funds to appear on your credit
          card account statement. {renderIfYouHaveQuestionsDisclaimer()}
        </span>
      );
    }

    if (value.status.hasFailed) {
      return renderFailedRefundDisclaimer();
    }

    return null;
  };

  if (value.mechanism.isAch) {
    return (
      <span>
        A refund using ACH has been successfully issued. It may take 5-10 business days for the funds to appear in your
        bank account statement. {renderIfYouHaveQuestionsDisclaimer()}
      </span>
    );
  }

  if (value.mechanism.isWire) {
    return (
      <span>
        A refund using Wire transfer has been successfully issued. It may take 1-3 business days for the funds to appear
        in your bank account statement. {renderIfYouHaveQuestionsDisclaimer()}
      </span>
    );
  }

  if (value.mechanism.isCheck) {
    return (
      <span>
        A refund using Check transfer has been successfully issued. It may take 7-14 business days for the check to
        arrive. {renderIfYouHaveQuestionsDisclaimer()}
      </span>
    );
  }

  return renderCardRefundDisclaimerByStatus();
};
