import { useDispatch } from 'react-redux';
import { unlinkBankAccount } from 'src/actions';

import Spinner from '../../../../lib/Miscellaneous/Spinner';
import BankAccountItem from '../BankAccountItem/BankAccountItem';

export interface AchRelationshipsProps {
  achRelationships: any;
  selectedBankLink: any;
  loadingAchRelationships: boolean;
  setSelectedBankLink: Function;
  testId?: string;
  onDeposit: (selectedBankAccount: any) => void;
  onWithdraw: (selectedBankAccount: any) => void;
  onEdit: (selectedBankAccount: any) => void;
}

const AchRelationships = ({
  achRelationships,
  selectedBankLink,
  loadingAchRelationships,
  setSelectedBankLink,
  testId,
  onDeposit,
  onWithdraw,
  onEdit,
}: AchRelationshipsProps) => {
  const dispatch = useDispatch();

  if (loadingAchRelationships) {
    return <Spinner />;
  }

  if (achRelationships) {
    return (
      <div data-testid={testId}>
        {achRelationships.length > 0 &&
          achRelationships.map((account: any) => (
            <BankAccountItem
              key={`${account?.tradingBlockACHRelationship?.id}_${account?.tradingBlockACHRelationship?.bankAccountType}_key`}
              account={account}
              onClick={() => {
                setSelectedBankLink(account);
              }}
              onDeposit={() => onDeposit(account)}
              onWithdrawal={() => onWithdraw(account)}
              onEdit={() => onEdit(account)}
              onUnlink={() => {
                dispatch(
                  unlinkBankAccount({
                    accountId: selectedBankLink.tradingBlockACHRelationship.accountId,
                    relationshipId: selectedBankLink.tradingBlockACHRelationship.id,
                  }),
                );
              }}
            />
          ))}
      </div>
    );
  }

  return null;
};

export default AchRelationships;
