import { getCountryOptionLabel } from 'src/utils';

export const mapAccountHolderBirthCountryDtoToModel = (birthCountry: string) => ({
  value: birthCountry,
  label: getCountryOptionLabel(birthCountry) ?? birthCountry,
});

export const mapAccountHolderCitizenshipCountryDtoToModel = (citizenship: string) => ({
  value: citizenship,
  label: 'U.S. Citizen',
});
