import { PropsWithChildren } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { MAlert } from 'src/lib';

import * as Styles from './MRadio.styles';

export interface MRadioProps {
  value?: string | number;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string | JSX.Element;
  description?: string | JSX.Element;
  className?: string;
  alert?: string | JSX.Element;
  testId?: string;
}

export const MRadio = ({
  value,
  description,
  className = '',
  alert,
  onChange,
  children,
  testId,
}: PropsWithChildren<MRadioProps>) => {
  const _onChange = (value: RadioChangeEvent) => {
    onChange(value.target.value);
  };

  return (
    <Radio value={value} className={cx(Styles.radio, className)} onChange={_onChange} data-testid={testId}>
      <Row>
        <Col span={24} className={Styles.label}>
          {children}
        </Col>
        <Col span={24} className={Styles.description}>
          {description}
        </Col>
        {alert && (
          <Col span={24}>
            <MAlert type='error' description={alert} icon={<ExclamationCircleOutlined />} />
          </Col>
        )}
      </Row>
    </Radio>
  );
};
