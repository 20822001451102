import * as d3 from 'd3';

export enum DateFilterEnum {
  LAST_DAY = '1D',
  LAST_WEEK = '1W',
  LAST_MONTH = '1M',
  LAST_6_MONTH = '6M',
  LAST_YEAR = '1Y',
  ALL = 'ALL',
}

export interface DateFilterOptions {
  value: string;
  name: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  nextDate: moment.Moment;
  tickInterval: d3.CountableTimeInterval;
  formatTick: string;
  tickStep: number;
}

export const getActiveFilterOptions = (activeFilter: string, date: moment.Moment): DateFilterOptions => {
  let result: DateFilterOptions;

  switch (activeFilter) {
    case DateFilterEnum.LAST_DAY:
      result = {
        value: '1d',
        name: DateFilterEnum.LAST_DAY,
        startDate: date.clone().subtract(1, 'day'),
        endDate: date.clone(),
        nextDate: date.clone().add(1, 'day'),
        tickInterval: d3.timeHour,
        formatTick: 'LT',
        tickStep: 2,
      };
      break;
    case DateFilterEnum.LAST_WEEK:
      result = {
        value: '1w',
        name: DateFilterEnum.LAST_WEEK,
        startDate: date.clone().subtract(1, 'week'),
        endDate: date.clone(),
        nextDate: date.clone().add(1, 'week'),
        tickInterval: d3.timeDay,
        formatTick: 'dddd',
        tickStep: 1,
      };
      break;
    case DateFilterEnum.LAST_MONTH:
      result = {
        value: '1m',
        name: DateFilterEnum.LAST_MONTH,
        startDate: date.clone().subtract(1, 'month'),
        endDate: date.clone(),
        nextDate: date.clone().add(1, 'month'),
        tickInterval: d3.timeWeek,
        formatTick: 'Do MMM',
        tickStep: 1,
      };
      break;
    case DateFilterEnum.LAST_6_MONTH:
      result = {
        value: '6m',
        name: DateFilterEnum.LAST_6_MONTH,
        startDate: date.clone().subtract(6, 'months'),
        endDate: date.clone(),
        nextDate: date.clone().add(1, 'months'),
        tickInterval: d3.timeMonth,
        formatTick: `MMM 'YY`,
        tickStep: 2,
      };
      break;
    case DateFilterEnum.LAST_YEAR:
      result = {
        value: '1y',
        name: DateFilterEnum.LAST_YEAR,
        startDate: date.clone().subtract(1, 'year'),
        endDate: date.clone(),
        nextDate: date.clone().add(1, 'year'),
        tickInterval: d3.timeMonth,
        formatTick: `MMM 'YY`,
        tickStep: 2,
      };
      break;
    case DateFilterEnum.ALL:
      result = {
        value: 'all',
        name: DateFilterEnum.ALL,
        startDate: date.clone().subtract(3, 'years'),
        endDate: date.clone(),
        nextDate: date.clone().add(1, 'years'),
        tickInterval: d3.timeYear,
        formatTick: 'YYYY',
        tickStep: 1,
      };
      break;
    default:
      result = {
        value: '1w',
        name: DateFilterEnum.LAST_WEEK,
        startDate: date.clone().subtract(1, 'week'),
        endDate: date.clone(),
        nextDate: date.clone().add(1, 'week'),
        tickInterval: d3.timeDay,
        formatTick: 'dddd',
        tickStep: 1,
      };
  }

  return result;
};
