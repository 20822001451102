import produce from 'immer';
import { combineReducers } from 'redux';

import { State, Type } from '../actions/utils';
import { TReduxAction } from '../typings/commonTypes';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededReplacePayload } from './utils';

export const offersList = (state: any = initialState.offers.offersList, action: TReduxAction) => {
  switch (action.type) {
    case State.actionCleared(Type.OFFER_GET_OFFER_LIST):
      return clearedDefaults(state);
    case State.actionRequested(Type.OFFER_GET_OFFER_LIST):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.__requested = new Date();
        draftState.__succeeded = null;
        draftState.__failed = null;
        draftState.message = action?.message;
      })(state, action);
    case State.actionSucceeded(Type.OFFER_GET_OFFER_LIST):
      return produce((draftState: any, action: TReduxAction) => {
        draftState.__requested = null;
        draftState.__succeeded = new Date();
        draftState.__failed = null;
        draftState.data = [...action.payload];
        draftState.message = action?.message;
      })(state, action);
    case State.actionFailed(Type.OFFER_GET_OFFER_LIST):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const offerDetails = (state: any = initialState.offers.offerDetails, action: TReduxAction) => {
  switch (action.type) {
    case State.actionCleared(Type.OFFER_GET_OFFER_DETAILS):
      return clearedDefaults(state);
    case State.actionRequested(Type.OFFER_GET_OFFER_DETAILS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.OFFER_GET_OFFER_DETAILS):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.OFFER_GET_OFFER_DETAILS):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export const offerOptions = (state: any = initialState.offers.options, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_ALL_OFFER_OPTIONS):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_ALL_OFFER_OPTIONS):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_ALL_OFFER_OPTIONS):
      return succeededReplacePayload(state, action);
    case State.actionFailed(Type.GET_ALL_OFFER_OPTIONS):
      return failedDefaults(state, action);
    default:
      return state;
  }
};

export default combineReducers({
  offersList,
  offerDetails,
  options: offerOptions,
});
