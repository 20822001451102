import React from 'react';

import { Outlet } from 'react-router-dom';

export const MAIN_PATH = 'main';

const Main = () => {
  return <Outlet />;
};

export default Main;
