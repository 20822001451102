import { cx } from '@emotion/css';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { FlexDirection } from 'src/styles';

import CurrencyField from '../Miscellaneous/FormattedFields/CurrencyField/CurrencyField';

import * as Styles from './Header.styles';

export const AccountValue = () => {
  const accountValue = useSelector((state: any) => state.accountDetails?.accountValue?.data?.totalValue);

  return (
    <Row justify='center' align='middle' className={cx(Styles.accountValueContainer, FlexDirection.column)}>
      <Col span={24}>
        <CurrencyField value={accountValue} className={Styles.accountValue} />
      </Col>
      <Col span={24}>
        <span className={Styles.accountLabel}>Account Value</span>
      </Col>
    </Row>
  );
};
