import { Offer, OfferDetails } from 'src/models';
import { findOfferRemainingDays } from 'src/utils';

import { MTag } from '../MTag/MTag';

export interface RemainingOfferDaysTagProps {
  offer?: Offer | OfferDetails;
}

export const RemainingOfferDaysTag = ({ offer }: RemainingOfferDaysTagProps) => {
  if (!offer) {
    return null;
  }
  const remainingDays = findOfferRemainingDays(offer);

  if (!remainingDays || offer.isComingSoon) {
    return null;
  }

  return (
    <MTag type='orange'>
      {remainingDays === 1
        ? `${remainingDays} Day Until ${offer.isRecurring ? 'Next' : ''} Closing`
        : `${remainingDays} Days Until ${offer.isRecurring ? 'Next' : ''} Closing`}
    </MTag>
  );
};
