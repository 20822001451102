import { MFormCheckbox, MFormTextField } from 'src/lib';
import { Account } from 'src/models';

export interface AccountHolderMailingAddressViewProps {
  account?: Account;
}

export const AccountHolderMailingAddressView = ({ account }: AccountHolderMailingAddressViewProps) => {
  if (account?.primaryAccountHolder?.mailingAddress?.sameAsPhysical) {
    return <MFormCheckbox label='Mailing address is the same as my physical address' checked disabled />;
  }

  return (
    <>
      <MFormTextField label='Address Line 1' value={account?.primaryAccountHolder?.mailingAddress?.address1} />
      <MFormTextField label='Address Line 2 (Opt.)' value={account?.primaryAccountHolder?.mailingAddress?.address2} />
      <MFormTextField label='Country' value={account?.primaryAccountHolder?.mailingAddress?.country?.label} />
      <MFormTextField label='City' value={account?.primaryAccountHolder?.mailingAddress?.city} />
      <MFormTextField label='State' value={account?.primaryAccountHolder?.mailingAddress?.state?.label} />
      <MFormTextField label='Postal Code' value={account?.primaryAccountHolder?.mailingAddress?.postalCode} />
    </>
  );
};
