import { useEffect, useState } from 'react';

import { useInvestigationListSelector } from './useInvestigationListSelector';

export const useHasAnyRequestedInvestigationDocuments = (): boolean => {
  const { applications, investigations } = useInvestigationListSelector();
  const [isUserActionNeeded, setIsUserActionNeeded] = useState<boolean>(false);

  useEffect(() => {
    const needUserActionAccountStatus =
      applications.some(application => application.appCategories?.some(category => !category.isResolved)) ||
      investigations.some(investigation => investigation.cipCategories?.some(category => !category.isResolved));

    setIsUserActionNeeded(needUserActionAccountStatus);
  }, [applications, investigations]);

  return isUserActionNeeded;
};
