import { InvestigationUrlConstant } from 'src/constants';
import {
  AppealInvestigationDto,
  ContinueApplicationDto,
  ContinueApplicationResponseDto,
  DeleteApplicationDocumentDto,
  DeleteInvestigationDocumentDto,
  DownloadApplicationDocumentDto,
  DownloadInvestigationDocumentDto,
  GetInvestigationListDto,
  GetInvestigationListResponseDto,
  RejectInvestigationDto,
  SubmitApplicationDocumentDto,
  SubmitInvestigationDocumentDto,
  UploadApplicationDocumentDto,
  UploadInvestigationDocumentDto,
  UploadInvestigationDocumentResponseDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class InvestigationsApi {
  private _configService: ConfigService;

  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async list({ params }: GetInvestigationListDto): Promise<GetInvestigationListResponseDto> {
    const url = replacePlaceholders(InvestigationUrlConstant.LIST, params);

    return httpRequest<GetInvestigationListResponseDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  // NOTE: auth token is temp passed as parameter until
  // token is removed from the store and kept in local storage
  async uploadDocument({
    params,
    body,
    authToken,
  }: UploadInvestigationDocumentDto & { authToken: string }): Promise<UploadInvestigationDocumentResponseDto> {
    const url = replacePlaceholders(InvestigationUrlConstant.UPLOAD_DOCUMENT, params);

    return httpRequest<UploadInvestigationDocumentResponseDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async downloadDocument({ params }: DownloadInvestigationDocumentDto): Promise<Blob> {
    const url = replacePlaceholders(InvestigationUrlConstant.DOWNLOAD_DOCUMENT, params);

    return httpRequest<Blob>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
      responseType: 'blob',
    });
  }

  async appeal({ params }: AppealInvestigationDto): Promise<void> {
    const url = replacePlaceholders(InvestigationUrlConstant.APPEAL, params);

    await httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async reject({ params }: RejectInvestigationDto): Promise<void> {
    const url = replacePlaceholders(InvestigationUrlConstant.REJECT, params);

    await httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async submitDocument({ params }: SubmitInvestigationDocumentDto): Promise<void> {
    const url = replacePlaceholders(InvestigationUrlConstant.SUBMIT_DOCUMENT, params);

    await httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async deleteDocument({ params }: DeleteInvestigationDocumentDto): Promise<void> {
    const url = replacePlaceholders(InvestigationUrlConstant.DELETE_DOCUMENT, params);

    await httpRequest<void>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }

  // NOTE: auth token is temp passed as parameter until
  // token is removed from the store and kept in local storage
  async uploadApplicationDocument({
    params,
    body,
    authToken,
  }: UploadApplicationDocumentDto & { authToken: string }): Promise<UploadInvestigationDocumentResponseDto> {
    const url = replacePlaceholders(InvestigationUrlConstant.UPLOAD_APPLICATION_DOCUMENT, params);

    return httpRequest<UploadInvestigationDocumentResponseDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async downloadApplicationDocument({ params }: DownloadApplicationDocumentDto): Promise<Blob> {
    const url = replacePlaceholders(InvestigationUrlConstant.DOWNLOAD_APPLICATION_DOCUMENT, params);

    return httpRequest<Blob>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
      responseType: 'blob',
    });
  }

  async submitApplicationDocument({ params }: SubmitApplicationDocumentDto): Promise<void> {
    const url = replacePlaceholders(InvestigationUrlConstant.SUBMIT_APPLICATION_DOCUMENT, params);

    await httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async continueApplication({ params }: ContinueApplicationDto): Promise<ContinueApplicationResponseDto> {
    const url = replacePlaceholders(InvestigationUrlConstant.CONTINUE_APPLICATION, params);

    return await httpRequest<ContinueApplicationResponseDto>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async deleteApplicationDocument({ params }: DeleteApplicationDocumentDto): Promise<void> {
    const url = replacePlaceholders(InvestigationUrlConstant.DELETE_APPLICATION_DOCUMENT, params);

    await httpRequest<void>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }
}
