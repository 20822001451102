import { css } from '@emotion/css';
import { BorderRadius, Color, ColorConstant, Display, Font, FontWeight, JustifyContent, Spacing } from 'src/styles';

export const screen = css`
  background: ${ColorConstant.GRAY1};
  ${Spacing.mt64};
`;

export const cardWrapper = css`
  width: 100%;
  ${Spacing.p24};
  background: ${ColorConstant.GRAY1};
  box-shadow: 0px 4px 16px ${ColorConstant.BRAND6}1A;
  ${BorderRadius.xs};
  .ant-card-body {
    ${Spacing.p0};
  }
`;

export const justifyRight = css`
  ${Display.flex};
  ${JustifyContent.flexEnd};
`;

export const label = css`
  ${FontWeight.semibold};
  ${Font.md};
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const value = css`
  ${FontWeight.normal};
  ${Font.md};
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const fieldLabel = css`
  ${FontWeight.normal};
  ${Font.xs};
  color: ${Color.GRAYSCALE.GRAY8};
`;

export const subtitle = css`
  ${Font.h3};
  color: ${ColorConstant.BRAND6};
`;
