import { AccountDto, AccountTypeDto } from 'src/dtos';

import * as Url from '../constants/url';
import { replacePlaceholders } from '../sagas/utils';
import { httpRequest } from '../utils/httpRequest';

export interface GetOneAccountArgs {
  accountIdentifier?: AccountTypeDto;
  authToken: string;
}

export class AccountsApi {
  async getOne({ accountIdentifier = AccountTypeDto.Individual, authToken }: GetOneAccountArgs): Promise<AccountDto> {
    const url = replacePlaceholders(Url.GET_ACCOUNT_URL, { accountIdentifier });

    return httpRequest<AccountDto>({
      method: 'GET',
      url,
      authToken,
    });
  }
}
