import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { AccountHolderTradesPerYearDto, AccountHolderYearsOfExperienceDto } from 'src/dtos';
import { MFormCheckbox, MFormSelect } from 'src/lib';
import { MFormItem } from 'src/lib/MFormItem/MFormItem';
import { Spacing } from 'src/styles';

import { SuitabilityInformationFormValues } from './SuitabilityInformationForm';

const YEARS_OF_EXPERIENCE_OPTION_LIST = [
  { value: AccountHolderYearsOfExperienceDto.One, label: '1' },
  { value: AccountHolderYearsOfExperienceDto.TwoToThree, label: '2-3' },
  { value: AccountHolderYearsOfExperienceDto.FourToNine, label: '4-9' },
  { value: AccountHolderYearsOfExperienceDto.TenOrMore, label: '10+' },
];

const TRADES_PER_YEAR_OPTION_LIST = [
  { value: AccountHolderTradesPerYearDto.OneToFive, label: '1-5' },
  { value: AccountHolderTradesPerYearDto.SixToTen, label: '6-10' },
  { value: AccountHolderTradesPerYearDto.ElevenToTwenty, label: '11-20' },
  { value: AccountHolderTradesPerYearDto.MoreThanTwenty, label: '20+' },
];

interface InvestmentExperienceProps {
  form: FormikProps<SuitabilityInformationFormValues>;
}

export const InvestmentExperienceForm = ({ form }: InvestmentExperienceProps) => {
  return (
    <>
      <Col span={24}>
        <MFormItem label='Financial Investment Experience' align='vertical'>
          <Row className={`${Spacing.mt16} ${Spacing.mb4}`}>
            <MFormCheckbox
              testId={'investment-margin'}
              label='Margin'
              value='margins'
              checked={form.values.financialInvestmentExperience?.margins?.checked}
              onChange={event => {
                form.setFieldValue('financialInvestmentExperience.margins.checked', event.checked);
              }}
            />
          </Row>
          {form.values.financialInvestmentExperience?.margins?.checked && (
            <Row className={Spacing.my8}>
              <Col span={24}>
                <MFormSelect
                  testId={'margin-years-of-experience'}
                  label='Years Of Experience'
                  weight='light'
                  options={YEARS_OF_EXPERIENCE_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.margins?.yearsOfExperience}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.margins.yearsOfExperience', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.margins?.yearsOfExperience}
                />
              </Col>
              <Col span={24}>
                <MFormSelect
                  testId={'margin-trades-year'}
                  label='Trades/Year'
                  weight='light'
                  options={TRADES_PER_YEAR_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.margins?.tradesPerYear}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.margins.tradesPerYear', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.margins?.tradesPerYear}
                />
              </Col>
            </Row>
          )}
          <Row className={Spacing.my4}>
            <MFormCheckbox
              testId={'investment-stocks'}
              label='Stocks/ETFs'
              value='stocks'
              onChange={event => {
                form.setFieldValue('financialInvestmentExperience.stocks.checked', event.checked);
              }}
              checked={form.values.financialInvestmentExperience?.stocks?.checked}
            />
          </Row>
          {form.values.financialInvestmentExperience?.stocks?.checked && (
            <Row className={Spacing.my8}>
              <Col span={24}>
                <MFormSelect
                  testId={'stocks-years-of-experience'}
                  label='Years Of Experience'
                  weight='light'
                  options={YEARS_OF_EXPERIENCE_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.stocks?.yearsOfExperience}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.stocks.yearsOfExperience', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.stocks?.yearsOfExperience}
                />
              </Col>
              <Col span={24}>
                <MFormSelect
                  testId={'stocks-trades-year'}
                  label='Trades/Year'
                  weight='light'
                  options={TRADES_PER_YEAR_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.stocks?.tradesPerYear}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.stocks.tradesPerYear', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.stocks?.tradesPerYear}
                />
              </Col>
            </Row>
          )}
          <Row className={Spacing.my4}>
            <MFormCheckbox
              testId={'investment-options-experience'}
              label='Options Experience'
              value='options'
              checked={form.values.financialInvestmentExperience?.options?.checked}
              onChange={event => {
                form.setFieldValue('financialInvestmentExperience.options.checked', event.checked);
              }}
            />
          </Row>
          {form.values.financialInvestmentExperience?.options?.checked && (
            <Row className={Spacing.my8}>
              <Col span={24}>
                <MFormSelect
                  label='Years Of Experience'
                  weight='light'
                  options={YEARS_OF_EXPERIENCE_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.options?.yearsOfExperience}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.options.yearsOfExperience', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.options?.yearsOfExperience}
                />
              </Col>
              <Col span={24}>
                <MFormSelect
                  label='Trades/Year'
                  weight='light'
                  options={TRADES_PER_YEAR_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.options?.tradesPerYear}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.options.tradesPerYear', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.options?.tradesPerYear}
                />
              </Col>
            </Row>
          )}
          <Row className={Spacing.my4}>
            <MFormCheckbox
              testId={'investment-commodities-experience'}
              label='Commodities Experience'
              value='commodities'
              checked={form.values.financialInvestmentExperience?.commodities?.checked}
              onChange={event => {
                form.setFieldValue('financialInvestmentExperience.commodities.checked', event.checked);
              }}
            />
          </Row>
          {form.values.financialInvestmentExperience?.commodities?.checked && (
            <Row>
              <Col span={24}>
                <MFormSelect
                  label='Years Of Experience'
                  weight='light'
                  options={YEARS_OF_EXPERIENCE_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.commodities?.yearsOfExperience}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.commodities.yearsOfExperience', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.commodities?.yearsOfExperience}
                />
              </Col>
              <Col span={24}>
                <MFormSelect
                  label='Trades/Year'
                  weight='light'
                  options={TRADES_PER_YEAR_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.commodities?.tradesPerYear}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.commodities.tradesPerYear', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.commodities?.tradesPerYear}
                />
              </Col>
            </Row>
          )}
          <Row className={Spacing.my4}>
            <MFormCheckbox
              testId={'investment-bonds-experience'}
              label='Bonds Experience'
              value='bonds'
              checked={form.values.financialInvestmentExperience?.bonds?.checked}
              onChange={event => {
                form.setFieldValue('financialInvestmentExperience.bonds.checked', event.checked);
              }}
            />
          </Row>
          {form.values.financialInvestmentExperience?.bonds?.checked && (
            <Row>
              <Col span={24}>
                <MFormSelect
                  label='Years Of Experience'
                  weight='light'
                  options={YEARS_OF_EXPERIENCE_OPTION_LIST}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.bonds.yearsOfExperience', value);
                  }}
                  defaultValue={form.values.financialInvestmentExperience?.bonds?.yearsOfExperience}
                  error={(form.errors.financialInvestmentExperience as any)?.bonds?.yearsOfExperience}
                />
              </Col>
              <Col span={24}>
                <MFormSelect
                  label='Trades/Year'
                  weight='light'
                  options={TRADES_PER_YEAR_OPTION_LIST}
                  defaultValue={form.values.financialInvestmentExperience?.bonds?.tradesPerYear}
                  onChange={value => {
                    form.setFieldValue('financialInvestmentExperience.bonds.tradesPerYear', value);
                  }}
                  error={(form.errors.financialInvestmentExperience as any)?.bonds?.tradesPerYear}
                />
              </Col>
            </Row>
          )}
        </MFormItem>
      </Col>
    </>
  );
};
