import { Col, Row } from 'antd';
import { AccountHolderStatementDeliveryDto } from 'src/dtos';
import { MFormCheckboxField, MFormRadioGroupField, MFormTextField } from 'src/lib';
import { Account } from 'src/models';
import { Spacing } from 'src/styles';

import * as Styles from './Disclosures.styles';

export interface DisclosuresViewProps {
  account?: Account;
}

export const DisclosuresView = ({ account }: DisclosuresViewProps) => {
  const renderIndividualRepresentations = () => {
    return (
      <Row>
        <Col span={24} className={Styles.titleViewContainer}>
          <p className={Styles.title}>Individual Representations</p>
          <p className={Styles.subtitle}>(Please attest as to whether any of the following apply to you.)</p>
        </Col>
        <Col span={24}>
          <MFormCheckboxField label='Are you a 10% shareholder, director and/or policy maker of a publicly-owned company?'>
            <MFormTextField
              label='Company Ticker Symbol(s)'
              value={
                account?.primaryAccountHolder?.disclosure?.stakeholder === false
                  ? ''
                  : account?.primaryAccountHolder?.disclosure?.stakeholder.tickerSymbol
              }
              className={Spacing.py0}
            />
          </MFormCheckboxField>
        </Col>
        <Col span={24}>
          <MFormCheckboxField
            label='Are you employed by or a member of a registered broker-dealer, securities or futures exchange, or securities
            industry regulatory body (e.g., FINRA, NFA)?'>
            <MFormTextField
              label='Firm Name'
              value={
                account?.primaryAccountHolder?.disclosure?.industryEmployed === false
                  ? ''
                  : account?.primaryAccountHolder?.disclosure?.industryEmployed.firmName
              }
              className={Spacing.py0}
            />
          </MFormCheckboxField>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Col span={24} className={Spacing.mb16}>
        <MFormRadioGroupField
          label='Statement Delivery'
          value={account?.primaryAccountHolder?.disclosure?.statementDelivery?.label}
          tooltip={
            account?.primaryAccountHolder?.disclosure?.statementDelivery.value ===
            AccountHolderStatementDeliveryDto.Paper
              ? {
                  type: 'popover',
                  content:
                    'Trade Confirmations: $2.00 per confirm Monthly Statement: $5.00 per statement Tax Document: $5.00 per document',
                }
              : undefined
          }
          alignment='vertical'
        />
      </Col>
      {renderIndividualRepresentations()}
    </>
  );
};
