import produce from 'immer';

export const clearedDefaults = produce((draftState: any) => {
  draftState.__requested = null;
  draftState.isLoading = false;
  draftState.__succeeded = null;
  draftState.__failed = null;
  draftState.data = null;
  draftState.message = null; // TODO: remove message and use _error
  draftState.__isFetched = false;
  draftState.error = null;
});

export const requestedDefaults = produce((draftState: any) => {
  draftState.__requested = new Date();
  draftState.isLoading = true;
  draftState.__succeeded = null;
  draftState.__failed = null;
  draftState.message = null; // TODO: remove message
  draftState.__isFetched = true;
  draftState.error = null;
});

export const succeededDefaults = produce((draftState: any, action?: any) => {
  draftState.__requested = null;
  draftState.isLoading = false;
  draftState.__succeeded = new Date();
  draftState.__failed = null;
  draftState.data = { ...draftState.data, ...action.payload };
  draftState.message = action?.message;
  draftState.error = null;
});

export const succeededReplacePayload = produce((draftState: any, action?: any) => {
  draftState.__requested = null;
  draftState.isLoading = false;
  draftState.__succeeded = new Date();
  draftState.__failed = null;
  draftState.data = action.payload;
  draftState.message = action?.message; // TODO: remove message
  draftState.error = action.payload;
});

export const failedDefaults = produce((draftState: any, action?: any) => {
  draftState.__requested = null;
  draftState.isLoading = false;
  draftState.__succeeded = null;
  draftState.__failed = new Date();
  draftState.data = { ...draftState.data };
  draftState.message = action.message; // TODO: remove message
  draftState.error = action.payload;
});
