import _ from 'lodash';

import * as Url from '../constants/url';
import { GetPrivateQuotesListResponseDto, GetPrivateQuotesSnapResponseDto } from '../dtos/privateQuotes.dtos';
import { httpRequest } from '../utils/httpRequest';

export class PrivateQuotesApi {
  async getMany(authToken: string): Promise<GetPrivateQuotesListResponseDto> {
    const response = await httpRequest<GetPrivateQuotesListResponseDto>({
      method: 'GET',
      url: Url.GET_PRIVATE_QUOTES_LIST_URL,
      authToken,
    });

    return response;
  }

  async snap(symbols: string[], authToken: string): Promise<GetPrivateQuotesSnapResponseDto> {
    if (_.isEmpty(symbols)) {
      return { data: [], total: 0 };
    }
    const url = `${Url.GET_PRIVATE_QUOTES_SNAP_URL}?symbols=${JSON.stringify(_.uniq(symbols))}`;
    const response = await httpRequest<GetPrivateQuotesSnapResponseDto>({ method: 'GET', url, authToken });

    return response;
  }
}
