import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountSelector } from 'src/hooks';

import { downloadTaxDocument } from '../../actions';
import { TaxDocumentDto } from '../../dtos/statements.dtos';
import { AccountStatementDownload } from '../../models/statements.models';
import { getAccountStatementFilename } from '../../utils/getAccountStatementFilename';

import { DownloadAccountStatementButton } from './DownloadAccountStatementButton';

export interface DownloadTaxDocumentButtonProps {
  statement: TaxDocumentDto;
}

export const DownloadTaxDocumentButton = ({ statement }: DownloadTaxDocumentButtonProps) => {
  const dispatch = useDispatch();

  const taxDocumentsDownloads: AccountStatementDownload<TaxDocumentDto>[] = useSelector(
    (state: any) => state.statements.taxDocuments.downloads,
  );

  const { account } = useAccountSelector();

  const findTaxDocumentDownloadUrl = () => {
    const tradeConfirmationDownload = taxDocumentsDownloads.find(
      aDownload => aDownload.data.date === statement.date && aDownload.data.form === statement.form,
    );

    return tradeConfirmationDownload?.data?.url ?? null;
  };

  const isButtonLoading = () => {
    const taxDocumentDownload = taxDocumentsDownloads.find(
      aDownload => aDownload.data.date === statement.date && aDownload.data.form === statement.form,
    );

    return Boolean(taxDocumentDownload?.isLoading);
  };

  const onClick = () => {
    const fileUrl = findTaxDocumentDownloadUrl();

    if (fileUrl) {
      saveAs(fileUrl, getAccountStatementFilename(statement));

      return;
    }

    dispatch(downloadTaxDocument({ accountId: account?.accountId, ...statement }));
  };

  return <DownloadAccountStatementButton onClick={onClick} loading={isButtonLoading()} />;
};
