import * as Styles from './MFormItemError.styles';

export interface MFormItemErrorProps {
  value?: string | JSX.Element;
  testId?: string;
}

export const MFormItemError = ({ value, testId }: MFormItemErrorProps) => {
  if (value) {
    return (
      <span className={Styles.error} data-testid={testId}>
        {value}
      </span>
    );
  }

  return null;
};
