import { AnyAction } from 'redux';

import {
  CreateOfferOrderCardPaymentIntentDto,
  UpdateOfferOrderDto,
  DeleteOfferOrderDto,
  CreateOfferOrderCardPaymentIntentResponseDto,
  UpdateOfferOrderCardPaymentIntentDto,
  UpdateOfferOrderCardPaymentIntentResponseDto,
} from '../dtos/offerOrders.dtos';
import { OfferOrder, OfferOrderDetails, OfferOrderIntent } from '../models/offer-orders.model';

import { createAction, State, Type } from './utils';

export const offerOrdersGetListRequested = (): AnyAction => createAction(State.actionRequested(Type.GET_OFFER_ORDERS));

export const offerOrdersGetListSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_OFFER_ORDERS), payload);

export const doGetOfferOrderDetails = (id: string): AnyAction =>
  createAction(State.actionRequested(Type.GET_OFFER_ORDER_DETAILS), { id });

export const doSucceededGetOfferOrderDetails = (payload: OfferOrderDetails): AnyAction =>
  createAction(State.actionSucceeded(Type.GET_OFFER_ORDER_DETAILS), payload);

export const doClearGetOfferOrderDetails = (): AnyAction =>
  createAction(State.actionCleared(Type.GET_OFFER_ORDER_DETAILS));

export const offerOrdersCreateRequested = (payload: OfferOrderIntent): AnyAction =>
  createAction(State.actionRequested(Type.CREATE_OFFER_ORDERS), payload);

export const offerOrdersCreateSucceeded = (payload?: any): AnyAction =>
  createAction(State.actionSucceeded(Type.CREATE_OFFER_ORDERS), payload);

export const clearCreateOfferOrder = (): AnyAction => createAction(State.actionCleared(Type.CREATE_OFFER_ORDERS));

export const doUpdateOfferOrder = (payload: UpdateOfferOrderDto): AnyAction =>
  createAction(State.actionRequested(Type.UPDATE_OFFER_ORDER), payload);

export const doSucceededUpdateOfferOrder = (payload: OfferOrder): AnyAction =>
  createAction(State.actionSucceeded(Type.UPDATE_OFFER_ORDER), payload);

export const clearUpdateOfferOrder = (): AnyAction => createAction(State.actionCleared(Type.UPDATE_OFFER_ORDER));

export const deleteOfferOrder = (payload: DeleteOfferOrderDto): AnyAction =>
  createAction(State.actionRequested(Type.DELETE_OFFER_ORDER), payload);

export const deleteOfferOrderSucceeded = (): AnyAction => createAction(State.actionSucceeded(Type.DELETE_OFFER_ORDER));

export const clearDeleteOfferOrder = (): AnyAction => createAction(State.actionCleared(Type.DELETE_OFFER_ORDER));

export const addOfferOrderIntent = (payload: OfferOrderIntent) => createAction(Type.ADD_OFFER_ORDER_INTENT, payload);

export const removeOfferOrderIntent = () => createAction(Type.REMOVE_OFFER_ORDER_INTENT);

export const createOfferOrderCardPaymentIntent = (payload: CreateOfferOrderCardPaymentIntentDto) =>
  createAction(State.actionRequested(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT), payload);

export const createOfferOrderCardPaymentIntentSucceeded = (payload: CreateOfferOrderCardPaymentIntentResponseDto) =>
  createAction(State.actionSucceeded(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT), payload);

export const clearCreateOfferOrderCardPaymentIntent = () =>
  createAction(State.actionCleared(Type.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT));

export const updateOfferOrderCardPaymentIntent = (payload: UpdateOfferOrderCardPaymentIntentDto) =>
  createAction(State.actionRequested(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT), payload);

export const updateOfferOrderCardPaymentIntentSucceeded = (payload: UpdateOfferOrderCardPaymentIntentResponseDto) =>
  createAction(State.actionSucceeded(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT), payload);

export const updateOfferOrderCardPaymentIntentFailed = (payload: any) =>
  createAction(State.actionFailed(Type.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT), payload);

export const submitCardPayment = () => createAction(State.actionRequested(Type.SUBMIT_CARD_PAYMENT));

export const clearSubmitCardPayment = () => createAction(State.actionCleared(Type.SUBMIT_CARD_PAYMENT));

export const confirmCardPayment = () => createAction(State.actionRequested(Type.CONFIRM_CARD_PAYMENT));

export const confirmCardPaymentSucceeded = () => createAction(State.actionSucceeded(Type.CONFIRM_CARD_PAYMENT));

export const confirmCardPaymentFailed = (payload: any) =>
  createAction(State.actionFailed(Type.CONFIRM_CARD_PAYMENT), payload);

export const clearConfirmCardPayment = () => createAction(State.actionCleared(Type.CONFIRM_CARD_PAYMENT));
