import produce from 'immer';
import _ from 'lodash';
import { combineReducers } from 'redux';

import * as State from '../actions/types';
import { Type } from '../actions/utils';

import initialState from './initial';
import { clearedDefaults, failedDefaults, requestedDefaults, succeededDefaults } from './utils';

const accountStatementDownloadSuccess = produce((draftState: any, action: any) => {
  let statement = draftState.find((aStatement: any) => aStatement.data.date === action.payload.date);

  if (action.payload.form) {
    statement = draftState.find(
      (aStatement: any) => aStatement.data.date === action.payload.date && aStatement.data.form === action.payload.form,
    );
  }

  if (statement) {
    statement.data = action.payload;
    statement.isLoading = false;
    statement.hasError = false;
    statement.error = null;
  }
});

const accountStatementDownloadError = produce((draftState: any, action: any) => {
  let statement = draftState.find((aStatement: any) => aStatement.data.date === action.payload.date);

  if (action.payload.form) {
    statement = draftState.find(
      (aStatement: any) => aStatement.data.date === action.payload.date && aStatement.data.form === action.payload.form,
    );
  }

  if (statement) {
    statement.data = action.payload;
    statement.isLoading = false;
    statement.hasError = true;
    statement.error = action.error;
  }
});

const requestAccountStatementDownload = produce((draftState: any, { payload }: any) => {
  const payloadWithoutAccountId = _.omit(payload, 'accountId');

  draftState.push({
    data: payloadWithoutAccountId,
    isLoading: true,
    hasError: false,
    error: null,
  });
});

export const tradeConfirmationList = (state = initialState.statements.tradeConfirmations.list, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_TRADE_CONFIRMATION_LIST):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_TRADE_CONFIRMATION_LIST):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_TRADE_CONFIRMATION_LIST):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_TRADE_CONFIRMATION_LIST):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.statements.tradeConfirmations.list;
    default:
      return state;
  }
};

export const downloadTradeConfirmation = (
  state = initialState.statements.tradeConfirmations.downloads,
  action: any,
) => {
  switch (action.type) {
    case State.actionCleared(Type.DOWNLOAD_TRADE_CONFIRMATION):
      return initialState.statements.tradeConfirmations.downloads;
    case State.actionRequested(Type.DOWNLOAD_TRADE_CONFIRMATION):
      return requestAccountStatementDownload(state, action);
    case State.actionSucceeded(Type.DOWNLOAD_TRADE_CONFIRMATION):
      return accountStatementDownloadSuccess(state, action);
    case State.actionFailed(Type.DOWNLOAD_TRADE_CONFIRMATION):
      return accountStatementDownloadError(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.statements.tradeConfirmations.downloads;
    default:
      return state;
  }
};

export const monthlyStatementList = (state = initialState.statements.monthlyStatements.list, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_MONTHLY_STATEMENT_LIST):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_MONTHLY_STATEMENT_LIST):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_MONTHLY_STATEMENT_LIST):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_MONTHLY_STATEMENT_LIST):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.statements.monthlyStatements.list;
    default:
      return state;
  }
};

export const downloadMonthlyStatement = (state = initialState.statements.monthlyStatements.downloads, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.DOWNLOAD_MONTHLY_STATEMENT):
      return initialState.statements.monthlyStatements.downloads;
    case State.actionRequested(Type.DOWNLOAD_MONTHLY_STATEMENT):
      return requestAccountStatementDownload(state, action);
    case State.actionSucceeded(Type.DOWNLOAD_MONTHLY_STATEMENT):
      return accountStatementDownloadSuccess(state, action);
    case State.actionFailed(Type.DOWNLOAD_MONTHLY_STATEMENT):
      return accountStatementDownloadError(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.statements.monthlyStatements.downloads;
    default:
      return state;
  }
};

export const taxDocumentList = (state = initialState.statements.taxDocuments.list, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.GET_TAX_DOCUMENT_LIST):
      return clearedDefaults(state);
    case State.actionRequested(Type.GET_TAX_DOCUMENT_LIST):
      return requestedDefaults(state);
    case State.actionSucceeded(Type.GET_TAX_DOCUMENT_LIST):
      return succeededDefaults(state, action);
    case State.actionFailed(Type.GET_TAX_DOCUMENT_LIST):
      return failedDefaults(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.statements.taxDocuments.list;
    default:
      return state;
  }
};

export const downloadTaxDocument = (state = initialState.statements.taxDocuments.downloads, action: any) => {
  switch (action.type) {
    case State.actionCleared(Type.DOWNLOAD_TAX_DOCUMENT):
      return initialState.statements.taxDocuments.downloads;
    case State.actionRequested(Type.DOWNLOAD_TAX_DOCUMENT):
      return requestAccountStatementDownload(state, action);
    case State.actionSucceeded(Type.DOWNLOAD_TAX_DOCUMENT):
      return accountStatementDownloadSuccess(state, action);
    case State.actionFailed(Type.DOWNLOAD_TAX_DOCUMENT):
      return accountStatementDownloadError(state, action);
    case State.actionSucceeded(Type.LOGOUT):
      return initialState.statements.taxDocuments.downloads;
    default:
      return state;
  }
};

export const statementsReducers = combineReducers({
  tradeConfirmations: combineReducers({ list: tradeConfirmationList, downloads: downloadTradeConfirmation }),
  monthlyStatements: combineReducers({ list: monthlyStatementList, downloads: downloadMonthlyStatement }),
  taxDocuments: combineReducers({ list: taxDocumentList, downloads: downloadTaxDocument }),
});
