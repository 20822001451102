/* eslint-disable no-console */
import { call, put, takeEvery } from 'redux-saga/effects';
import { OfferOptionDto, OfferOptionTypeDto } from 'src/dtos';

import { getOfferDetailsSucceeded } from '../actions';
import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import {
  mapOfferDtoToModel,
  mapOfferDetailsDtoToModel,
  mapGetOfferOptionListResponseDtoToModel,
} from '../mappers/offers.mappers';
import { Offer, OfferDetails, OfferOption } from '../models/offers.models';
import { ResponseGenerator, TReduxAction } from '../typings/commonTypes';

import { HttpClient, replacePlaceholders, safeSaga } from './utils';

export function* getOffersList() {
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', Url.GET_OFFERS_URL, undefined);
  const offers: Offer[] = mapOfferDtoToModel(response.data);

  yield put({
    type: State.actionSucceeded(Type.OFFER_GET_OFFER_LIST),
    payload: offers,
  });
}

export function* getOfferDetails(action: TReduxAction) {
  const { id } = action.payload;

  const url = replacePlaceholders(Url.GET_OFFER_DETAILS_URL, {
    id,
  });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined);
  const offer: OfferDetails = mapOfferDetailsDtoToModel(response.data);
  yield put(getOfferDetailsSucceeded(offer));
}

function* getOfferOptionList(offerOption: string) {
  const url = replacePlaceholders(Url.GET_OFFER_OPTIONS, {
    type: offerOption,
  });
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', url, undefined);

  return response.data;
}

function* getAllOfferOptions() {
  const securityType: OfferOptionDto[] = yield call(getOfferOptionList, OfferOptionTypeDto.SecurityType);
  const industry: OfferOptionDto[] = yield call(getOfferOptionList, OfferOptionTypeDto.Industry);
  const category: OfferOptionDto[] = yield call(getOfferOptionList, OfferOptionTypeDto.Category);
  const offerType: OfferOptionDto[] = yield call(getOfferOptionList, OfferOptionTypeDto.Type);
  const status: OfferOptionDto[] = yield call(getOfferOptionList, OfferOptionTypeDto.Status);

  let result: OfferOption[] = [];
  result = result.concat(
    mapGetOfferOptionListResponseDtoToModel({ offerOptions: securityType, type: OfferOptionTypeDto.SecurityType }),
  );
  result = result.concat(
    mapGetOfferOptionListResponseDtoToModel({ offerOptions: industry, type: OfferOptionTypeDto.Industry }),
  );
  result = result.concat(
    mapGetOfferOptionListResponseDtoToModel({ offerOptions: category, type: OfferOptionTypeDto.Category }),
  );
  result = result.concat(
    mapGetOfferOptionListResponseDtoToModel({ offerOptions: offerType, type: OfferOptionTypeDto.Type }),
  );
  result = result.concat(
    mapGetOfferOptionListResponseDtoToModel({ offerOptions: status, type: OfferOptionTypeDto.Status }),
  );

  yield put({
    type: State.actionSucceeded(Type.GET_ALL_OFFER_OPTIONS),
    payload: result,
  });
}

/**
 * Offers sagas
 */
export default function* offersSaga() {
  yield takeEvery(State.actionRequested(Type.OFFER_GET_OFFER_LIST), safeSaga(getOffersList, Type.OFFER_GET_OFFER_LIST));
  yield takeEvery(
    State.actionRequested(Type.OFFER_GET_OFFER_DETAILS),
    safeSaga(getOfferDetails, Type.OFFER_GET_OFFER_DETAILS),
  );
  yield takeEvery(
    State.actionRequested(Type.GET_ALL_OFFER_OPTIONS),
    safeSaga(getAllOfferOptions, Type.GET_ALL_OFFER_OPTIONS),
  );
}
