import { Col, Row } from 'antd';

import TooltipComp from '../../../../lib/Miscellaneous/Tooltip';
import { OfferDetails } from '../../../../models/offers.models';

import { getFirstColumnDetails, getSecondColumnDetails } from './constants';
import * as Styles from './OfferDetailsTab.styles';

export interface OfferDetailsTabProps {
  offering: OfferDetails;
}

export const OfferDetailsTab = ({ offering }: OfferDetailsTabProps) => {
  const detailsTabDataFirstColumn = getFirstColumnDetails(offering);

  const detailsTabDataSecondColumn = getSecondColumnDetails(offering);

  return (
    <Row className={Styles.container}>
      <Col className={Styles.tabContainer}>
        {detailsTabDataFirstColumn.map(offerDetails => {
          return (
            !offerDetails.hidden && (
              <div key={offerDetails.label} className={Styles.detailTabs}>
                <div className={Styles.detailsLabel}>{offerDetails.label}</div>
                <div className={Styles.detailsValue}>{offerDetails.value}</div>
              </div>
            )
          );
        })}
      </Col>

      <Col className={Styles.tabDataContainer}>
        {detailsTabDataSecondColumn.map(offerDetails => {
          return (
            !offerDetails.hidden && (
              <div key={`${offerDetails.label}_key`} className={Styles.detailTabs}>
                <div className={Styles.detailsLabel}>
                  <span>{offerDetails.label}</span>
                  {offerDetails.description && <TooltipComp text={offerDetails.description} />}
                </div>
                <div className={Styles.detailsValue}>{offerDetails.value}</div>
              </div>
            )
          );
        })}
      </Col>
    </Row>
  );
};
