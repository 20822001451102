import React, { Fragment, useMemo } from 'react';

import { Card, Col, Divider, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { Link } from 'react-router-dom';
import { MTag } from 'src/lib';
import { isRegDTypeCOffer } from 'src/utils';

import CurrencyField from '../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { RemainingOfferDaysTag } from '../../../../lib/RemainingOfferDaysTag/RemainingOfferDaysTag';
import { Offer } from '../../../../models/offers.models';
import { Images } from '../../../../styles';

import * as Styles from './styles';

export interface FeaturedOfferProps {
  featuredOffer: Offer & { hasPendingOfferOrders: boolean };
  setIsWhatsAccreditedInvestorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeaturedOffer = ({ featuredOffer, setIsWhatsAccreditedInvestorOpen }: FeaturedOfferProps) => {
  const coverCard = useMemo(() => {
    return (
      <Fragment>
        <img
          alt='feature_image'
          className={Styles.featuredOfferImage}
          src={
            featuredOffer?.videoThumbnailUrl ||
            featuredOffer?.assets[0]?.previewImageUrl ||
            featuredOffer?.assets[0]?.url ||
            Images.DefaultOffering
          }
        />
        <Row
          gutter={[
            { xs: 5, sm: 6, md: 8 },
            { xs: 5, sm: 6, md: 8 },
          ]}
          className={Styles.featuredOfferTags}>
          <Col>
            <MTag type='blue'>Featured</MTag>
          </Col>
          <Col>
            <RemainingOfferDaysTag offer={featuredOffer} />
          </Col>
        </Row>
      </Fragment>
    );
  }, [featuredOffer]);

  const metaCard = useMemo(() => {
    return (
      <Fragment>
        <Row>
          <Col span={24} className={Styles.featuredOfferName}>
            {featuredOffer.name}
          </Col>
          <Col span={24} className={Styles.featuredOfferSubDetails}>
            {featuredOffer.industry}
          </Col>
          <Col span={24} className={Styles.featuredOfferSubDetails}>
            {featuredOffer.securityType}
          </Col>
        </Row>
        <Row style={{ marginTop: '2.5rem' }}>
          <Col span={24} className={Styles.minimalInvestment}>
            <CurrencyField value={featuredOffer?.minimumInvestCurrencyAmt} />
          </Col>

          <Col span={24} className={Styles.featuredOfferSubDetails}>
            <p>Minimum Investment</p>
          </Col>
        </Row>
        <Divider className={Styles.divider} />
        <Row
          gutter={[
            { xs: 5, sm: 6, md: 8 },
            { xs: 5, sm: 6, md: 8 },
          ]}>
          {(featuredOffer?.isComingSoon || featuredOffer?.new) && (
            <Col>
              <MTag type='lightblue' closable={false}>
                {featuredOffer?.isComingSoon ? 'Coming Soon' : 'New'}
              </MTag>
            </Col>
          )}

          {featuredOffer?.offerType && (
            <Col>
              <MTag type='gray'>{featuredOffer.offerType}</MTag>
            </Col>
          )}

          {isRegDTypeCOffer(featuredOffer) && (
            <Col>
              <MTag
                type='gray'
                closable={false}
                className={Styles.accreditedInvestorsOnly}
                onClick={event => {
                  event.preventDefault();
                  setIsWhatsAccreditedInvestorOpen(true);
                }}>
                <span className={Styles.accreditedInvestorsOnlyText}>Accredited Investors Only</span>{' '}
                <i className='ri-information-line' />
              </MTag>
            </Col>
          )}
          {featuredOffer?.hasPendingOfferOrders && (
            <Col>
              <MTag type='orange'>Pending Order</MTag>
            </Col>
          )}
        </Row>
      </Fragment>
    );
  }, [featuredOffer]);

  return (
    <Link
      key={featuredOffer?.name}
      to={`/offers/${featuredOffer.shortUrlRoute ? featuredOffer.shortUrlRoute : featuredOffer.id}`}>
      <Card className={Styles.cardWrapper} cover={coverCard}>
        <Meta title={metaCard} />
      </Card>
    </Link>
  );
};

export default FeaturedOffer;
