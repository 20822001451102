import { useEffect } from 'react';

import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { useAccountSelector } from 'src/hooks';
import { ScreenBreakpoint } from 'src/styles';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { DisclosuresSection } from './DisclosuresSection';

export const Disclosures = () => {
  const navigate = useNavigate();

  const isCreateSuccess = useSelector((state: any) => Boolean(state.accountHolders.createDisclosures.__succeeded));
  const isPatchSuccess = useSelector((state: any) => Boolean(state.accountHolders.patchDisclosures.__succeeded));

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const hasAnyUpsertRequest = () => isCreateSuccess || isPatchSuccess;

  const isComplete = () => !isUndefined(account?.primaryAccountHolder?.disclosure?.statementDelivery?.value);

  const shouldSendAnalyticsEvent = () => !account?.accountId && isCreateSuccess && isComplete();

  const shouldRedirectToAccountAgreements = () => !account?.accountId && hasAnyUpsertRequest() && isComplete();

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_disclosures_complete');
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (shouldRedirectToAccountAgreements()) {
      navigate(`/accounts/${account?.id}/account-agreements`);
    }
  }, [isCreateSuccess, isPatchSuccess]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.Disclosures}
      title={isMobile ? undefined : 'Disclosures'}>
      <DisclosuresSection />
    </MyAccountLayout>
  );
};
