import { countryCodes, US_COUNTRY_STATE_LIST } from 'src/constants';

export const countryOptions = countryCodes.map(country => ({ value: country.code, label: country.name }));

export const getCountryOptionList = () => countryOptions;

export const getCountryOptionLabel = (value?: string) => {
  const country = countryOptions.find(country => country.value === value);

  return country?.label ?? value;
};

export const getUnitedStateOptionLabel = (value?: string) => {
  const state = US_COUNTRY_STATE_LIST.find(state => state.value === value);

  return state?.label ?? value;
};
