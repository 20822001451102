import { cx } from '@emotion/css';
import { AlignItems, Display, Font, Spacing } from 'src/styles';

export interface SampleDocumentDownloadLinkProps {
  text: string;
  link: string;
  icon: string;
}

export const SampleDocumentDownloadLink = ({ text, link, icon }: SampleDocumentDownloadLinkProps): JSX.Element => {
  return (
    <a href={link} target='_blank' rel='noreferrer' className={cx(Display.flex, AlignItems.center)}>
      <i className={cx(icon, Spacing.mr4)}></i> <span className={Font.md}> {text}</span>
    </a>
  );
};
