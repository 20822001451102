import React, { useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

export const BusinessContinuityPlan = () => {
  const [, setTitle] = useOutletContext<any>();

  useEffect(() => {
    setTitle('Business Continuity Plan');
  }, []);

  return (
    <div>
      <p>
        Cambria Capital, LLC dba My IPO has developed a Business Continuity Plan on how we will respond to events that
        significantly disrupt our business. Since the timing and impact of disasters and disruptions is unpredictable,
        we will have to be flexible in responding to actual events as they occur. With that in mind, we are providing
        you with this information on our business continuity plan.
      </p>
      <p>
        <strong>Contacting Us</strong> – If after a significant business disruption you cannot contact us as you usually
        do at (385) 429-6400 or at (844) 226-0640, you should contact the clearing firm for your account for
        instructions on how it may obtain prompt access to customer funds and securities, enter and process orders and
        any other trade-related items.
      </p>
      <p>
        Apex Clearing Corporation, at 214-765-1055 or{' '}
        <a target='_blank' rel='noopener noreferrer' href='www.apexclearing.com'>
          www.apexclearing.com
        </a>
      </p>
      <p>
        <strong>Our Business Continuity Plan</strong> – We plan to quickly recover and resume business operations after
        a significant business disruption and respond by safeguarding our employees and property, making a financial and
        operational assessment, protecting the firm’s books and records, and allowing our customers to transact
        business. In short, our business continuity plan is designed to permit our firm to resume operations as quickly
        as possible, given the scope and severity of the significant business disruption.
      </p>
      <p>
        Our business continuity plan addresses: data back-up and recovery; all mission critical systems; financial and
        operational assessments; alternative communications with customers, employees, and regulators; alternate
        physical location of employees; critical supplier, contractor, bank and counter-party impact; regulatory
        reporting; and assuring our customers prompt access to their funds and securities if we are unable to continue
        our business.
      </p>
      <p>
        Our clearing firm, Apex Clearing, backs up our important records in a geographically separate area. While every
        emergency situation poses unique problems based on external factors, such as time of day and the severity of the
        disruption, our clearing firm has advised us that its objective is to restore its own operations and be able to
        complete existing transactions and accept new transactions and payments within 4 to 12 hours. Your orders and
        requests for funds and securities could be delayed during this period.
      </p>
      <p>
        <strong>Varying Disruptions</strong> – Significant business disruptions can vary in their scope, such as only
        our firm, a single building housing our firm, the business district where our firm is located, the city where we
        are located, or the whole region. Within each of these areas, the severity of the disruption can also vary from
        minimal to severe. In a disruption to only our firm or a building housing our firm, we will transfer our
        operations to a local site when needed and expect to recover and resume business within 4 to 12 hours. In a
        disruption affecting our business district, city, or region, we will transfer our operations to a site outside
        of the affected area, and recover and resume business within 4 to 12 hours. In either situation, we plan to
        continue in business, and transfer operations to our clearing firm if necessary, and notify you through our
        customer emergency hotline as to how to contact us. If the significant business disruption is so severe that it
        prevents us from remaining in business, we will assure our customer’s prompt access to their funds and
        securities.
      </p>
      <p>
        <strong>For more information</strong> – If you have questions about our business continuity planning, you can
        contact us at (844) 226-0640.
      </p>
    </div>
  );
};

export default BusinessContinuityPlan;
