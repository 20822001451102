import React, { Fragment } from 'react';

import { Card, Col, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { Link } from 'react-router-dom';
import { MTag } from 'src/lib';
import { isOfferClosed, isRegDTypeCOffer, shouldDisplayTotalCapitalRaised } from 'src/utils';

import { OfferWatchDto } from '../../../../dtos/offerWatches.dtos';
import { MDivider } from '../../../../lib/MDivider/MDivider';
import CurrencyField from '../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { RemainingOfferDaysTag } from '../../../../lib/RemainingOfferDaysTag/RemainingOfferDaysTag';
import { Offer } from '../../../../models/offers.models';
import { Images } from '../../../../styles';

import * as Styles from './styles';

export interface OfferCardProps {
  offer: Offer & { hasPendingOfferOrders: boolean };
  offerWatch?: OfferWatchDto;
  setIsWhatsAccreditedInvestorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OfferCard = ({ offer, offerWatch, setIsWhatsAccreditedInvestorOpen }: OfferCardProps) => {
  const coverCard = () => {
    return (
      <Fragment>
        <img
          alt='thumbnail'
          className={Styles.offerImage}
          src={
            offer?.videoThumbnailUrl ||
            offer?.assets[0]?.previewImageUrl ||
            offer?.assets[0]?.url ||
            Images.DefaultOffering
          }
        />
        <Row
          gutter={[
            { xs: 5, sm: 6, md: 8 },
            { xs: 5, sm: 6, md: 8 },
          ]}
          className={Styles.offerTags}>
          {!isOfferClosed(offer) && (
            <Fragment>
              {(offer.isComingSoon || offer.new) && (
                <Col>
                  <MTag type='lightblue' closable={false}>
                    {offer?.isComingSoon ? 'Coming Soon' : 'New'}
                  </MTag>
                </Col>
              )}
              <Col>
                <RemainingOfferDaysTag offer={offer} />
              </Col>
            </Fragment>
          )}
        </Row>
      </Fragment>
    );
  };

  const metaCard = () => {
    return (
      <Fragment>
        <Row>
          <Col span={24} className={Styles.offerName}>
            {offer.name}
          </Col>
          <Col span={24} className={Styles.offerSubDetails}>
            {offer.industry}
          </Col>
          <Col span={24} className={Styles.offerSubDetails}>
            {offer.securityType}
          </Col>
        </Row>
        <Row className={Styles.offerSectionContainer}>
          {shouldDisplayTotalCapitalRaised(offer) ? (
            <Col span={24} className={Styles.totalCapitalRaised}>
              <CurrencyField value={offer.totalCapitalRaised} />
            </Col>
          ) : (
            <Col span={24} className={Styles.minimalInvestment}>
              <CurrencyField value={offer.minimumInvestCurrencyAmt} />
            </Col>
          )}
          <Col span={24} className={Styles.offerSubDetails}>
            <p>{shouldDisplayTotalCapitalRaised(offer) ? 'Total Capital Raised' : 'Minimum Investment'}</p>
          </Col>
        </Row>
        <MDivider />
        <Row
          gutter={[
            { xs: 5, sm: 6, md: 8 },
            { xs: 5, sm: 6, md: 8 },
          ]}>
          {offer.status && (
            <Col>
              <MTag type={isOfferClosed(offer) ? 'red' : 'green'}>{offer.status}</MTag>
            </Col>
          )}
          {offer.offerType && (
            <Col>
              <MTag type='gray'>{offer.offerType}</MTag>
            </Col>
          )}
          {isRegDTypeCOffer(offer) && (
            <Col>
              <MTag
                type='gray'
                closable={false}
                className={Styles.accreditedInvestorsOnly}
                onClick={event => {
                  event.preventDefault();
                  setIsWhatsAccreditedInvestorOpen(true);
                }}>
                <span className={Styles.accreditedInvestorsOnlyText}>Accredited Investors Only</span>{' '}
                <i className='ri-information-line' />
              </MTag>
            </Col>
          )}
          {offer?.isComingSoon && offerWatch && (
            <Col>
              <MTag type='lightgreen' icon={<i className={`ri-heart-2-line ${Styles.indicationLogo}`} />}>
                Interest Indicated
              </MTag>
            </Col>
          )}
          {offer?.hasPendingOfferOrders && (
            <Col>
              <MTag type='orange' icon={<i className='ri-time-line' />}>
                Pending Order
              </MTag>
            </Col>
          )}
        </Row>
      </Fragment>
    );
  };

  return (
    <Link key={offer.name} to={`/offers/${offer.shortUrlRoute ? offer.shortUrlRoute : offer.id}`}>
      <Card className={Styles.cardWrapper} cover={coverCard()}>
        <Meta title={metaCard()} />
      </Card>
    </Link>
  );
};

export default OfferCard;
