import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { signUp, clearSignup, clearLogin, setAuthentication } from 'src/actions';
import { US_PHONE_NUMBER_PREFIX } from 'src/constants';
import { AccountHolderPhoneNumberTypeDto, AuthenticatedUserLoginResponseDto } from 'src/dtos';
import { MInput, PhoneNumberInput, PhoneNumberOption, MButton, MInputPassword, PasswordStrengthBar } from 'src/lib';
import { ScreenBreakpoint } from 'src/styles';

import * as Styles from './Register.styles';
import { registerValidationSchema } from './validations';

interface RegisterFormValues {
  email: string;
  phoneNumber: PhoneNumberOption[];
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  password: string;
  confirmPassword: string;
}

export const RegisterForm = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const isRegisterLoading = useSelector((state: any) => Boolean(state.user.signUp.__requested));
  const hasRegisterSucceeded = useSelector((state: any) => Boolean(state.user.signUp.__succeeded));
  const userSignup = useSelector((state: any) => state.user.signUp.data);
  const isLoginLoading = useSelector((state: any) => Boolean(state.user.login.__requested));
  const loginSucceded = useSelector((state: any) => Boolean(state.user.login.__succeeded));
  const loginData: AuthenticatedUserLoginResponseDto | undefined = useSelector((state: any) => state.user.login.data);
  const [credentials, setCredentials] = useState<{ email: string; password: string } | null>(null);
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });
  const CAN_PREFIX_CODE = '+1-';

  const findColSpan = () => (isMobile ? 24 : 12);

  useEffect(() => {
    if (hasRegisterSucceeded && loginSucceded && !isEmpty(loginData) && !isEmpty(userSignup)) {
      window.gtag('event', 'sign_up_register_complete', {
        user_properties: {
          user_id: loginData.user.id, // TODO: return user id from /signup and use it
          user_email: userSignup.email,
          user_first_name: userSignup.firstName,
          user_last_name: userSignup.lastName,
        },
      });
      const { accessToken, idToken, tbToken } = loginData;
      const authToken = `Bearer ${accessToken}; idToken ${idToken}; tbToken ${tbToken}`;
      dispatch(setAuthentication({ ...loginData, authToken, ...credentials }));
      navigateTo(`/address`);
    }
  }, [hasRegisterSucceeded, loginSucceded, loginData, userSignup]);

  useEffect(() => {
    return () => {
      dispatch(clearSignup());
      dispatch(clearLogin());
    };
  }, []);

  return (
    <>
      <Formik<RegisterFormValues>
        validationSchema={registerValidationSchema}
        initialValues={{
          email: '',
          phoneNumber: [
            {
              type: AccountHolderPhoneNumberTypeDto.Mobile,
              value: '',
              prefix: US_PHONE_NUMBER_PREFIX,
            },
          ],
          firstName: '',
          lastName: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={values => {
          const dto = {
            ...registerValidationSchema.cast(values),
            suffix: values.suffix === '' ? undefined : values.suffix,
            middleName: values.middleName === '' ? undefined : values.middleName,
            confirmPassword: undefined,
            phoneNumber:
              values.phoneNumber?.[0].prefix === CAN_PREFIX_CODE
                ? `+1${values.phoneNumber?.[0].value}`
                : `${values.phoneNumber?.[0].prefix}${values.phoneNumber?.[0].value}`,
          };
          dispatch(signUp(dto));

          if (values.email && values.password) {
            setCredentials({ email: values.email, password: values.password });
          }
        }}>
        {form => {
          return (
            <>
              <Row>
                <Col span={findColSpan()} className={Styles.leftFormItem(isMobile)}>
                  <MInput
                    placeholder='Email'
                    value={form.values.email}
                    onChange={value => form.setFieldValue('email', value)}
                    error={form.errors.email}
                    name='email_123'
                    autoComplete='off'
                    testId={'signup-email'}
                  />
                </Col>
                <Col span={findColSpan()} className={Styles.rightFormItem(isMobile)}>
                  <PhoneNumberInput
                    placeholder='Phone Number'
                    multiple={false}
                    international
                    defaultValue={{
                      type: AccountHolderPhoneNumberTypeDto.Mobile,
                      value: '',
                      prefix: US_PHONE_NUMBER_PREFIX,
                    }}
                    options={
                      form.values.phoneNumber
                        ? form.values.phoneNumber
                        : [
                            {
                              type: AccountHolderPhoneNumberTypeDto.Mobile,
                              value: '',
                              prefix: US_PHONE_NUMBER_PREFIX,
                            },
                          ]
                    }
                    onChange={value => {
                      form.setFieldValue('phoneNumber', value);
                    }}
                    error={form.errors.phoneNumber as any}
                    autoComplete='off'
                    testId={'signup-phone-number'}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={findColSpan()} className={Styles.leftFormItem(isMobile)}>
                  <MInput
                    placeholder='First Name'
                    value={form.values.firstName}
                    onChange={value => form.setFieldValue('firstName', value)}
                    error={form.errors.firstName}
                    autoComplete='off'
                    testId={'signup-first-name'}
                  />
                </Col>
                <Col span={findColSpan()} className={Styles.rightFormItem(isMobile)}>
                  <MInput
                    placeholder='Middle Initial or Middle Name'
                    value={form.values.middleName}
                    onChange={value => form.setFieldValue('middleName', value)}
                    error={form.errors.middleName}
                    autoComplete='off'
                    testId={'signup-middle-name'}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={findColSpan()} className={Styles.leftFormItem(isMobile)}>
                  <MInput
                    placeholder='Last Name'
                    value={form.values.lastName}
                    onChange={value => form.setFieldValue('lastName', value)}
                    error={form.errors.lastName}
                    testId={'signup-last-name'}
                  />
                </Col>
                <Col span={findColSpan()} className={Styles.rightFormItem(isMobile)}>
                  <MInput
                    placeholder='Suffix (i.e. Jr, Sr, II, etc.)'
                    value={form.values.suffix}
                    onChange={value => form.setFieldValue('suffix', value)}
                    error={form.errors.suffix}
                    autoComplete='off'
                    testId={'signup-suffix'}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={findColSpan()} className={Styles.leftFormItem(isMobile)}>
                  <MInputPassword
                    placeholder='Password'
                    value={form.values.password}
                    onChange={value => form.setFieldValue('password', value)}
                    error={form.errors.password}
                    name='password_123'
                    autoComplete='new-password'
                    testId={'signup-password'}
                  />
                </Col>
                <Col span={findColSpan()} className={Styles.rightFormItem(isMobile)}>
                  <MInputPassword
                    placeholder='Confirm Password'
                    value={form.values.confirmPassword}
                    onChange={value => form.setFieldValue('confirmPassword', value)}
                    error={form.errors.confirmPassword}
                    name='confirmPassword_123'
                    autoComplete='new-password'
                    testId={'signup-confirm-password'}
                  />
                </Col>
              </Row>
              <Row>
                <PasswordStrengthBar
                  password={form.values.password}
                  confirmPassword={form.values.confirmPassword}
                  firstName={form.values.firstName}
                  lastName={form.values.lastName}
                  showStrengthLabel
                  showValidationRules
                />
              </Row>

              <Row className={Styles.center}>
                <MButton
                  type='secondary'
                  onClick={() => {
                    form.submitForm();
                  }}
                  loading={isRegisterLoading || isLoginLoading}
                  disabled={isRegisterLoading || isLoginLoading || !form.isValid}
                  testId={'register-btn-signup'}>
                  Sign up
                </MButton>
              </Row>
            </>
          );
        }}
      </Formik>
      <div className={Styles.alreadyHaveAccountLabel}>
        <span className={Styles.space}>Already have an account?</span>
        <Link to='/login' className={Styles.linkButton}>
          Log in here.
        </Link>
      </div>
    </>
  );
};
