import { TradingBlockApplicationCategoryNameDto } from 'src/dtos';

import * as Styles from './AccountStatus.styles';

interface CipFailureDescriptionProps {
  category: TradingBlockApplicationCategoryNameDto;
}

export const ApplicationCipFailureDescription = ({ category }: CipFailureDescriptionProps) => {
  if (category === TradingBlockApplicationCategoryNameDto.AffiliateApproval) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Affiliated Firm Approval (3210 Letter) is required</div>
        <div className={Styles.investigationDescription}>
          Since you stated you are employed by or a member of a registered broker-dealer, securities or futures
          exchange, or securities industry regulatory body (e.g., FINRA, NFA), a 3210 Form Letter from your employer is
          required to process your application.
        </div>
      </div>
    );
  }

  if (category === TradingBlockApplicationCategoryNameDto.Signature) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Signature is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate your Signature are required to process your application.
        </div>
      </div>
    );
  }

  if (category === TradingBlockApplicationCategoryNameDto.GovernmentId) {
    return (
      <div>
        <div className={Styles.investigationTitle}>Proof of Goverment Identification is required</div>
        <div className={Styles.investigationDescription}>
          Additional document(s) to validate your Goverment Identification are required to process your application.
        </div>
      </div>
    );
  }

  return null;
};
