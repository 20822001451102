import React from 'react';

const RestrictedPersonDisclaimer = (): JSX.Element => {
  return (
    <div>
      Certain persons are prohibited from participating in new issue offerings. Please review the following definition
      of a &quot;restricted person&quot; prior to electronically acknowledging and confirming that you do not fall into
      &quot;restricted person&quot; status.
      <br />
      <br />
      The Financial Industry Regulatory Authority (FINRA) is the largest independent regulator for all securities firms
      doing business in the United States. FINRA has a number of rules related to participation in new issue offerings.
      One of the rules, 5130, states that &quot;restricted persons&quot; are prohibited from participating in new issue
      offerings. Please review the following definition of a &quot;restricted person&quot; prior to electronically
      acknowledging and confirming that you do not fall into &quot;restricted person&quot; status.
      <br />
      <br />
      FINRA Rule 5130 defines a restricted person as:
      <br />
      <br />
      <ol>
        <li>FINRA Member Firms or other Broker/Dealers</li>
        <li>Broker Dealer personnel</li>
        <ol type='a'>
          <li>
            Any Officer, Director, General Partner, associated person or employee of a member firm or any other
            Broker/Dealer.
          </li>

          <li>
            Any agent of a member firm or any other Broker/Dealer that is engaged in the investment banking or
            securities business.
          </li>

          <li>
            Any immediate family member of a person specified above. Immediate family members include a persons parents,
            mother-in-law or father-in-law, spouse, brother or sister, brother-in-law or sister-in-law, son-in-law or
            daughter-in-law, and children.
          </li>
          <ol type='I'>
            <li>Person that materially supports or receives material support from the immediate family member.</li>
            <li>
              Person employed by or associated with the member, or an affiliate of the member, selling the new issue to
              the immediate family member.
            </li>
            <li>Person that has an ability to control the allocation of the new issue.</li>
          </ol>
        </ol>
        <li>Person that has an ability to control the allocation of the new issue.</li>
        <ol type='a'>
          <li>
            With respect to the security being offered, a finder or any person acting in a fiduciary capacity to the
            managing underwriter, including, but not limited to, attorneys, accountants, and financial consultants and
            any immediate family members (or person(s) receiving material support or receives material support from the
            family member) of a person identified as a Finder or Fiduciary.
          </li>
        </ol>
        <li>Portfolio Managers</li>
        <ol type='a'>
          <li>
            Any person who has authority to buy or sell securities for a bank, savings and loan institution, insurance
            company, investment company, investment advisor, or collective investment account.
          </li>
          <li>
            Any immediate family member of a person specified under Portfolio Managers that materially supports, or
            receives material support from such person.
          </li>
        </ol>
        <li>Persons Owning a Broker/Dealer</li>
        <ol type='a'>
          <li>
            Any person listed, or required to be listed, in Schedule A, Schedule B, and Schedule C of a Form BD, except
            persons identified by ownership of less than 10%.
          </li>
          <li>
            Any person that directly or indirectly owns 10% or more of a public reporting company listed, or required to
            be listed, in Schedule A of a Form BD.
          </li>
          <li>
            Any person that directly or indirectly owns 25% or more of a public reporting company listed, or required to
            be listed, in Schedule B of a Form BD.
          </li>
          <li>
            Any immediate family member of a person specified in (5) (1-3) unless the person owning the Broker/Dealer:
          </li>
          <ol type='I'>
            <li>Does not materially support, or receive material support from the immediate family member.</li>
            <li>
              Is not an owner of the member, or an affiliate of the member, selling the new issue to the immediate
              family member
            </li>
            <li>Has no ability to control the allocation of the new issue;</li>
          </ol>
        </ol>
      </ol>
      <br />
      <br />
      The account holder hereby represents and warrants to My IPO as of the date of this acknowledgment and confirmation
      below that:
      <ol>
        <li>The account holder is authorized to represent the beneficial holders of the account.</li>
        <li>
          Neither the account holder nor any beneficial holder of the account is a &quot;restricted persons&quot; as
          that term is described in FINRA Rule 5130 (described above).
        </li>
        <li>
          The account holder understands FINRA Rule 5130 and the account is eligible to purchase new issues in
          compliance with such a rule.
        </li>
      </ol>
      <br />
      <br />
      The account holder acknowledges and confirms to My IPO with the intent that My IPO rely on them in effecting
      transactions on behalf of the account and undertakes to provide My IPO with prompt notice should any of the
      representations and warranties contained herein no longer be true.
      <br />
      <br />
      The Financial Industry Regulatory Authority (FINRA) has a number of rules related to participation in &quot;New
      Issue&quot; offerings. In compliance with FINRA Rule 5131 New Issue Allocations and Distribution, My IPO may not
      allocate shares of a new issue to any account in which an executive officer or director of a &quot;Public
      Company&quot; or &quot;Covered Non-Public Company&quot;, who is in a position to, and either has, or intends to
      influence the direction of &quot;Investment Banking Services&quot;, or a person &quot;materially supported&quot;
      by such executive officer or director, has a &quot;Beneficial Interest&quot; under Rule 5131. My IPO must, before
      selling a new issue to any account, in good faith have obtained within the prior 12 months written certification
      by the account owner(s), certifying that the account is eligible to purchase New Issues in compliance with Rule
      5131.
      <br />
      <br />
      The undersigned hereby certifies that he or she is authorized to provide this certification and will promptly
      notify My IPO if in the event that this certification ceases to be true and correct.
      <br />
      <br />
      Definitions:
      <ul>
        <li>
          New Issue means any initial public offering (&quot;IPO&quot;) of an equity security as defined in Section
          3(a)(11) of the Securities Exchange Act of 1934, made pursuant to a registration statement or offering
          circular.
        </li>
        <li>
          Public Company refers to any company that is registered under Section 12 of the Exchange Act or files periodic
          reports pursuant to Section 15(d) thereof.
        </li>
        <li>Covered Non-Public Company means any non-public company satisfying the following criteria:</li>
        <ol type='a'>
          <li>
            Income of at least $1 million in the last fiscal year or in two of the last three fiscal years and
            shareholders&apos; equity of at least $15 million.
          </li>
          <li>Shareholders&apos; equity of at least $30 million and a two-year operating history</li>
          <li>
            Total assets and total revenue of at least $75 million in the latest fiscal year or in two of the last three
            fiscal years.
          </li>
        </ol>
        <li>
          Investment Banking Services includes, without limitation, acting as an underwriter, participating in a selling
          group in an offering for the issuer or otherwise acting in furtherance of a public offering of the issuer.
        </li>
        <li>
          Material Support means directly or indirectly providing more than 25% of a person&apos;s income in the prior
          calendar year. Members of the immediate family living in the same household are deemed to be providing each
          other with material support.
        </li>
      </ul>
    </div>
  );
};

export default RestrictedPersonDisclaimer;
