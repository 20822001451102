import { call, put, select, takeEvery } from 'redux-saga/effects';

import { configsSucceeded } from '../actions/configs';
import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator } from '../typings/commonTypes';

import { HttpClient, safeSaga } from './utils';

export function* getConfigsRequested() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', Url.CONFIGS_URL, null, authToken);
  yield put(configsSucceeded(response.data));
}

/**
 * Configs sagas
 */
export default function* configSagas() {
  yield takeEvery(State.actionRequested(Type.GET_ALL_CONFIGS), safeSaga(getConfigsRequested, Type.GET_ALL_CONFIGS));
}
