import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { useLocation } from 'react-router-dom';

import faqAnswers from './constants';
import * as Styles from './FAQ.styles';

export const FAQ = () => {
  const location = useLocation();
  const [faqs] = useState(faqAnswers);

  useEffect(() => {
    if (location?.hash) document.querySelector(`${location?.hash}`)?.scrollIntoView();
  }, []);

  const sections = [
    'The Basics',
    'Account Information',
    'For Companies',
    'Offer and Trading Information',
    'Investment Information',
    'General Offer Information',
    'Regulatory Information',
  ];

  const getCategorySelector = (section: string) => {
    const sectionName = section.toLowerCase();

    return (
      <a href={`#${sectionName.replace(/\s/g, '')}`} className={Styles.categorySelector}>
        {section}
      </a>
    );
  };

  const getQuestions = (key: string, question: string, answer: any) => {
    return (
      <Row className={Styles.questionContainer} key={key}>
        <Col span={24}>
          <span className={Styles.question}>{question}</span>
        </Col>
        <Col span={24}>
          <span className={Styles.answer}>{answer()}</span>
        </Col>
      </Row>
    );
  };

  const getFAQSection = (title: string, questions: any, id: string) => (
    <Row id={id} className={Styles.faqSectionBox} key={id}>
      <Col span={24} className={Styles.sectionHeader}>
        {title}
      </Col>
      <Col span={24}>
        {questions.map((questionObject: any) =>
          getQuestions(
            `${id}+${questionObject.question.toLowerCase().replace(/\s/g, '')}`,
            questionObject.question,
            questionObject.answerComp,
          ),
        )}
      </Col>
    </Row>
  );

  return (
    <div className={Styles.faqContainer}>
      <Row className={Styles.titleWrapper}>
        <Col span={24}>
          <span className={Styles.title}>Frequently Asked Questions</span>
        </Col>
        <Col span={24}>
          <span className={Styles.subtitle}>
            Can’t find an answer to your question? Contact us at{' '}
            <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>.
          </span>
        </Col>
      </Row>
      <Row justify='space-between' className={Styles.sectionContainer}>
        {sections.map((section: string) => (
          <Col span={7} key={`${section.toLowerCase()}_col`} className={Styles.categoryWrapper}>
            {getCategorySelector(section)}
          </Col>
        ))}
      </Row>
      {sections.map((section: string) => {
        const modifiedS: string = section.toLowerCase().replace(/\s/g, '');

        return getFAQSection(section, faqs[modifiedS as keyof typeof faqAnswers], modifiedS);
      })}
    </div>
  );
};

export default FAQ;
