import { css } from '@emotion/css';
import { AlignItems, ColorConstant, Display, Font, FontWeight, JustifyContent, Spacing, TextAlign } from 'src/styles';

export const container = css`
  width: 100%;
`;

export const label = css`
  ${Font.h1}
  ${FontWeight.bold}
  ${Spacing.py4}
  ${Spacing.px0}
  color: ${ColorConstant.GRAY8};
`;

export const groupContainer = css`
  width: 100%;
  ${Spacing.mb4}
`;

export const title = css`
  ${Font.lg}
  color: ${ColorConstant.GRAY8};
`;

export const option = css`
  width: 100%;
  ${Spacing.my4}
  ${Font.md}
  ${Spacing.pl8}
  ${Display.flex}
  ${JustifyContent.spaceBetween}
  ${AlignItems.center}
`;

export const optionValue = css`
  ${TextAlign.right}
`;
