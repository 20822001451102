export const SocialSecurityNumberTooltipContent = () => (
  <div>
    <p>
      A social security number is required to comply with security regulatory requirements, My IPO must get information
      such as name, address, DOB, social security number, employment information and basic financial information.
    </p>
    <p>
      My IPO’s site is secure, and we do not share your personal information as per our privacy policy. As part of our
      regulatory requirements, we must follow very strict security measures as a financial firm. Your social security
      number is encrypted at account creation you will notice we only show the last 4 digits of your SSN after logging
      into My IPO once your account is created.
    </p>
  </div>
);
