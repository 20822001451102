import { css } from '@emotion/css';
import { ColorConstant, FontWeight, Spacing } from 'src/styles';

export const description = css`
  color: ${ColorConstant.ORANGE7};
  background-color: ${ColorConstant.ORANGE1};

  &:hover:not(:focus) {
    color: ${ColorConstant.ORANGE7};
  }
`;

export const text = css`
  ${Spacing.mr4}
`;

export const viewMoreButton = css`
  color: ${ColorConstant.ORANGE7};
  background-color: ${ColorConstant.ORANGE1};
  ${FontWeight.bold}

  &:hover:not(:focus) {
    color: ${ColorConstant.ORANGE7};
  }
`;
