import { css } from '@emotion/css';
import { BorderRadius, ColorConstant, Display, FlexDirection, Font, Spacing } from 'src/styles';

export const input = (weight: 'light' | 'medium') => css`
  ${weight === 'light' ? Font.md : Font.lg}
  color: ${ColorConstant.GRAY10};
  width: 100% !important;
  ${Spacing.pl0}
  ${BorderRadius.none};
  box-sizing: border-box !important;
  box-shadow: none !important;
  border-top: none;
  border-left: none;
  border-right: none;

  &:hover {
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;

export const inputContainer = css`
  ${Display.flex};
  ${FlexDirection.column}
`;
