import { css } from '@emotion/css';
import {
  AlignItems,
  BorderRadius,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  ScreenBreakpoint,
  Spacing,
} from 'src/styles';

export const col = css`
  width: 100%;
`;

export const option = css`
  ${Display.flex}
  ${AlignItems.flexStart}
`;

export const input = css`
  ${Spacing.mb8}

  .ant-input-group-addon {
    border-top: none !important;
    border-left: none !important;
  }

  .ant-input-group-addon:first-child {
    ${Spacing.p0}
    border-left: none !important;
    border-right: solid 1px ${ColorConstant.GRAY4} !important;
  }

  .ant-input-group-addon:last-child {
    ${Spacing.p0}
    border-right: none !important;
    border-left: solid 1px ${ColorConstant.GRAY4} !important;
  }

  input {
    ${Font.lg};
    color: ${ColorConstant.GRAY10};
    width: 100% !important;
    ${BorderRadius.none};
    box-sizing: border-box !important;
    box-shadow: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;

    &:hover {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
    }
  }
`;

export const beforeInput = css`
  border: none !important;
  width: 115px;
`;

export const afterInput = css`
  border: none !important;
  width: 80px;
`;

export const addPhoneNumberText = css`
  ${FontWeight.semibold}
  ${Font.lg}
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.GREEN7} !important;
  cursor: pointer;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${JustifyContent.flexStart}
  }
`;

export const removePhoneNumberText = css`
  ${FontWeight.semibold}
  ${Font.h1}
  ${Display.flex}
  ${AlignItems.center}
  color: ${ColorConstant.RED7} !important;
  cursor: pointer;
`;
