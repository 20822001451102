import * as Styles from './EmptyTableState.styles';

export interface EmptyTableStateProps {
  type?: string;
}

export const EmptyTableState = ({ type }: EmptyTableStateProps) => (
  <>
    <div className={Styles.iconContainer}>
      <i className={`ri-arrow-left-right-line ${Styles.arrowIcon}`} />
    </div>
    <p className={Styles.noItemsLabel}>You currently have no {type ?? 'transactions'}.</p>
  </>
);
