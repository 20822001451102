import { Fragment, useEffect } from 'react';

import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { toastMessagesRemove } from '../../../actions';
import { Color } from '../../../styles';
import { ToastMessage, SeverityEnum } from '../../../typings/commonTypes';

import { AUTO_CLOSE_DURATION, NEVER_CLOSE_DURATION } from './constants';

const showNotification = (
  key: string,
  title: string,
  type: SeverityEnum,
  autoClose: boolean,
  onNotificationClick: () => void,
) => {
  const notificationElement = notification as any;
  const borderColor =
    type === SeverityEnum.Success
      ? Color.GREEN.GREEN6
      : type === SeverityEnum.Error
      ? Color.RED.RED6
      : Color.ORANGE.ORANGE6;
  notificationElement[type]({
    key,
    message: title,
    onClose: () => onNotificationClick(),
    onClick: () => onNotificationClick(),
    duration: autoClose ? AUTO_CLOSE_DURATION : NEVER_CLOSE_DURATION,
    style: {
      minWidth: 350,
      maxWidth: 489,
      width: 'fit-content',
      borderLeft: `6px solid ${borderColor}`,
    },
  });
};

export const ToastMessages = (): JSX.Element => {
  const dispatch = useDispatch();

  const open: ToastMessage | null = useSelector((state: any) => state.toastMessages?.data?.open);
  const close: string | null = useSelector((state: any) => state.toastMessages?.data?.close);
  const clearList: ToastMessage[] | undefined = useSelector((state: any) => state.toastMessages?.data?.clearList);

  const closeToastMessage = (item: ToastMessage) => {
    dispatch(toastMessagesRemove(item));
  };

  useEffect(() => {
    if (open) {
      showNotification(open.key, open.message, open.severity, open.autoClose, () => closeToastMessage(open));
    }
  }, [open]);

  useEffect(() => {
    if (close) {
      notification.close(close);
    }
  }, [close]);

  useEffect(() => {
    if (Array.isArray(clearList)) {
      clearList.forEach(aToastMessage => {
        notification.close(aToastMessage.key);
      });
    }
  }, [clearList]);

  return <Fragment></Fragment>;
};
