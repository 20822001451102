import { stateTypes } from '../constants/actionTypes';

/**
 * Exports action types based on action states for side effects
 * [ Requested, Failed, Succeeded ] resulting in {Action}/{State}
 */
export const actionCleared = (action: string) => `${action}/${stateTypes.CLEARED}`;
export const actionRequested = (action: string) => `${action}/${stateTypes.REQUESTED}`;
export const actionSucceeded = (action: string) => `${action}/${stateTypes.SUCCEEDED}`;
export const actionFailed = (action: string) => `${action}/${stateTypes.FAILED}`;
