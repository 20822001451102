import * as Styles from './CountryFlag.styles';

interface CountryFlagProps {
  name: string;
  dialCode: string;
  flag: string;
}

export const CountryFlag = ({ name, dialCode, flag }: CountryFlagProps) => (
  <div className={Styles.container}>
    <img src={flag} alt={name} />
    <span>({dialCode})</span>
  </div>
);
