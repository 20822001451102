import { css } from '@emotion/css';

import {
  ColorConstant,
  Font,
  FontFamily,
  FontWeight,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
} from '../../../../../../styles';

export const securityHeader = css`
  ${FontFamily.sourceSansPro};
  ${FontWeight.bold};
  ${Font.lg};
  color: ${ColorConstant.GRAY10};
`;

export const imageWrapper = css`
  width: 31px;
  height: 31px;
`;

export const alignCenter = css`
  ${TextAlign.center};
`;

export const totalValueDetail = css`
  ${FontFamily.sourceSansPro};
  ${FontWeight.semibold};
  ${Font.h7};
  color: ${ColorConstant.BRAND6};
`;

export const totalTextDetail = css`
  ${FontFamily.sourceSansPro};
  ${FontWeight.semibold};
  ${Font.lg};
  color: ${ColorConstant.GRAY8};
`;

export const textDetail = css`
  ${FontFamily.sourceSansPro};
  ${FontWeight.semibold};
  ${Font.md};
  color: ${ColorConstant.GRAY8};
`;

export const valueDetail = css`
  ${FontWeight.normal};
  ${TextAlign.right};
`;

export const divider = css`
  ${Spacing.mx0};
  ${Spacing.my12};
`;

export const chartWrapper = css`
  ${Spacing.mt24};
`;

export const arrowsStyling = css`
  color: ${ColorConstant.BRAND6};
  ${Font.h1};
  cursor: pointer;
`;

export const arrowsDisabledStyling = css`
  color: ${ColorConstant.GRAY5};
  cursor: default;
`;

export const rowWrapper = css`
  width: 480px;
  height: 262px;
  ${Spacing.mb32};
  ${Spacing.mt20};
`;

export const swiperContainer = css`
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    overflow: scroll;
  }
`;
