import { CardLayout } from 'src/lib';

export const OfferCardPaymentAccountLinkExpire = () => {
  return (
    <CardLayout
      cardTitle='Link Expired'
      cardSubtitle={
        <>
          <p>
            Your Stripe Connect account confirmation link has expired. Please email{' '}
            <a href='mailto:jv@cambriacapital.com'>jv@cambriacapital.com</a> to request a new link.
          </p>
        </>
      }></CardLayout>
  );
};
