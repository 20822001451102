import { css } from '@emotion/css';
import { AlignItems, ColorConstant, Display, Font, FontWeight, JustifyContent, Spacing } from 'src/styles';

export const center = css`
  ${Display.flex}
  ${AlignItems.center}
  ${JustifyContent.center}
`;

export const title = css`
  ${Spacing.mb32}
  ${Spacing.mt40}
  ${FontWeight.bold}
  ${Font.h2}
  color: ${ColorConstant.GRAY8};
`;
