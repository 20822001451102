import { useEffect } from 'react';

import { Col } from 'antd';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { DefinitionConstant } from 'src/constants/common.constants';
import { useAccountSelector } from 'src/hooks';
import { useFindMinimalDepositAmountForInvestment } from 'src/hooks/useFindMinimalDepositAmountForInvestment';
import { MFormCurrencyField } from 'src/lib';
import { MTooltip } from 'src/lib/MTooltip/MTooltip';
import { OfferOrderIntent } from 'src/models';

import * as Styles from './WirePayment.styles';

export interface WirePaymentProps {
  isValid: (value: boolean) => void;
}

export const WirePayment = ({ isValid }: WirePaymentProps) => {
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const { account } = useAccountSelector();

  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  const renderOfferWireInstructions = () => (
    <>
      <span className={Styles.subTitle} data-testid={'wire-transfer-instructions'}>
        Please send USD incoming wires as follows:
      </span>
      <p className={Styles.paragraph}>
        <span>BMO Harris Bank</span> <br />
        <span>111 W. Monroe St.</span> <br />
        <span>Chicago, IL 60603</span> <br />
      </p>

      <p className={Styles.paragraph}>
        <span>ABA: 071000288 (for non-domestic USD wires, use SWIFT Code: HATRUS44)</span>
        <br />
        <span>Acct: 3713286</span>
        <br />
        <span>FBO: Apex Clearing Corporation</span>
        <br />
        <span>
          FFC: {account?.primaryAccountHolder?.firstName} {account?.primaryAccountHolder?.lastName},{' '}
          {account?.accountNumber}
        </span>
      </p>
      <span className={Styles.subTitle}>Please include the address for Apex Clearing on incoming wires:</span>
      <p className={Styles.paragraph}>
        <span>Apex Clearing Corporation</span>
        <br />
        <span>350 N St Paul, Ste. 1400</span>
        <br />
        <span>Dallas, TX 75201</span>
        <br />
      </p>
    </>
  );

  const renderExternalOfferWireInstructions = () => (
    <>
      <span className={Styles.subTitle} data-testid={'wire-transfer-instructions'}>
        Please send USD incoming wires as follows:
      </span>
      <p className={Styles.paragraph}>
        <span>{parse(offerOrderIntent.wireInstructions?.bankName ?? '')}</span>
      </p>

      <p className={Styles.paragraph}>
        <span>ABA #: {offerOrderIntent.wireInstructions?.abaNumber}</span>
        <br />
        <span>A/C #: {offerOrderIntent.wireInstructions?.accountNumber}</span>
        <br />
        <span>A/C Name: {offerOrderIntent.wireInstructions?.accountName}</span>
        <br />
        <span>Attn: {offerOrderIntent.wireInstructions?.attentionLine}</span>
      </p>
    </>
  );

  useEffect(() => {
    if (!offerOrderIntent.isExternal && !account?.accountNumber) {
      isValid(false);

      return;
    }
    isValid(true);
  }, []);

  return (
    <div className={Styles.container} data-testid={'confirm-order-modal-wire'}>
      {!offerOrderIntent.isExternal && (
        <Col span={24}>
          <MFormCurrencyField
            label='Cash Available'
            value={offerOrderIntent.cashAvailable}
            tooltip={{ type: 'popover', content: DefinitionConstant.CASH_AVAILABLE, icon: 'question' }}
            testId={'wire-cash-available'}
          />
        </Col>
      )}
      <Col span={24}>
        <MFormCurrencyField label='Transfer Amount' value={minimalDepositAmount} testId={'wire-transfer-amount'} />
      </Col>
      {offerOrderIntent.isExternal ? renderExternalOfferWireInstructions() : renderOfferWireInstructions()}
      <span className={Styles.subTitle}>IMPORTANT NOTE:</span>
      <p className={Styles.paragraph}>
        Without FFC Information, your wire may be rejected. If your bank does not have a specific FFC instruction field,
        enter the {offerOrderIntent.isExternal ? "Investor's name " : "Account Holder's Name and Account Number "}in the
        &quot;Message to recipient&quot; or &quot;Message to recipient bank&quot; field.
      </p>
      <MTooltip
        type='modal'
        title='More details about Wire Transfer'
        label='More details about Wire Transfer'
        icon='info'
        showIcon>
        <>
          <p className={Styles.paragraph}>
            Incoming wires are free and must be initiated with your financial institution. Wire transfers typically
            require 1 business day to process. Please instruct your bank to include the sender name on the wire. This
            information must be included for the transfer to be credited to{' '}
            {offerOrderIntent.isExternal ? 'the Escrow Account' : 'your account'}. A rejected wire may incur a bank fee.
          </p>
          <span className={Styles.subTitle}>Third-party Wires:</span>
          <p className={Styles.paragraph}>
            {offerOrderIntent.isExternal ? (
              <>
                <span className={Styles.highlight}>
                  The Escrow Agent does not accept “third party” funding of any kind
                </span>
                . This means that the name on your bank account must match the name on your investment exactly. For
                example, money transfers from a trust or business bank account (owned by you) to an individual or joint
                investment name are considered third-party and{' '}
                <span className={Styles.highlight}>will not be accepted.</span>
              </>
            ) : (
              <>
                <span className={Styles.highlight}>My IPO does not accept “third party” funding of any kind</span>. This
                means that the name on your bank account must match the name on your My IPO account exactly. For
                example, money transfers from a trust or business bank account (owned by you) to an individual or joint
                My IPO account are considered third-party and{' '}
                <span className={Styles.highlight}>will not be accepted.</span>
              </>
            )}
          </p>
        </>
      </MTooltip>
    </div>
  );
};
