import { css } from '@emotion/css';
import { BorderRadius, ColorConstant, Font, FontWeight } from 'src/styles';

export const paymentCollapse = css`
  ${BorderRadius.xxs}
  overflow:hidden;
`;

export const title = css`
  ${Font.h1}
  ${FontWeight.bold}
`;

export const subtitle = css`
  ${Font.md}
  color: ${ColorConstant.GRAY7};
`;

export const paymentPanel = css`
  .ant-collapse-header {
    background-color: ${ColorConstant.GRAY1};
    ${Font.md}
  }

  .ant-collapse-header-text {
    width: 100%;
  }

  .ant-collapse-content {
    background-color: ${ColorConstant.GRAY2};
  }
`;
