import { Switch, type SwitchProps } from 'antd';

import * as Styles from './MSwitch.styles';

export interface MSwitchProps extends Omit<SwitchProps, 'onChange'> {
  onChange: (checked: boolean) => void;
}

export const MSwitch = ({
  defaultChecked,
  checked,
  disabled,
  loading,
  onChange,
  size,
  className = ' ',
}: MSwitchProps) => (
  <Switch
    defaultChecked={defaultChecked}
    checked={checked}
    disabled={disabled}
    loading={loading}
    onChange={checked => onChange(checked)}
    className={Styles.mSwitch.concat(' ').concat(className).trimEnd()}
    size={size}
  />
);
