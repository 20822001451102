import { css } from '@emotion/css';

import { BorderRadius, Color, ScreenBreakpoint, Spacing } from '../../../../../../styles';

export const cardWrapper = css`
  background: ${Color.GRAYSCALE.GRAY1};
  border: 1px solid ${Color.LIST_ROW_BORDER_GREY};
  ${BorderRadius.xs};
  max-height: 227px;
  overflow: hidden;
  .ant-card-body {
    ${Spacing.p0};
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    border-radius: 0;
  }
`;

export const negativeValue = css`
  color: ${Color.RED.RED6};
`;

export const pozitiveValue = css`
  color: ${Color.GREEN.GREEN7};
`;
