import React, { useEffect, useState } from 'react';

import { cx } from '@emotion/css';
import { Col, Row, Space } from 'antd';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { createOrdersReview } from '../../../../../../actions';
import { OrderConstant } from '../../../../../../constants/orders.constants';
import {
  TradingBlockOrderActionEnum,
  TradingBlockOrderDurationEnum,
  TradingBlockOrderTypeEnum,
} from '../../../../../../dtos/orders.dtos';
import Dropdown from '../../../../../../lib/FormComponents/Dropdown';
import { TextAlign } from '../../../../../../lib/FormComponents/Input/constants';
import Input from '../../../../../../lib/FormComponents/Input/Input';
import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import NumberField from '../../../../../../lib/Miscellaneous/FormattedFields/NumberField/NumberField';
import { MAlert } from '../../../../../../lib/Miscellaneous/MAlert/MAlert';
import { CreateOrderView, OrderPosition } from '../../../../../../models/orders.models';
import { QuoteData, SnapQuotes } from '../../../../../../models/quote-media.model';
import { SecuritiesModalStepsEnum } from '../../../../../../models/securities-modal-steps.enum';

import * as Styles from './styles';
import { actionFormValidation } from './validations';

interface ActionFormProps {
  action: SecuritiesModalStepsEnum.BUY_FORM | SecuritiesModalStepsEnum.SELL_FORM;
  securityData: SnapQuotes;
  selectedSecurity: OrderPosition;
  onDisclaimerClick: (step: number) => void;
  bindOnSubmitActionForm: (submitForm: any) => any;
  onSubmitedSuccessForm: () => void;
}

const initialCreateOrderView: CreateOrderView = {
  orderType: TradingBlockOrderTypeEnum.Market,
  marketPrice: '',
  quantity: '0',
  limitPrice: '',
  stopPrice: '',
  shares: '',
  serviceFee: `${OrderConstant.ESTIMATED_SERVICE_FEE}`,
  orderDuration: TradingBlockOrderDurationEnum.Day,
  estimatedTotal: '0',
  action: TradingBlockOrderActionEnum.Buy,
  symbol: '',
};

export const ActionForm = ({
  action,
  securityData,
  selectedSecurity,
  onDisclaimerClick,
  bindOnSubmitActionForm,
  onSubmitedSuccessForm,
}: ActionFormProps) => {
  const [securityQuoteMediaData, setSecurityQuoteMediaData] = useState<QuoteData>();
  const [title] = useState(
    action === SecuritiesModalStepsEnum.BUY_FORM ? TradingBlockOrderActionEnum.Buy : TradingBlockOrderActionEnum.Sell,
  );
  const [showBanner, setShowBanner] = useState<{ visible: boolean; message?: string }>({ visible: false });
  const orderToReview = useSelector((state: any) => state.orders.createOrdersReviewPositions?.data);
  const cashAvailable = useSelector((state: any) => state.accountDetails?.accountValue?.data?.cashAvailable);
  const dispatch = useDispatch();

  const findOrderPrice = (values: CreateOrderView): string => {
    const marketPrice = values.marketPrice;
    const orderType: TradingBlockOrderTypeEnum = values.orderType;

    if (Number(values.limitPrice) < 0 || Number(values.stopPrice) < 0) {
      return '0';
    }

    switch (orderType) {
      case TradingBlockOrderTypeEnum.Market: {
        return marketPrice;
      }

      case TradingBlockOrderTypeEnum.Limit: {
        return values.limitPrice;
      }

      case TradingBlockOrderTypeEnum.Stop_Limit: {
        return values.limitPrice;
      }

      case TradingBlockOrderTypeEnum.Stop_Market: {
        return values.stopPrice;
      }

      case TradingBlockOrderTypeEnum.Market_On_Close: {
        return marketPrice;
      }

      default: {
        return marketPrice;
      }
    }
  };

  const findEstimatedTotal = (values: CreateOrderView): number => {
    const shares: number = Number(values.shares);
    const orderPrice: number = Number(findOrderPrice(values));
    const estimatedTotal = orderPrice * shares;
    const estimatedTotalwithFee =
      title === TradingBlockOrderActionEnum.Buy
        ? estimatedTotal + OrderConstant.ESTIMATED_SERVICE_FEE
        : estimatedTotal - OrderConstant.ESTIMATED_SERVICE_FEE;

    return estimatedTotalwithFee;
  };

  const isOversellOrder = (values: CreateOrderView): boolean =>
    values.action === TradingBlockOrderActionEnum.Sell ? Number(values.shares) > Number(values?.quantity) : false;

  const hasEnoughMoneyOnBuyOrder = (values: CreateOrderView): boolean =>
    values.action === TradingBlockOrderActionEnum.Buy ? Number(values.estimatedTotal) <= Number(cashAvailable) : true;

  useEffect(() => {
    const quoteMediaSecurity = securityData?.quotedata?.find(data => data.symbol === selectedSecurity.symbol);
    setSecurityQuoteMediaData(quoteMediaSecurity);
  }, [securityData]);

  const FormObserver: React.FC = () => {
    const { values, setFieldValue, setValues } = useFormikContext<CreateOrderView>();

    useEffect(() => {
      setFieldValue('estimatedTotal', findEstimatedTotal(values));
    }, [values]);

    useEffect(() => {
      setValues({
        ...values,
        ...orderToReview,
        marketPrice: (securityQuoteMediaData?.pricedata?.last || 0).toFixed(2),
        symbol: securityQuoteMediaData?.symbol || '',
        quantity: selectedSecurity.quantity,
        action:
          action === SecuritiesModalStepsEnum.BUY_FORM
            ? TradingBlockOrderActionEnum.Buy
            : TradingBlockOrderActionEnum.Sell,
      });
    }, [orderToReview, securityQuoteMediaData, selectedSecurity, action]);

    return null;
  };

  const formatNumber = (numStr: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    }).format(numStr);
  };

  const createOrder = (values: CreateOrderView) => {
    dispatch(createOrdersReview(values));

    if (isOversellOrder(values)) {
      return setShowBanner({ visible: true, message: 'You do not have enough shares to sell.' });
    }

    if (!hasEnoughMoneyOnBuyOrder(values)) {
      return setShowBanner({
        visible: true,
        message: `You do not have enough available cash to buy ${values.shares} shares.`,
      });
    }
    onSubmitedSuccessForm();
  };

  const generateBannerError = () => {
    return <MAlert type='error' description={<div>{showBanner?.message || ''}</div>} />;
  };

  return (
    <>
      <Row className={Styles.titleWrapper}>
        <Col span={24} className={Styles.alignCenter}>
          <Space size={10} direction='vertical'>
            <h1>{`${title} ${securityQuoteMediaData?.symbol}`}</h1>
            <span>{`Cash Available (${formatNumber(cashAvailable || 0)})`}</span>
          </Space>
        </Col>
      </Row>
      {showBanner?.visible && (
        <Row>
          <Col span={24} className={Styles.alignCenter}>
            {generateBannerError()}
          </Col>
        </Row>
      )}
      <Row>
        <Formik
          initialValues={initialCreateOrderView}
          onSubmit={createOrder}
          validateOnChange={false}
          validationSchema={actionFormValidation}>
          {(form: FormikProps<CreateOrderView>) => {
            const { handleSubmit, submitForm } = form;
            bindOnSubmitActionForm(submitForm);

            return (
              <Form onSubmit={handleSubmit}>
                <FormObserver />
                <Row className={Styles.rowWrapper}>
                  <Col xs={12} md={16}>
                    <span className={Styles.fieldLabel}>Order type</span>
                  </Col>
                  <Col xs={12} md={8}>
                    <Dropdown
                      {...form}
                      key='orderType'
                      name='orderType'
                      placeholder='Order Type'
                      value={form.values.orderType}
                      options={[
                        { text: 'Market Order', value: TradingBlockOrderTypeEnum.Market },
                        { text: 'Limit Order', value: TradingBlockOrderTypeEnum.Limit },
                        {
                          text: 'Stop Market Order',
                          value: TradingBlockOrderTypeEnum.Stop_Market,
                        },
                        { text: 'Stop Limit Order', value: TradingBlockOrderTypeEnum.Stop_Limit },
                        {
                          text: 'Market on Close Order',
                          value: TradingBlockOrderTypeEnum.Market_On_Close,
                        },
                      ]}></Dropdown>
                  </Col>
                  <Col span={16} className={Styles.borderBottom}>
                    <Space size={6}>
                      <span className={Styles.fieldLabel}>Last Price</span>
                      <i
                        className={cx('ri-question-line', Styles.disclaimerStyle)}
                        onClick={() => onDisclaimerClick(SecuritiesModalStepsEnum.INFO_DISCLAIMER_2)}></i>
                    </Space>
                  </Col>
                  <Col span={8} className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue)}>
                    <CurrencyField key='marketPrice' value={Number(form.values.marketPrice)} />
                  </Col>
                  <Col span={16} className={Styles.borderBottom}>
                    <span className={Styles.fieldLabel}>Shares Held</span>
                  </Col>
                  <Col span={8} className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue)}>
                    <NumberField key='sharesHeld' value={Number(form.values.quantity)} />
                  </Col>
                  {[TradingBlockOrderTypeEnum.Limit, TradingBlockOrderTypeEnum.Stop_Limit].includes(
                    form.values.orderType,
                  ) && (
                    <>
                      <Col span={16}>
                        <span className={Styles.fieldLabel}>Limit Price</span>
                      </Col>
                      <Col span={8} className={cx(Styles.alignRight, Styles.inputBottom)}>
                        <Input
                          key='limitPrice'
                          {...form}
                          name='limitPrice'
                          placeholder='0.00'
                          prefix='$'
                          value={form.values.limitPrice}
                          textAlign={TextAlign.Right}></Input>
                      </Col>
                    </>
                  )}
                  {[TradingBlockOrderTypeEnum.Stop_Market, TradingBlockOrderTypeEnum.Stop_Limit].includes(
                    form.values.orderType,
                  ) && (
                    <>
                      <Col span={16}>
                        <span className={Styles.fieldLabel}>Stop Price</span>
                      </Col>
                      <Col span={8} className={cx(Styles.alignRight, Styles.inputBottom)}>
                        <Input
                          key='stopPrice'
                          {...form}
                          name='stopPrice'
                          placeholder='0.00'
                          prefix='$'
                          value={form.values.stopPrice}
                          textAlign={TextAlign.Right}></Input>
                      </Col>
                    </>
                  )}
                  <Col span={16}>
                    <span className={Styles.fieldLabel}>
                      Shares {action === SecuritiesModalStepsEnum.BUY_FORM ? 'to Buy' : 'to Sell'}
                    </span>
                  </Col>
                  <Col span={8} className={cx(Styles.alignRight, Styles.inputBottom)}>
                    <Input
                      dataTestId='ant-modal-security-input-share-number'
                      key='shares'
                      {...form}
                      name='shares'
                      placeholder='0'
                      value={form.values.shares}
                      textAlign={TextAlign.Right}></Input>
                  </Col>
                  <Col xs={12} md={16}>
                    <span className={Styles.fieldLabel}>Order Expiration</span>
                  </Col>
                  <Col xs={12} md={8}>
                    <Dropdown
                      {...form}
                      key='orderDuration'
                      name='orderDuration'
                      placeholder='Order Duration'
                      value={form.values.orderDuration}
                      options={[
                        { text: 'End of Day', value: TradingBlockOrderDurationEnum.Day },
                        {
                          text: 'Good Until Cancelled',
                          value: TradingBlockOrderDurationEnum.GTC,
                        },
                      ]}></Dropdown>
                  </Col>
                  <Col span={16} className={Styles.borderBottom}>
                    <Space size={6}>
                      <span className={Styles.fieldLabel}>Service Fee</span>
                      <i
                        className={cx('ri-share-box-line', Styles.disclaimerStyle)}
                        onClick={() => onDisclaimerClick(SecuritiesModalStepsEnum.INFO_DISCLAIMER_3)}></i>
                    </Space>
                  </Col>
                  <Col span={8} className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue)}>
                    <CurrencyField key='serviceFee' value={Number(form.values.serviceFee)} />
                  </Col>
                  <Col span={16} className={Styles.borderBottom}>
                    <span className={Styles.fieldLabel}>Estimated Total</span>
                  </Col>
                  <Col span={8} className={cx(Styles.alignRight, Styles.borderBottom, Styles.fieldValue)}>
                    <CurrencyField key='estimatedTotal' value={Number(form.values.estimatedTotal)} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Row>
    </>
  );
};
