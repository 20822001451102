import { MFormTextField } from 'src/lib';
import { Account } from 'src/models';

export interface AccountHolderPhysicalAddressViewProps {
  account?: Account;
}

export const AccountHolderPhysicalAddressView = ({ account }: AccountHolderPhysicalAddressViewProps) => {
  return (
    <>
      <MFormTextField label='Address Line 1' value={account?.primaryAccountHolder?.physicalAddress?.address1} />
      <MFormTextField label='Address Line 2 (Opt.)' value={account?.primaryAccountHolder?.physicalAddress?.address2} />
      <MFormTextField label='Country' value={account?.primaryAccountHolder?.physicalAddress?.country.label} />
      <MFormTextField label='City' value={account?.primaryAccountHolder?.physicalAddress?.city} />
      <MFormTextField label='State' value={account?.primaryAccountHolder?.physicalAddress?.state.label} />
      <MFormTextField label='Postal Code' value={account?.primaryAccountHolder?.physicalAddress?.postalCode} />
      <MFormTextField label='Citizenship' value={account?.primaryAccountHolder?.citizenshipCountry?.label} />
      <MFormTextField label='Birth Country' value={account?.primaryAccountHolder?.birthCountry?.label} />
    </>
  );
};
