import { css } from '@emotion/css';
import { BaseStyle, ColorConstant, FlexDirection, Font, FontWeight, Spacing, TextAlign } from 'src/styles';

export const title = (type: 'error' | 'success') => css`
  color: ${type === 'error' ? ColorConstant.RED6 : ColorConstant.GREEN6};
  ${FontWeight.bold}
  ${TextAlign.center}
  ${Font.lg}
  ${Spacing.my12}
  ${BaseStyle.level}
  ${FlexDirection.column}
`;

export const successMessage = css`
  ${Font.md}
  ${TextAlign.center}
  color: ${ColorConstant.GRAY7};
`;
