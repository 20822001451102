import { useEffect, useState } from 'react';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import _ from 'lodash';
import { ColorConstant } from 'src/styles';
import zxcvbn from 'zxcvbn';

import * as Styles from './PasswordStrengthBar.styles';

const InvalidLogo = () => <CloseOutlined style={{ fontSize: 11, color: ColorConstant.RED5 }} />;

const ValidLogo = () => <CheckOutlined style={{ fontSize: 11, color: ColorConstant.GREEN6 }} />;

export const calculatePercentage = (value: number) => {
  switch (value) {
    case 0:
      return 10;
    case 1:
      return 25;
    case 2:
      return 50;
    case 3:
      return 80;
    case 4:
      return 100;
    default:
      return 0;
  }
};

export const calculateBarColor = (value: number) => {
  switch (value) {
    case 0:
      return ColorConstant.RED6;
    case 1:
      return ColorConstant.RED4;
    case 2:
      return ColorConstant.ORANGE6;
    case 3:
      return ColorConstant.GREEN5;
    case 4:
      return ColorConstant.GREEN6;
    default:
      return ColorConstant.RED6;
  }
};

export const calculateLabel = (value: number) => {
  switch (value) {
    case 0:
      return 'Very Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Average';
    case 3:
      return 'Strong';
    case 4:
      return 'Very Strong';
    default:
      return 'Very Weak';
  }
};

interface PasswordStrengthBarProps {
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  showStrengthLabel?: boolean;
  showValidationRules?: boolean;
}

export const PasswordStrengthBar = ({
  password = '',
  confirmPassword = '',
  firstName = '',
  lastName = '',
  showStrengthLabel = false,
  showValidationRules = false,
}: PasswordStrengthBarProps) => {
  const [result, setResult] = useState(0);
  const [testedResult, setTestedResult] = useState(0);
  const [color, setColor] = useState('#F25F5C');
  const [label, setLabel] = useState('');

  useEffect(() => {
    setTestedResult(zxcvbn(password).score);
    setResult(calculatePercentage(testedResult));
    setLabel(calculateLabel(testedResult));
    setColor(calculateBarColor(testedResult));
  }, [password, testedResult]);

  return (
    <div style={{}}>
      {testedResult >= 0 && !_.isEmpty(password) && (
        <>
          <Progress percent={_.isEmpty(password) ? 0 : result} strokeColor={color} showInfo={false} />
          <div style={{ display: 'flex', marginTop: -5 }}>
            {showStrengthLabel && !_.isEmpty(password) && (
              <span style={{ color, marginLeft: 'auto', fontSize: 12 }}>{label}</span>
            )}
          </div>
          {showValidationRules && !_.isEmpty(password) && (
            <div>
              <span className={password.length >= 8 ? Styles.passingRule : Styles.errorRule}>
                {password.length >= 8 ? <ValidLogo /> : <InvalidLogo />} Be 8 character’s at minimum
              </span>
              <br />
              <span className={/[A-Z]/.test(password) ? Styles.passingRule : Styles.errorRule}>
                {/[A-Z]/.test(password) ? <ValidLogo /> : <InvalidLogo />} Contain one uppercase letter
              </span>
              <br />
              <span className={/[a-z]/.test(password) ? Styles.passingRule : Styles.errorRule}>
                {/[a-z]/.test(password) ? <ValidLogo /> : <InvalidLogo />} Contain one lowercase letter
              </span>
              <br />
              <span className={/[\d]/.test(password) ? Styles.passingRule : Styles.errorRule}>
                {/[\d]/.test(password) ? <ValidLogo /> : <InvalidLogo />} Contain one number
              </span>
              <br />
              <span
                className={
                  password?.toLowerCase().includes(firstName.toLowerCase()) ? Styles.errorRule : Styles.passingRule
                }>
                {password?.toLowerCase().includes(firstName.toLowerCase()) ? <InvalidLogo /> : <ValidLogo />} Password
                should not contain first name
              </span>
              <br />
              <span
                className={
                  password?.toLowerCase().includes(lastName.toLowerCase()) ? Styles.errorRule : Styles.passingRule
                }>
                {password?.toLowerCase().includes(lastName.toLowerCase()) ? <InvalidLogo /> : <ValidLogo />} Password
                should not contain last name
              </span>
              <br />
              <span className={password?.toLowerCase().includes('password') ? Styles.errorRule : Styles.passingRule}>
                {password?.toLowerCase().includes('password') ? <InvalidLogo /> : <ValidLogo />} Password should not
                contain the word &quot;password&quot;
              </span>
              <br />
              <span className={confirmPassword === password ? Styles.passingRule : Styles.errorRule}>
                {confirmPassword === password ? <ValidLogo /> : <InvalidLogo />} Confirm Password should be the same as
                password
              </span>
              <br />
              <span
                className={
                  /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password) ? Styles.passingRule : Styles.errorRule
                }>
                {/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password) ? <ValidLogo /> : <InvalidLogo />} Contain one
                special character
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
