import { css } from '@emotion/css';
import { ColorConstant, Display, Font, JustifyContent, Spacing, TextAlign } from 'src/styles';

export const noItemsLabel = css`
  ${Font.sm}
  ${TextAlign.center}
  color: ${ColorConstant.GRAY7};
`;

export const arrowIcon = css`
  color: ${ColorConstant.BRAND6};
  ${Font.md}
`;

export const iconContainer = css`
  ${Display.flex}
  ${JustifyContent.center}
  ${Spacing.py16}
`;
