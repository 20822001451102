import React, { useEffect, useState } from 'react';

import { Card } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { MTable } from 'src/lib';

import { OrderPosition } from '../../../../../../models/orders.models';

import NonTradedHoldingsColumns from './non-traded-holdings-columns';
import SecurityColumns from './securities-columns';
import * as Styles from './SummaryTable.styles';

interface SummaryTableProps<ObjectType extends Object> {
  name: 'Securities' | 'NonTradableHoldings';
  data: ObjectType[];
  loading?: boolean;
  onRowClick?: (record: OrderPosition, rowIndex: any) => any;
}

function SummaryTable<ObjectType extends Object>({
  name,
  data,
  loading = false,
  onRowClick,
}: SummaryTableProps<ObjectType>) {
  const [columns, setColumns] = useState<ColumnsType<any>>();

  useEffect(() => {
    if (name === 'Securities') setColumns(SecurityColumns);
    else setColumns(NonTradedHoldingsColumns);
  }, [data]);

  return (
    <Card className={Styles.cardWrapper}>
      <MTable
        columns={columns}
        data={data}
        pagination={false}
        loading={loading}
        scroll={{ y: 180 }}
        {...(onRowClick && {
          onRowClick: (record: any, rowIndex: any) => onRowClick(record, rowIndex),
        })}
      />
    </Card>
  );
}

export default SummaryTable;
