import { useSelector } from 'react-redux';

import { OfferOrderIntent } from '../models/offer-orders.model';

export const useFindMinimalDepositAmountForInvestment = (): number => {
  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  if (offerOrderIntent.isExternal) {
    return offerOrderIntent.totalInvestment;
  }

  if (offerOrderIntent.cashAvailable <= 0) {
    return offerOrderIntent.totalInvestment;
  } else {
    return Number((offerOrderIntent.totalInvestment - offerOrderIntent.cashAvailable).toFixed(2));
  }
};
