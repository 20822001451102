import React from 'react';

import { MAlert } from '../../../../lib/Miscellaneous/MAlert/MAlert';

export interface YourTransferNotCompletedAlertMessageProps {
  isAchBankLinkPending: boolean;
}

export const YourTransferNotCompletedAlertMessage = ({
  isAchBankLinkPending,
}: YourTransferNotCompletedAlertMessageProps) => {
  if (isAchBankLinkPending) {
    return (
      <MAlert
        type='warning'
        description='Your transfer cannot be completed until your ACH bank link is approved.'
        testId={'link-bank-account-warning-alert'}
      />
    );
  }

  return null;
};
