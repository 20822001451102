import { Col, Row, Tabs } from 'antd';
import MediaQuery from 'react-responsive';
import { useLocation, useNavigate } from 'react-router';
import { AccountConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import { getLocalStorageService } from 'src/services';

import { ScreenBreakpoint } from '../../../styles';
import { Logger } from '../../../utils/Logger';

import { MyAccountProgressBar } from './MyAccountProgressBar';
import * as Styles from './MyAccountSidebar.styles';

const localStorageService = getLocalStorageService();

export enum MyAccountSidebarMainMenuItemKey {
  PersonalInformation = 'PersonalInformation',
  TrustedContact = 'TrustedContact',
  SuitabilityInformation = 'SuitabilityInformation',
  FinancialInformation = 'FinancialInformation',
  Disclosures = 'Disclosures',
  AccountAgrements = 'AccountAgreements',
  SignApplication = 'SignApplication',
}

export enum MyAccountSidebarOtherMenuItemKey {
  MoneyTransfers = 'MoneyTransfers',
  AccountStatus = 'AccountStatus',
  AccountStatements = 'AccountStatements',
  IndicationsOfInterest = 'IndicationsOfInterest',
  UserSettings = 'UserSettings',
}

export interface MyAccountSidebarMainMenuItem {
  key: MyAccountSidebarMainMenuItemKey;
  name: string;
  path: (accountId: string) => string;
  icon?: JSX.Element | null;
  isOptional?: boolean;
}

export interface MyAccountSidebarOtherMenuItem {
  key: MyAccountSidebarOtherMenuItemKey;
  name: string;
  path: (accountId: string) => string;
  isOptional?: boolean;
}

export type MyAccountSidebarMenuItemKey = MyAccountSidebarMainMenuItemKey | MyAccountSidebarOtherMenuItemKey;

const completedAccountMenuItems: MyAccountSidebarMainMenuItem[] = [
  {
    key: MyAccountSidebarMainMenuItemKey.PersonalInformation,
    name: 'Personal',
    path: (accountId: string) => `/accounts/${accountId}/personal-information`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.TrustedContact,
    name: 'Trusted Contact Person (Optional)',
    path: (accountId: string) => `/accounts/${accountId}/trusted-contact`,
    isOptional: true,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.SuitabilityInformation,
    name: 'Suitability Information',
    path: (accountId: string) => `/accounts/${accountId}/suitability-information`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.FinancialInformation,
    name: 'Financial Information',
    path: (accountId: string) => `/accounts/${accountId}/financial-information`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.Disclosures,
    name: 'Disclosures',
    path: (accountId: string) => `/accounts/${accountId}/disclosures`,
  },
];

const incompleteAccountMenuItems: MyAccountSidebarMainMenuItem[] = completedAccountMenuItems.concat([
  {
    key: MyAccountSidebarMainMenuItemKey.AccountAgrements,
    name: 'Account Agreements',
    path: (accountId: string) => `/accounts/${accountId}/account-agreements`,
  },
  {
    key: MyAccountSidebarMainMenuItemKey.SignApplication,
    name: 'Sign Application',
    path: (accountId: string) => `/accounts/${accountId}/sign-application`,
  },
]);

const otherMenuItems: MyAccountSidebarOtherMenuItem[] = [
  {
    key: MyAccountSidebarOtherMenuItemKey.MoneyTransfers,
    name: 'Money Transfers & Preferences',
    path: (accountId: string) => `/accounts/${accountId}/money-transfers`,
  },
  {
    key: MyAccountSidebarOtherMenuItemKey.AccountStatus,
    name: 'Account Status',
    path: (accountId: string) => `/accounts/${accountId}/account-status`,
  },
  {
    key: MyAccountSidebarOtherMenuItemKey.AccountStatements,
    name: 'Statements & Documents',
    path: (accountId: string) => `/accounts/${accountId}/statements`,
  },
  {
    key: MyAccountSidebarOtherMenuItemKey.UserSettings,
    name: 'Change Email Address & Phone Number',
    path: () => `/user/settings`,
  },
  {
    key: MyAccountSidebarOtherMenuItemKey.IndicationsOfInterest,
    name: 'Indications of Interest',
    path: () => `/user/indications-of-interest`,
  },
];

export interface MyAccountSidebarProps {
  menuItemKey: MyAccountSidebarMenuItemKey;
}

export const MyAccountSidebar = ({ menuItemKey }: MyAccountSidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { account } = useAccountSelector();
  const agreeElectronicConsent =
    'true' === localStorageService.find(`${AccountConstant.SIGN_APPLICATION_PREFIX_KEY}-${account?.id}`);

  const isAccountSidebarMenuItemComplete = (key: MyAccountSidebarMainMenuItemKey): boolean => {
    if (!account) {
      return false;
    }
    const { primaryAccountHolder } = account;

    switch (key) {
      case MyAccountSidebarMainMenuItemKey.PersonalInformation: {
        return Boolean(
          primaryAccountHolder?.dateOfBirth &&
            primaryAccountHolder?.physicalAddress &&
            primaryAccountHolder?.mailingAddress,
        );
      }

      case MyAccountSidebarMainMenuItemKey.TrustedContact: {
        return true;
      }

      case MyAccountSidebarMainMenuItemKey.SuitabilityInformation: {
        return Boolean(primaryAccountHolder?.suitabilityInformation);
      }

      case MyAccountSidebarMainMenuItemKey.FinancialInformation: {
        return Boolean(
          primaryAccountHolder?.financialInformation?.annualIncome &&
            primaryAccountHolder?.financialInformation?.employmentStatus,
        );
      }

      case MyAccountSidebarMainMenuItemKey.Disclosures: {
        return Boolean(primaryAccountHolder?.disclosure);
      }

      case MyAccountSidebarMainMenuItemKey.AccountAgrements: {
        return agreeElectronicConsent;
      }

      case MyAccountSidebarMainMenuItemKey.SignApplication: {
        return false;
      }
    }
  };

  const getMyAccountMenuItems = (): MyAccountSidebarMainMenuItem[] => {
    if (account?.accountId) {
      return completedAccountMenuItems.map(aMenuItem => ({
        ...aMenuItem,
        icon: !aMenuItem.isOptional ? (
          <i className={`ri-checkbox-circle-fill ${Styles.menuItemIcon({ isComplete: true })}`} />
        ) : null,
      }));
    }

    return incompleteAccountMenuItems.map(aMenuItem => {
      let isComplete = false;

      try {
        isComplete = isAccountSidebarMenuItemComplete(aMenuItem.key);
      } catch (error) {
        Logger.error(error);
      }

      return {
        ...aMenuItem,
        icon: aMenuItem.isOptional ? null : isComplete ? (
          <i className={`ri-checkbox-circle-fill ${Styles.menuItemIcon({ isComplete: true })}`} />
        ) : (
          <i className={`ri-time-line ${Styles.menuItemIcon({ isComplete: false })}`} />
        ),
      };
    });
  };

  const getOtherMenuItems = (): MyAccountSidebarOtherMenuItem[] => {
    if (account?.accountId) {
      return otherMenuItems;
    }

    return [];
  };

  const getProgressBarSteps = () => {
    if (account?.accountId) {
      return completedAccountMenuItems
        .filter(aMenuItem => !aMenuItem.isOptional)
        .map(() => ({
          isComplete: true,
        }));
    }

    return incompleteAccountMenuItems
      .filter(aMenuItem => !aMenuItem.isOptional)
      .map(aMenuItem => ({
        isComplete: isAccountSidebarMenuItemComplete(aMenuItem.key),
      }));
  };

  const renderAccountMenuItems = () => {
    return getMyAccountMenuItems().map(aMenuItem => {
      return (
        <Col
          key={aMenuItem.key}
          span={22}
          className={Styles.menuItem({ isActive: aMenuItem.key === menuItemKey })}
          onClick={() => {
            navigate(aMenuItem.path(account!.id));
          }}>
          <div data-testid={'account-' + aMenuItem.key}>{aMenuItem.name}</div>
          <div className={Styles.menuItemIconContainer}>{aMenuItem.icon}</div>
        </Col>
      );
    });
  };

  const getActiveMenuItemKey = () => {
    if (account?.id) {
      const activeTab = getMyAccountMenuItems().find(aMenuItem =>
        aMenuItem.path(account?.id).includes(location.pathname),
      );

      return activeTab?.key;
    }
  };

  const getMobileTabItems = () => {
    return getMyAccountMenuItems().map(aMenuItem => {
      return {
        key: aMenuItem.key,
        label: (
          <div className={Styles.tabLabelContainer}>
            {aMenuItem.name} {aMenuItem.icon}
          </div>
        ),
        children: null,
      };
    });
  };

  const renderOtherMenuItems = () => {
    return getOtherMenuItems().map(anOtherMenuItem => {
      return (
        <Col
          key={anOtherMenuItem.key}
          span={22}
          className={Styles.menuItem({ isActive: anOtherMenuItem.key === menuItemKey })}
          onClick={() => {
            navigate(anOtherMenuItem.path(account!.id));
          }}>
          <div>{anOtherMenuItem.name}</div>
        </Col>
      );
    });
  };

  const onTabChange = (key: string) => {
    const selectedMenuItem = getMyAccountMenuItems().find(aMenuItem => aMenuItem.key === key);

    if (!selectedMenuItem) {
      throw new Error(
        `Unable to find selected menu item. Expected type "MyAccountSidebarMainMenuItemKey". Received key: ${key}`,
      );
    }

    navigate(selectedMenuItem.path(account!.id));
  };

  const isAnyMainMenuItemActive = () => {
    if (account?.id) {
      return incompleteAccountMenuItems.some((aMenuItem: MyAccountSidebarMainMenuItem) =>
        aMenuItem.path(account.id).includes(location.pathname),
      );
    }

    return true;
  };

  return (
    <Col className={Styles.mainContainer} md={8} sm={24} xs={24}>
      <MediaQuery maxWidth={ScreenBreakpoint.mobile.max}>
        {isAnyMainMenuItemActive() && (
          <>
            <Row>
              <Col span={22} className={Styles.pageName}>
                <span>My Account</span>
              </Col>
            </Row>
            <Row>
              <MyAccountProgressBar steps={getProgressBarSteps()} />
            </Row>
            <Row>
              <Col span={24} className={Styles.tabsContainer}>
                <Tabs
                  items={getMobileTabItems()}
                  onChange={onTabChange}
                  type='line'
                  centered
                  activeKey={getActiveMenuItemKey()}
                />
              </Col>
            </Row>
          </>
        )}
      </MediaQuery>
      <MediaQuery minWidth={ScreenBreakpoint.laptop.min}>
        <Row>
          <Col span={22} className={Styles.pageName}>
            <span>My Account</span>
          </Col>
        </Row>
        <Row>
          <MyAccountProgressBar steps={getProgressBarSteps()} />
        </Row>
        <Row>{renderAccountMenuItems()}</Row>
        <Col span={24} className={Styles.horizontalLine}></Col>
        <Row>{renderOtherMenuItems()}</Row>
      </MediaQuery>
    </Col>
  );
};
