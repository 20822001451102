import { useEffect, useState } from 'react';

import { Card, Col, Row } from 'antd';
import { Formik } from 'formik';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addOfferOrderIntent, getACHRelationships, getConfigs } from 'src/actions';
import { DefinitionConstant, TransferConstant } from 'src/constants';
import { useAccountSelector, useFindMinimalDepositAmountForInvestment, useAccountBalance } from 'src/hooks';
import { MFormCurrencyField, MFormCurrencyInput, MFormSelect, MButton, MAlert, NewAchRelationshipModal } from 'src/lib';
import { OfferOrderIntent, OfferDetails } from 'src/models';
import { getCurrencyFormatter } from 'src/utils';

import CurrencyField from '../../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import Spinner from '../../../../../../lib/Miscellaneous/Spinner';

import * as Styles from './AchPayment.styles';

export const ORDER_PATH = 'order';
export interface NewDepositFormValues {
  transferAmount?: string;
  bankAccount?: string;
}

export interface AchPaymentProps {
  isValid: (value: boolean) => void;
}

export const AchPayment = ({ isValid }: AchPaymentProps) => {
  const dispatch = useDispatch();

  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const achRelationships = useSelector((state: any) => state.cashiering.achRelationships.data);
  const loadingAchRelationships = useSelector((state: any) => state.cashiering.achRelationships.__requested);

  const [isNewAchRealtionshipModalOpen, setIsNewAchRealtionshipModalOpen] = useState<boolean>(false);

  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);
  const [newDepositFormErrors, setNewDepositFormErrors] = useState<NewDepositFormValues>();
  const [newDepositFormInitialValues, setNewDepositFormInitialValues] = useState<NewDepositFormValues>({});
  const [bankAccountList, setBankAccountList] = useState<{ label: string; value: string }[]>([]);

  const [hasBankAccount, setHasBankAccount] = useState(false);
  const [transferAmount, setTransferAmount] = useState<string>();
  const { cashAvailable } = useAccountBalance();
  const { account } = useAccountSelector();
  const minimalDepositAmount = useFindMinimalDepositAmountForInvestment();

  const quantity = offerOrderIntent.quantity;

  const hasEnoughMoneyWithDepositAmountOnConditional = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.totalInvestment <= transferAmountNumber;
    } else {
      return offerOrderIntent.totalInvestment <= cashAvailable + transferAmountNumber;
    }
  };

  const hasEnoughMoneyWithDepositAmountOnStandard = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.price * quantity <= transferAmountNumber;
    } else {
      return offerOrderIntent.price * quantity <= cashAvailable + transferAmountNumber;
    }
  };
  const hasEnoughMoneyWithDepositAmount = () =>
    offerOrderIntent.isConditional
      ? hasEnoughMoneyWithDepositAmountOnConditional()
      : hasEnoughMoneyWithDepositAmountOnStandard();

  const findRemainingDepositAmountOnConditionalOffer = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.totalInvestment - transferAmountNumber;
    } else {
      return offerOrderIntent.totalInvestment - cashAvailable - transferAmountNumber;
    }
  };

  const findRemainingDepositAmountOnStandardOffer = () => {
    const transferAmountNumber = Number(transferAmount) ?? 0;

    if (cashAvailable <= 0) {
      return offerOrderIntent.price * quantity - transferAmountNumber;
    } else {
      return offerOrderIntent.price * quantity - cashAvailable - transferAmountNumber;
    }
  };

  const findRemainingDepositAmount = () =>
    offerOrderIntent.isConditional
      ? findRemainingDepositAmountOnConditionalOffer()
      : findRemainingDepositAmountOnStandardOffer();

  const onLinkBankAccount = () => {
    setIsNewAchRealtionshipModalOpen(true);
  };

  const renderNewDepositForm = () => {
    return (
      <Formik<NewDepositFormValues>
        initialValues={newDepositFormInitialValues}
        enableReinitialize
        validateOnMount
        validate={value => {
          if (isNaN(Number(value.transferAmount)) || Number(value.transferAmount) < 0) {
            setNewDepositFormErrors({ transferAmount: 'Amount must be a positive number' });

            return;
          }

          if (TransferConstant.MAX_AMOUNT < Number(value.transferAmount)) {
            setNewDepositFormErrors({
              transferAmount: `The maximum transfer amount for ACH is ${getCurrencyFormatter().format(
                TransferConstant.MAX_AMOUNT,
              )} per Order. To invest over ${getCurrencyFormatter().format(
                TransferConstant.MAX_AMOUNT,
              )} on an Order, please select Wire or Check transfer.`,
            });

            return;
          }
          setNewDepositFormErrors({});
        }}
        onSubmit={() => {}}>
        {form => {
          return (
            <div className={Styles.container}>
              <Row gutter={[, 16]}>
                {!hasEnoughMoneyWithDepositAmount() && (
                  <MAlert
                    type='error'
                    description={
                      <span>
                        You do not have enough money to successfully complete the order. Please add a minimum of{' '}
                        <CurrencyField value={findRemainingDepositAmount()} /> {'.'}
                      </span>
                    }
                  />
                )}
                <span className={Styles.depositTitle} data-testid={'confirm-order-transfer-ach-info'}>
                  The requested order amount requires you to transfer funds into your account.
                </span>
                <Col span={24}>
                  <MFormCurrencyField
                    label='Cash Available'
                    value={cashAvailable}
                    tooltip={{
                      type: 'popover',
                      content: DefinitionConstant.CASH_AVAILABLE,
                      icon: 'question',
                    }}
                    testId={'ach-cash-available'}
                  />
                </Col>
                <Col span={24}>
                  <MFormSelect
                    label='From Account'
                    defaultValue={bankAccountList?.[0].value}
                    value={form.values.bankAccount}
                    options={bankAccountList}
                    onChange={value => {
                      form.setFieldValue('bankAccount', value);
                      dispatch(
                        addOfferOrderIntent({
                          ...offerOrderIntent,
                          achDeposit: { ...offerOrderIntent.achDeposit, relationshipId: value },
                        }),
                      );
                    }}
                    testId={'ach-from-account'}
                  />
                </Col>
                <Col span={24}>
                  <MFormCurrencyInput
                    label='Transfer Amount'
                    value={form.values.transferAmount}
                    onChange={value => {
                      form.setFieldValue('transferAmount', value);
                      setTransferAmount(value);
                      dispatch(
                        addOfferOrderIntent({
                          ...offerOrderIntent,
                          achDeposit: { ...offerOrderIntent.achDeposit, amount: value },
                        }),
                      );
                    }}
                    error={form.errors.transferAmount || newDepositFormErrors?.transferAmount}
                    testId={'ach-transfer-amount'}
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      </Formik>
    );
  };

  useEffect(() => {
    if (!achRelationships && !loadingAchRelationships) dispatch(getACHRelationships(account?.accountId));
    dispatch(getConfigs());
  }, [dispatch]);

  useEffect(() => {
    setHasBankAccount(!_.isEmpty(achRelationships));
    const bankAccountOptions =
      (!loadingAchRelationships &&
        Array.isArray(achRelationships) &&
        achRelationships.map((ach: any) => {
          const { bankAccountType, nickName, bankAccount, id } = ach.tradingBlockACHRelationship;

          return { label: `${bankAccountType} (${nickName}) ${bankAccount}`, value: id };
        })) ||
      [];
    setBankAccountList(bankAccountOptions);
  }, [achRelationships, loadingAchRelationships]);

  useEffect(() => {
    if (!_.isEmpty(bankAccountList) && offerDetails) {
      const minValue = minimalDepositAmount.toString();

      setTransferAmount(minValue);
      setNewDepositFormInitialValues({
        bankAccount: bankAccountList[0]['value'],
        transferAmount: minValue,
      });

      dispatch(
        addOfferOrderIntent({
          ...offerOrderIntent,
          achDeposit: { amount: minValue, relationshipId: bankAccountList[0]['value'] },
        }),
      );
    }
  }, [bankAccountList, offerDetails]);

  useEffect(() => {
    isValid(
      hasBankAccount && hasEnoughMoneyWithDepositAmount() && Number(transferAmount) <= TransferConstant.MAX_AMOUNT,
    );
  }, [hasBankAccount, transferAmount, cashAvailable]);

  if (loadingAchRelationships) {
    return <Spinner />;
  }

  if (hasBankAccount) {
    return renderNewDepositForm();
  }

  return (
    <>
      <Card className={Styles.cardContainer}>
        <div className={Styles.linkBankContainer}>
          <div className={Styles.linkBankText}>
            <p data-testid={'confirm-order-ach-payment-text'}>
              <span>
                You do not have any linked bank accounts to successfully complete the order.
                <br />
                To continue, please link a bank account.
              </span>
            </p>
          </div>
          <div className={Styles.linkBankButton}>
            <MButton type='secondary' onClick={onLinkBankAccount} size='small'>
              Link Bank Account
            </MButton>
          </div>
        </div>
      </Card>

      <NewAchRelationshipModal
        open={isNewAchRealtionshipModalOpen}
        onClose={() => {
          setIsNewAchRealtionshipModalOpen(false);
        }}
        onExit={() => {
          setIsNewAchRealtionshipModalOpen(false);
        }}
        onFinish={() => {
          setIsNewAchRealtionshipModalOpen(false);
        }}
      />
    </>
  );
};
