import { css } from '@emotion/css';

export const Outer = css`
  position: relative;
`;

export const VimeoContainer = css`
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
`;

export const VimeoMedia = css`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const PlayLarge = css`
  width: 110px;
  height: 110px;
  margin-top: 20%;
  margin-left: -55px;
  position: absolute;
  top: 0px;
  left: 50%;
`;

export const PlayMedium = css`
  width: 108px;
  height: 108px;
  margin-top: 20%;
  margin-left: -54px;
  position: absolute;
  top: 0px;
  left: 50%;
`;

export const PlaySmall = css`
  width: 54px;
  height: 54px;
  margin-top: 22%;
  margin-left: -28px;
  position: absolute;
  top: 0px;
  left: 50%;
`;

export const Media = css`
  width: 100%;
  height: auto;
`;
