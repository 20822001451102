import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getOrdersPositionsRequested } from '../actions';
import { CustomHookBaseOptions } from '../models/common.model';
import { OrderPosition } from '../models/orders.models';

import { useAccountSelector } from './useAccountSelector';

interface UseOrderPositionsReturnType {
  orderPositionList: OrderPosition[];
  isOrderPositionListLoading: boolean;
  hasOrderPositionListError: boolean;
  orderPositionListError?: string;
  isOrderPositionListFetched: boolean;
}

export interface UseOrderPositionsOptions extends CustomHookBaseOptions {}

const EMPTY_ORDER_POSITIONS: OrderPosition[] = [];

export const useOrderPositions = (
  { skip }: UseOrderPositionsOptions = { skip: false },
): UseOrderPositionsReturnType => {
  const dispatch = useDispatch();

  const positionList: OrderPosition[] | undefined = useSelector(
    (state: any) => state.orders.getOrdersPositions?.data?.data,
  );
  const isPositionListLoading: boolean = useSelector((state: any) =>
    Boolean(state.orders?.getOrdersPositions?.__requested),
  );
  const hasPositionListFailed: boolean = useSelector((state: any) =>
    Boolean(state.orders?.getOrdersPositions?.__failed),
  );
  const positionsError: string | undefined = useSelector((state: any) => state.orders?.getOrdersPositions?.message);
  const isFetched: boolean = useSelector((state: any) => Boolean(state.orders.getOrdersPositions?.__isFetched));

  const { account } = useAccountSelector();

  useEffect(() => {
    if (!positionList && !isPositionListLoading && !skip && !hasPositionListFailed) {
      dispatch(getOrdersPositionsRequested(account?.accountId));
    }
  }, [positionList, isPositionListLoading, dispatch, account?.accountId]);

  return {
    orderPositionList: Array.isArray(positionList) ? positionList : EMPTY_ORDER_POSITIONS,
    isOrderPositionListLoading: isPositionListLoading,
    hasOrderPositionListError: hasPositionListFailed,
    orderPositionListError: positionsError,
    isOrderPositionListFetched: isFetched,
  };
};
