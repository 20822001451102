import { useRef, useState } from 'react';

import { cx } from '@emotion/css';
import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { depositFunds } from 'src/actions';
import { TransferConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import { MFormCurrencyInput, MFormTextField, MModal } from 'src/lib';
import { Display, FlexDirection, Images, Spacing } from 'src/styles';
import { getCurrencyFormatter } from 'src/utils';
import * as Yup from 'yup';

import * as Styles from '../../NewTransfer.styles';
import { NewTransferConfirmStep } from '../NewTransferConfirmStep/NewTransferConfirmStep';

const formValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .positive('Amount must be a positive number')
    .max(
      TransferConstant.MAX_AMOUNT,
      `The maximum transfer amount is ${getCurrencyFormatter().format(TransferConstant.MAX_AMOUNT)}`,
    ),
});

export interface NewDepositFormValues {
  amount: string;
}

export interface NewDepositModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBankAccount?: any;
}

const initialFormValues: NewDepositFormValues = {
  amount: '',
};

export const NewDepositModal = ({ isOpen, onClose, selectedBankAccount }: NewDepositModalProps) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<'deposit' | 'confirm'>('deposit');
  const [isFormValid, setIsFormvalid] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<NewDepositFormValues | null>(null);
  const formRef = useRef<FormikProps<NewDepositFormValues> | null>(null);

  const { account } = useAccountSelector();

  const renderDepositStep = () => {
    return (
      <Formik
        enableReinitialize
        validate={async values => {
          try {
            await formValidationSchema.validate(values);
            setIsFormvalid(true);

            return {};
          } catch (error: any) {
            setIsFormvalid(false);

            return { [error.path]: error.errors };
          }
        }}
        initialValues={formValues ?? initialFormValues}
        innerRef={form => (formRef.current = form)}
        onSubmit={values => {
          setFormValues(values);
        }}>
        {form => {
          return (
            <>
              <Col span={24}>
                <MFormTextField
                  label={
                    <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                      <span className={Styles.senderReceiverTopLabel}>From</span>
                      <span>
                        {selectedBankAccount.tradingBlockACHRelationship.bankAccountType}
                        <span
                          className={
                            Styles.nickname
                          }>{` (${selectedBankAccount.tradingBlockACHRelationship.nickName})`}</span>
                      </span>
                    </Col>
                  }
                  value={
                    <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                      <div>
                        {selectedBankAccount.tradingBlockACHRelationship.bankName}{' '}
                        <img
                          alt='bankLogo'
                          src={
                            selectedBankAccount.tradingBlockPlaidInstitution?.logo
                              ? `data:image/jpeg;base64,${selectedBankAccount?.tradingBlockPlaidInstitution?.logo}`
                              : Images.BankPlaceholder
                          }
                          className={Styles.bankLogo}
                        />
                      </div>
                      <div>{selectedBankAccount.tradingBlockACHRelationship?.bankAccount} </div>
                    </Col>
                  }
                />
              </Col>

              <Col span={24}>
                <MFormTextField
                  label={
                    <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                      <div className={Styles.senderReceiverTopLabel}>TO</div>
                      <div>My IPO</div>
                    </Col>
                  }
                  value={
                    <Col span={24} className={cx(Display.flex, FlexDirection.column)}>
                      <div>&nbsp;</div>
                      <div>{account?.accountNumber}</div>
                    </Col>
                  }
                />
              </Col>

              <Col span={24} className={Spacing.my12}>
                <MFormCurrencyInput
                  label='Deposit Amount'
                  value={form.values.amount}
                  onChange={value => form.setFieldValue('amount', value)}
                  error={form.errors.amount}
                  testId={'money-transfer-deposit'}
                />
              </Col>
            </>
          );
        }}
      </Formik>
    );
  };

  const renderConfirmStep = () => {
    return (
      <NewTransferConfirmStep
        type='deposit'
        accountNumber={account?.accountNumber}
        amount={formValues?.amount ?? ''}
        selectedBankAccount={selectedBankAccount}
      />
    );
  };

  const renderContent = () => {
    if (step === 'deposit') {
      return renderDepositStep();
    }

    return renderConfirmStep();
  };

  return (
    <MModal
      customWidth={750}
      customHeight={step === 'deposit' ? 300 : 450}
      visible={isOpen}
      title='New Deposit'
      primaryButtonText={step === 'deposit' ? 'Continue' : 'Transfer'}
      secondaryButtonText={step === 'confirm' ? 'Back' : undefined}
      tertiaryButtonText='Close'
      onPrimaryButtonClick={async () => {
        if (step === 'deposit') {
          formRef.current?.submitForm();
          setStep('confirm');
        }

        if (step === 'confirm' && formValues) {
          onClose();
          dispatch(
            depositFunds(selectedBankAccount.tradingBlockACHRelationship.accountId, {
              Amount: Number(formValues.amount),
              RelationshipId: selectedBankAccount.tradingBlockACHRelationship.id,
            }),
          );
        }
      }}
      onSecondaryButtonClick={() => {
        setStep('deposit');
      }}
      onTertiaryButtonClick={onClose}
      isDisabledPrimaryButton={!isFormValid}
      onClose={onClose}>
      {renderContent()}
    </MModal>
  );
};
