import { SecAndTafFeesDescription } from 'src/lib/SecAndTafFeesDescription/SecAndTafFeesDescription';

const ServiceFeeDisclaimer = (): JSX.Element => {
  return (
    <div>
      My IPO sign up is free and there are no associated membership fees to maintain an account. You are not charged any
      fees when you purchase offers and invest. For publicly traded securities there is a $10 trade fee charged if you
      enter trade online or 1% of trade value, plus $10 if you call our trade desk to enter trade, should you buy shares
      in the open market (outside of an offering) or sell shares in the future, plus we pass along regulatory fees such
      as SEC and TAF fees as part of the “Commission/Service Fees”. The SEC and TAF fees or “Service Fees” are very
      minimal charges.
      <br />
      <br />
      For non-public securities there may be no liquidity, limited liquidity and redemption fees may apply if redeemed
      before maturity as outlined in each offering prospectus.
      <br />
      <br />
      <SecAndTafFeesDescription />
    </div>
  );
};

export default ServiceFeeDisclaimer;
