import { css } from '@emotion/css';
import { Font, Spacing } from 'src/styles';

export const container = css`
  span {
    ${Font.lg}
    ${Spacing.mt12}
  }

  img {
    height: 16px;
    width: 16px;
    ${Spacing.mr8}
  }
`;
