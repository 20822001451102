import {
  AccountHolderAnnualIncomeDto,
  AccountHolderLiquidNetWorthDto,
  AccountHolderTaxBracketDto,
  AccountHolderTotalNetWorthDto,
} from 'src/dtos';
import {
  AccountHolderAnnualIncomeLabel,
  AccountHolderLiquidNetWorthLabel,
  AccountHolderTaxBracketLabel,
  AccountHolderTotalNetWorthLabel,
} from 'src/models';

export const ANNUAL_INCOME_OPTION_LIST = [
  {
    value: AccountHolderAnnualIncomeDto.LessThan25K,
    label: AccountHolderAnnualIncomeLabel.LessThan25K,
  },
  {
    value: AccountHolderAnnualIncomeDto.Between25KAnd50K,
    label: AccountHolderAnnualIncomeLabel.Between25KAnd50K,
  },
  {
    value: AccountHolderAnnualIncomeDto.Between50KAnd100K,
    label: AccountHolderAnnualIncomeLabel.Between50KAnd100K,
  },
  {
    value: AccountHolderAnnualIncomeDto.Between100KAnd200K,
    label: AccountHolderAnnualIncomeLabel.Between100KAnd200K,
  },
  {
    value: AccountHolderAnnualIncomeDto.Between200KAnd300K,
    label: AccountHolderAnnualIncomeLabel.Between200KAnd300K,
  },
  {
    value: AccountHolderAnnualIncomeDto.Between300KAnd500K,
    label: AccountHolderAnnualIncomeLabel.Between300KAnd500K,
  },
  {
    value: AccountHolderAnnualIncomeDto.Between500KAnd1Point2M,
    label: AccountHolderAnnualIncomeLabel.Between500KAnd1Point2M,
  },
  {
    value: AccountHolderAnnualIncomeDto.MoreThan1Point2M,
    label: AccountHolderAnnualIncomeLabel.MoreThan1Point2M,
  },
];

export const TOTAL_NET_WORTH_OPTION_LIST = [
  {
    value: AccountHolderTotalNetWorthDto.LessThan50K,
    label: AccountHolderTotalNetWorthLabel.LessThan50K,
  },
  {
    value: AccountHolderTotalNetWorthDto.Between50KAnd100K,
    label: AccountHolderTotalNetWorthLabel.Between50KAnd100K,
  },
  {
    value: AccountHolderTotalNetWorthDto.Between100KAnd200K,
    label: AccountHolderTotalNetWorthLabel.Between100KAnd200K,
  },
  {
    value: AccountHolderTotalNetWorthDto.Between200KAnd500K,
    label: AccountHolderTotalNetWorthLabel.Between200KAnd500K,
  },
  {
    value: AccountHolderTotalNetWorthDto.Between500KAnd1M,
    label: AccountHolderTotalNetWorthLabel.Between500KAnd1M,
  },
  {
    value: AccountHolderTotalNetWorthDto.Between1MAnd5M,
    label: AccountHolderTotalNetWorthLabel.Between1MAnd5M,
  },
  {
    value: AccountHolderTotalNetWorthDto.MoreThan5M,
    label: AccountHolderTotalNetWorthLabel.MoreThan5M,
  },
];

export const LIQUID_NET_WORTH_OPTION_LIST = [
  {
    value: AccountHolderLiquidNetWorthDto.LessThan50K,
    label: AccountHolderLiquidNetWorthLabel.LessThan50K,
  },
  {
    value: AccountHolderLiquidNetWorthDto.Between50KAnd100K,
    label: AccountHolderLiquidNetWorthLabel.Between50KAnd100K,
  },
  {
    value: AccountHolderLiquidNetWorthDto.Between100KAnd200K,
    label: AccountHolderLiquidNetWorthLabel.Between100KAnd200K,
  },
  {
    value: AccountHolderLiquidNetWorthDto.Between200KAnd500K,
    label: AccountHolderLiquidNetWorthLabel.Between200KAnd500K,
  },
  {
    value: AccountHolderLiquidNetWorthDto.Between500KAnd1M,
    label: AccountHolderLiquidNetWorthLabel.Between500KAnd1M,
  },
  {
    value: AccountHolderLiquidNetWorthDto.Between1MAnd5M,
    label: AccountHolderLiquidNetWorthLabel.Between1MAnd5M,
  },
  {
    value: AccountHolderLiquidNetWorthDto.MoreThan5M,
    label: AccountHolderLiquidNetWorthLabel.MoreThan5M,
  },
];

export const TAX_BRACKET_OPTION_LIST = [
  {
    value: AccountHolderTaxBracketDto.Bottom,
    label: AccountHolderTaxBracketLabel.Bottom,
  },
  {
    value: AccountHolderTaxBracketDto.Middle,
    label: AccountHolderTaxBracketLabel.Middle,
  },
  {
    value: AccountHolderTaxBracketDto.Top,
    label: AccountHolderTaxBracketLabel.Top,
  },
];
