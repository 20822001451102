import * as Yup from 'yup';

export const validationMessages = {
  passwordRequired: 'Password is required.',
  passwordMinCharacters: 'Password should be 8 characters at minimum.',
  passwordMaxCharacters: 'Password should be 24 characters at most.',
  passwordMustContainUpperCase: 'Password should contain one uppercase letter.',
  passwordMustContainLowercase: 'Password should contain one lowercase letter.',
  passwordMustContainNumber: 'Password should contain at least one number.',
  passwordMustContainSpecialCharacter: 'Password should contain at least one special character.',
  passwordMustNotContainWordPassword: `Password should not contain the word 'password'`,
  passwordMustNotContainFirstnameAndLastname: 'Password must not contain First name and Last name',
  confirmPasswordRequired: 'Confirm Password is required',
  passwordsDoNotMatch: 'Passwords do not match.',
};

export const newPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(validationMessages.passwordRequired)
    .min(8, validationMessages.passwordMinCharacters)
    .max(24, validationMessages.passwordMaxCharacters)
    .matches(/[A-Z]/, validationMessages.passwordMustContainUpperCase)
    .matches(/[a-z]/, validationMessages.passwordMustContainLowercase)
    .matches(/[\d]/, validationMessages.passwordMustContainNumber)
    .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, validationMessages.passwordMustContainSpecialCharacter)
    .test(
      'compare',
      validationMessages.passwordMustNotContainWordPassword,
      function (password: string | any, schema: string | any) {
        if (schema !== undefined && schema !== null) {
          return !password?.toLowerCase().includes('password');
        } else return false;
      },
    )
    .test(
      'compare',
      validationMessages.passwordMustNotContainFirstnameAndLastname,
      function (password: string | any, schema: string | any) {
        if (schema !== undefined && schema !== null) {
          return (
            !password?.toLowerCase().includes(schema?.parent?.firstName?.toLowerCase()) &&
            !password?.toLowerCase().includes(schema?.parent?.lastName?.toLowerCase())
          );
        } else return false;
      },
    ),

  confirmPassword: Yup.string()
    .required(validationMessages.confirmPasswordRequired)
    .test(validationMessages.passwordsDoNotMatch, validationMessages.passwordsDoNotMatch, function () {
      return this.parent.password === this.parent.confirmPassword;
    }),
});
