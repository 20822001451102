import { css } from '@emotion/css';

import { Color } from '../../styles';

export const menuName = css`
  font-size: 1.75rem;
  line-height: 2rem;
  color: ${Color.BRAND.BRAND6};
  margin-top: 4rem;
  margin-left: 4.625rem;
`;

export const formCard = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem rgba(2, 85, 163, 0.15);
  padding: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const noIndicationText = css`
  line-height: 22px;
  text-align: center;
  color: ${Color.GRAYSCALE.GRAY9};
  margin-top: 16px;
`;

export const noIndicationsContainer = css`
  margin-top: 186px;
  text-align: center;
`;
