import * as Url from '../constants/url';
import {
  CreateOfferOrderDto,
  GetOfferOrderParams,
  GetOfferOrdersQueryParams,
  OfferOrderDto,
  OfferOrderDetailsDto,
  GetOfferOrdersResponseDto,
  UpdateOfferOrderStatusDto,
  UpdateOfferOrderDto,
  DeleteOfferOrderDto,
  CreateOfferOrderCardPaymentIntentDto,
  CreateOfferOrderCardPaymentIntentResponseDto,
  UpdateOfferOrderCardPaymentIntentDto,
  UpdateOfferOrderCardPaymentIntentResponseDto,
} from '../dtos/offerOrders.dtos';
import { replacePlaceholders } from '../sagas/utils';
import { httpRequest } from '../utils/httpRequest';

export interface CreateOfferOrderArgs extends CreateOfferOrderDto {
  authToken: string;
}

export interface GetManyOfferOrdersArgs {
  authToken: string;
  queryParams?: GetOfferOrdersQueryParams;
}

export interface GetOneOfferOrderArgs {
  params: GetOfferOrderParams;
  authToken: string;
}

export interface UpdateOfferOrderStatusArgs {
  dto: UpdateOfferOrderStatusDto;
  authToken: string;
}

export interface UpdateOfferOrderArgs extends UpdateOfferOrderDto {
  authToken: string;
}

export interface DeleteOfferOrderArgs extends DeleteOfferOrderDto {
  authToken: string;
}

export interface CreateOfferOrderCardPaymentIntentArgs extends CreateOfferOrderCardPaymentIntentDto {
  authToken: string;
}

export interface UpdateOfferOrderCardPaymentIntentArgs extends UpdateOfferOrderCardPaymentIntentDto {
  authToken: string;
}

export class OfferOrdersApi {
  async create({ params, body, authToken }: CreateOfferOrderArgs): Promise<OfferOrderDetailsDto> {
    const { accountId } = params;
    const url = replacePlaceholders(Url.OFFER_ORDERS_BY_ACCOUNT_URL, { accountId });

    return httpRequest<OfferOrderDetailsDto>({
      method: 'POST',
      url,
      body,
      authToken,
    });
  }

  async getMany({ authToken, queryParams }: GetManyOfferOrdersArgs): Promise<GetOfferOrdersResponseDto> {
    return httpRequest<GetOfferOrdersResponseDto>({
      method: 'GET',
      url: Url.OFFER_ORDERS_BASE_URL,
      authToken,
      queryParams,
    });
  }

  async getOne({ params, authToken }: GetOneOfferOrderArgs): Promise<OfferOrderDetailsDto> {
    const { id } = params;

    const url = replacePlaceholders(Url.OFFER_ORDERS_BY_ID_URL, { id });

    return httpRequest<OfferOrderDetailsDto>({
      method: 'GET',
      url,
      authToken,
    });
  }

  async updateStatus({ dto, authToken }: UpdateOfferOrderStatusArgs): Promise<OfferOrderDto> {
    return httpRequest<OfferOrderDetailsDto>({
      method: 'PUT',
      url: Url.OFFER_ORDERS_STATUS_URL,
      body: dto,
      authToken,
    });
  }

  async update({ params, body, authToken }: UpdateOfferOrderArgs): Promise<OfferOrderDetailsDto> {
    const url = replacePlaceholders(Url.UPDATE_OFFER_ORDER, { ...params });

    return httpRequest<OfferOrderDetailsDto>({
      method: 'PATCH',
      url,
      body,
      authToken,
    });
  }

  async delete({ params, authToken }: DeleteOfferOrderArgs): Promise<void> {
    const url = replacePlaceholders(Url.DELETE_OFFER_ORDER, { ...params });

    return httpRequest<void>({
      method: 'DELETE',
      url,
      authToken,
    });
  }

  async createCardPaymentIntent({
    body,
    authToken,
  }: CreateOfferOrderCardPaymentIntentArgs): Promise<CreateOfferOrderCardPaymentIntentResponseDto> {
    return httpRequest<CreateOfferOrderCardPaymentIntentResponseDto>({
      method: 'POST',
      url: Url.CREATE_OFFER_ORDER_CARD_PAYMENT_INTENT_URL,
      body,
      authToken,
    });
  }

  async updateCardPaymentIntent({
    params,
    body,
    authToken,
  }: UpdateOfferOrderCardPaymentIntentArgs): Promise<UpdateOfferOrderCardPaymentIntentResponseDto> {
    const url = replacePlaceholders(Url.UPDATE_OFFER_ORDER_CARD_PAYMENT_INTENT_URL, { ...params });

    return httpRequest<UpdateOfferOrderCardPaymentIntentResponseDto>({
      method: 'PUT',
      url,
      body,
      authToken,
    });
  }
}
