import React from 'react';

import { NumericFormat } from 'react-number-format';

interface NumberFieldProps {
  value?: number;
  fixedDecimals?: number;
  className?: string;
}

const NumberField = ({ value, fixedDecimals = 0, className }: NumberFieldProps) => {
  return (
    <NumericFormat
      value={value?.toFixed(fixedDecimals) || 0}
      thousandSeparator
      displayType={'text'}
      decimalScale={fixedDecimals}
      className={className}
    />
  );
};

export default NumberField;
