import { type ChangeEvent } from 'react';

import { Col, Input, Row } from 'antd';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import { MInputProps } from './MInput';
import * as Styles from './MInput.styles';

const { Password } = Input;

export interface MInputPasswordProps extends MInputProps {}

export const MInputPassword = ({
  placeholder,
  value,
  defaultValue,
  error,
  onChange,
  disabled,
  addOnAfter,
  addOnBefore,
  size = 'middle',
  weight = 'medium',
  className = ' ',
  name,
  testId,
}: MInputPasswordProps) => {
  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Row className={Styles.inputContainer}>
      <Col span={24}>
        <Password
          size={size}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={_onChange}
          addonBefore={addOnBefore}
          addonAfter={addOnAfter}
          className={Styles.input(weight).concat(' ').concat(className).trimEnd()}
          disabled={disabled}
          name={name}
          data-testid={testId}
        />
      </Col>
      <Col span={24} data-testid={testId + '-' + 'error'}>
        <MFormItemError value={error} />
      </Col>
    </Row>
  );
};
