import { AccountBalanceDetailsDto } from '../dtos/accountBalances.dtos';
import { AccountBalanceDetails } from '../models/accountBalances.models';

export const mapAccountBalanceDetailsDtoToModel = (dto: AccountBalanceDetailsDto): AccountBalanceDetails => ({
  ...dto,
  cashAvailable: Number(
    (dto.availableFunds - dto.pendingOfferOrdersTotal + dto.pendingExternalOfferOrdersTotal).toFixed(2),
  ),
  cashAvailableForWithdrawal: Number(
    (dto.availableFundsForWithdrawal - dto.pendingOfferOrdersTotal + dto.pendingExternalOfferOrdersTotal).toFixed(2),
  ),
});
