import { css } from '@emotion/css';

import { AlignItems, Color, Display, Font, FontWeight, Spacing } from '../../../../styles';

export const cardWrapper = css`
  background: ${Color.GRAYSCALE.GRAY1};
  box-shadow: 0rem 0.125rem 0.25rem rgba(2, 85, 163, 0.25);
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  &:hover {
    box-shadow: 0 4px 12px 0 rgba(2, 85, 163, 0.25);
  }
  .ant-card-cover {
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  .ant-card-body {
    padding: 1rem;
  }
  .ant-card-meta-title {
    white-space: normal;
    font-size: 1rem;
  }
`;

export const offerName = css`
  ${Font.h1}
  ${FontWeight.semibold}
  color: ${Color.GRAYSCALE.GRAY10};
  word-break: break-all;
  min-height: 4rem;
`;

export const offerSubDetails = css`
  color: ${Color.GRAYSCALE.GRAY7};
`;

export const offerSectionContainer = css`
  ${Spacing.mt24}
`;

export const minimalInvestment = css`
  ${Font.lg}
  ${FontWeight.bold}
  color: ${Color.ORANGE.ORANGE6};
`;

export const totalCapitalRaised = css`
  ${Font.lg}
  ${FontWeight.bold}
  color: ${Color.BRAND.BRAND6};
`;

export const offerImage = css`
  position: relative;
  aspect-ratio: 16/9;
`;

export const indicationLogo = {
  fontSize: '0.8125rem',
  color: Color.GREEN.GREEN8,
  marginRight: '0.3125rem',
  fontWeight: 400,
};

export const offerTags = css`
  left: 1rem;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

// Note: duplicateed styles
export const accreditedInvestorsOnly = css`
  ${Display.flex}
  ${AlignItems.center}
`;

// Note: duplicateed styles
export const accreditedInvestorsOnlyText = css`
  ${Spacing.mr4}
`;
