import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import {
  offerWatchesCreateSucceeded,
  offerWatchesDeleteSucceeded,
  offerWatchesGetListRequested,
  offerWatchesGetListSucceeded,
  toastMessagesAdd,
} from '../actions';
import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, SeverityEnum, TReduxAction } from '../typings/commonTypes';

import { HttpClient, replacePlaceholders, safeSaga } from './utils';

export function* offerWatchesGetList() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const response: ResponseGenerator<any> = yield call(HttpClient, 'GET', Url.OFFER_WATCHES, undefined, authToken);

  yield put(offerWatchesGetListSucceeded({ data: response.data }));
}

export function* offerWatchesCreateOfferWatch(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state?.auth?.data || { authToken: undefined });

  const response: ResponseGenerator<any> = yield call(HttpClient, 'POST', Url.OFFER_WATCHES, action.payload, authToken);

  yield put(offerWatchesCreateSucceeded(response.data));
  yield put(offerWatchesGetListRequested());
}

export function* offerWatchesDeleteOfferWatch(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  const { offerWatchId } = action?.payload;

  const url = replacePlaceholders(Url.DELETE_OFFER_WATCH, {
    offerWatchId,
  });
  yield call(HttpClient, 'DELETE', url, undefined, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Indication of Interest was successfully canceled',
    }),
  );

  yield put(offerWatchesDeleteSucceeded());
  yield put(offerWatchesGetListRequested());
}

/**
 * OfferWatches sagas
 */
export default function* offerWatchesSagas() {
  yield takeEvery(
    State.actionRequested(Type.OFFER_WATCHES_GET_LIST),
    safeSaga(offerWatchesGetList, Type.OFFER_WATCHES_GET_LIST),
  );
  yield takeEvery(
    State.actionRequested(Type.OFFER_WATCHES_CREATE),
    safeSaga(offerWatchesCreateOfferWatch, Type.OFFER_WATCHES_CREATE),
  );
  yield takeEvery(
    State.actionRequested(Type.OFFER_WATCHES_DELETE),
    safeSaga(offerWatchesDeleteOfferWatch, Type.OFFER_WATCHES_DELETE),
  );
}
