import React from 'react';

import { Link, To } from 'react-router-dom';

import * as Styles from './BacklinkButton.styles';

export type BacklinkButtonProps = {
  to: To;
  label: string;
  state?: any;
  testId?: string;
};

export const BacklinkButton = ({ to, label, state, testId }: BacklinkButtonProps) => {
  return (
    <div>
      <Link className={Styles.bcLink} to={to} state={state}>
        <i className={`ri-arrow-left-s-line ${Styles.backArrow}`} />
        <div className={Styles.label} data-testid={testId}>
          {label}
        </div>
      </Link>
    </div>
  );
};
