import { css } from '@emotion/css';

import {
  Display,
  FlexDirection,
  Images,
  ScreenBreakpoint,
  Spacing,
  TextAlign,
  Font,
  FontWeight,
  BaseStyle,
  JustifyContent,
  Color,
  BorderRadius,
  AlignItems,
} from '../../styles';

export const missionSection = css`
  ${Spacing.py224};
  ${Spacing.px16};
  ${Display.flex};
  ${JustifyContent.center};
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Images.AboutImage});
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: ${ScreenBreakpoint.desktop.max}) {
    background-position-y: center;
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Images.AboutImageMobile});
    ${Spacing.p0};
    ${TextAlign.center}
    ${AlignItems.center}
    min-height: 300px;
    background-position: center;
  }
`;

export const sectionContainer = css`
  max-width: 1200px;
`;

export const missionHeader = css`
  ${Spacing.mb20};
  ${Font.h4};
  ${FontWeight.bold};
`;

export const missionSubtext = css`
  ${Font.lg}
  ${FontWeight.normal};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${TextAlign.center};
  }
`;

export const missionContainer = css`
  color: ${Color.GRAYSCALE.GRAY1};
  ${Spacing.ml128};
  max-width: 524px;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml0};
    ${Display.flex};
    ${FlexDirection.column};
    ${AlignItems.center};
    color: ${Color.GRAYSCALE.GRAY1};
    max-width: initial;
  }
  .ant-space-item {
    max-width: 288px;
  }
`;

export const aboutTextSectionContainer = css`
  ${Display.flex};
  ${Spacing.px0};
  ${Spacing.py64};
  max-width: 1200px;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column}
    ${Spacing.px16};
    ${Spacing.py20};
  }
`;

export const aboutTextSection = css`
  ${BaseStyle.level};
  width: 100%;
`;

export const aboutTextContainer = css`
  ${Spacing.py0};
  ${Spacing.px48};
  ${Font.md};
  flex: 1 1;
  &:first-child {
    border-right: 1px solid #cadcec;
  }
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.p0};
    &:first-child {
      border: none;
    }
  }
`;

export const aboutTextContainerTitle = css`
  ${Font.h1};
  ${FontWeight.bold};
  ${Spacing.mb32};
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mb16}
  }
`;

export const signupSection = css`
  ${Spacing.px0};
  ${Spacing.py48};
  ${JustifyContent.center}
  background-color: ${Color.BRAND.BRAND2};
  width: 100%;
  -ms-flex-pack: center;
`;

export const signupContainer = css`
  ${BaseStyle.level}
  ${JustifyContent.spaceAround}
  -ms-flex-pack: distribute;
  -ms-flex-align: center;
  max-width: 850px;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.column}
  }
`;

export const signupTextHeader = css`
  ${Font.h4};
  ${FontWeight.bold};
  line-height: normal;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.mb16}
  }
`;

export const signupBtn = css`
  ${BaseStyle.level};
  ${FontWeight.bold};
  ${Font.h2};
  background-color: ${Color.BRAND.BRAND6};
  color: ${Color.GRAYSCALE.GRAY1};
  border: 0.0625rem solid ${Color.BRAND.BRAND6};
  ${BorderRadius.lg};
  width: 17.5rem;
  height: 3.125rem;
  text-decoration: none;
  @media (min-width: ${ScreenBreakpoint.mobile.min}) {
    width: 16.25rem;
  }
  &:hover {
    background-color: ${Color.GRAYSCALE.GRAY1};
    color: #${Color.BRAND.BRAND6};
  }
`;
