import { TransferDetailsModal, OrderDetailsModal, OfferOrderDetailsModal } from 'src/lib';
import { OfferOrder } from 'src/models/offer-orders.model';
import { Order } from 'src/models/orders.models';
import { Transfer } from 'src/models/transfers.models';

import { isOrder, isTransfer } from './history.utils';

interface HistoryViewDetailsProps {
  value: Order | OfferOrder | Transfer;
  isOpen: boolean;
  onClose: () => void;
}

export const HistoryViewDetailsModal = ({ value, isOpen, onClose }: HistoryViewDetailsProps) => {
  if (isOrder(value)) {
    return <OrderDetailsModal value={value} isOpen={isOpen} onClose={onClose} />;
  }

  if (isTransfer(value)) {
    return <TransferDetailsModal value={value} isOpen={isOpen} onClose={onClose} />;
  }

  return <OfferOrderDetailsModal value={value} isOpen={isOpen} onClose={onClose} />;
};
