import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { UserDto, SignUpStatusDto } from '../../dtos/users.dtos';
import { OnboardStep } from '../../models/app.models';

export const OnboardedUserGuard = () => {
  const authenticatedUser: UserDto = useSelector((state: any) => state.user.authenticated.data);
  const onboardStep: OnboardStep | null = useSelector((state: any) => state.app.onboardStep);

  if (onboardStep === OnboardStep.Address) {
    return <Navigate to='/address' />;
  }

  if (onboardStep === OnboardStep.MfaConfiguration) {
    return <Navigate to='/security' />;
  }

  if (onboardStep === OnboardStep.MfaConfirm) {
    return <Navigate to='/security-confirmation' />;
  }

  if (authenticatedUser.signUpStatus === SignUpStatusDto.AddressStep) {
    return <Navigate to='/address' />;
  }

  if (authenticatedUser.signUpStatus === SignUpStatusDto.SecurityStep) {
    return <Navigate to='/security' />;
  }

  return <Outlet />;
};
