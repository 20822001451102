import * as Yup from 'yup';

import { TradingBlockOrderTypeEnum } from '../../../../../../dtos/orders.dtos';

export const actionFormValidation = Yup.object().shape({
  shares: Yup.number()
    .integer('shares must be a positive integer.')
    .typeError('shares must be a positive integer.')
    .required('shares is required.')
    .test('Is Positive?', 'shares must be a positive integer.', value => (value as any) > 0),
  limitPrice: Yup.number().when('orderType', {
    is: (value: TradingBlockOrderTypeEnum) =>
      [TradingBlockOrderTypeEnum.Limit, TradingBlockOrderTypeEnum.Stop_Limit].includes(value),
    then: Yup.number()
      .typeError('limit price must be a positive number.')
      .required('limit price is required.')
      .test('Is Positive?', 'limit price must be a positive number.', value => (value as any) > 0),
  }),
  stopPrice: Yup.number().when('orderType', {
    is: (value: TradingBlockOrderTypeEnum) =>
      [TradingBlockOrderTypeEnum.Stop_Market, TradingBlockOrderTypeEnum.Stop_Limit].includes(value),
    then: Yup.number()
      .typeError('stop price must be a positive number.')
      .required('stop price is required.')
      .test('Is Positive?', 'stop price must be a positive number.', value => (value as any) > 0),
  }),
});
