import { css } from '@emotion/css';
import {
  Color,
  ScreenBreakpoint,
  Font,
  FontWeight,
  BaseStyle,
  SpaceSize,
  Spacing,
  BorderRadius,
  ColorConstant,
} from 'src/styles';

export const tableHeader = css`
  .ant-table-thead > tr > th {
    background: ${Color.BRAND.BRAND6};
    height: ${SpaceSize.is40};
    ${FontWeight.semibold}
    ${Font.h1}
    color: ${Color.GRAYSCALE.GRAY1};
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    ${BorderRadius.topLeft.xs}
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    ${BorderRadius.topRight.xs}
  }
  .ant-table-container table > tbody > tr {
    ${Font.lg}
    height: ${SpaceSize.is64};
    color: ${Color.GRAYSCALE.GRAY8};
    background: ${Color.GRAYSCALE.GRAY1};
    box-shadow: inset 0px -1px 0px ${Color.LIST_ROW_BORDER_GREY};
    ${BaseStyle.clickable}
  }

  .ant-empty-normal {
    cursor: default;
    ${Spacing.my32}
  }

  .ant-table-container table > tbody > tr:nth-child(2n) {
    background: ${Color.GRAYSCALE.GRAY2};
  }

  .ant-table-container table > tbody > tr > td:first-child {
    color: ${Color.BRAND.BRAND6};
  }

  .ant-table-container table > tbody > tr > td:nth-child(2) {
    ${FontWeight.normal}
    ${Font.lg}
    color: ${Color.GRAYSCALE.GRAY7};
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    ${BaseStyle.shadowless}
  }

  .ant-table-body {
    overflow: auto !important;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    .ant-table-container table {
      min-width: 1024px;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      ${BorderRadius.none}
    }
  }

  .ant-table-content {
    @media (max-width: ${ScreenBreakpoint.laptop.max}) {
      overflow: scroll;
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 12px 8px;
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child,
  .ant-table tfoot > tr > th:last-child,
  .ant-table tfoot > tr > td:last-child {
    padding-right: 32px;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child,
  .ant-table tfoot > tr > th:first-child,
  .ant-table tfoot > tr > td:first-child {
    padding-left: 32px;
  }

  .ant-table-tbody > tr > td:first-child {
    ${Font.lg}
    color: ${ColorConstant.GRAY8} !important;
  }
`;

export const notSelectableRow = css`
  .ant-table-container table > tbody > tr {
    cursor: default;
  }
`;
