import React from 'react';

import { useAccountSelector } from 'src/hooks';

import { BacklinkButton } from '../../../lib/Miscellaneous/BacklinkButton/BacklinkButton';
import Card from '../../../lib/Miscellaneous/Card';
import { Images } from '../../../styles';

import * as Styles from './ArbitrationClauses.styles';
import { arbitrationClausesLinks } from './constants';

export const ArbitrationClauses = () => {
  const { account } = useAccountSelector();

  return (
    <div className={Styles.arbitrationClausesContainer}>
      <div className={Styles.top}>
        <BacklinkButton to={`/accounts/${account?.id}/sign-application`} label='Return to My Account' />
        <img src={Images.logo} alt='My IPO' className={Styles.logo} />
      </div>
      <div className={Styles.container}>
        <div className={Styles.mainText}>Arbitration Clauses</div>
        <Card>
          <div className={Styles.certificationMain}>
            {arbitrationClausesLinks.map(item => (
              <p key={`${item.label}_key`} className={Styles.certificationLink}>
                <a className={Styles.linkStyle} href={item.url} target='_blank' rel='noreferrer'>
                  {item.label}
                </a>
              </p>
            ))}
            <p className={Styles.arbitrationThanks}>
              Apex Arbitration clause found on page 3 and 4 of Apex Clearing Corporation Customer Agreement & Privacy
              Policy and page 1 of Cambria Capital dba My IPO Customer Agreement & Privacy Policy.
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ArbitrationClauses;
