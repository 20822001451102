import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { OfferDetails, OfferOrder } from 'src/models';

export interface OfferOrderDetailsDisclaimerProps {
  value: OfferOrder;
  offer?: OfferDetails;
}

export const OfferOrderDetailsDisclaimer = ({ value, offer }: OfferOrderDetailsDisclaimerProps) => {
  const renderExternalDisclaimer = () => {
    if (value.status.isPendingFunds) {
      return (
        <>
          Your order will remain pending until you fund the Offering Escrow Account and the investment is accepted on
          the closing date referenced above. This Offering requires you to send funds directly from an existing bank
          account to the Offering Escrow Account. For this offering type, if you have funds in your My IPO account they
          cannot be transferred to the Offering Escrow Account. Please be sure to send funds as soon as possible from an
          existing bank account to the Offering Escrow Account for the amount indicated below to complete your
          investment.
          <br />
          <br />
          Due to demand and{' '}
          <Link target='_blank' to='/other-factors'>
            other factors
          </Link>{' '}
          it is possible your investment may not be accepted.
        </>
      );
    }

    if (value.status.isPendingOfferClose) {
      return (
        <>
          The Offering Escrow account has received your funds for your pending order of &quot;{value.offerName}&quot;
          shares. Your order will remain pending until the investment is accepted on the closing date referenced above.
          Due to demand and{' '}
          <Link target='_blank' to='/other-factors'>
            other factors
          </Link>{' '}
          it is possible your investment may not be accepted.
        </>
      );
    }

    if (value.status.isPendingAction) {
      return (
        <>
          Action is required on your pending order. Please contact us as soon as possible at{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a> to resolve the issue(s).
        </>
      );
    }

    if (value.status.isPendingFirmCancellation) {
      return (
        <>
          My IPO has received your request to cancel order. The cancellation will be processed and your funds will be
          returned if your order is eligible to be cancelled. If you have any questions please contact My IPO at{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>.
        </>
      );
    }

    if (value.status.isCancelled && isEmpty(value.refunds)) {
      return (
        <>
          My IPO has cancelled your order. If you sent funds directly from an existing bank account to the Offering
          Escrow Account then your funds will be returned. If you have any questions on why your order was cancelled
          please contact My IPO at <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>.
        </>
      );
    }

    if (value.status.isApproved) {
      return (
        <>
          Your placed offer order has been approved! Once the Offering is closed and shares are issued by the Transfer
          Agent of the Issuer then your order will be completed.
        </>
      );
    }

    if (value.status.isRejected) {
      return (
        <>
          If you need more information on why your order was rejected, please contact My IPO at{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>
        </>
      );
    }

    if (value.status.isCompleted) {
      return (
        <>
          {offer?.externalDetails?.completedOfferOrderDisclaimer ?? (
            <>
              Your Offer Order has been completed. Since this Offering type required you to send funds directly from
              {value.paymentType.isCard ? ' your credit card' : ' an existing bank account'} to the Offering Escrow
              Account, the shares will be held in electronic from at the Transfer Agent of the Issuer.
              <br />
              <br />
              You should receive a statement from the transfer agent via email showing your holdings in the company. You
              can contact the transfer agent to try and transfer these shares to another brokerage account or continue
              to hold shares with transfer agent.
            </>
          )}
        </>
      );
    }

    return null;
  };

  const renderDisclaimer = () => {
    if (value.status.isPendingFunds) {
      return (
        <>
          We have received your order. The order will remain pending until your funds are received. If you have not
          already initiated a transfer of funds to your My IPO account, please deposit funds promptly.
        </>
      );
    }

    if (value.status.isPendingOfferClose) {
      return (
        <>
          Funds have been received for your order. Your order will remain pending until the investment is accepted on
          the closing date referenced above. Due to demand and{' '}
          <Link target='_blank' to='/other-factors'>
            other factors
          </Link>{' '}
          it is possible your investment may not be accepted.
        </>
      );
    }

    if (value.status.isPendingAction) {
      return (
        <>
          Action is required on your pending order. Please contact us as soon as possible at{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a> to resolve the issue(s).
        </>
      );
    }

    if (value.status.isPendingFirmCancellation) {
      return (
        <>
          My IPO has received your request to cancel order. The cancellation will be processed if your order is eligible
          to be cancelled. If you have any questions please contact My IPO at{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>.
        </>
      );
    }

    if (value.status.isCancelled && isEmpty(value.refunds)) {
      return (
        <>
          My IPO has cancelled your order. If you have any questions on why your order was cancelled please contact My
          IPO at <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>.
        </>
      );
    }

    if (value.status.isApproved) {
      return (
        <>
          Your placed offer order has been approved! Once the Offering is closed and shares are issued by the Transfer
          Agent of the Issuer then your order will be completed.
        </>
      );
    }

    if (value.status.isRejected) {
      return (
        <>
          If you need more information on why your order was rejected, please contact My IPO at{' '}
          <a href='mailto:clientservices@myipo.com'>clientservices@myipo.com</a>
        </>
      );
    }

    if (value.status.isCompleted) {
      return <>Your Offer Order has been completed and shares are held in your My IPO account.</>;
    }

    return null;
  };

  if (value.isExternal) {
    return renderExternalDisclaimer();
  }

  return renderDisclaimer();
};
