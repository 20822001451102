import { css } from '@emotion/css';

import { ColorConstant, Font, ScreenBreakpoint, Spacing } from '../../../styles';

export const mainContainer = css`
  min-height: 100vh;
  ${Spacing.ml64}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml0}
    ${Spacing.p16}
  }
`;

export const title = ({ hasBacklink }: { hasBacklink: boolean }) => css`
  ${Font.h3}
  ${hasBacklink ? Spacing.mt20 : Spacing.mt64}
  ${Spacing.ml64}
  color: ${ColorConstant.BRAND6};
  background: ${ColorConstant.GRAY1};

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml16}
  }
`;

export const backlinkButtonContainer = css`
  ${Spacing.mt8}
  ${Spacing.ml64}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.ml16}
  }
`;
